import { FC, ReactNode, createContext, useContext } from "react";

type CardAuthorizationFlexpaneContextState = {
  authorizationId: string;
  onClose: () => void;
};

const CardAuthorizationFlexpaneContext = createContext<CardAuthorizationFlexpaneContextState>(
  {} as CardAuthorizationFlexpaneContextState
);

type Props = {
  children: ReactNode;
  authorizationId: string;
  onClose: () => void;
};

const CardAuthorizationFlexpaneProvider: FC<Props> = ({ children, authorizationId, onClose }) => {
  return (
    <CardAuthorizationFlexpaneContext.Provider
      value={{
        authorizationId: authorizationId,
        onClose: onClose,
      }}
    >
      {children}
    </CardAuthorizationFlexpaneContext.Provider>
  );
};

export default CardAuthorizationFlexpaneProvider;

export const useCardAuthorizationFlexpaneContext = () => {
  return useContext(CardAuthorizationFlexpaneContext);
};
