import { MagnifyingGlass, X } from "@phosphor-icons/react";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import TextInput from "ui/inputs/TextInput";
import Tabs from "ui/navigation/Tabs";
import { LocalMonth } from "utils/types/date";

type PeriodOption = "last3Months" | "last6Months" | "yearToDate";

type Period = {
  id: PeriodOption;
  label: string;
  since: (now: LocalMonth) => LocalMonth;
  until: (now: LocalMonth) => LocalMonth;
};

const periods: Record<PeriodOption, Period> = {
  last3Months: {
    id: "last3Months",
    label: "Last 3 months",
    since: (now: LocalMonth) => now.minus(2, "month"),
    until: (now: LocalMonth) => now,
  },
  last6Months: {
    id: "last6Months",
    label: "Last 6 months",
    since: (now: LocalMonth) => now.minus(5, "month"),
    until: (now: LocalMonth) => now,
  },
  yearToDate: {
    id: "yearToDate",
    label: "Year-to-date",
    since: (now: LocalMonth) => now.startOf("year"),
    until: (now: LocalMonth) => now,
  },
};

export type Controls = {
  period: Period;
  setPeriod: (period: Period) => void;

  search: string | undefined;
  setSearch: (search: string) => void;

  since: LocalMonth;
  until: LocalMonth;
};

type Props = {
  controls: Controls;
};

const SpendByVendorTableControls: React.FC<Props> = ({ controls }) => {
  const handleActiveTabChange = (tabId: string) => {
    controls.setPeriod(periods[tabId as PeriodOption]);
  };

  return (
    <>
      <Tabs
        tabs={Object.entries(periods).map(([key, value]) => ({ id: key, label: value.label }))}
        variant="rounded"
        activeTab={controls.period.id}
        setActiveTab={handleActiveTabChange}
      />
      <TextInput
        placeholder="Search for vendors by name"
        value={controls.search || ""}
        onChange={controls.setSearch}
        icon={controls.search ? <X size={16} /> : <MagnifyingGlass size={16} />}
        onIconClick={() => controls.setSearch("")}
      />
    </>
  );
};

export default SpendByVendorTableControls;

export const useSpendByVendorTableControls = (): Controls => {
  const now = useMemo(() => LocalMonth.now(), []);

  const [searchParams, setSearchParams] = useSearchParams();

  const period =
    periods[(searchParams.get("period") as keyof typeof periods | null) ?? periods.last3Months.id];
  const search = searchParams.get("search") || "";

  const setPeriod = (period: Period) => setSearchParams({ period: period.id, search });
  const setSearch = (search: string) => setSearchParams({ period: period.id, search });

  return {
    period,
    search,

    setPeriod,
    setSearch,

    since: period.since(now),
    until: period.until(now),
  };
};
