import { LockLaminated } from "@phosphor-icons/react";
import Text from "ui/typography/Text";

import styles from "./HighbeamCard.module.scss";

const HighbeamCardFraud = () => (
  <div className={styles.lockedContainer}>
    <LockLaminated size={12} weight={"bold"} className={styles.fraudColor} />
    <Text size={12} weight={"bold"} className={styles.fraudColor}>
      Locked: unusual activity
    </Text>
  </div>
);

export default HighbeamCardFraud;
