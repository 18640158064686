import { ArrowLineUpRight, ArrowsLeftRight, DotsThree } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import colors from "styles/colors";
import ChequeIcon from "ui/icons/ChequeIcon";
import ButtonArray from "ui/navigation/ButtonArray";
import Menu from "ui/overlay/Menu";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import useMediaQuery from "utils/device/useMediaQuery";
import useIsAllowedToNavigateToDepositCheckPage from "utils/permissions/navigation/useIsAllowedToNavigateToDepositCheckPage";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import useIsAllowedToCreateDraftPayments from "utils/permissions/useIsAllowedToCreateDraftPayments";

const AccountsOverviewPageHeaderActions = () => {
  const isAllowedToCreatePaymentDrafts = useIsAllowedToCreateDraftPayments();
  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const isAllowedToNavigateToDepositCheckPage = useIsAllowedToNavigateToDepositCheckPage();

  // NB(alex): We should standardize these values soon. `useIsMobile` was too wide :/
  const isMobileSmall = useMediaQuery("(max-width: 640px)");

  return (
    <ButtonArray alignment="horizontal">
      {/* TODO(alex): ButtonArray.ButtonLink */}
      {(isAllowedToCreatePaymentDrafts || isAllowedToApprovePayments) && (
        <Link to="/send-money" key="send-money">
          <ButtonArray.Button variant="secondary">
            <ArrowLineUpRight size={14} />
            {isAllowedToApprovePayments ? "Send money" : "Draft a payment"}
          </ButtonArray.Button>
        </Link>
      )}

      <Link to="/transfer-money" key="transfer-money">
        <ButtonArray.Button variant="secondary">
          <ArrowsLeftRight size={14} />
          Transfer money
        </ButtonArray.Button>
      </Link>

      {isMobileSmall ? (
        // Hide menu icon on mobile if user doesn't have permission. Feel free to modify if we don't like this.
        !isAllowedToNavigateToDepositCheckPage ? null : (
          <Menu
            button={
              <ButtonArray.Button
                variant="secondary"
                key="menu"
                // NB(alex): There should be a better way to do this via props. Going to leave this hack here for now.
                style={{ minWidth: "auto", minHeight: 42 }}
              >
                <DotsThree weight="bold" />
              </ButtonArray.Button>
            }
          >
            <Link to="/deposit-check" key="deposit-check">
              <Menu.Item style={{ display: "flex", alignItems: "center" }} icon={<ChequeIcon />}>
                Deposit check
              </Menu.Item>
            </Link>
          </Menu>
        )
      ) : (
        <Link to="/deposit-check" key="deposit-check">
          <ButtonArray.Button
            variant="secondary"
            disabled={!isAllowedToNavigateToDepositCheckPage}
            tooltip={
              !isAllowedToNavigateToDepositCheckPage && (
                <Tooltip.Content>You don’t have permission to deposit checks.</Tooltip.Content>
              )
            }
          >
            <ChequeIcon color={colors.purple[500]} />
            Deposit check
          </ButtonArray.Button>
        </Link>
      )}
    </ButtonArray>
  );
};

export default AccountsOverviewPageHeaderActions;
