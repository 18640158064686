import { captureException } from "@sentry/react";
import { useRefreshBillApprovalsQuery } from "resources/bill-approvals/queries/useBillApprovals";
import { useRefreshBillQuery } from "resources/bills/queries/useBill";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

type Variables = {
  billApprovalId: string;
};

const useGrantBillApprovalMutation = (
  billId: string,
  additionalOptions?: MutationAdditionalOptions<void, Variables>
) => {
  const highbeamApi = useHighbeamApi();
  const refreshBillQuery = useRefreshBillQuery(billId);
  const refreshBillApprovalsQuery = useRefreshBillApprovalsQuery(billId);

  return useMutationWithDefaults(
    {
      mutationFn: async ({ billApprovalId }) => {
        await highbeamApi.billApprovalAction.grant(billApprovalId);
      },
      onSuccess: async () => {
        // Invalidate the relevant bill summary (as well as the bill approvals)
        // since the bill summary will now have a new requested approval count.
        await Promise.all([refreshBillQuery(), refreshBillApprovalsQuery()]);
      },
      onError: (error) => {
        captureException(error);
        notify("error", error.message ?? "Something went wrong! Please try again.");
      },
    },
    additionalOptions || {}
  );
};

export default useGrantBillApprovalMutation;
