import { captureException } from "@sentry/react";
import { useCallback, useState } from "react";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import plaidAccountsQueryHooks from "resources/plaid-connections/queries/plaidAccountsQueryHooks";
import usePlaidConnectionsQueryOptions from "resources/plaid-connections/queries/usePlaidConnectionsQueryOptions";
import { notify } from "ui/feedback/Toast";
import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

type Props = {
  onClose: () => void;
  onDelete: () => void;
  name: string;
  connectionGuid: string;
};

const DeletePlaidConnectionModal: React.FC<Props> = ({
  onClose,
  onDelete,
  name,
  connectionGuid,
}) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const refreshPlaidConnectionsQuery = useRefreshQuery(usePlaidConnectionsQueryOptions().queryKey);
  const refreshPlaidAccountsQuery = plaidAccountsQueryHooks.useRefreshQueries();
  const [isLoading, setIsLoading] = useState(false);

  const deleteConnection = useCallback(async () => {
    setIsLoading(true);

    try {
      await highbeamApi.plaid.deleteConnection({ businessGuid, connectionGuid });
      refreshPlaidConnectionsQuery();
      refreshPlaidAccountsQuery();
      onDelete();
    } catch (e) {
      notify(
        "error",
        "Something went wrong deleting connection " + connectionGuid + "! Please try again."
      );
      captureException(e);
    } finally {
      setIsLoading(false);
    }
  }, [
    businessGuid,
    connectionGuid,
    highbeamApi.plaid,
    onDelete,
    refreshPlaidAccountsQuery,
    refreshPlaidConnectionsQuery,
  ]);

  return (
    <Modal
      title={"Delete connection?"}
      buttonText={"Confirm"}
      onClick={deleteConnection}
      onClose={onClose}
      isLoading={isLoading}
      showCancel
    >
      <Text size={16}>{`Are you sure you want to delete the ${name} connection?`}</Text>
    </Modal>
  );
};

export default DeletePlaidConnectionModal;
