import classNames from "classnames";
import { ReactNode } from "react";
import InfoIcon from "ui/icons/InfoIcon";
import Text from "ui/typography/Text";

import styles from "./InfoBox.module.scss";

type InfoBoxVariant = "info" | "success" | "warning" | "error";

export type InfoBoxProps = {
  text: string | ReactNode;
  iconSize?: number;
  variant: InfoBoxVariant;
  className?: string;
};

const InfoBox: React.FC<InfoBoxProps> = ({ variant, iconSize, text, className }: InfoBoxProps) => {
  return (
    <div className={classNames(className, getVariantClassNames(variant), styles.container)}>
      {/* wrap with div to prevent icon scaling down due to parent flex when text is multi-line */}
      <div>
        <InfoIcon size={iconSize} variant={variant} />
      </div>
      <Text size={14} as="p">
        {text}
      </Text>
    </div>
  );
};

export default InfoBox;

const getVariantClassNames = (variant: InfoBoxVariant): string => {
  switch (variant) {
    case "info":
      return styles.info;
    case "success":
      return ""; // TODO: Deleted due to https://github.com/highbeamco/highbeam/pull/9133
    case "warning":
      return styles.warning;
    case "error":
      return ""; // TODO: Deleted due to https://github.com/highbeamco/highbeam/pull/9133
  }
};
