import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z
  .object({
    readAgreementConfirmed: z.boolean(),
    chargeCardAgreementConfirmed: z.boolean(),
  })
  .refine((data) => data.readAgreementConfirmed && data.chargeCardAgreementConfirmed, {
    message: "Check both checkboxes to agree to the terms",
  });

export type ReviewLineOfferAgreementFormInputs = z.infer<typeof schema>;

const useReviewLineOfferAgreementForm = () => {
  return useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      readAgreementConfirmed: false,
      chargeCardAgreementConfirmed: false,
    },
  });
};

export default useReviewLineOfferAgreementForm;
