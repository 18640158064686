import { ArrowRight, LinkBreak } from "@phosphor-icons/react";
import classNames from "classnames";
import AccountLabel from "components/Accounts/AccountLabel";
import styles from "pages/accounts/AccountsOverviewPage/components/PlaidBankAccountsTable/PlaidBankAccountsTable.module.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import colors from "styles/colors";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import Shimmer from "ui/feedback/ShimmerV2";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";
import { getDollarsFromCents } from "utils/money";
import { formatAmount } from "utils/string";

type Props = {
  accounts: PlaidBankAccountRep.Complete[];
  plaidBalancesRefreshing: boolean;
};

const PlaidCreditCardsTable: React.FC<Props> = ({ accounts, plaidBalancesRefreshing }) => {
  const navigate = useNavigate();

  return (
    <Table
      columns={createColumns(plaidBalancesRefreshing)}
      rowKey={(account) => account.plaidAccountId}
      data={accounts}
      onRowClick={() => navigate("/settings/banks-and-cards")}
    />
  );
};
export default PlaidCreditCardsTable;

function createColumns(plaidBalancesRefreshing: boolean): Column<PlaidBankAccountRep.Complete>[] {
  return [
    {
      title: "Account",
      cellRender: (account) => <AccountLabel plaidAccount={account} showConnectedStores />,
    },
    {
      title: "Balance",
      align: TableColumnAlignment.RIGHT,
      cellRender: (account) => (
        <div className={styles.balance}>
          {plaidBalancesRefreshing ? (
            <Shimmer className="h-4 w-24" />
          ) : (
            <Text size={14} color={colors.grey[800]} weight="medium" numeric align="right">
              {account.currentBalance !== null
                ? formatAmount(getDollarsFromCents(account.currentBalance))
                : "-"}
            </Text>
          )}
        </div>
      ),
    },
    {
      title: "Available",
      align: TableColumnAlignment.RIGHT,
      cellRender: (account) => {
        return (
          <div className={styles.balance}>
            {plaidBalancesRefreshing ? (
              <Shimmer className="h-4 w-24" />
            ) : account.availableBalance !== null && account.creditLimit !== null ? (
              <MoneyAmountFraction
                numeratorInCents={account.availableBalance}
                denominatorInCents={account.creditLimit}
              />
            ) : (
              <Text size={14} color={colors.grey[800]} weight="medium" numeric align="right">
                {account.availableBalance !== null
                  ? formatAmount(getDollarsFromCents(account.availableBalance))
                  : "-"}
              </Text>
            )}
          </div>
        );
      },
    },
    {
      title: "",
      align: TableColumnAlignment.RIGHT,
      width: 160,
      cellRender: (account) => {
        if (account.isActive && account.isConnectionActive) {
          return (
            <div className={styles.details}>
              <ArrowRight size={16} />
            </div>
          );
        }

        return (
          <div className={classNames(styles.details, styles.detailsInactive)}>
            <LinkBreak size={16} />
            Disconnected
            <ArrowRight size={16} />
          </div>
        );
      },
    },
  ];
}
