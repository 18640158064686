import { FC } from "react";
import { Link } from "react-router-dom";
import { BillPaymentMethodType } from "resources/bills/queries/useBillPaymentMethods";
import {
  BILL_DETAILS_PAYEE_PAYMENT_METHOD_TAB_KEY,
  billDetailsPayeePaymentMethodTabs,
} from "resources/bills/search-params/useBillDetailsPayeePaymentMethodTabs";
import {
  BILL_INFO_FORM_TAB_KEY,
  billInfoFormTabs,
} from "resources/bills/search-params/useBillInfoFormTabs";
import colors from "styles/colors";
import Helper from "ui/inputs/Helper";
import Text from "ui/typography/Text";

type Props = {
  billPaymentMethodType: BillPaymentMethodType;
  payeeName: string;
  billId: string;
};

const BillPaymentMethodNotFoundHelper: FC<Props> = ({
  billPaymentMethodType,
  payeeName,
  billId,
}) => {
  const billDetailsPayeePaymentMethodTab = {
    ach: "ach" as const,
    "domestic-wire": "domestic-wire" as const,
    "international-wire-local-currency": "international-wire" as const,
    "international-wire-usd-swift": "international-wire" as const,
  }[billPaymentMethodType] satisfies keyof typeof billDetailsPayeePaymentMethodTabs;

  // Type-safe way to get the appropriate redirect search params.
  const qp = new URLSearchParams({
    [BILL_INFO_FORM_TAB_KEY]: "update-payee-details" satisfies keyof typeof billInfoFormTabs,
    [BILL_DETAILS_PAYEE_PAYMENT_METHOD_TAB_KEY]: billDetailsPayeePaymentMethodTab,
  });

  // A cheeky way to get the payment method name.
  const paymentMethodName =
    billDetailsPayeePaymentMethodTabs[billDetailsPayeePaymentMethodTab].label.toLowerCase();

  const billDetailsPayeeUrl = `/bills/${billId}?${qp}`;

  return (
    <Helper iconVariant="warning">
      No {paymentMethodName} details for{" "}
      <Text as="strong" weight="medium">
        {payeeName}
      </Text>
      .{" "}
      <Link to={billDetailsPayeeUrl}>
        <Text as="span" weight="medium" color={colors.purple[500]}>
          Add details
        </Text>
      </Link>
    </Helper>
  );
};

export default BillPaymentMethodNotFoundHelper;
