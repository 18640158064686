import { Copy } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import Text from "ui/typography/Text";
import { copyToClipboard } from "utils/string";

import ItemWithTooltip from "../ItemWithTooltip/ItemWithTooltip";

import styles from "./CopyIconTooltip.module.scss";

type CopyIconTooltipProps = {
  textToCopy?: string;
};

const CopyIconTooltip: React.FC<CopyIconTooltipProps> = ({ textToCopy }) => {
  const [copied, setCopied] = useState(false);
  const [rerenderTooltip, setRerenderTooltip] = useState(false);
  useEffect(() => {
    if (!copied) return;

    const timer = setTimeout(() => {
      setRerenderTooltip(true);
      setCopied(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [copied, setCopied]);

  const handleClick = () => {
    if (textToCopy) copyToClipboard(textToCopy);
    setCopied(true);
    setRerenderTooltip(true);
  };

  return (
    <ItemWithTooltip
      rerenderTooltip={rerenderTooltip}
      setRerenderTooltip={setRerenderTooltip}
      content={<Text size={12}>{!copied ? "Click to copy" : "Copied"}</Text>}
      wrapperClassName={styles.wrapper}
      onClick={handleClick}
    >
      <Copy size={16} />
    </ItemWithTooltip>
  );
};

export default CopyIconTooltip;
