import classNames from "classnames";
import { ComponentProps, FC, ReactNode, Suspense } from "react";
import colors from "styles/colors";
import Shimmer from "ui/feedback/Shimmer";
import { Heading3 } from "ui/typography";
import Text, { TextProps } from "ui/typography/Text";

import styles from "./FlexpaneSection.module.scss";

const DefaultFlexpaneSectionShimmer = () => (
  <div className={styles.shimmerContainer}>
    <Shimmer />
    <Shimmer />
  </div>
);

const FlexpaneSectionHeading: FC<ComponentProps<typeof Heading3>> = ({
  className,
  children,
  ...headingProps
}) => {
  return (
    <Heading3 className={classNames(className, styles.heading)} {...headingProps}>
      {children}
    </Heading3>
  );
};

const FlexpaneSectionSubheading: FC<TextProps> = ({ className, children, ...textProps }) => {
  return (
    <Text
      size={14}
      color={colors.grey[600]}
      className={classNames(className, styles.subheading)}
      {...textProps}
    >
      {children}
    </Text>
  );
};

type Props = {
  suspenseFallback?: ReactNode;
  children?: ReactNode;
  className?: string;
  heading?: ReactNode;
  subheading?: ReactNode;
};

const FlexpaneSection: FC<Props> = ({
  suspenseFallback = <DefaultFlexpaneSectionShimmer />,
  className,
  children,
  heading,
  subheading,
}) => {
  if (!children) {
    return null;
  }

  return (
    <div className={classNames(className, styles.container)}>
      <Suspense fallback={suspenseFallback}>
        {typeof heading === "string" ? (
          <FlexpaneSectionHeading>{heading}</FlexpaneSectionHeading>
        ) : (
          heading
        )}

        {typeof subheading === "string" ? (
          <FlexpaneSectionSubheading>{subheading}</FlexpaneSectionSubheading>
        ) : (
          subheading
        )}

        {children}
      </Suspense>
    </div>
  );
};

export default Object.assign(FlexpaneSection, {
  Heading: FlexpaneSectionHeading,
  Subheading: FlexpaneSectionSubheading,
  Shimmer: DefaultFlexpaneSectionShimmer,
});
