import { ComponentProps, FC, ReactNode } from "react";
import { SetRequired } from "type-fest";
import Button, { ButtonProps } from "ui/inputs/Button";
import BackButtonLink from "ui/navigation/BackButton";
import NextButton from "ui/navigation/NextButton";
import SkipButton from "ui/navigation/SkipButton";

import styles from "./MultiStepControls.module.scss";

const MultiStepControlsButton: FC<ButtonProps> = ({ ...buttonProps }) => {
  return <Button type="button" variant="tertiary" {...buttonProps} />;
};

type MultiStepControlsNextButtonProps = SetRequired<ComponentProps<typeof NextButton>, "form">;

const MultiStepControlsNextButton: FC<MultiStepControlsNextButtonProps> = ({
  ...nextButtonProps
}) => {
  return <NextButton type="submit" {...nextButtonProps} />;
};

type MultiStepControlsProps = {
  children: ReactNode;
};

const MultiStepControls: FC<MultiStepControlsProps> = ({ children }) => {
  return <div className={styles.controlSection}>{children}</div>;
};

export default Object.assign(MultiStepControls, {
  Button: MultiStepControlsButton,
  NextButton: MultiStepControlsNextButton,
  BackButtonLink: BackButtonLink,
  SkipButton: SkipButton,
});
