import { Bank } from "@phosphor-icons/react";
import amex from "assets/bank-logos/amex.png";
import bankOfAmerica from "assets/bank-logos/bank-of-america.png";
import capitalOne from "assets/bank-logos/capital-one.png";
import chase from "assets/bank-logos/chase.png";
import chime from "assets/bank-logos/chime.png";
import creditUnionOfColorado from "assets/bank-logos/credit-union-of-colorado.png";
import fcu from "assets/bank-logos/fcu.png";
import mercury from "assets/bank-logos/mercury.png";
import novo from "assets/bank-logos/novo.png";
import shopify from "assets/bank-logos/shopify.png";
import td from "assets/bank-logos/td.png";
import usBank from "assets/bank-logos/us-bank.png";
import wellsFargo from "assets/bank-logos/wells-fargo.png";
import classNames from "classnames";
import { ReactNode } from "react";
import BankAccountRep from "reps/BankAccountRep";
import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import HighbeamAccountIcon from "resources/bank-accounts/components/HighbeamAccountIcon";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { getPlaidBankAccountDisplayName, getAccountDisplayName } from "utils/account";

import ShopifyPayoutsStatusIcon from "../ShopifyPayoutsStatusIcon";

import styles from "./AccountLabel.module.scss";

type Props = {
  bankAccount?: BankAccountRep.Complete;
  plaidAccount?: PlaidBankAccountRep.Complete;
  /*
   * Show payout icon next to the account name
   */
  showConnectedStores?: boolean;
  wrapperClassName?: string;
};

/**
 * DEPRECATED: Use `<AccountBar>` instead
 */
const AccountLabel: React.FC<Props> = ({
  bankAccount,
  plaidAccount,
  showConnectedStores = false,
  wrapperClassName,
}) => {
  if (plaidAccount) {
    return (
      <div className={classNames(wrapperClassName, styles.container)}>
        {getIcon(plaidAccount.institutionId)}
        <Text size={14} weight="medium">
          {getPlaidBankAccountDisplayName(plaidAccount)}
        </Text>
      </div>
    );
  }
  if (bankAccount) {
    return (
      <div className={classNames(wrapperClassName, styles.container)}>
        <HighbeamAccountIcon highbeamTypeName={bankAccount.highbeamType.name} />
        <Text
          size={14}
          weight="medium"
          color={
            bankAccount.status !== BankAccountRep.Status.OPEN ? colors.grey[600] : colors.grey[900]
          }
        >
          {getAccountDisplayName(bankAccount)}
        </Text>
        {showConnectedStores && bankAccount?.isPrimary && <ShopifyPayoutsStatusIcon />}
      </div>
    );
  }
  return null;
};

export default AccountLabel;

// TODO: HB-615 We should have the backend import and pass in all logos
export const getIcon = (institutionId: string): ReactNode => {
  switch (institutionId) {
    case "ins_3":
      return <img className={styles.icon} src={chase} alt="Chase logo" />;
    case "ins_4":
      return <img className={styles.icon} src={wellsFargo} alt="Wells Fargo logo" />;
    case "ins_10":
      return <img className={styles.icon} src={amex} alt="American Express logo" />;
    case "ins_14":
      return <img className={styles.icon} src={td} alt="Toronto Dominion logo" />;
    case "ins_35":
      return <img className={styles.icon} src={chime} alt="Chime logo" />;
    case "ins_56":
      return <img className={styles.icon} src={chase} alt="Chase logo" />;
    case "ins_116594":
      return <img className={styles.icon} src={novo} alt="Novo Bank logo" />;
    case "ins_116794":
      return <img className={styles.icon} src={mercury} alt="Mercury logo" />;
    case "ins_118051":
      return <img className={styles.icon} src={fcu} alt="Schools First FCU logo" />;
    case "ins_127934":
      return (
        <img
          className={styles.icon}
          src={creditUnionOfColorado}
          alt="Credit Union of Colorado logo"
        />
      );
    case "ins_127989":
      return <img className={styles.icon} src={bankOfAmerica} alt="Bank of America logo" />;
    case "ins_127990":
      return <img className={styles.icon} src={usBank} alt="U.S. Bank logo" />;
    case "ins_128026":
      return <img className={styles.icon} src={capitalOne} alt="Capital One logo" />;
    case "ins_132442":
      return <img className={styles.icon} src={shopify} alt="Shopify Balance logo" />;
    default:
      return <Bank className={styles.icon} weight="fill" />;
  }
};
