import {
  ArrowFatLinesRight,
  ArrowLineRight,
  ArrowsClockwise,
  CalendarCheck,
  CalendarPlus,
  ChartLineUp,
  CreditCard,
  CurrencyDollar,
  Eyeglasses,
  Icon,
  IconProps,
  Money,
  WaveSine,
} from "@phosphor-icons/react";
import classNames from "classnames";
import { FC } from "react";
import colors from "styles/colors";
import HighlightCard from "ui/data-display/HighlightCard";
import Text, { TextProps } from "ui/typography/Text";

import lineOfCreditIcon from "./assets/line-of-credit-icon.svg";
import styles from "./UpsellCapitalHighlightItem.module.scss";

const BulletPointText: FC<TextProps> = (props) => {
  return <Text color={colors.grey[600]} size={14} lineHeight={20} {...props} />;
};

type BulletPointIconProps = IconProps & {
  IconElement: Icon;
};

const BulletPointIcon: FC<BulletPointIconProps> = ({ IconElement, ...props }) => {
  return (
    <IconElement size={20} weight="light" className={styles.bulletPointIcon} {...props}>
      {props.children}
    </IconElement>
  );
};

type UpsellCapitalCardProps = {
  className?: string;
};

const UpsellCapitalLineOfCredit: FC<UpsellCapitalCardProps> = ({ className }) => {
  return (
    <HighlightCard
      className={className}
      header={
        <HighlightCard.Header
          icon={
            <div className={classNames(styles.iconOutline)}>
              <img src={lineOfCreditIcon} alt="line-of-credit" />
            </div>
          }
        >
          Line of credit
        </HighlightCard.Header>
      }
      body={
        <div>
          <Text color={colors.grey[600]} size={14}>
            Access a revolving line of credit with flexible draw options, customizable repayment
            plans, and no prepayment fees.
          </Text>

          <ul className={styles.bulletPointsContainer}>
            <li className={styles.bulletPointContainer}>
              <BulletPointIcon IconElement={CalendarCheck} />
              <BulletPointText>
                Revolving line of credit means you only pay for what you use.
              </BulletPointText>
            </li>
            <li className={styles.bulletPointContainer}>
              <BulletPointIcon IconElement={ArrowsClockwise} />
              <BulletPointText>
                Quarterly underwriting–so your credit line grows with your brand.
              </BulletPointText>
            </li>
            <li className={styles.bulletPointContainer}>
              {/* TODO(alex): make this <MoneyInOut /> once we have the custom icon. */}
              <BulletPointIcon IconElement={Money} />
              <BulletPointText>
                Always available to draw, with instant access in your operating account.
              </BulletPointText>
            </li>
          </ul>
        </div>
      }
    />
  );
};

const UpsellCapitalAdvance: FC<UpsellCapitalCardProps> = ({ className }) => {
  return (
    <HighlightCard
      className={className}
      header={
        <HighlightCard.Header
          icon={
            <div className={classNames(styles.iconOutline)}>
              <ArrowFatLinesRight size={24} weight="thin" color={colors.purple[500]} />
            </div>
          }
        >
          Advance
        </HighlightCard.Header>
      }
      body={
        <div>
          <Text color={colors.grey[600]} size={14}>
            Fast turnarounds and instant funding with customizable repayment plans to fit your
            brand’s cash flow cycle.
          </Text>

          <ul className={styles.bulletPointsContainer}>
            <li className={styles.bulletPointContainer}>
              <BulletPointIcon IconElement={CalendarPlus} />
              <BulletPointText>
                Extend your payment terms for inventory and marketing.
              </BulletPointText>
            </li>
            <li className={styles.bulletPointContainer}>
              <BulletPointIcon IconElement={Eyeglasses} />
              <BulletPointText>
                Transparent, flat fee pricing based only on the cash you use.
              </BulletPointText>
            </li>
            <li className={styles.bulletPointContainer}>
              <BulletPointIcon IconElement={ChartLineUp} />
              <BulletPointText>
                Higher limits that help cover your largest expenses.
              </BulletPointText>
            </li>
          </ul>
        </div>
      }
    />
  );
};

const UpsellCapitalCashCard: FC<UpsellCapitalCardProps> = ({ className }) => {
  return (
    <HighlightCard
      className={className}
      header={
        <HighlightCard.Header>
          <div className={styles.cardHeaderContainer}>
            <div className={styles.cardHeaderIconContainer}>
              <CreditCard size={32} weight="thin" color={colors.purple[500]} />
              <div className={styles.cardHeaderIconBadge}>
                <CurrencyDollar size={14} weight="regular" color={colors.purple[500]} />
              </div>
            </div>
            Cash card
          </div>
        </HighlightCard.Header>
      }
      body={
        <div className={styles.cardBodyContainer}>
          <Text color={colors.grey[600]} size={14}>
            Get <strong>1.5% back</strong> on all your spend with single-day charge cards—no cap on
            earnings.
          </Text>
        </div>
      }
    />
  );
};

const UpsellCapitalFlexCard: FC<UpsellCapitalCardProps> = ({ className }) => {
  return (
    <HighlightCard
      className={className}
      header={
        <HighlightCard.Header>
          <div className={styles.cardHeaderContainer}>
            <div className={styles.cardHeaderIconContainer}>
              <CreditCard size={32} weight="thin" color={colors.purple[500]} />
              <div className={styles.cardHeaderIconBadge}>
                <WaveSine size={14} weight="regular" color={colors.purple[500]} />
              </div>
            </div>
            Flex card
          </div>
        </HighlightCard.Header>
      }
      body={
        <div className={styles.cardBodyContainer}>
          <Text color={colors.grey[600]} size={14}>
            Earn <strong>1%</strong> on all purchases, with no cap on earnings–and up to{" "}
            <strong>30 days of interest-free float</strong>.
          </Text>
        </div>
      }
    />
  );
};

const UpsellCapitalExtendCard: FC<UpsellCapitalCardProps> = ({ className }) => {
  return (
    <HighlightCard
      className={className}
      header={
        <HighlightCard.Header>
          <div className={styles.cardHeaderContainer}>
            <div className={styles.cardHeaderIconContainer}>
              <CreditCard size={32} weight="thin" color={colors.purple[500]} />
              <div className={styles.cardHeaderIconBadge}>
                <ArrowLineRight size={14} weight="regular" color={colors.purple[500]} />
              </div>
            </div>
            Extend card
          </div>
        </HighlightCard.Header>
      }
      body={
        <div className={styles.cardBodyContainer}>
          <Text color={colors.grey[600]} size={14}>
            Enjoy up to a <strong>60-day float</strong>, allowing you to stay flexible with your
            brand’s cash flow.
          </Text>
        </div>
      }
    />
  );
};

type UpsellCapitalHighlightItemType =
  | "line-of-credit"
  | "advance"
  | "cash-card"
  | "flex-card"
  | "extend-card";

type Props = {
  type: UpsellCapitalHighlightItemType;
  className?: string;
};

const UpsellCapitalHighlightItem: FC<Props> = ({ type, className }) => {
  switch (type) {
    case "line-of-credit":
      return <UpsellCapitalLineOfCredit className={className} />;
    case "advance":
      return <UpsellCapitalAdvance className={className} />;
    case "cash-card":
      return <UpsellCapitalCashCard className={className} />;
    case "flex-card":
      return <UpsellCapitalFlexCard className={className} />;
    case "extend-card":
      return <UpsellCapitalExtendCard className={className} />;
  }
};

export default UpsellCapitalHighlightItem;
