import { FC } from "react";
import CapitalAccountDetailsRep from "reps/CapitalAccountDetailsRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import checkIsCapitalAccountSummaryWithChargeCard, {
  CapitalAccountSummaryWithChargeCard,
} from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import StatementCycleExplanation from "resources/charge-cards/components/StatementCycleExplanation";
import useChargeCardOffer from "resources/charge-cards/queries/useChargeCardOffer";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text from "ui/typography/Text";

import styles from "./AgreementPlainLanguageSummary.module.scss";

type ChargeCardCapitalAccountPlainLanguageSummaryProps = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardCapitalAccountPlainLanguageSummary: FC<
  ChargeCardCapitalAccountPlainLanguageSummaryProps
> = ({ capitalAccountSummaryWithChargeCard }) => {
  const chargeCardOffer = useChargeCardOffer(capitalAccountSummaryWithChargeCard.guid, {
    required: true,
  });

  return (
    <Text size={14} color={colors.grey[500]}>
      <ul className={styles.agreementSummaryList}>
        <li>
          Your card limit will start at{" "}
          <MoneyAmount
            cents={capitalAccountSummaryWithChargeCard.details.limit}
            withCents={false}
          />
          .
        </li>
        <li>
          Statement period: <StatementCycleExplanation creditTerms={chargeCardOffer.creditTerms} />
        </li>
        <li>
          Cards that are unpaid at the end of the statement period will become overdue and be paid
          back over {capitalAccountSummaryWithChargeCard.details.targetRepaymentDays} additional
          days at {capitalAccountSummaryWithChargeCard.details.apr}% APR. Missing an overdue payment
          can result in your card being frozen.
        </li>
        {/*
          TODO(alex): API does not have `drawPeriodInDays` or `drawPeriodEndsAt` yet.
          <li>
            This card will be active until {{Today() + drawPeriodInDays}}. At the end of the term, your card will be re-underwritten to reflect updated performance. 
          </li>
        */}
        <li>
          Within 45 days of activation, Highbeam must act as your primary operating account. i.e.
          sales payouts, payroll, inventory, marketing and card payments must flow through Highbeam.
        </li>
        <li>
          No new debt can be taken on without Highbeam approval. Taking on new debt without approval
          can cause the card to be frozen.
        </li>
        <li>
          Highbeam will request financials at least once per quarter, and they need to be submitted
          within two weeks of receiving the request.
        </li>
        {capitalAccountSummaryWithChargeCard.details.securedStatus ===
          CapitalAccountDetailsRep.SecuredStatus.Secured && (
          <li>All assets are used as collateral against this line.</li>
        )}
      </ul>
    </Text>
  );
};

type CashAccessPlainLanguageSummaryProps = {
  capitalAccountSummaryWithCashAccessOnly: CapitalAccountSummaryRep.CashAccessOnlyComplete;
};

const CashAccessPlainLanguageSummary: FC<CashAccessPlainLanguageSummaryProps> = ({
  capitalAccountSummaryWithCashAccessOnly,
}) => {
  return (
    <Text size={14} color={colors.grey[500]}>
      <ul className={styles.agreementSummaryList}>
        <li>
          Your line limit will start at{" "}
          <MoneyAmount
            cents={capitalAccountSummaryWithCashAccessOnly.details.limit}
            withCents={false}
          />{" "}
          with a {capitalAccountSummaryWithCashAccessOnly.details.apr * 100}% APR.
        </li>
        {/* TODO(alex): API does not have `drawPeriodInDays` or `drawPeriodEndsAt` yet.
          <li>
            This line will be active until {{Today() + drawPeriodInDays}}. At the end of the term, your line will be re-underwritten to reflect updated financial performance. 
          </li>
        */}
        <li>
          Most draws are funded immediately. However, large draws can take 2-5 business days to
          process.
        </li>
        {/* TODO(alex): API does not have `drawPeriodInDays` or `drawPeriodEndsAt` yet.
          <li>
            Each draw must be paid back automatically within {capitalAccountSummaryWithCashAccessOnly.details.targetRepaymentDays} of the draw, of which you are able to redraw until {{Today() + drawPeriodInDays}}.
          </li>
        */}
        <li>
          Within 45 days of activation, Highbeam must act as your primary operating account. i.e.
          sales payouts, payroll, inventory, marketing and card payments must flow through Highbeam.
        </li>
        <li>
          No new debt can be taken on without Highbeam approval. Taking on new debt without approval
          can cause the line to be paused.
        </li>
        <li>
          Highbeam will request financials at least once per quarter, and they need to be submitted
          within two weeks of receiving the request.
        </li>
        {capitalAccountSummaryWithCashAccessOnly.details.securedStatus ===
          CapitalAccountDetailsRep.SecuredStatus.Secured && (
          <li>All assets are used as collateral against this line.</li>
        )}
      </ul>
    </Text>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const AgreementPlainLanguageSummary: FC<Props> = ({ capitalAccountSummary }) => (
  <div className={styles.agreementSummaryContent}>
    {checkIsCapitalAccountSummaryWithChargeCard(capitalAccountSummary) ? (
      <ChargeCardCapitalAccountPlainLanguageSummary
        capitalAccountSummaryWithChargeCard={capitalAccountSummary}
      />
    ) : (
      <CashAccessPlainLanguageSummary
        capitalAccountSummaryWithCashAccessOnly={capitalAccountSummary}
      />
    )}
  </div>
);

export default AgreementPlainLanguageSummary;
