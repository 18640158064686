import { Card } from "@highbeam/unit-node-sdk";
import useMfa from "modules/mfa/useMfa";
import { useState } from "react";
import EditCardModal from "resources/cards/dialogs/EditCardModal";
import Button from "ui/inputs/Button";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import useIsAllowedToPerformCardActions from "utils/permissions/useIsAllowedToPerformCardActions";

type Props = {
  card: Card;
};

const SetSpendLimitButton = ({ card }: Props) => {
  const [showEditCardModal, setShowEditCardModal] = useState(false);
  const isAllowedToPerformCardActions = useIsAllowedToPerformCardActions();
  const { mfa } = useMfa();

  return (
    <>
      {showEditCardModal && (
        <EditCardModal cardId={card.id} onClose={() => setShowEditCardModal(false)} />
      )}

      <Button
        variant="tertiary"
        size="xs"
        onClick={() => mfa().then(() => setShowEditCardModal(true))}
        disabled={!isAllowedToPerformCardActions}
        tooltip={
          !isAllowedToPerformCardActions && (
            <Tooltip.Content>You don’t have permission to edit the card budget.</Tooltip.Content>
          )
        }
      >
        Set spend limit
      </Button>
    </>
  );
};

export default SetSpendLimitButton;
