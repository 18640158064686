import { Minus } from "@phosphor-icons/react";
import TransactionFlexpaneDetails from "components/common/transaction-flexpane/TransactionFlexpaneDetails";
import useCardAuthorization from "dialogs/CardAuthorizationFlexpane/hooks/useCardAuthorization";
import useCard from "resources/cards/queries/useCard";
import { checkIsBusinessCard } from "resources/cards/utils/typeguards";
import MetadataList from "ui/data-display/MetadataList";

const CardAuthorizationDetails = () => {
  const authorization = useCardAuthorization();
  const card = useCard({ cardId: authorization.relationships.card.data.id, required: true });

  // TODO(alex): Double-check to make sure this is static for all card authorizations
  const longMethodName = "Card transaction";

  return (
    <TransactionFlexpaneDetails>
      <TransactionFlexpaneDetails.Heading>
        {longMethodName} details
      </TransactionFlexpaneDetails.Heading>

      <MetadataList>
        <MetadataList.Item>
          <MetadataList.ItemLabel>Card</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{card.attributes.tags.name}</MetadataList.ItemValue>
        </MetadataList.Item>

        {checkIsBusinessCard(card) && (
          <MetadataList.Item>
            <MetadataList.ItemLabel>Cardholder’s name</MetadataList.ItemLabel>
            <MetadataList.ItemValue>
              {card.attributes.fullName.first} {card.attributes.fullName.last}
            </MetadataList.ItemValue>
          </MetadataList.Item>
        )}

        <MetadataList.Item>
          <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{authorization.attributes.merchant.name}</MetadataList.ItemValue>
        </MetadataList.Item>

        {authorization.attributes.status === "Declined" && (
          <MetadataList.Item>
            <MetadataList.ItemLabel>Decline reason</MetadataList.ItemLabel>
            <MetadataList.ItemValue>
              {authorization.attributes.declineReason || <Minus size={16} />}
            </MetadataList.ItemValue>
          </MetadataList.Item>
        )}
      </MetadataList>
    </TransactionFlexpaneDetails>
  );
};

export default CardAuthorizationDetails;
