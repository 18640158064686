import { ArrowFatLinesUp } from "@phosphor-icons/react";
import { ComponentProps, FC } from "react";
import { Link } from "react-router-dom";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import ButtonArray from "ui/navigation/ButtonArray";
import useIsAllowedToRepayCapital from "utils/permissions/useIsAllowedtoRepayCapital";

import { NotAllowedToRepayCapital } from "../../constants";

type ButtonArrayButtonProps = Partial<ComponentProps<typeof ButtonArray.Button>>;

type Props = ButtonArrayButtonProps & {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const LineOfCreditRepayButtonArrayButton: FC<Props> = ({
  className,
  style,
  capitalAccountSummary,
  ...buttonArrayButtonProps
}) => {
  const isAllowedToRepayCapital = useIsAllowedToRepayCapital();
  const lineUsed = 0 - capitalAccountSummary.runningBalance;

  return (
    <Link
      to={`/capital/${capitalAccountSummary.guid}/credit/repay`}
      className={className}
      style={style}
    >
      <ButtonArray.Button
        variant="secondary"
        className="w-full"
        disabled={
          !isAllowedToRepayCapital ||
          // TODO(alex): Use `isAbleToEarlyPay` once the feature flags are cleaned up. It's confusing to do this now because there are conflicting feature flags between charge cards and separating repayment flows.
          capitalAccountSummary.state !== CapitalAccountRep.State.Active ||
          lineUsed <= 0
        }
        tooltip={!isAllowedToRepayCapital && <NotAllowedToRepayCapital />}
        {...buttonArrayButtonProps}
      >
        <ArrowFatLinesUp size={20} weight="light" />
        Repay
      </ButtonArray.Button>
    </Link>
  );
};

export default LineOfCreditRepayButtonArrayButton;
