import { Copy } from "@phosphor-icons/react";
import classNames from "classnames";
import { ReactNode, useEffect, useState } from "react";
import ItemWithTooltip from "ui/overlay/Tooltip/ItemWithTooltip/ItemWithTooltip";
import Text from "ui/typography/Text";
import { copyToClipboard } from "utils/string";

import styles from "./CopyItemTooltip.module.scss";

type Props = {
  accented?: boolean;
  textToCopy?: string;
  wrapperClassName?: string;
  children: ReactNode;
  rootElementId?: string;
  tooltipContent?: ReactNode;
};

const CopyItemTooltip: React.FC<Props> = ({
  accented = false,
  textToCopy,
  children,
  wrapperClassName,
  rootElementId,
  tooltipContent = "Click to copy",
}) => {
  const [copied, setCopied] = useState(false);
  const [rerenderTooltip, setRerenderTooltip] = useState(false);
  useEffect(() => {
    if (!copied) return;

    const timer = setTimeout(() => {
      setRerenderTooltip(true);
      setCopied(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [copied, setCopied]);

  const handleClick = () => {
    if (copied) return;
    if (textToCopy) copyToClipboard(textToCopy);
    setCopied(true);
    setRerenderTooltip(true);
  };

  return (
    <ItemWithTooltip
      rootElementId={rootElementId}
      rerenderTooltip={rerenderTooltip}
      setRerenderTooltip={setRerenderTooltip}
      content={<Text size={12}>{!copied ? <>{tooltipContent}</> : "Copied"}</Text>}
      wrapperClassName={classNames(
        styles.wrapper,
        { [styles.accented]: accented },
        wrapperClassName
      )}
      onClick={handleClick}
    >
      {children}
      <Copy size={16} />
    </ItemWithTooltip>
  );
};

export default CopyItemTooltip;
