import { FC, ReactNode } from "react";
import cn from "utils/tailwind/cn";

type Props = {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
};

const BusinessDisambiguatorButton: FC<Props> = ({ children, className, onClick }) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className={cn(
        "flex h-24 cursor-pointer items-center gap-x-4 px-6",
        onClick ? "cursor-pointer hover:bg-grey-50" : "cursor-auto",
        "border-b border-b-grey-100 last-of-type:border-b-0",
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default BusinessDisambiguatorButton;
