import { PlusCircle as PlusCircleIcon } from "@phosphor-icons/react";
import IconWithTooltip from "components/common/IconWithTooltip";
import { DISABLE_SCROLL_TO_TOP_STATE } from "components/navigation/ScrollToTopOnNavigate";
import { FC, Suspense } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BillSummaryRep from "reps/BillSummaryRep";
import useCurrentApEmailAddress from "resources/ap-email-aliases/queries/useCurrentApEmailAddress";
import BillApprovalStatusPill from "resources/bills/components/BillApprovalStatusPill";
import BillPaymentStatusPill from "resources/bills/components/BillPaymentStatusPill";
import { useAllBillsQuery } from "resources/bills/queries/useBills";
import PayeeNameCell from "resources/payees/components/PayeeNameCell";
import EmptyState from "ui/data-display/EmptyState";
import InlineCopyText from "ui/data-display/InlineCopyText";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import DateCell from "ui/table/DateCell";
import MoneyCell from "ui/table/MoneyCell";
import NotFoundCell from "ui/table/NotFoundCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import TextCell from "ui/table/TextCell";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import { formatBankingDate } from "utils/date";

import BillsEmailInboxEmptyState from "../BillEmailInboxEmptyState";

type AllBillEmptyStateProps = {
  createBill: () => void;
  isCreatingBill: boolean;
  openEmailSettings: () => void;
};

const AllBillsEmptyState: FC<AllBillEmptyStateProps> = ({
  createBill,
  isCreatingBill,
  openEmailSettings,
}) => {
  const isManualBillCreationEnabled = useFeatureFlag("BILL_PAY_MANUAL_BILL_CREATION");
  const currentApEmailAddress = useCurrentApEmailAddress();

  if (isManualBillCreationEnabled) {
    return (
      <EmptyState
        variant="card"
        body={
          <>
            <EmptyState.PrimaryText>No bills</EmptyState.PrimaryText>
            <EmptyState.SecondaryText>
              To create a bill, click &ldquo;Create bill&rdquo;
              {currentApEmailAddress ? (
                <>
                  {" "}
                  or email an invoice to{" "}
                  <InlineCopyText
                    textToCopy={currentApEmailAddress}
                    tooltipContent="Copy email address"
                  >
                    {currentApEmailAddress}
                  </InlineCopyText>
                </>
              ) : null}
            </EmptyState.SecondaryText>
          </>
        }
        footer={
          <EmptyState.CTA isLoading={isCreatingBill} onClick={() => createBill()}>
            {!isCreatingBill && <PlusCircleIcon />}
            Create bill
          </EmptyState.CTA>
        }
      />
    );
  }

  return <BillsEmailInboxEmptyState openEmailSettings={openEmailSettings} />;
};

type BillCellProps = { bill: BillSummaryRep.Complete };

export const BillPayeeNameCell: FC<BillCellProps> = ({ bill }) => {
  if (!bill.payeeGuid) {
    return <NotFoundCell />;
  }
  return <PayeeNameCell payeeGuid={bill.payeeGuid} />;
};

export const BillInvoiceDueDateCell: FC<BillCellProps> = ({ bill }) => {
  const { invoiceDueDate } = bill;
  if (!invoiceDueDate) {
    return <NotFoundCell />;
  }
  return (
    <DateCell
      date={invoiceDueDate}
      hideCurrentYear
      darken
      tooltipContent={<Tooltip.Content>{formatBankingDate(invoiceDueDate)}</Tooltip.Content>}
    />
  );
};

const BillPurchaseOrderNumberCell: FC<BillCellProps> = ({ bill }) => {
  if (!bill.purchaseOrderNumber) {
    return <NotFoundCell />;
  }
  return <TextCell darken>{bill.purchaseOrderNumber}</TextCell>;
};

// TODO(alex): differentiate between bill amount and amount still due
export const BillTotalAmountCell: FC<BillCellProps> = ({ bill }) => {
  if (!bill.amount) {
    return <NotFoundCell />;
  }
  return <MoneyCell amount={bill.amount.amount} currencyCode={bill.amount.currency} darken />;
};

const BillAmountDueCell: FC<BillCellProps> = ({ bill }) => {
  if (!bill.remainingAmount) {
    return <NotFoundCell />;
  }
  return (
    <MoneyCell
      amount={bill.remainingAmount.amount}
      currencyCode={bill.remainingAmount.currency}
      darken
    />
  );
};

const BillApprovalStatusCell: FC<BillCellProps> = ({ bill }) => {
  return <BillApprovalStatusPill bill={bill} />;
};

const BillPaymentStatusCell: FC<BillCellProps> = ({ bill }) => {
  return <BillPaymentStatusPill bill={bill} />;
};

const columns: Column<BillSummaryRep.Complete>[] = [
  {
    title: "Invoice #",
    cellRender: (bill) => {
      if (!bill.invoiceNumber) {
        return <NotFoundCell />;
      }

      return (
        <TextCell darken>
          {bill.invoiceNumber}
          {bill.duplicateOfBillId && (
            <IconWithTooltip icon="warning" color="warning" content="Possible duplicate bill" />
          )}
        </TextCell>
      );
    },
  },
  {
    title: "Payee",
    cellRender: (bill) => {
      return <BillPayeeNameCell bill={bill} />;
    },
  },
  {
    title: "Due",
    cellRender: (bill) => {
      return <BillInvoiceDueDateCell bill={bill} />;
    },
  },
  {
    title: "PO #",
    cellRender: (bill) => {
      return <BillPurchaseOrderNumberCell bill={bill} />;
    },
  },
  {
    title: "Amount due",
    align: TableColumnAlignment.RIGHT,
    cellRender: (bill) => {
      return <BillAmountDueCell bill={bill} />;
    },
  },
  {
    title: "Approval status",
    cellRender: (bill) => {
      return <BillApprovalStatusCell bill={bill} />;
    },
  },
  {
    title: "Payment status",
    cellRender: (bill) => {
      return <BillPaymentStatusCell bill={bill} />;
    },
  },
];

type Props = {
  createBill: () => void;
  isCreatingBill: boolean;
  openEmailSettings: () => void;
};

const AllBillsView: FC<Props> = ({ createBill, isCreatingBill, openEmailSettings }) => {
  const { data: bills, isPending } = useAllBillsQuery();
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <Suspense fallback={<Table.Loading columns={columns} isLoadingRows={2} />}>
      {!isPending && bills && !bills.length ? (
        <AllBillsEmptyState
          createBill={createBill}
          isCreatingBill={isCreatingBill}
          openEmailSettings={openEmailSettings}
        />
      ) : (
        <Table
          isLoading={isPending}
          rowKey={(bill) => bill.id}
          onRowClick={({ id }) =>
            navigate(`/bills/${id}${search}`, { state: DISABLE_SCROLL_TO_TOP_STATE })
          }
          data={bills}
          columns={columns}
        />
      )}
    </Suspense>
  );
};

export default AllBillsView;
