import { Paperclip as PaperclipIcon } from "@phosphor-icons/react";
import IconWithTooltip from "components/common/IconWithTooltip";
import { DISABLE_SCROLL_TO_TOP_STATE } from "components/navigation/ScrollToTopOnNavigate";
import { FC, Suspense } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BillSummaryRep from "reps/BillSummaryRep";
import useCurrentApEmailAddress from "resources/ap-email-aliases/queries/useCurrentApEmailAddress";
import { useProcessingBills, useDraftBillsQuery } from "resources/bills/queries/useBills";
import colors from "styles/colors";
import InlineCopyText from "ui/data-display/InlineCopyText";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Shimmer from "ui/feedback/Shimmer";
import Button from "ui/inputs/Button";
import NotFoundCell from "ui/table/NotFoundCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import TextCell from "ui/table/TextCell";
import Text from "ui/typography/Text";
import { pluralize } from "utils/string";

import { BillInvoiceDueDateCell, BillPayeeNameCell, BillTotalAmountCell } from "../AllBillsView";
import BillsEmailInboxEmptyState from "../BillEmailInboxEmptyState";

import styles from "./BillsEmailInboxView.module.scss";

// This component displays information about the number of currently processing bills,
// if there are any. Otherwise, it falls back to displaying info about the current email
// alias to which invoices should be sent.
const EmailInboxStatusIndicator: FC = () => {
  const processingBills = useProcessingBills();
  const processingCount = processingBills.length;
  const currentApEmailAddress = useCurrentApEmailAddress();

  if (!currentApEmailAddress) {
    return null;
  }

  return (
    <Text as="p" size={14} color={colors.grey[600]}>
      {processingCount > 0 ? (
        <>
          <AnimatedSpinner className={styles.statusIndicatorSpinner} /> Processing{" "}
          {pluralize(processingCount, "email")} sent to
        </>
      ) : (
        <>Email invoices to</>
      )}{" "}
      <InlineCopyText textToCopy={currentApEmailAddress} tooltipContent="Copy email address">
        {currentApEmailAddress}
      </InlineCopyText>
    </Text>
  );
};

const columns: Column<BillSummaryRep.Complete>[] = [
  {
    title: "Sent by",
    cellRender: (bill) => {
      if (!bill.sentByEmail) {
        return <NotFoundCell />;
      }
      return (
        <TextCell darken overflowEllipsis>
          {bill.sentByEmail}
        </TextCell>
      );
    },
  },
  {
    title: "Invoice #",
    cellRender: (bill) => {
      if (!bill.invoiceNumber) {
        return <NotFoundCell />;
      }
      return (
        <TextCell darken>
          {bill.invoiceNumber}{" "}
          {bill.duplicateOfBillId && (
            <IconWithTooltip icon="warning" color="warning" content="Possible duplicate bill" />
          )}
        </TextCell>
      );
    },
  },
  {
    title: "Payee",
    cellRender: (bill) => {
      return <BillPayeeNameCell bill={bill} />;
    },
  },
  {
    title: "Due",
    cellRender: (bill) => {
      return <BillInvoiceDueDateCell bill={bill} />;
    },
  },
  {
    title: "Bill amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: (bill) => {
      return <BillTotalAmountCell bill={bill} />;
    },
  },
  {
    title: "Attachments",
    cellRender: (bill) => {
      return (
        <TextCell darken>
          <PaperclipIcon size={16} />
          {bill.billDocumentsCount}
        </TextCell>
      );
    },
  },
  {
    title: "Actions",
    cellRender: (_bill) => {
      return (
        <Button variant="secondary" size="sm">
          Review
        </Button>
      );
    },
  },
];

type Props = {
  openEmailSettings: () => void;
};

const BillsEmailInboxView: FC<Props> = ({ openEmailSettings }) => {
  const { data: bills, isPending } = useDraftBillsQuery();
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <div className={styles.container}>
      <div className={styles.controlsContainer}>
        <Suspense fallback={<Shimmer additionalShimmerWrapperClassName={styles.controlsShimmer} />}>
          <EmailInboxStatusIndicator />
        </Suspense>
      </div>

      <Suspense fallback={<Table.Loading columns={columns} isLoadingRows={2} />}>
        {!isPending && bills && !bills.length ? (
          <BillsEmailInboxEmptyState openEmailSettings={openEmailSettings} />
        ) : (
          <Table
            isLoading={isPending}
            rowKey={(bill) => bill.id}
            onRowClick={({ id }) =>
              navigate(`/bills/${id}${search}`, { state: DISABLE_SCROLL_TO_TOP_STATE })
            }
            data={bills}
            columns={columns}
          />
        )}
      </Suspense>
    </div>
  );
};

export default BillsEmailInboxView;
