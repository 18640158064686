import { DISABLE_SCROLL_TO_TOP_STATE } from "components/navigation/ScrollToTopOnNavigate";
import TransactionInfoFlexpane from "components/Transactions/TransactionInfoFlexpane";
import CardAuthorizationFlexpane from "dialogs/CardAuthorizationFlexpane";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getCardName } from "resources/cards/utils";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import CardDetailsHeader from "./CardDetailsHeader/CardDetailsHeader";
import CardDetailsInfo from "./CardDetailsInfo/CardDetailsInfo";
import CardTransactionsTable from "./components/CardTransactionsTable";
import PendingAuthorizationsTable from "./components/PendingAuthorizationsTable";
import CardDetailsPageProvider, {
  useCardDetailsPageContext,
} from "./providers/CardDetailsPageProvider";

const CardDetailsBreadcrumb = () => {
  const { card } = useCardDetailsPageContext();
  return <Breadcrumbs.CurrentItem>{getCardName(card)}</Breadcrumbs.CurrentItem>;
};

const CardDetailsPageContent = () => {
  const { authorizationId, unitCoDepositAccountId, transactionId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { card } = useCardDetailsPageContext();
  const redirectTo = `/cards/${card.id}?${searchParams}`;

  return (
    <>
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => {
          // NB(alex): Necessary for resetting the error boundary after navigation takes place.
          resetErrorBoundary();

          if (error instanceof RequiredButNotFoundError) {
            return <Navigate to={redirectTo} state={DISABLE_SCROLL_TO_TOP_STATE} />;
          }

          // Trigger a different error boundary if it isn't a RequiredButNotFoundError
          throw error;
        }}
      >
        <CardAuthorizationFlexpane
          authorizationId={authorizationId}
          onClose={() => navigate(redirectTo, { state: DISABLE_SCROLL_TO_TOP_STATE })}
        />

        <TransactionInfoFlexpane
          params={
            unitCoDepositAccountId && transactionId
              ? {
                  unitCoDepositAccountId: unitCoDepositAccountId,
                  transactionId: transactionId,
                }
              : undefined
          }
          onClose={() => navigate(redirectTo, { state: DISABLE_SCROLL_TO_TOP_STATE })}
        />
      </ErrorBoundary>

      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/cards">Cards</Breadcrumbs.Item>
          <Suspense fallback={<Breadcrumbs.CurrentItemShimmer />}>
            <CardDetailsBreadcrumb />
          </Suspense>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <CardDetailsHeader />

        <DashboardPage.Section>
          <CardDetailsInfo />
        </DashboardPage.Section>

        <DashboardPage.Section>
          <PendingAuthorizationsTable />
        </DashboardPage.Section>

        <DashboardPage.Section>
          <CardTransactionsTable />
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

const CardDetailsPage = () => {
  return (
    <CardDetailsPageProvider>
      <CardDetailsPageContent />
    </CardDetailsPageProvider>
  );
};

export default CardDetailsPage;
