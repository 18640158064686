import { Bank, ChartLine, Coins, Info } from "@phosphor-icons/react";
import bankIcon from "assets/bank-icon.svg";
import clock from "assets/clock.svg";
import storefrontSyncing from "assets/storefront-syncing.svg";
import OnboardingCardLayout from "modules/onboarding/layouts/OnboardingCardLayout";
import { FC, ReactElement } from "react";
import { useResetRecoilState } from "recoil";
import BankApplicationRep from "reps/BankApplicationRep";
import useArchiveBusinessMutation from "resources/businesses/mutations/useArchiveBusinessMutation";
import businessesByMemberUserQueryHooks from "resources/businesses/queries/businessesByMemberUserQueryHooks";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import businessGuidState from "state/auth/businessGuid";
import colors from "styles/colors";
import AccentCard from "ui/data-display/AccentCard";
import Pill from "ui/data-display/Pill";
import DotsLoader from "ui/feedback/DotsLoader";
import HighbeamLogo from "ui/icons/HighbeamLogo";
import Button from "ui/inputs/Button";
import { Heading3, Heading4, Paragraph, Span } from "ui/typography";

const StoreSyncIndicator = () => (
  <div className="mb-6 flex items-center gap-x-3">
    <img className="" src={storefrontSyncing} alt="Store loading icon" />
    <DotsLoader dots={6} small />
    <HighbeamLogo className="size-8" />
  </div>
);

const Highlight: React.FC<{ icon: ReactElement; text: string }> = ({ icon, text }) => (
  <div className="flex min-w-32 flex-grow items-center gap-x-2 rounded-lg bg-white p-3">
    <div className="flex rounded-full bg-purple-50 p-2">{icon}</div>
    <Span className="text-xs font-medium">{text}</Span>
  </div>
);

type Props = {
  bankApplication: BankApplicationRep.Complete;
  fromConnectStore: boolean;
};

const OnboardingStartBankApplicationCard: FC<Props> = ({ bankApplication, fromConnectStore }) => {
  const resetBusinessGuid = useResetRecoilState(businessGuidState);

  const userGuid = useUserGuid();
  const hasAtLeastOneActiveBusiness = businessesByMemberUserQueryHooks.useData({
    userGuid,
    select: (businesses) => businesses.some((business) => business.status === "Active"),
  });

  const { mutate: archiveBusinessApplication, isPending: isArchivingBusinessApplication } =
    useArchiveBusinessMutation({
      onSuccess: () => {
        resetBusinessGuid();
        window.location.href = "/"; // Intended to force a refresh.
      },
    });

  return (
    <OnboardingCardLayout
      header={fromConnectStore ? <StoreSyncIndicator /> : null}
      title={
        fromConnectStore ? "We're crunching your store numbers" : "Apply for a Highbeam account"
      }
      footer={
        <div className="flex gap-x-4">
          {hasAtLeastOneActiveBusiness && (
            <Button
              variant="default"
              isLoading={isArchivingBusinessApplication}
              onClick={() => {
                archiveBusinessApplication({
                  businessGuid: bankApplication.businessGuid,
                });
              }}
            >
              Cancel
            </Button>
          )}

          {/* TODO: HB-5976 */}
          <a href={bankApplication.url!}>
            <Button variant="primary">Start application</Button>
          </a>
        </div>
      }
    >
      <AccentCard withTopBorder>
        <div className="mb-4">
          {fromConnectStore ? (
            <img src={bankIcon} className="size-[3.25rem]" alt="Bank logo" />
          ) : (
            <HighbeamLogo className="size-8" />
          )}
        </div>

        <Heading3 className="mb-6">
          {fromConnectStore
            ? "Let’s get started on your Highbeam banking application"
            : "Experience business banking like never before"}
        </Heading3>

        <div className="flex flex-col gap-3 tablet:flex-row">
          <Highlight
            icon={<Bank size={16} color={colors.purple[500]} />}
            text="Best-in-class banking"
          />
          <Highlight
            icon={<ChartLine size={16} color={colors.purple[500]} />}
            text="Cash flow insights"
          />
          <Highlight
            icon={<Coins size={16} color={colors.purple[500]} />}
            text="Transparent credit"
          />
        </div>

        <div className="mt-7">
          <div className="mb-3 flex items-center justify-between py-3">
            <Heading4>You’ll need</Heading4>
            <Pill color="purple-light" iconLeft={<img src={clock} alt="" />}>
              3-5 minutes
            </Pill>
          </div>
          <ul className="ml-1.5 flex list-inside list-disc flex-col gap-y-3 text-xs text-grey-600">
            <li>Social security number (SSN)</li>
            <li>Employer identification number (EIN)</li>
          </ul>
        </div>

        <div className="mt-9 flex gap-x-3 p-1">
          <Info size={12} color={colors.purple[500]} className="shrink-0" />

          <Paragraph className="text-xs">
            This will not affect your personal credit score. We require this information to ensure
            security and compliance with federal regulations. We never share this information.
          </Paragraph>
        </div>
      </AccentCard>
    </OnboardingCardLayout>
  );
};

export default OnboardingStartBankApplicationCard;
