import {
  ButtonHTMLAttributes,
  ForwardRefRenderFunction,
  MouseEvent,
  ReactNode,
  forwardRef,
  useRef,
  useState,
} from "react";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import cn from "utils/tailwind/cn";
import variants from "utils/tailwind/variants";

// Exemplar: usage of `forwardRef`

export type ButtonVariant =
  | "default"
  | "primary"
  | "secondary"
  | "tertiary"
  | "danger"
  | "ghost"
  | "plain";

export type ButtonPaddingVariant = "regular" | "square" | "bare";

export type ButtonSize = "md" | "sm" | "xs";

export const getButtonVariantClasses = (variant: ButtonVariant): string => {
  return variants(variant, {
    default:
      "bg-transparent text-purple-500 data-[disabled=false]:hover:bg-grey-50 data-[disabled=false]:hover:shadow-none data-[disabled=false]:active:shadow-inset",
    primary:
      "bg-purple-500 border border-transparent text-grey-50 data-[disabled=false]:hover:bg-purple-600 data-[disabled=false]:active:shadow-inset-purple-dark",
    secondary:
      "bg-purple-50 border border-purple-100 shadow-xs text-purple-500 data-[disabled=false]:hover:bg-purple-100 data-[disabled=false]:hover:shadow-sm data-[disabled=false]:active:shadow-inset-purple",
    tertiary:
      "bg-white border border-grey-200 text-grey-800 shadow-xs data-[disabled=false]:hover:bg-grey-100 data-[disabled=false]:hover:shadow-none data-[disabled=false]:active:shadow-inset",
    danger:
      "bg-red-50 border border-red-100 shadow-xs text-red-500 data-[disabled=false]:hover:bg-red-100 data-[disabled=false]:active:shadow-inset-red",
    ghost: "text-grey-800",
    plain: "text-inherit",
  });
};

export const getButtonSizeClasses = (size: ButtonSize): string => {
  return variants(size, {
    md: "text-sm gap-2",
    sm: "text-xs gap-1",
    xs: "text-xs gap-1",
  });
};

export const getButtonPaddingVariantClasses = (
  paddingVariant: ButtonPaddingVariant,
  size: ButtonSize
): string => {
  return variants(paddingVariant, {
    regular: variants(size, {
      md: "px-4 py-2.5",
      sm: "px-3 py-2",
      xs: "px-2 py-1",
    }),
    square: variants(size, {
      md: "p-2.5",
      sm: "p-2",
      xs: "p-1",
    }),
    bare: "p-0",
  });
};

export const baseButtonClasses =
  "inline-flex h-fit items-center justify-center whitespace-nowrap rounded-md font-medium data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-50";

type GetButtonClassesParams = {
  variant: ButtonVariant;
  paddingVariant: ButtonPaddingVariant;
  size: ButtonSize;
};

export const getButtonClasses = ({ variant, paddingVariant, size }: GetButtonClassesParams) => {
  return cn(
    baseButtonClasses,
    getButtonVariantClasses(variant),
    getButtonSizeClasses(size),
    getButtonPaddingVariantClasses(paddingVariant, size)
  );
};

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: ButtonVariant;
  paddingVariant?: ButtonPaddingVariant;
  size?: ButtonSize;
  isLoading?: boolean;
  tooltip?: ReactNode;
};

const Button: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  {
    variant = "default",
    paddingVariant = "regular",
    className,
    size = "md",
    isLoading,
    disabled,
    children,
    tooltip,
    onMouseOver,
    onMouseOut,
    ...props
  },
  ref
) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const hasForwardedRef = ref && "current" in ref && ref.current;
  const referenceElement = hasForwardedRef ? ref.current : buttonRef.current;
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseOver = (e: MouseEvent<HTMLButtonElement>) => {
    setIsHovered(true);
    onMouseOver && onMouseOver(e);
  };

  const handleMouseOut = (e: MouseEvent<HTMLButtonElement>) => {
    setIsHovered(false);
    onMouseOut && onMouseOut(e);
  };

  const dataDisabled = Boolean(disabled || isLoading);

  return (
    <>
      {tooltip && referenceElement && isHovered && (
        <Tooltip
          closeTooltip={() => setIsHovered(false)}
          openTooltip={() => setIsHovered(true)}
          referenceElement={referenceElement}
          rootElementId={"tooltip"}
        >
          {typeof tooltip === "string" ? <Tooltip.Content>{tooltip}</Tooltip.Content> : tooltip}
        </Tooltip>
      )}

      <button
        type="button"
        ref={ref ? ref : buttonRef}
        className={cn(
          getButtonClasses({
            variant,
            paddingVariant,
            size,
          }),
          className
        )}
        disabled={dataDisabled}
        data-disabled={dataDisabled}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        {...props}
      >
        {isLoading && <AnimatedSpinner />}
        {children}
      </button>
    </>
  );
};

// This ensures the component name is correct when inferred by storybook.
Button.displayName = "Button";

export default forwardRef(Button);
