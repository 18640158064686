import { captureException } from "@sentry/react";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useSendReminderEmailMutation = (
  billApprovalId: string,
  additionalOptions?: MutationAdditionalOptions<void, void>
) => {
  const highbeamApi = useHighbeamApi();

  return useMutationWithDefaults(
    {
      mutationFn: async () => {
        await highbeamApi.billApprovalAction.sendReminder(billApprovalId);
      },
      onSuccess: () => {
        notify("success", "Reminder sent");
      },
      onError: (error) => {
        captureException(error);
        notify("error", error.message ?? "Something went wrong! Please try again.");
      },
    },
    additionalOptions || {}
  );
};

export default useSendReminderEmailMutation;
