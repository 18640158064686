import MultiStep from "layouts/MultiStep";

import { useReviewLineOfferContext } from "../context/ReviewLineOfferProvider";

const ReviewLineOfferBackButton = () => {
  const { prevPathname } = useReviewLineOfferContext();

  if (!prevPathname) {
    return null;
  }

  return <MultiStep.Controls.BackButtonLink disabled={!Boolean(prevPathname)} to={prevPathname} />;
};

export default ReviewLineOfferBackButton;
