import { Coins } from "@phosphor-icons/react";
import HighYieldIcon from "assets/high-yield-icon.svg?react";
import HighbeamIcon from "assets/highbeam-logo-without-label.svg?react";
import { CSSProperties, FC } from "react";
import colors from "styles/colors";
import cn from "utils/tailwind/cn";

// NB(alex): wip! would like to account all platforms eventually
export type PlatformName = "highbeam" | "highbeam-high-yield" | "highbeam-capital";

export type BaseIconProps = {
  className?: string;
  size?: number;
  style?: CSSProperties;
};

type Props = BaseIconProps & {
  platformName: PlatformName;
};

const PlatformIcon: FC<Props> = ({ platformName, className, ...baseIconProps }) => {
  const classes = cn("shrink-0", className);

  switch (platformName) {
    case "highbeam":
      return <HighbeamIcon className={classes} {...baseIconProps} />;
    case "highbeam-high-yield":
      return <HighYieldIcon className={classes} {...baseIconProps} />;
    case "highbeam-capital":
      return (
        <Coins color={colors.purple[500]} alt="Capital" className={classes} {...baseIconProps} />
      );
  }
};

PlatformIcon.defaultProps = {
  size: 18,
};

export default PlatformIcon;
