import { Money } from "@phosphor-icons/react";
import checkIcon from "assets/check-deposit-icon.svg";
import successGif from "assets/success.gif";
import PaymentDetailsCard from "components/PaymentDetailsCard";
import { CHECK_DEPOSIT_CUTOFF_WARNING } from "config/constants/banking";
import { PaymentMethod } from "pages/SendMoneyPage/utils";
import { useNavigate } from "react-router-dom";
import InfoBox from "ui/data-display/InfoBox";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./CheckDepositProcessed.module.scss";

type Props = {
  amount: number;
  to: React.ReactNode;
  description: string;
  makeAnotherDeposit: () => void;
};

const CheckDepositProcessed: React.FC<Props> = ({
  amount,
  to,
  description,
  makeAnotherDeposit,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <img className={styles["success-gif"]} src={`${successGif}`} alt="success" />
        <h1 className={styles.text}>Check deposit is processing</h1>
        <Text size={14}>
          You can track the status of the deposit under{" "}
          <Money size={14} className={styles.moneyIcon} /> Accounts / Transactions.
        </Text>
      </div>
      <PaymentDetailsCard
        paymentMethod={PaymentMethod.CHECK}
        iconBesideAmount={<img src={checkIcon} alt="check icon" className={styles.checkIcon} />}
        amountInCents={amount}
        to={to}
        descriptionMessage={description}
        toLabel={"Deposit to"}
      />
      <div className={styles.buttons}>
        <Button className={styles.button} variant="tertiary" onClick={makeAnotherDeposit}>
          Make another deposit
        </Button>
        <Button
          className={styles.button}
          variant="primary"
          onClick={() => {
            navigate("/accounts/transactions");
          }}
          autoFocus
        >
          Track status
        </Button>
      </div>
      <div>
        <InfoBox variant="info" iconSize={24} text={CHECK_DEPOSIT_CUTOFF_WARNING} />
      </div>
    </div>
  );
};

export default CheckDepositProcessed;
