import {
  ArrowLeft,
  ArrowLineUpRight,
  ArrowRight,
  CaretDown,
  DotsThree,
  Pencil,
  Star,
  Trash,
} from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import CloseBankAccountModal from "pages/accounts/CloseBankAccountModal";
import { useCloseBankAccountModal } from "pages/accounts/CloseBankAccountModal/state/closeBankAccountModalState";
import EditBankAccountModal from "pages/accounts/EditAccountNameModal";
import { useEditAccountNameModal } from "pages/accounts/EditAccountNameModal/state/editAccountNameModalState";
import SetAsPrimaryAccountModal from "pages/accounts/SetAsPrimaryAccountModal";
import { useSetPrimaryAccountModal } from "pages/accounts/SetAsPrimaryAccountModal/state/setPrimaryAccountModalState";
import { useNavigate } from "react-router-dom";
import BankAccountRep from "reps/BankAccountRep";
import isValidBankAccountToClose from "resources/bank-accounts/utils/isValidBankAccountToClose";
import isValidBankAccountToSetAsPrimary from "resources/bank-accounts/utils/isValidBankAccountToSetAsPrimary";
import isValidStatusForMoneyMovement from "resources/bank-accounts/utils/isValidStatusForMoneyMovement";
import ChequeIcon from "ui/icons/ChequeIcon";
import Button from "ui/inputs/Button";
import Menu from "ui/overlay/Menu";
import { getAccountHeaderName } from "utils/account";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useIsAllowedToNavigateToDepositCheckPage from "utils/permissions/navigation/useIsAllowedToNavigateToDepositCheckPage";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import useIsAllowedToCloseBankAccount from "utils/permissions/useIsAllowedToCloseBankAccount";
import useIsAllowedToCreateDraftPayments from "utils/permissions/useIsAllowedToCreateDraftPayments";

type Props = {
  account: BankAccountRep.Complete;
};

const AccountDetailsPageHeader: React.FC<Props> = ({ account }) => {
  const navigate = useNavigate();
  const isAllowedToCreatePaymentDrafts = useIsAllowedToCreateDraftPayments();
  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const isCloseBankAccountEnabled = useFeatureFlag("CLOSE_BANK_ACCOUNT");
  const isAllowedToCloseBankAccount = useIsAllowedToCloseBankAccount();
  const isAllowedToNavigateToDepositCheckPage = useIsAllowedToNavigateToDepositCheckPage();

  const { open: openEditBankAccountModal } = useEditAccountNameModal();
  const { open: openSetPrimaryAccountModal } = useSetPrimaryAccountModal();
  const { open: openCloseBankAccountModal } = useCloseBankAccountModal();

  return (
    <DashboardPage.Header
      actions={
        isValidStatusForMoneyMovement(account) && (
          <>
            <Menu
              button={
                <Button variant="secondary">
                  Move money
                  <CaretDown size={14} />
                </Button>
              }
            >
              {(isAllowedToCreatePaymentDrafts || isAllowedToApprovePayments) &&
                account.highbeamType.includeInSendMoney && (
                  <Menu.Item
                    icon={<ArrowLineUpRight size={16} />}
                    onClick={() => {
                      navigate("/send-money", { state: { accountGuid: account.guid } });
                    }}
                  >
                    {isAllowedToApprovePayments ? "Send money" : "Draft a payment"}
                  </Menu.Item>
                )}

              <Menu.Item
                icon={<ArrowRight size={16} />}
                onClick={() => {
                  navigate("/transfer-money", { state: { fromAccountGuid: account.guid } });
                }}
              >
                Transfer from this account
              </Menu.Item>

              <Menu.Item
                icon={<ArrowLeft size={16} />}
                onClick={() => {
                  navigate("/transfer-money", { state: { toAccountGuid: account.guid } });
                }}
              >
                Transfer to this account
              </Menu.Item>

              {isAllowedToNavigateToDepositCheckPage && (
                // NB(alex): We hide the menu item if they don't have permission because the tooltip is currently not supported on the menu item.
                <Menu.Item
                  icon={<ChequeIcon size={16} />}
                  onClick={() => {
                    navigate("/deposit-check", { state: { toAccountGuid: account.guid } });
                  }}
                >
                  Deposit check to this account
                </Menu.Item>
              )}
            </Menu>

            <Menu
              button={
                <Button variant="tertiary" paddingVariant="square">
                  <DotsThree size={16} />
                </Button>
              }
            >
              <Menu.Item
                icon={<Pencil size={16} />}
                onClick={() => {
                  openEditBankAccountModal({ bankAccount: account });
                }}
              >
                Edit account name
              </Menu.Item>

              {isCloseBankAccountEnabled &&
                isAllowedToCloseBankAccount &&
                isValidBankAccountToSetAsPrimary(account) && (
                  <Menu.Item
                    icon={<Star size={16} />}
                    onClick={() => {
                      openSetPrimaryAccountModal({ bankAccount: account });
                    }}
                  >
                    Set as primary account
                  </Menu.Item>
                )}

              {isCloseBankAccountEnabled &&
                isAllowedToCloseBankAccount &&
                isValidBankAccountToClose(account) && (
                  <Menu.Item
                    icon={<Trash size={16} />}
                    variant="danger"
                    onClick={() => {
                      openCloseBankAccountModal({
                        bankAccount: account,
                      });
                    }}
                  >
                    Close account
                  </Menu.Item>
                )}
            </Menu>
          </>
        )
      }
    >
      <DashboardPage.Header.BackCaret to="/accounts" />
      <DashboardPage.Header.Title>{getAccountHeaderName(account)}</DashboardPage.Header.Title>

      <SetAsPrimaryAccountModal />
      <EditBankAccountModal />
      <CloseBankAccountModal
        onSuccess={() => {
          navigate("/accounts");
        }}
      />
    </DashboardPage.Header>
  );
};

export default AccountDetailsPageHeader;
