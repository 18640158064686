import { IncomingPayment } from "modules/incoming-payments/types";
import Pill, { PillColor } from "ui/data-display/Pill";

// NB(alex): This component was originally in the flexpane. Had to split it off because we are getting rid of `HighbeamPayment`.

type StatusLabel = {
  text: string;
  pillColor: PillColor;
};

const pending: StatusLabel = {
  text: "Pending",
  pillColor: "grey-light",
};

const failed: StatusLabel = {
  text: "Failed",
  pillColor: "pink-dark",
};

const canceled: StatusLabel = {
  text: "Canceled",
  pillColor: "orange",
};

const getLabel = (payment: IncomingPayment): StatusLabel | undefined => {
  switch (payment.attributes.status) {
    // TODO(alex): Audit
    case "Pending":
    case "PendingReview":
    case "Clearing":
      return pending;
    case "Rejected":
    case "Returned":
      return failed;
    case "Canceled":
      return canceled;
    default:
      return undefined;
  }
};

type Props = {
  payment: IncomingPayment;
  className?: string;
};

const IncomingPaymentStatusPill: React.FC<Props> = ({ payment, className }) => {
  const label = getLabel(payment);

  if (!label) return null;

  return (
    <Pill bordered color={label.pillColor} className={className}>
      {label.text}
    </Pill>
  );
};

export default IncomingPaymentStatusPill;
