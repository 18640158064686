import IconWithTooltip from "components/common/IconWithTooltip";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalAccountRepaymentPeriodContent from "resources/capital-accounts/components/CapitalAccountRepaymentPeriodContent";
import CapitalPromotionalAprLabel from "resources/capital-accounts/components/CapitalPromotionalAprLabel";
import colors from "styles/colors";
import CashDisplay from "ui/data-display/money/CashDisplay";
import SummaryArray from "ui/data-display/SummaryArray";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import { roundEpsilon } from "utils/math";

type Props = {
  capitalAccountSummaryCashAccessOnly: CapitalAccountSummaryRep.CashAccessOnlyComplete;
};

const LineOfCreditSummaryItems = ({ capitalAccountSummaryCashAccessOnly }: Props) => {
  const isCapitalPromotionalRateEnabled = useFeatureFlag("CAPITAL_PROMOTIONAL_RATES");

  return (
    <SummaryArray className="w-full bg-white">
      <SummaryArray.Item>
        <SummaryArray.Heading>
          Starting limit
          <IconWithTooltip
            color="dark"
            content="This limit will grow over time with healthy credit usage"
          />
        </SummaryArray.Heading>
        <CashDisplay cents={capitalAccountSummaryCashAccessOnly.details.limit} />
      </SummaryArray.Item>

      {capitalAccountSummaryCashAccessOnly.details.netApr > 0 && (
        <SummaryArray.Item>
          <SummaryArray.Heading>
            {isCapitalPromotionalRateEnabled ? (
              <CapitalPromotionalAprLabel withGradient />
            ) : (
              <>
                <Text size={14}>APR</Text>
                <IconWithTooltip
                  color="dark"
                  content="Interest is charged daily based on the current balance"
                />
              </>
            )}
          </SummaryArray.Heading>

          {isCapitalPromotionalRateEnabled ? (
            <SummaryArray.Value className="flex items-start gap-x-2">
              10.00%
              <Text size={16} color={colors.grey[600]} weight="medium" lineThrough className="pt-1">
                {roundEpsilon(capitalAccountSummaryCashAccessOnly.details.netApr * 100, 2)}%
              </Text>
            </SummaryArray.Value>
          ) : (
            <SummaryArray.Value>
              {roundEpsilon(capitalAccountSummaryCashAccessOnly.details.netApr * 100, 2)}%
            </SummaryArray.Value>
          )}
        </SummaryArray.Item>
      )}

      <SummaryArray.Item>
        <SummaryArray.Heading>Repayment period</SummaryArray.Heading>
        <SummaryArray.Value>
          <CapitalAccountRepaymentPeriodContent
            capitalAccountSummary={capitalAccountSummaryCashAccessOnly}
          />
        </SummaryArray.Value>
      </SummaryArray.Item>
    </SummaryArray>
  );
};

export default LineOfCreditSummaryItems;
