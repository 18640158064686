import { Lock } from "@phosphor-icons/react";
import Text from "ui/typography/Text";

import styles from "./HighbeamCard.module.scss";

const HighbeamCardLocked = () => (
  <div className={styles.lockedContainer}>
    <Lock size={12} weight={"bold"} className={styles.lockedColor} />
    <Text size={12} weight={"bold"} className={styles.lockedColor}>
      Locked
    </Text>
  </div>
);

export default HighbeamCardLocked;
