import { ArrowsCounterClockwise, CheckCircle } from "@phosphor-icons/react";
import dayjs, { Dayjs } from "dayjs";
import { FC } from "react";
import colors from "styles/colors";
import HighlightItem from "ui/data-display/HighlightItem";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Pill from "ui/data-display/Pill";
import Text from "ui/typography/Text";

const GREEN = colors.green[600];

const NothingDueHighlightItem = () => {
  return (
    <HighlightItem
      sideRuleColor="success"
      label={
        <Text weight="medium" color={GREEN}>
          Nothing due
        </Text>
      }
      value={<CheckCircle size={32} color={GREEN} weight="light" />}
      subtext={<Text color={GREEN}>You’re up to date</Text>}
    />
  );
};

type Props = {
  balanceDueDate: Dayjs;
  balanceDueAmountInCents: number;
};

const ChargeCardRepaymentHighlightItem: FC<Props> = ({
  balanceDueDate,
  balanceDueAmountInCents,
}) => {
  const isTodaySameMonthAsBalanceDueDate = dayjs().isSame(balanceDueDate, "month");

  return (
    <>
      {isTodaySameMonthAsBalanceDueDate ? (
        balanceDueAmountInCents === 0 ? (
          <NothingDueHighlightItem />
        ) : (
          <HighlightItem
            label={
              <>
                {balanceDueDate.subtract(1, "month").format("MMM")} balance (due{" "}
                {balanceDueDate.format("MMM D")})
              </>
            }
            value={
              <MoneyAmount
                size={20}
                weight="medium"
                centsTextSize={16}
                centsTextWeight="regular"
                cents={balanceDueAmountInCents}
              />
            }
            subtext={
              <>
                <ArrowsCounterClockwise />
                <Text>Auto-pay</Text>
                <Pill color="green">On</Pill>
              </>
            }
          />
        )
      ) : (
        <HighlightItem
          label={<>Due {balanceDueDate.format("MMM D")}</>}
          value={
            <Text size={20} weight="medium" color={colors.grey[900]}>
              <MoneyAmount
                size={20}
                weight="medium"
                centsTextSize={16}
                centsTextWeight="regular"
                cents={balanceDueAmountInCents}
              />
            </Text>
          }
          subtext={
            <>
              <ArrowsCounterClockwise />
              <Text>Auto-pay</Text>
              <Pill color="green">On</Pill>
            </>
          }
        />
      )}
    </>
  );
};

export default ChargeCardRepaymentHighlightItem;
