import { Card } from "@highbeam/unit-node-sdk";
import HighbeamCard from "components/HighbeamCard";
import useMfa from "modules/mfa/useMfa";
import { FC, useState } from "react";
import ActivateCardModal from "resources/cards/dialogs/ActivateCardModal";
import ItemWithTooltip from "ui/overlay/Tooltip/ItemWithTooltip/ItemWithTooltip";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import useIsAllowedToActivateCard from "utils/permissions/useIsAllowedToActivateCard";
import cn from "utils/tailwind/cn";

type Props = {
  card: Card;
};

const InactivePhysicalCard: FC<Props> = ({ card }) => {
  const isAllowedtoActivateCard = useIsAllowedToActivateCard(card);
  const { mfa } = useMfa();
  const [activateCard, setActivateCard] = useState<Card>();

  return (
    <>
      {activateCard && (
        <ActivateCardModal card={activateCard} onClose={() => setActivateCard(undefined)} />
      )}

      <ItemWithTooltip
        key={card.id}
        shouldRenderTooltip={!isAllowedtoActivateCard}
        wrapperClassName={cn(!isAllowedtoActivateCard && "cursor-not-allowed opacity-50")}
        content={<Tooltip.Content>You don’t have permission to activate cards.</Tooltip.Content>}
      >
        <HighbeamCard
          key={card.id}
          card={card}
          areDetailsAvailable
          showActivateCardButton
          containerClickable={isAllowedtoActivateCard}
          onClick={() => {
            if (isAllowedtoActivateCard) {
              mfa().then(() => setActivateCard(card));
            }
          }}
        />
      </ItemWithTooltip>
    </>
  );
};

export default InactivePhysicalCard;
