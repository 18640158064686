import { forwardRef } from "react";
import Button, { ButtonProps } from "ui/inputs/Button";
import cn from "utils/tailwind/cn";

const ButtonArrayButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => {
    return (
      <Button ref={ref} {...props} className={cn("whitespace-nowrap rounded-none", className)} />
    );
  }
);

export default ButtonArrayButton;
