import { Authorization } from "@highbeam/unit-node-sdk";
import { DISABLE_SCROLL_TO_TOP_STATE } from "components/navigation/ScrollToTopOnNavigate";
import TransactionsV2 from "components/TransactionsV2";
import { FC, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { CardAvatarBarByCardId } from "resources/cards/components/CardAvatarBar";
import getCounterpartyVendor from "resources/counterparty-vendor/utils/getCounterpartyVendor";
import PageIndicator from "ui/navigation/PageIndicator";
import AmountCell from "ui/table/AmountCell";
import CounterpartyCell from "ui/table/CounterpartyCell";
import DateTimeCell from "ui/table/DateTimeCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";

import useCardAuthorizations, {
  AUTHORIZATIONS_PAGE_SIZE,
  useCurrentPageSearchParam,
} from "./hooks/useCardAuthorizations";

const columns: Column<Authorization>[] = [
  {
    title: "Date",
    cellRender: ({ attributes }) => <DateTimeCell date={attributes.createdAt} />,
  },
  {
    title: "Vendor",
    cellRender: (authorization) => {
      const counterpartyVendor = getCounterpartyVendor(authorization);

      if (counterpartyVendor) {
        return (
          <CounterpartyCell>
            {counterpartyVendor.name}
            <img src={counterpartyVendor.logo} alt={counterpartyVendor.name} />
          </CounterpartyCell>
        );
      } else {
        return <CounterpartyCell>{authorization.attributes.merchant.name}</CounterpartyCell>;
      }
    },
  },
  {
    title: "Card",
    cellRender: ({ relationships }) => (
      <CardAvatarBarByCardId cardId={relationships.card.data.id} />
    ),
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: ({ attributes: { amount } }) => <AmountCell cents={amount} direction="negative" />,
  },
];

type Props = {
  accountType?: "deposit" | "credit";
};

/**
 * CardsAuthorizationsTable component renders a table of card authorizations.
 * - If `accountType` is provided, it filters authorizations specific to that account type.
 * - If `accountType` is not provided, it displays all cards authorizations.
 */
const CardsAuthorizationsTable: FC<Props> = ({ accountType }) => {
  const { authorizations } = useCardAuthorizations({ accountType });
  const navigate = useNavigate();

  return (
    <TransactionsV2.TransactionsTable
      data={authorizations}
      rowKey={({ id }) => id}
      columns={columns}
      onRowClick={(authorization) =>
        navigate(`/cards/transactions/pending/${authorization.id}`, {
          state: DISABLE_SCROLL_TO_TOP_STATE,
        })
      }
    />
  );
};

const AuthorizationsPageIndicator: FC<Props> = ({ accountType }) => {
  const [currentPage, setCurrentPage] = useCurrentPageSearchParam();
  const page = useCardAuthorizations({ accountType }).pagination;

  const totalPages = Math.ceil(page.total / AUTHORIZATIONS_PAGE_SIZE);
  if (totalPages <= 1) return null;

  return (
    <PageIndicator
      currentPage={currentPage - 1}
      setCurrentPage={(value) => setCurrentPage(value + 1)}
      totalPages={totalPages}
    />
  );
};

const CardAuthorizations: FC<Props> = ({ accountType }) => {
  return (
    <TransactionsV2
      title={<TransactionsV2.Title>Pending</TransactionsV2.Title>}
      table={
        <Suspense fallback={<Table.Loading columns={columns.map(({ title }) => ({ title }))} />}>
          <CardsAuthorizationsTable accountType={accountType} />
        </Suspense>
      }
      footer={<TransactionsV2.Footer pageIndicator={<AuthorizationsPageIndicator />} />}
    />
  );
};

export default CardAuthorizations;
