import { PlusCircle as PlusCircleIcon } from "@phosphor-icons/react";
import { captureException } from "@sentry/react";
import { FC, PropsWithChildren, useCallback, useMemo, useEffect } from "react";
import BillSummaryRep from "reps/BillSummaryRep";
import BillSyncErrorRep from "reps/BillSyncErrorRep";
import ContactSupportToConnectAccounting from "resources/accounting-accounts/components/ContactSupportToConnectAccounting";
import { useApSettings } from "resources/ap-settings/queries/apSettingsQueryHooks";
import useCreateBillLineItemMutation from "resources/bill-line-items/mutations/useCreateBillLineItemMutation";
import inferBillLineItemCurrencyFromBill from "resources/bill-line-items/utils/inferBillLineItemCurrency";
import useBillSyncIsSyncing from "resources/bill-syncs/hooks/useBillSyncIsSyncing";
import useBillSyncQueryIfEnabled from "resources/bill-syncs/hooks/useBillSyncQueryIfEnabled";
import billSyncQueryHooks from "resources/bill-syncs/queries/billSyncQueryHooks";
import shouldBillSyncBeDryRun from "resources/bill-syncs/utils/shouldBillSyncBeDryRun";
import useBill from "resources/bills/queries/useBill";
import { usePayeeQuery } from "resources/payees/queries/usePayee";
import Alert from "ui/feedback/Alert";
import Button from "ui/inputs/Button";
import { Strong, TextButton } from "ui/typography";
import { useEventBusDispatcher } from "utils/event-bus";
import { parseMoneyFloat, formatMoney } from "utils/money";

import BillLineItemsTable from "../BillLineItemsTable";

const SYNC_ERROR_MESSAGE_PREAMBLE = "Changes not synced to accounting.";

const renderSyncErrorMessagePreamble = (dryRun: boolean) =>
  dryRun ? "" : `${SYNC_ERROR_MESSAGE_PREAMBLE} `;

export const BILL_LINE_ITEMS_UPDATE_PAYEE_REQUESTED_EVENT = "billLineItemsEditPayeeRequested";

const makeBillLineItemsUpdatePayeeRequestedEvent = () =>
  new CustomEvent(BILL_LINE_ITEMS_UPDATE_PAYEE_REQUESTED_EVENT);

type BillSyncErrorAlertTextContentProps = {
  error: BillSyncErrorRep.Complete;
  bill: BillSummaryRep.Complete;
};

const BillSyncErrorAlertTextContent: FC<BillSyncErrorAlertTextContentProps> = ({ error, bill }) => {
  const { type, message } = error;
  const { payeeGuid, id: billId } = bill;
  const dryRun = shouldBillSyncBeDryRun(bill);
  const { data: payee } = usePayeeQuery(payeeGuid || undefined);
  const isSyncing = useBillSyncIsSyncing(billId);
  const refreshBillSyncQuery = billSyncQueryHooks.useRefreshQuery({ billId, dryRun });
  const dispatchEvent = useEventBusDispatcher();

  if (type === "BillPayeeMissing") {
    return (
      <>
        {renderSyncErrorMessagePreamble(dryRun)}This bill does not have a payee. Please select from
        existing payees or add a new one.
      </>
    );
  }

  if (type === "LineItemAccountMissing") {
    return (
      <>
        {renderSyncErrorMessagePreamble(dryRun)}Line items are missing accounting categories.{" "}
        {payee && !payee.defaultChartOfAccountId && (
          <>
            Select accounting categories or set a default category for <Strong>{payee.name}</Strong>
            .{" "}
            <TextButton onClick={() => dispatchEvent(makeBillLineItemsUpdatePayeeRequestedEvent())}>
              Edit payee details
            </TextButton>
            .
          </>
        )}
      </>
    );
  }

  if (type === "LineItemsTotalDiscrepancy") {
    const { billAmount, discrepancyAmount } = error;
    // A positive discrepancy amount means the line items total is greater than the bill amount.
    // A negative discrepancy amount means the line items total is less than the bill amount.
    const isGreaterThan = parseMoneyFloat(discrepancyAmount.amount) > 0;
    const formattedDiscrepancyAmount = formatMoney(discrepancyAmount, {
      showCurrencySymbol: true,
    });
    const formattedBillAmount = formatMoney(billAmount, {
      showCurrencySymbol: true,
      showTrailingCurrencyCode: true,
      dropZeroCents: false,
    });

    return (
      <>
        {renderSyncErrorMessagePreamble(dryRun)}The line items total is{" "}
        <Strong>{formattedDiscrepancyAmount}</Strong> {isGreaterThan ? "greater" : "less"} than the
        bill amount of <Strong>{formattedBillAmount}</Strong>. Please edit line items or the bill
        amount so they match.
      </>
    );
  }

  if (type === "GenericError") {
    // For "GenericError" errors, the error message may come from Rutter.
    // Note that the Rutter error messages typically have a period at the end,
    // so we add one here conditionally.
    return (
      <>
        {renderSyncErrorMessagePreamble(dryRun)}
        {message}
        {message.endsWith(".") ? "" : "."}{" "}
        <TextButton disabled={isSyncing} onClick={() => refreshBillSyncQuery()}>
          Resync
        </TextButton>
      </>
    );
  }

  return (
    <>
      {renderSyncErrorMessagePreamble(dryRun)}
      {message}.
    </>
  );
};

const BillSyncErrorAlertBase: FC<PropsWithChildren> = ({ children }) => (
  <Alert
    variant="warning"
    className="mb-4 last-of-type:border-b-grey-100 tablet:mb-0 tablet:last-of-type:border-b"
  >
    <Alert.Icon />
    <Alert.Text>{children}</Alert.Text>
  </Alert>
);

type BillSyncEndpointErrorAlertProps = {
  error: unknown;
  bill: BillSummaryRep.Complete;
};

const BillSyncEndpointErrorAlert: FC<BillSyncEndpointErrorAlertProps> = ({ error, bill }) => {
  const dryRun = shouldBillSyncBeDryRun(bill);
  const billId = bill.id;
  const isSyncing = useBillSyncIsSyncing(billId);
  const refreshBillSyncQuery = billSyncQueryHooks.useRefreshQuery({ billId, dryRun });

  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <BillSyncErrorAlertBase>
      {renderSyncErrorMessagePreamble(dryRun)}There was an error syncing this bill. Please try
      again.{" "}
      <TextButton disabled={isSyncing} onClick={() => refreshBillSyncQuery()}>
        Resync
      </TextButton>
    </BillSyncErrorAlertBase>
  );
};

type BillSyncErrorAlertProps = {
  error: BillSyncErrorRep.Complete;
  bill: BillSummaryRep.Complete;
};

const BillSyncErrorAlert: FC<BillSyncErrorAlertProps> = ({ error, bill }) => (
  <BillSyncErrorAlertBase>
    <BillSyncErrorAlertTextContent error={error} bill={bill} />
  </BillSyncErrorAlertBase>
);

const dedupBillSyncErrors = (errors: BillSyncErrorRep.Complete[]) => {
  // We dedupe most error types because, if they occur multiple times, they are redundant.
  // This does *not* apply to all error types (e.g. "GenericError" errors).
  const seenErrorTypes = new Set<string>();

  return errors.filter((error) => {
    const { type } = error;

    if (type !== "GenericError") {
      if (seenErrorTypes.has(type)) {
        return false;
      }
      seenErrorTypes.add(type);
    }

    return true;
  });
};

const AccountingSyncNotEnabledAlert: FC = () => (
  <Alert className="mb-4 items-center border-b-grey-100 tablet:mb-0 tablet:border-b">
    <Alert.Icon />
    <Alert.Text>
      Please contact support to connect your accounting software to Highbeam Bill Pay.{" "}
      <ContactSupportToConnectAccounting />
    </Alert.Text>
  </Alert>
);

type Props = {
  billId: string;
};

const BillLineItemsContent: FC<Props> = ({ billId }) => {
  const bill = useBill(billId, { required: true });
  const currency = inferBillLineItemCurrencyFromBill(bill);
  const { payeeGuid } = bill;
  const { data: payee } = usePayeeQuery(payeeGuid || undefined);

  const { accountingSyncEnabled } = useApSettings();

  const { data: billSync, error: billSyncEndpointError } = useBillSyncQueryIfEnabled(billId);
  const billSyncErrorsToShow = useMemo(
    () => dedupBillSyncErrors(billSync?.errors ?? []),
    [billSync]
  );
  const hasBillSyncErrorsToShow = billSyncErrorsToShow.length > 0;

  const { mutate: createBillLineItem, isPending: isCreatingBillLineItem } =
    useCreateBillLineItemMutation(billId, {
      onSuccess: () => {},
    });

  const addItem = useCallback(() => {
    createBillLineItem({
      description: "",
      amount: {
        amount: "0",
        currency,
      },
      accountingAccountId: payee ? payee.defaultChartOfAccountId : null,
    });
  }, [createBillLineItem, currency, payee]);

  return (
    <div className="pb-2">
      {Boolean(billSyncEndpointError) && (
        <BillSyncEndpointErrorAlert error={billSyncEndpointError} bill={bill} />
      )}
      {!billSyncEndpointError && hasBillSyncErrorsToShow && (
        <div>
          {billSyncErrorsToShow.map((error) => (
            <BillSyncErrorAlert key={JSON.stringify(error)} error={error} bill={bill} />
          ))}
        </div>
      )}
      {!accountingSyncEnabled && <AccountingSyncNotEnabledAlert />}

      <BillLineItemsTable billId={billId} />

      <div className="px-4 pb-2 pt-2">
        <Button
          paddingVariant="bare"
          size="sm"
          isLoading={isCreatingBillLineItem}
          onClick={() => addItem()}
        >
          <PlusCircleIcon size={16} />
          Add item
        </Button>
      </div>
    </div>
  );
};

export default BillLineItemsContent;
