import IconWithTooltip from "components/common/IconWithTooltip";

export const CashBackInfoIconWithTooltip = () => {
  return (
    <IconWithTooltip
      color="dark"
      content="Cash back is paid directly to your Highbeam bank account"
    />
  );
};
