import { captureException } from "@sentry/core";
import PayeeRep from "reps/PayeeRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import { useRefreshPayeesQueries } from "../queries/usePayees";

type Variables = PayeeRep.Update & { payeeGuid: string };

const useUpdatePayeeMutation = (
  additionalOptions: MutationAdditionalOptions<PayeeRep.Complete, Variables> = {}
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const refreshPayeesQueries = useRefreshPayeesQueries();

  return useMutationWithDefaults(
    {
      mutationFn: ({ payeeGuid, ...update }) => {
        return highbeamApi.payee.update(businessGuid, payeeGuid, update);
      },
      onError: (error) => {
        captureException(error);
      },
      onSuccess: () => {
        refreshPayeesQueries();
      },
    },
    additionalOptions
  );
};

export default useUpdatePayeeMutation;
