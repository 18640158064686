import React, { ComponentProps, ReactElement } from "react";
import { Falsy, isNotFalsy } from "utils/array";
import cn from "utils/tailwind/cn";

import styles from "./ButtonArray.module.scss";
import ButtonArrayButton from "./ButtonArrayButton";

type ButtonArrayButtonProps = ComponentProps<typeof ButtonArrayButton>;

type ButtonArrayProps = {
  alignment: "horizontal" | "vertical";
  children:
    | ReactElement<ButtonArrayButtonProps>
    | Falsy
    | Array<ReactElement<ButtonArrayButtonProps> | Falsy>;
  className?: string;
};

const ButtonArray: React.FC<ButtonArrayProps> = ({
  alignment = "horizontal",
  className,
  children,
}) => {
  const childrenAsArray = Array.isArray(children) ? children : [children];
  const childrenWithoutFalsyValues = childrenAsArray.filter(isNotFalsy);

  // Don't render anything if `children` only has falsy values.
  if (childrenWithoutFalsyValues.length === 0) {
    return null;
  }

  const childrenWithProps = React.Children.map(childrenWithoutFalsyValues, (child, index) => {
    return React.cloneElement(child, {
      className: cn(child.props.className, {
        [styles.first]: index === 0,
        [styles.last]: index === React.Children.count(childrenWithoutFalsyValues) - 1,
      }),
    });
  });

  return <div className={cn(styles[alignment], className)}>{childrenWithProps}</div>;
};

export default Object.assign(ButtonArray, {
  Button: ButtonArrayButton,
});
