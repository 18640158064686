import { captureException } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import plaidAccountsQueryHooks from "../queries/plaidAccountsQueryHooks";

type Params = {
  forceFetch: boolean;
};

const useRefreshPlaidBalancesMutation = (
  additionalOptions?: MutationAdditionalOptions<PlaidBankAccountRep.Complete[], Params>
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const queryClient = useQueryClient();
  const plaidAccountsQueryKey = plaidAccountsQueryHooks.useQueryKey({});

  return useMutationWithDefaults(
    {
      mutationFn: async (params: Params) => {
        return highbeamApi.plaid.refreshPlaidBalances(businessGuid, params.forceFetch);
      },
      onSuccess: (data) => {
        // This returns the same data as the Plaid accounts query, so we can safely set it here too.
        queryClient.setQueryData(plaidAccountsQueryKey, data);
      },
      onError: (error) => {
        captureException(error);
        notify("error", "Failed to refresh Plaid balances.");
      },
    },
    additionalOptions || {}
  );
};

export default useRefreshPlaidBalancesMutation;
