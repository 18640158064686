import { ArrowLineUpRight } from "@phosphor-icons/react";
import RecurringPaymentDetailsCard from "components/RecurringPaymentDetailsCard";
import { Dayjs } from "dayjs";
import PayeeInfo, { Recipient } from "pages/SendMoneyPage/SendMoneySteps/PayeeInfo";
import {
  BankingInfo,
  PaymentMethod,
  getSendMoneyDeliveryMessage,
  InternationalBankingInfo,
  PaymentMethodOption,
} from "pages/SendMoneyPage/utils";
import colors from "styles/colors";
import InfoBox from "ui/data-display/InfoBox";
import Button from "ui/inputs/Button";
import StepHeader from "ui/navigation/Steps/StepHeader";
import Text from "ui/typography/Text";
import { getDollarsFromCents } from "utils/money";
import { formatAmount } from "utils/string";
import { TransferOption } from "utils/transfers";

import styles from "./RecurringPaymentConfirmDetails.module.scss";

type SendMoneyConfirmation = {
  from: TransferOption;
  amountInCents: number;
  to: Recipient;
  scheduledDate: Dayjs;
  bankingInfo?: BankingInfo;
  internationalBankingInfo?: InternationalBankingInfo;
  paymentMethodOption: PaymentMethodOption;
  invoiceName?: string;
};

type Props = {
  sendMoneyConfirmation: SendMoneyConfirmation;
  onNextPress: () => void;
  onPrevPress: () => void;
  isTransferLoading: boolean;
  numberOfPayments?: string;
};

const RecurringPaymentConfirmDetails: React.FC<Props> = ({
  sendMoneyConfirmation,
  onNextPress,
  onPrevPress,
  isTransferLoading,
  numberOfPayments,
}) => {
  const onTransferButtonClick = () => {
    onNextPress();
  };

  const getTotalRecurringPaymentAmount = () => {
    const totalAmount = getDollarsFromCents(
      Number(numberOfPayments) * sendMoneyConfirmation.amountInCents
    );
    const formattedTotalAmount = formatAmount(totalAmount);
    return formattedTotalAmount.split(".");
  };
  const [dollars, cents] = getTotalRecurringPaymentAmount();

  return (
    <>
      <StepHeader
        handleBackButtonClick={onPrevPress}
        disableBackButton={isTransferLoading}
        stepNumber={3}
        disableForwardButton
        title="Confirm details"
      />
      <RecurringPaymentDetailsCard
        iconBesideAmount={<ArrowLineUpRight size={16} />}
        amountInCents={sendMoneyConfirmation.amountInCents}
        from={sendMoneyConfirmation.from.label}
        to={
          <PayeeInfo
            to={sendMoneyConfirmation.to}
            infoText="The recipient will receive a confirmation email for this payment."
          />
        }
        scheduledDate={sendMoneyConfirmation.scheduledDate}
        routingNumber={sendMoneyConfirmation.bankingInfo?.routingNumber}
        accountNumber={
          sendMoneyConfirmation.paymentMethodOption?.value === PaymentMethod.INTERNATIONAL
            ? sendMoneyConfirmation.bankingInfo?.accountNumber
            : sendMoneyConfirmation.internationalBankingInfo?.accountNumber?.value
        }
        deliveryMessage={getSendMoneyDeliveryMessage(
          sendMoneyConfirmation.paymentMethodOption?.value as PaymentMethod,
          true
        )}
      />
      <Text size={14} color={colors.grey[600]} className={styles.authorizeMsg}>
        By clicking Schedule payments, I authorize the above amount to be debited from the bank
        account for every monthly recurring payment starting on the specified date.
      </Text>
      {numberOfPayments && (
        <div className={styles.infoText}>
          <div className={styles.flex}>
            <Text size={14} weight="medium">
              {`${numberOfPayments} payments totaling`}
            </Text>
            <Text size={14} weight="bold" className={styles.ml2}>
              {dollars}.{cents}.
            </Text>
          </div>
        </div>
      )}
      <div className={styles.buttons}>
        <Button onClick={onPrevPress} variant="tertiary" disabled={isTransferLoading}>
          Back
        </Button>
        <Button
          variant="primary"
          onClick={onTransferButtonClick}
          isLoading={isTransferLoading}
          autoFocus
        >
          {"Schedule payments"}
        </Button>
      </div>
      <div className={styles.info}>
        <InfoBox
          variant="info"
          iconSize={24}
          text="Future payments that fall on weekends or bank holidays will be processed the following business day."
        />
      </div>
    </>
  );
};
export default RecurringPaymentConfirmDetails;
