import { ComponentPropsWithRef, FC } from "react";
import cn from "utils/tailwind/cn";
import variants from "utils/tailwind/variants";

export type CardShadow = "none" | "xs" | "sm" | "md" | "lg" | "xl";

export type CardBorderVariant = "default" | "dashed";

type Props = ComponentPropsWithRef<"div"> & {
  className?: string;
  shadow?: CardShadow;
  borderVariant?: CardBorderVariant;
};

const Card: FC<Props> = ({
  children,
  className,
  borderVariant = "default",
  shadow = "md",
  ...divProps
}) => (
  <div
    className={cn(
      "rounded-lg bg-white",
      variants(shadow, {
        none: "shadow-none",
        xs: "shadow-xs",
        sm: "shadow-sm",
        md: "shadow-md",
        lg: "shadow-lg",
        xl: "shadow-xl",
      }),
      variants(borderVariant, {
        default: "",
        dashed: "border border-dashed border-grey-800",
      }),
      className
    )}
    {...divProps}
  >
    {children}
  </div>
);

export default Card;
