import {
  CaretUpDown as CaretUpDownIcon,
  Check as CheckIcon,
  X as XIcon,
} from "@phosphor-icons/react";
import * as LabelPrimitive from "@radix-ui/react-label";
import {
  FC,
  ReactNode,
  PropsWithChildren,
  ComponentPropsWithoutRef,
  useState,
  createContext,
  useContext,
  useId,
  useCallback,
} from "react";
import Command from "ui/inputs/Command";
import { InputVariant } from "ui/inputs/InputWrapperV2";
import Popover from "ui/overlay/Popover";
import cn from "utils/tailwind/cn";

type ComboboxContextValue = {
  variant: InputVariant;
  baseId: string;
  value: string | null;
  onValueChange: (value: string | null) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  clearable?: boolean;
  showErrorOutline?: boolean;
  disabled?: boolean;
};

const ComboboxContext = createContext<ComboboxContextValue>({} as ComboboxContextValue);

const useComboboxContext = () => {
  const context = useContext(ComboboxContext);
  if (!context) {
    throw new Error("useComboboxContext must be used within a Combobox");
  }
  return context;
};

const makeTriggerId = (baseId: string) => `${baseId}-trigger`;

type ComboboxFieldProps = PropsWithChildren<{
  className?: string;
}>;

const ComboboxField: FC<ComboboxFieldProps> = ({ children, className }) => (
  <div className={cn("relative", className)}>{children}</div>
);

type ComboboxLabelProps = PropsWithChildren<{
  className?: string;
}>;

const ComboboxLabel: FC<ComboboxLabelProps> = ({ children, className }) => {
  const { baseId, value, variant } = useComboboxContext();
  const triggerId = makeTriggerId(baseId);
  const shouldShrink = variant !== "minimal" && Boolean(value);

  return (
    <LabelPrimitive.Root
      htmlFor={triggerId}
      data-shrink={shouldShrink}
      className={cn(
        "peer/label pointer-events-none absolute left-0 top-0 flex h-full max-w-[calc(100%-2rem)] items-center justify-start truncate px-4 text-sm leading-none text-grey-400",
        {
          "text-xs font-bold text-grey-700 [&>span]:-top-2.5": shouldShrink,
          "sr-only": variant === "minimal",
        },
        className
      )}
    >
      <span className="relative">{children}</span>
    </LabelPrimitive.Root>
  );
};

type ComboboxTriggerRenderProps = {
  value: string | null;
};

type ComboboxTriggerProps = {
  className?: string;
  children?: ReactNode | ((props: ComboboxTriggerRenderProps) => ReactNode);
};

const ComboboxTrigger: FC<ComboboxTriggerProps> = ({ children, className }) => {
  const { baseId, value, onValueChange, variant, clearable, showErrorOutline, disabled } =
    useComboboxContext();
  const triggerId = makeTriggerId(baseId);

  return (
    <Popover.Trigger asChild>
      <div
        role="button"
        tabIndex={disabled ? -1 : 0}
        id={triggerId}
        className={cn(
          "data-[state=open]:input-focus-outline focus:input-focus-outline group/trigger inline-flex w-full cursor-pointer items-center justify-between rounded-md border border-solid border-grey-200 px-4 text-sm focus:outline-none data-[disabled=true]:pointer-events-none data-[disabled=true]:cursor-not-allowed data-[disabled=true]:bg-grey-50 data-[placeholder]:text-grey-400 [&>span]:line-clamp-1 peer-data-[shrink=true]/label:[&>span]:top-2.5",
          {
            "h-[3.25rem]": variant === "default",
            "h-10": variant === "minimal",
          },
          {
            "border-yellow-400": showErrorOutline,
          },
          className
        )}
        data-disabled={disabled}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            e.currentTarget.click();
          }
        }}
      >
        <span
          className={cn("relative", {
            "text-grey-600": variant !== "minimal",
          })}
        >
          {children ? (
            typeof children === "function" ? (
              children({ value })
            ) : (
              children
            )
          ) : (
            <>{value ?? ""}</>
          )}
        </span>

        {!disabled && (
          <div className="flex items-center gap-2">
            {clearable && value !== null && (
              <button
                type="button"
                className="leading-[0]"
                onClick={(e) => {
                  e.stopPropagation();
                  onValueChange(null);
                }}
                onKeyDown={(e) => e.stopPropagation()}
              >
                <XIcon className="h-4 w-4 text-grey-500" />
              </button>
            )}
            <CaretUpDownIcon className="h-4 w-4 text-grey-500" />
          </div>
        )}
      </div>
    </Popover.Trigger>
  );
};

type ComboboxMenuProps = ComponentPropsWithoutRef<typeof Popover.Content> & {
  searchPlaceholder?: string;
};

const ComboboxMenu: FC<ComboboxMenuProps> = ({
  children,
  className,
  searchPlaceholder = "Search",
  ...props
}) => (
  <Popover.Content
    className={cn(
      "max-h-[var(--radix-popover-content-available-height)] w-[var(--radix-popover-trigger-width)] overflow-hidden",
      className
    )}
    {...props}
  >
    <Command>
      <Command.InputWrapper placeholder={searchPlaceholder} />
      <Command.List>{children}</Command.List>
    </Command>
  </Popover.Content>
);

type ComboboxMenuEmptyStateProps = ComponentPropsWithoutRef<typeof Command.Empty>;

const ComboboxMenuEmptyState: FC<ComboboxMenuEmptyStateProps> = ({
  children,
  className,
  ...props
}) => (
  <Command.Empty className={className} {...props}>
    {children}
  </Command.Empty>
);

type ComboboxGroupProps = ComponentPropsWithoutRef<typeof Command.Group>;

const ComboboxGroup: FC<ComboboxGroupProps> = ({ children, className, ...props }) => (
  <Command.Group className={className} {...props}>
    {children}
  </Command.Group>
);

type ComboboxMenuItemProps = Omit<ComponentPropsWithoutRef<typeof Command.Item>, "value"> & {
  value: string;
  searchableText?: string;
};

const ComboboxItem: FC<ComboboxMenuItemProps> = ({
  children,
  className,
  onSelect,
  value: itemValue,
  searchableText,
  ...props
}) => {
  const { value: currentValue, onValueChange, setIsOpen } = useComboboxContext();
  const isSelected = itemValue === currentValue;

  const defaultOnSelect = useCallback(() => {
    onValueChange(itemValue);
    setIsOpen(false);
  }, [onValueChange, setIsOpen, itemValue]);

  return (
    <Command.Item
      className={cn("whitespace-nowrap", className)}
      value={searchableText}
      onSelect={onSelect ?? defaultOnSelect}
      {...props}
    >
      <div>{children}</div>
      {isSelected && <CheckIcon className="h-4 w-4 text-purple-500" />}
    </Command.Item>
  );
};

type Props = PropsWithChildren<{
  value: string | null;
  onValueChange: (value: string | null) => void;
  variant?: InputVariant;
  clearable?: boolean;
  showErrorOutline?: boolean;
  disabled?: boolean;
}>;

const Combobox: FC<Props> = ({
  children,
  value,
  onValueChange,
  variant = "default",
  clearable,
  showErrorOutline,
  disabled,
}) => {
  const baseId = useId();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ComboboxContext.Provider
      value={{
        variant,
        baseId,
        value,
        onValueChange,
        isOpen,
        setIsOpen,
        clearable,
        showErrorOutline,
        disabled,
      }}
    >
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        {children}
      </Popover>
    </ComboboxContext.Provider>
  );
};

export default Object.assign(Combobox, {
  Field: ComboboxField,
  Label: ComboboxLabel,
  Trigger: ComboboxTrigger,
  Menu: ComboboxMenu,
  MenuEmptyState: ComboboxMenuEmptyState,
  Group: ComboboxGroup,
  Item: ComboboxItem,
});
