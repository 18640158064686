/**
 * Ensure this matches the {@see clean} function on the backend.
 */
export const clean = (string: string): string => {
  // eslint-disable-next-line functional/no-let
  let result = string.normalize("NFD");
  result = result.toLowerCase();
  result = result.replace(/[^a-z0-9]/g, "");
  return result;
};
