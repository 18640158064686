import { captureException } from "@sentry/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CreditApplicationDocumentRep from "reps/CreditApplicationDocumentRep";
import useCreditApplicationDocumentsQueryOptions from "resources/credit-application/queries/useCreditApplicationDocumentsQueryOptions";
import useNewCreditApplication from "resources/credit-application/queries/useNewCreditApplication";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const useDeleteCreditApplicationDocument = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const newCreditApplication = useNewCreditApplication();
  const creditApplicationGuid = newCreditApplication.guid;
  const creditApplicationDocumentsQueryKey = useCreditApplicationDocumentsQueryOptions().queryKey;
  const refreshCreditApplicationDocumentsQuery = useRefreshQuery(
    creditApplicationDocumentsQueryKey
  );
  const queryClient = useQueryClient();

  // NB(alex): please consult #eng-frontend before using react-query
  return useMutation({
    mutationFn: (creditApplicationDocumentGuid: string) =>
      highbeamApi.creditApplication.deleteDocument(
        businessGuid,
        creditApplicationGuid,
        creditApplicationDocumentGuid
      ),
    onMutate: async (guid) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: creditApplicationDocumentsQueryKey });

      // Optimistically update to the new value
      queryClient.setQueryData<CreditApplicationDocumentRep.Complete[]>(
        creditApplicationDocumentsQueryKey,
        (prev) => prev?.filter((d) => d.guid !== guid) || []
      );
    },
    onError: async (error) => {
      captureException(error);
      notify("info", "An error occurred while deleting the file. Please try again.");
    },
    onSettled: () => {
      refreshCreditApplicationDocumentsQuery();
    },
  });
};

export default useDeleteCreditApplicationDocument;
