import { useQuery } from "@tanstack/react-query";
import IconWithTooltip from "components/common/IconWithTooltip";
import { PaymentMethod, PaymentMethodOption } from "pages/SendMoneyPage/utils";
import { useEffect, useState } from "react";
import useUnitCoAccountLimitQueryOptions from "resources/unit-co-account-limits/queries/useUnitCoAccountLimitQueryOptions";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import ProgressBar from "ui/data-visualization/ProgressBar";
import Text from "ui/typography/Text";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import useIsAllowedToCreateDraftPayments from "utils/permissions/useIsAllowedToCreateDraftPayments";
import { percentage } from "utils/string";
import { TransferOption } from "utils/transfers";

import styles from "./PaymentLimitWarningBar.module.scss";

type Props = {
  paymentMethodOption: PaymentMethodOption | null;
  amountInCents: number;
  transferFrom: TransferOption | null;
  isRecurringPayment: boolean;
};

const PaymentLimitWarningBar: React.FC<Props> = ({
  paymentMethodOption,
  amountInCents,
  transferFrom,
  isRecurringPayment,
}) => {
  const [limitToDisplay, setLimitToDisplay] = useState<number | null>();
  const [currentLimitPlusAmount, setCurrentLimitPlusAmount] = useState<number | null>();
  const [barTitle, setBarTitle] = useState("");
  const [exceededLimit, setExceededLimit] = useState(false);
  const { data: accountLimits } = useQuery(useUnitCoAccountLimitQueryOptions(transferFrom?.value));
  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const isAllowedToCreatePaymentDrafts = useIsAllowedToCreateDraftPayments();

  useEffect(() => {
    if (!accountLimits) {
      return;
    }

    setExceededLimit(false);
    setLimitToDisplay(null);
    setBarTitle("");

    if (
      paymentMethodOption &&
      paymentMethodOption?.value === PaymentMethod.ACH &&
      accountLimits.attributes.ach
    ) {
      const dailyAchLimit = accountLimits.attributes.ach.limits.dailyCredit;
      const monthlyAchLimit = accountLimits.attributes.ach.limits.monthlyCredit;
      // Currently skipping the checks for daily and monthly usage limits for recurring payments. This is because accurately calculating the limits for future scheduled dates introduces significant complexity.
      const dailyLimitUsed = isRecurringPayment
        ? 0
        : accountLimits.attributes.ach.totalsDaily.credits;
      const monthlyLimitUsed = isRecurringPayment
        ? 0
        : accountLimits.attributes.ach.totalsMonthly.credits;
      const leftoverDailyAchLimit = dailyAchLimit - dailyLimitUsed;
      const leftoverMonthlyAchLimit = monthlyAchLimit - monthlyLimitUsed;

      const dailyAchLimitThreshold = leftoverDailyAchLimit * 0.9;
      const monthlyAchLimitThreshold = leftoverMonthlyAchLimit * 0.9;

      const isExceedingDailyAchLimit = amountInCents > leftoverDailyAchLimit;
      const isApproachingDailyAchLimit = amountInCents >= dailyAchLimitThreshold;
      const isAtDailyAchLimit = amountInCents === leftoverDailyAchLimit;

      const isExceedingMonthlyAchLimit = amountInCents > leftoverMonthlyAchLimit;
      const isApproachingMonthlyAchLimit = amountInCents >= monthlyAchLimitThreshold;
      const isAtMonthlyAchLimit = amountInCents === leftoverMonthlyAchLimit;

      if (isApproachingMonthlyAchLimit && leftoverMonthlyAchLimit < leftoverDailyAchLimit) {
        if (isExceedingMonthlyAchLimit) {
          setExceededLimit(true);
          setBarTitle("You have exceeded the monthly ACH limit");
        } else if (isAtMonthlyAchLimit) {
          setBarTitle("You have reached the monthly ACH limit");
        } else {
          setBarTitle("You are nearing the monthly ACH limit");
        }
        setLimitToDisplay(monthlyAchLimit);
        setCurrentLimitPlusAmount(monthlyLimitUsed + amountInCents);
      } else if (isExceedingDailyAchLimit || isApproachingDailyAchLimit) {
        if (isExceedingDailyAchLimit) {
          setExceededLimit(true);
          setBarTitle("You have exceeded the daily ACH limit");
        } else if (isAtDailyAchLimit) {
          setBarTitle("You have reached the daily ACH limit");
        } else {
          setBarTitle("You are nearing the daily ACH limit");
        }
        setLimitToDisplay(dailyAchLimit);
        setCurrentLimitPlusAmount(dailyLimitUsed + amountInCents);
      }
    } else if (
      paymentMethodOption &&
      paymentMethodOption?.value === PaymentMethod.WIRE &&
      accountLimits.attributes.wire
    ) {
      // TODO: remove unknown type once wire is added as type to AccountLimits in unit sdk
      const wireLimit = accountLimits.attributes.wire as unknown as any;
      const dailyWireLimit = wireLimit.limits.dailyTransfer;
      const dailyWireLimitUsed = isRecurringPayment ? 0 : wireLimit.totalsDaily.transfers;
      const currentDailyWireLimit = dailyWireLimit - dailyWireLimitUsed;

      const dailyWireLimitThreshold = currentDailyWireLimit * 0.9;

      const isExceedingDailyWireLimit = amountInCents > currentDailyWireLimit;
      const isApproachingDailyWireLimit = amountInCents >= dailyWireLimitThreshold;
      const isAtDailyWireLimit = amountInCents === currentDailyWireLimit;

      if (isExceedingDailyWireLimit || isApproachingDailyWireLimit) {
        if (isExceedingDailyWireLimit) {
          setExceededLimit(true);
          setBarTitle("You have exceeded the daily wire limit");
        } else if (isAtDailyWireLimit) {
          setBarTitle("With this payment, you will reach the daily wire limit");
        } else {
          setBarTitle("You are nearing the daily wire limit");
        }
        setLimitToDisplay(dailyWireLimit);
        setCurrentLimitPlusAmount(dailyWireLimitUsed + amountInCents);
      }
    }
  }, [accountLimits, amountInCents, paymentMethodOption, isRecurringPayment]);

  if (!limitToDisplay || !currentLimitPlusAmount) {
    return null;
  }

  return (
    <div className={styles.warningBarContainer}>
      <div className={styles.warningBarHeading}>
        <div className={styles.warningBarHeadingText}>
          <Text size={14} color={colors.grey[800]} className={styles.warningBarTitle}>
            {barTitle}
          </Text>
          <IconWithTooltip
            color="light"
            content="We set this limit to protect you from events that could leave you financially
            responsible for large amounts, like fraud and hacks. We’ll work with you to adjust your limit over time."
          />
        </div>

        <div className={styles.amountContainer}>
          <MoneyAmount
            size={14}
            weight="medium"
            color={exceededLimit ? colors.orange[600] : colors.yellow[700]}
            cents={currentLimitPlusAmount}
            withCents={false}
          />

          <Text size={14}>/</Text>

          <MoneyAmount
            size={14}
            color={colors.grey[500]}
            cents={limitToDisplay}
            withCents={false}
          />
        </div>
      </div>

      <ProgressBar
        className={styles.progressBar}
        percent={percentage(currentLimitPlusAmount, limitToDisplay)}
        color={exceededLimit ? colors.orange[400] : colors.yellow[500]}
      />
      {!isAllowedToApprovePayments && isAllowedToCreatePaymentDrafts && !exceededLimit && (
        <Text size={14} color={colors.grey[800]}>
          If this payment exceeds the limit at the time of approval, it will not send. Consider
          sending large amounts as wires instead
        </Text>
      )}
      {isAllowedToApprovePayments && exceededLimit && (
        <Text size={14} color={colors.grey[800]}>
          Consider sending from a different account or using a wire for large payments
        </Text>
      )}
    </div>
  );
};

export default PaymentLimitWarningBar;
