import classNames from "classnames";
import { SetOptional } from "type-fest";
import Button from "ui/inputs/Button";

import styles from "./ConnectionItem.module.scss";

type ConnectionItemBodyProps = {
  iconSrc: string;
  providerName: string;
  subtext: string;
};

const ConnectionItemBody: React.FC<ConnectionItemBodyProps> = ({
  iconSrc,
  providerName,
  subtext,
}) => (
  <div className={styles.body}>
    <div className={styles.iconContainer}>
      <img src={iconSrc} alt="Quickbooks logo" className={styles.icon} />
    </div>
    <div className={styles.textContainer}>
      <div className={styles.providerName}>{providerName}</div>
      <div className={styles.subtext}>{subtext}</div>
    </div>
  </div>
);

type NotConnectedConnectionItemProps = SetOptional<ConnectionItemBodyProps, "subtext"> & {
  onClickConnect: () => void;
};

const NotConnectedConnectionItem: React.FC<NotConnectedConnectionItemProps> = ({
  onClickConnect,
  ...connectionItemBodyProps
}) => (
  <div className={classNames(styles.container, styles.clickable)} onClick={onClickConnect}>
    <ConnectionItemBody subtext="Click to connect" {...connectionItemBodyProps} />
  </div>
);

type ConnectedConnectionItemProps = SetOptional<ConnectionItemBodyProps, "subtext"> & {
  onClickDisconnect: () => void;
};

const ConnectedConnectionItem: React.FC<ConnectedConnectionItemProps> = ({
  onClickDisconnect,
  ...connectionItemBodyProps
}) => (
  <div className={styles.container}>
    <ConnectionItemBody subtext="Connected" {...connectionItemBodyProps} />
    <Button onClick={onClickDisconnect} variant="danger" size="sm">
      Disconnect
    </Button>
  </div>
);

type Props = NotConnectedConnectionItemProps &
  ConnectedConnectionItemProps & {
    isConnected: boolean;
  };

const ConnectionItem: React.FC<Props> = ({
  isConnected,
  onClickConnect,
  onClickDisconnect,
  ...connectionItemBodyProps
}) =>
  isConnected ? (
    <ConnectedConnectionItem onClickDisconnect={onClickDisconnect} {...connectionItemBodyProps} />
  ) : (
    <NotConnectedConnectionItem onClickConnect={onClickConnect} {...connectionItemBodyProps} />
  );

export default ConnectionItem;
