import { ReactNode } from "react";
import { createPortal } from "react-dom";

type Props = {
  children: ReactNode;
  elementId?: string; // Only required if the tooltip is mounted in another dialog such as a modal.
};

const Portal: React.FC<Props> = ({ children, elementId }) => {
  const element = elementId ? document.getElementById(elementId) : null;
  return createPortal(children, element || document.body);
};

export default Portal;
