import colors from "styles/colors";
import CopyItemTooltip from "ui/overlay/Tooltip/CopyItemTooltip/CopyItemTooltip";
import Text from "ui/typography/Text";
import { removeScheme } from "utils/url";

import styles from "./CopyLink.module.scss";

type Props = {
  link: string;
  text?: string;
  tooltipContent?: React.ReactNode;
};

const CopyLink: React.FC<Props> = ({ link, text = removeScheme(link), tooltipContent }) => (
  <CopyItemTooltip
    wrapperClassName={styles.container}
    textToCopy={link}
    tooltipContent={tooltipContent}
  >
    <Text color={colors.grey[500]} size={14} className={styles.text}>
      {text}
    </Text>
  </CopyItemTooltip>
);

export default CopyLink;
