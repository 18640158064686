import { useQuery } from "@tanstack/react-query";
import { FC, ReactNode, createContext, useContext, useMemo, useState } from "react";
import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import mapTransferOption from "resources/bank-accounts/utils/mapTransferOption";
import usePaymentApproval from "resources/payment-approvals/queries/usePaymentApproval";
import getPaymentApprovalMethodName, {
  METHOD_NAMES,
} from "resources/payment-approvals/utils/getPaymentApprovalMethodName";
import useUnitCoAccountLimitQueryOptions from "resources/unit-co-account-limits/queries/useUnitCoAccountLimitQueryOptions";
import { getAccountDisplayName } from "utils/account";
import { isHighbeamBankAccount, TransferOption } from "utils/transfers";

export type PaymentApprovalTransferOption = {
  accountDisplayName: string;
} & TransferOption;

type PaymentApprovalFlexpaneContextState = {
  paymentApprovalGuid: string;
  transferFrom: PaymentApprovalTransferOption | null;
  supportedTransferOptions: PaymentApprovalTransferOption[];
  limitToDisplay: number | null;
  currentLimitPlusAmount: number | null;
  isApproachingMonthlyLimit: boolean;
  isApproveDisabled: boolean;
  setTransferFrom: (option: PaymentApprovalTransferOption) => void;
  close: () => void;
};

const PaymentApprovalFlexpaneContext = createContext<PaymentApprovalFlexpaneContextState>(
  {} as PaymentApprovalFlexpaneContextState
);

type TransferOptionLimits =
  | {
      isApproveDisabled: false;
      isApproachingMonthlyLimit: boolean;
      currentLimitPlusAmount: null;
      limitToDisplay: null;
    }
  | {
      isApproveDisabled: true;
      isApproachingMonthlyLimit: boolean;
      currentLimitPlusAmount: number;
      limitToDisplay: number;
    };

type Props = {
  children: ReactNode;
  paymentApprovalGuid: string;
  close: () => void;
};

const PaymentApprovalFlexpaneProvider: FC<Props> = ({ children, paymentApprovalGuid, close }) => {
  const paymentApproval = usePaymentApproval(paymentApprovalGuid, { required: true });
  const bankAccounts = bankAccountsQueryHooks.useData({ status: "open" });
  const allTransferOptions = bankAccounts.map((account) => {
    const options = mapTransferOption(account);
    return { ...options, accountDisplayName: getAccountDisplayName(account) };
  });
  const supportedTransferOptions = allTransferOptions.filter((it) => it.supportsSendMoney);
  const [transferFrom, setTransferFrom] = useState<PaymentApprovalTransferOption | null>(
    () =>
      supportedTransferOptions.find(
        (option) => isHighbeamBankAccount(option) && option.guid === paymentApproval.bankAccountGuid
      ) || null
  );

  const { data: accountLimits } = useQuery(useUnitCoAccountLimitQueryOptions(transferFrom?.value));
  const methodName = getPaymentApprovalMethodName(paymentApproval);

  const transferOptionLimits: TransferOptionLimits = useMemo(() => {
    if (
      accountLimits &&
      methodName.short === METHOD_NAMES.ACH.short &&
      accountLimits.attributes.ach
    ) {
      const dailyAchLimit = accountLimits.attributes.ach.limits.dailyCredit;
      const monthlyAchLimit = accountLimits.attributes.ach.limits.monthlyCredit;
      const dailyLimitUsed = accountLimits.attributes.ach.totalsDaily.credits;
      const monthlyLimitUsed = accountLimits.attributes.ach.totalsMonthly.credits;
      const leftoverDailyAchLimit = dailyAchLimit - dailyLimitUsed;
      const leftoverMonthlyAchLimit = monthlyAchLimit - monthlyLimitUsed;

      const isExceedingDailyAchLimit = paymentApproval.amount > leftoverDailyAchLimit;

      const isExceedingMonthlyAchLimit = paymentApproval.amount > leftoverMonthlyAchLimit;

      if (isExceedingMonthlyAchLimit && leftoverMonthlyAchLimit < leftoverDailyAchLimit) {
        return {
          isApproachingMonthlyLimit: true,
          isApproveDisabled: true,
          currentLimitPlusAmount: monthlyLimitUsed + paymentApproval.amount,
          limitToDisplay: monthlyAchLimit,
        };
      } else if (isExceedingDailyAchLimit) {
        return {
          isApproachingMonthlyLimit: false,
          isApproveDisabled: true,
          currentLimitPlusAmount: dailyLimitUsed + paymentApproval.amount,
          limitToDisplay: dailyAchLimit,
        };
      }
    } else if (
      accountLimits &&
      methodName.short === METHOD_NAMES.DOMESTIC_WIRE.short &&
      accountLimits.attributes.wire
    ) {
      // TODO: remove unknown type once wire is added as type to AccountLimits in unit sdk
      const wireLimit = accountLimits.attributes.wire as unknown as any;
      const dailyWireLimit = wireLimit.limits.dailyTransfer;
      const dailyWireLimitUsed = wireLimit.totalsDaily.transfers;
      const currentDailyWireLimit = dailyWireLimit - dailyWireLimitUsed;

      const isExceedingDailyWireLimit = paymentApproval.amount > currentDailyWireLimit;

      if (isExceedingDailyWireLimit) {
        return {
          isApproachingMonthlyLimit: false,
          isApproveDisabled: true,
          currentLimitPlusAmount: dailyWireLimitUsed + paymentApproval.amount,
          limitToDisplay: dailyWireLimit,
        };
      }
    }

    //Fallback
    return {
      isApproachingMonthlyLimit: false,
      isApproveDisabled: false,
      currentLimitPlusAmount: null,
      limitToDisplay: null,
    };
  }, [methodName, accountLimits, paymentApproval.amount]);

  return (
    <PaymentApprovalFlexpaneContext.Provider
      value={{
        paymentApprovalGuid: paymentApprovalGuid,
        close: close,
        transferFrom,
        supportedTransferOptions,
        setTransferFrom,
        ...transferOptionLimits,
      }}
    >
      {children}
    </PaymentApprovalFlexpaneContext.Provider>
  );
};

export default PaymentApprovalFlexpaneProvider;

export const usePaymentApprovalFlexpaneContext = () => {
  return useContext(PaymentApprovalFlexpaneContext);
};
