import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import ConnectStores from "components/ConnectStores";
import OnboardingLayout from "layouts/OnboardingLayout";
import OnboardingCardLayout from "modules/onboarding/layouts/OnboardingCardLayout";
import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import useConnectedStoresQueryOptions from "resources/connected-stores/queries/useConnectedStoresQueryOptions";
import { CREDIT_COMPARISON_SIGNUP_SOURCE } from "resources/signup-source/constants";
import useSignupSourceQueryOptions from "resources/signup-source/queries/useSignupSourceQueryOptions";
import { onboardingProgressState } from "state/onboarding/progress";
import Button from "ui/inputs/Button";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useMountEffect from "utils/customHooks/useMountEffect";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

import genericStyles from "../OnboardingGeneric.module.scss";

const OnboardingConnectStoresPage = () => {
  const { segmentTrack } = useSegment();
  const navigate = useNavigate();

  const requireStoreConnectionInOnboarding = useFeatureFlag(
    "REQUIRE_STORE_CONNECTION_IN_ONBOARDING"
  );
  const setProgress = useSetRecoilState(onboardingProgressState);
  const { data: signupSource } = useSuspenseQuery(useSignupSourceQueryOptions());
  const isSignupSourceCreditComparison = signupSource === CREDIT_COMPARISON_SIGNUP_SOURCE;
  const { data: connectedStores = [] } = useQuery(useConnectedStoresQueryOptions());

  const hasConnectedShopifyOrAmazon =
    connectedStores.filter(
      (integration) => integration.type === "Shopify" || integration.type === "Amazon"
    ).length > 0;

  const handleNext = ({ skippedConnectStore = false }) => {
    if (isSignupSourceCreditComparison) {
      return navigate(CREDIT_COMPARISON_PATH);
    }
    navigate("/onboarding/bank-application", {
      state: { fromConnectStore: !skippedConnectStore },
    });
  };

  const [searchParams] = useSearchParams();
  const allowSkip = searchParams.get("allowSkip") || !requireStoreConnectionInOnboarding;

  useMountEffect(() => {
    setProgress(60);
  });

  return (
    <OnboardingLayout>
      <OnboardingCardLayout
        title="Let’s get started by connecting your stores"
        subtitle={
          "Highbeam uses data from your stores to provide tailored banking services and real-time insights to manage cash flow. We recommend connecting at least one store."
        }
        footer={
          <>
            {allowSkip && (
              <Button
                disabled={hasConnectedShopifyOrAmazon}
                className={genericStyles.button__skip}
                type="submit"
                onClick={() => {
                  segmentTrack(SEGMENT_EVENTS.ONBOARDING_STORES_SKIPPED);
                  handleNext({ skippedConnectStore: true });
                }}
              >
                Skip this step
              </Button>
            )}

            <Button
              variant="primary"
              disabled={!hasConnectedShopifyOrAmazon}
              className={genericStyles.button}
              type="submit"
              tooltip={
                !hasConnectedShopifyOrAmazon &&
                !isSignupSourceCreditComparison && (
                  <Tooltip.Content>Connect at least 1 store to continue</Tooltip.Content>
                )
              }
              onClick={() => {
                handleNext({ skippedConnectStore: false });
              }}
            >
              {isSignupSourceCreditComparison ? "Compare offers" : "Next"}
            </Button>
          </>
        }
      >
        <ConnectStores redirectPathKey="onboarding" />
      </OnboardingCardLayout>
    </OnboardingLayout>
  );
};

export default OnboardingConnectStoresPage;
