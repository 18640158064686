import IconWithTooltip from "components/common/IconWithTooltip";
import { ComponentProps, FC } from "react";
import { useRecoilValue } from "recoil";
import { ExchangeRateTooltipContent } from "resources/international-wires/constants";
import DraftPaymentExchangeRateTooltipContent from "resources/international-wires/constants/DraftPaymentExchangeRateTooltipContent";
import { quoteCalculatedLocalAmountInCentsState } from "state/payments/international/quoteCalculatedLocalAmountInCents";
import { quoteCalculatedUsdAmountInCentsState } from "state/payments/international/quoteCalculatedUsdAmountInCents";
import { quoteCurrencyState } from "state/payments/international/quoteCurrency";
import { quoteFixedSideState } from "state/payments/international/quoteFixedSideState";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";

type IconWithTooltipProps = ComponentProps<typeof IconWithTooltip>;

type Props = {
  color: IconWithTooltipProps["color"];
  wrapperClassName?: IconWithTooltipProps["wrapperClassName"];
};

const ExchangeRateInfoIcon: FC<Props> = (props) => {
  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const fixedSide = useRecoilValue(quoteFixedSideState);
  const calculatedLocalAmountInCents = useRecoilValue(quoteCalculatedLocalAmountInCentsState);
  const calculatedUsdAmountInCents = useRecoilValue(quoteCalculatedUsdAmountInCentsState);
  const quoteCurrency = useRecoilValue(quoteCurrencyState);

  return (
    <IconWithTooltip
      content={
        isAllowedToApprovePayments ? (
          <ExchangeRateTooltipContent />
        ) : (
          <DraftPaymentExchangeRateTooltipContent
            fixedSide={fixedSide}
            cents={fixedSide === "Send" ? calculatedUsdAmountInCents : calculatedLocalAmountInCents}
            receiveCurrency={quoteCurrency.value}
          />
        )
      }
      {...props}
    />
  );
};

export default ExchangeRateInfoIcon;
