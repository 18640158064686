import InsightsVendorPageV2 from "pages/Insights/InsightsVendorPage/InsightsVendorPageV2";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import InsightsVendorPageV1 from "./InsightsVendorPageV1";

const InsightsVendorPage = () => {
  const spendByVendorV2 = useFeatureFlag("SPEND_BY_VENDOR_V2");

  return spendByVendorV2 ? <InsightsVendorPageV2 /> : <InsightsVendorPageV1 />;
};

export default InsightsVendorPage;
