import { CalendarCheck, Coin, CurrencyCircleDollar } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC, ReactNode } from "react";
import CardRep from "reps/CardRep";
import colors from "styles/colors";
import RadioCard, { RadioCardProps } from "ui/inputs/RadioCard";
import Text from "ui/typography/Text";

import CardAvatar from "../CardAvatar";

import styles from "./CardTypeRadioCard.module.scss";

type DescriptionHighlightItemProps = {
  checked: boolean;
  children: ReactNode;
};

const DescriptionHighlightItem: FC<DescriptionHighlightItemProps> = ({ children, checked }) => {
  return (
    <div className={classNames(styles.descriptionHighlightItem, checked && styles.checked)}>
      {children}
    </div>
  );
};

type CardTypeRadioCardCreditDescriptionProps = {
  checked: boolean;
  chargeCardMaxCashback: number;
  maxRepaymentDays: number;
};

const CardTypeRadioCardCreditDescription: FC<CardTypeRadioCardCreditDescriptionProps> = ({
  checked,
  chargeCardMaxCashback,
  maxRepaymentDays,
}) => {
  return (
    <div className={styles.descriptionContainer}>
      <Text size={14} weight="regular" color={colors.grey[600]} lineHeight={20}>
        Spend via a capital account.
      </Text>
      <div className={styles.descriptionHighlightsContainer}>
        {Boolean(chargeCardMaxCashback) && (
          <DescriptionHighlightItem checked={checked}>
            <CurrencyCircleDollar size={16} className={styles.descriptionIcon} />
            <Text size={12} weight="medium" lineHeight={24}>
              {chargeCardMaxCashback}% cash back
            </Text>
          </DescriptionHighlightItem>
        )}
        <DescriptionHighlightItem checked={checked}>
          <CalendarCheck size={16} className={styles.descriptionIcon} />
          <Text size={12} weight="medium" lineHeight={24}>
            {maxRepaymentDays} day repayment
          </Text>
        </DescriptionHighlightItem>
      </div>
    </div>
  );
};

type CardTypeRadioCardDebitDescriptionProps = {
  checked: boolean;
};

const CardTypeRadioCardDebitDescription: FC<CardTypeRadioCardDebitDescriptionProps> = ({
  checked,
}) => {
  return (
    <div className={styles.descriptionContainer}>
      <Text size={14} weight="regular" color={colors.grey[600]} lineHeight={20}>
        Spend via a bank account.
      </Text>
      <div className={styles.descriptionHighlightsContainer}>
        <DescriptionHighlightItem checked={checked}>
          <CurrencyCircleDollar size={16} className={styles.descriptionIcon} />
          <Text size={12} weight="medium" lineHeight={24}>
            2% cash back on ads
          </Text>
        </DescriptionHighlightItem>

        <DescriptionHighlightItem checked={checked}>
          <Coin size={16} className={styles.descriptionIcon} />
          <Text size={12} weight="medium" lineHeight={24}>
            1% cash back on everything else
          </Text>
        </DescriptionHighlightItem>
      </div>
    </div>
  );
};

type Props = Omit<RadioCardProps<string>, "icon"> & {
  cardType: CardRep.CardType;
};

const CardTypeRadioCard: FC<Props> = ({ checked, description, cardType, ...radioCardProps }) => {
  return (
    <RadioCard
      checked={checked}
      icon={
        <div className={styles.iconContainer}>
          <CardAvatar cardType={cardType} />
        </div>
      }
      description={description}
      tintBackgroundWhenChecked
      {...radioCardProps}
    />
  );
};

export default Object.assign(CardTypeRadioCard, {
  CreditDescription: CardTypeRadioCardCreditDescription,
  DebitDescription: CardTypeRadioCardDebitDescription,
});
