import { X } from "@phosphor-icons/react";
import { ComponentProps, FC, ReactNode } from "react";
import { RequireOneOrNone } from "type-fest";
import Button, { ButtonProps } from "ui/inputs/Button";
import { Heading4, Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";
import variants from "utils/tailwind/variants";

type BannerColor = "green" | "grey" | "orange" | "purple" | "red" | "white" | "yellow";

type BannerPadding = "compact" | "normal" | "lg";

type Props = {
  color: BannerColor;
  padding?: BannerPadding;
  icon?: ReactNode;
  title?: ReactNode;
  body?: ReactNode;
  paragraph?: ReactNode;
  button?: ReactNode;
  footer?: ReactNode;
  className?: string;
} & RequireOneOrNone<{
  body: ReactNode;
  paragraph: ReactNode;
}>;

const Banner: FC<Props> = ({
  color,
  padding = "normal",
  icon,
  title,
  body,
  paragraph,
  button,
  footer,
  className,
}) => {
  return (
    <div
      className={cn(
        "overflow-hidden rounded-lg border",
        variants(color, {
          green: "border-green-200",
          grey: "border-grey-200",
          orange: "border-orange-100",
          purple: "border-purple-100",
          red: "border-red-100",
          white: "border-grey-200",
          yellow: "border-yellow-100",
        }),
        className
      )}
    >
      <div
        className={cn(
          "flex flex-col items-center justify-between gap-x-4 gap-y-6 p-8 tablet:flex-row",
          variants(color, {
            green: "bg-green-50 text-green-800",
            grey: "bg-grey-50 text-grey-800",
            orange: "bg-orange-50 text-orange-800",
            purple: "bg-purple-50 text-purple-800",
            red: "bg-red-50 text-red-800",
            white: "bg-white text-grey-800",
            yellow: "bg-yellow-50 text-yellow-800",
          }),
          variants(padding, {
            normal: "p-6",
            compact: "p-3",
            lg: "p-8",
          })
        )}
      >
        <div className="flex gap-x-4">
          {icon}

          <div className="flex flex-1 flex-col justify-center gap-y-1">
            {title && <Heading4>{title}</Heading4>}

            {body}
            {paragraph && <Paragraph className="text-sm font-regular">{paragraph}</Paragraph>}
          </div>
        </div>

        {button}
      </div>

      {footer}
    </div>
  );
};

const BannerDismissButton: FC<ButtonProps> = (props) => {
  return (
    <Button variant="ghost" {...props}>
      <X size={16} />
    </Button>
  );
};

type BannerFooterProps = ComponentProps<"div"> & {
  padding?: BannerPadding;
};

const BannerFooter: FC<BannerFooterProps> = ({
  padding = "normal",
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={cn(
        "px-6 py-6",
        variants(padding, {
          normal: "px-6 py-4",
          compact: "px-3 pb-4 pt-1",
          lg: "px-8 py-6",
        }),
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Object.assign(Banner, {
  DismissButton: BannerDismissButton,
  Footer: BannerFooter,
});
