import { ChartLineUp } from "@phosphor-icons/react";
import FeatureStatusIndicator from "components/FeatureStatusIndicator";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

const InsightsVendorPageV2 = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/spend">Spend</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>By vendor</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.IconTile icon={<ChartLineUp />} />
          <DashboardPage.Header.Title>Spend by vendor</DashboardPage.Header.Title>
          <FeatureStatusIndicator featureStatus="Beta" />
        </DashboardPage.Header>
      </DashboardPage>
    </>
  );
};

export default InsightsVendorPageV2;
