import { Card } from "@highbeam/unit-node-sdk";
import { captureException } from "@sentry/react";
import CardChip from "assets/card-chip.svg?react";
import classNames from "classnames";
import CardholderInitialsAndFullName from "components/CardholderInitialsAndFullName";
import isMfaInProgressState from "modules/mfa/isMfaInProgressState";
import useMfa, { MfaCanceledError } from "modules/mfa/useMfa";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { getCardName } from "resources/cards/utils";
import getCardTypeByCard from "resources/cards/utils/getCardTypeByCard";
import {
  checkIsPhysicalCard,
  checkIsPhysicalCreditCard,
  checkIsPhysicalDebitCard,
  checkIsVirtualChargeCard,
  checkIsVirtualDebitCard,
} from "resources/cards/utils/typeguards";
import { highbeamCardSizeClasses } from "ui/feedback/CardShimmer";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useIsAllowedToReadCardNumber from "utils/permissions/useIsAllowedToReadCardNumber";
import cn from "utils/tailwind/cn";

import styles from "./HighbeamCard.module.scss";
import HighbeamCardArchived from "./HighbeamCardArchived";
import HighbeamCardCvv from "./HighbeamCardCvv";
import HighbeamCardDetailsButton from "./HighbeamCardDetailsButton";
import HighbeamCardExpiry from "./HighbeamCardExpiry";
import HighbeamCardFraud from "./HighbeamCardFraud";
import HighbeamCardLocked from "./HighbeamCardLocked";
import HighbeamCardLogo from "./HighbeamCardLogo";
import HighbeamCardNumber from "./HighbeamCardNumber";
import HighbeamCardTypePills from "./HighbeamCardTypePills";
import VisaLogo from "./VisaLogo";

type Props = {
  card: Card;
  areDetailsAvailable: boolean;
  useHighbeamLogo?: boolean;
  showDetailsButton?: boolean;
  showStatus?: boolean;
  showActivateCardButton?: boolean;
  onClick?: () => void;
  containerClickable?: boolean;
};

const HighbeamCard: React.FC<Props> = ({
  card,
  areDetailsAvailable,
  useHighbeamLogo = false,
  showDetailsButton = false,
  showStatus = true,
  showActivateCardButton = false,
  onClick,
  containerClickable = Boolean(onClick),
}) => {
  const isAllowedToReadCardNumber = useIsAllowedToReadCardNumber(card);

  const { mfa } = useMfa();
  const isMfaInProgress = useRecoilValue(isMfaInProgressState);

  const isLoading = isAllowedToReadCardNumber && isMfaInProgress;
  const isLocked = card.attributes.status === "Frozen";
  const isActive = card.attributes.status === "Active";
  const isFraud = card.attributes.status === "SuspectedFraud";
  const isArchived = card.attributes.status === "ClosedByCustomer";

  const shouldFetchSensitiveData = isActive || isLocked;

  const [isRevealed, setIsRevealed] = useState(false);

  const handleHide = () => {
    setIsRevealed(false);
  };

  const handleReveal = async () => {
    try {
      // `isMfaComplete` triggers sensitive data fetching in `HighbeamCardSensitiveData`.
      await mfa();
      setIsRevealed(true);
    } catch (error) {
      if (!(error instanceof MfaCanceledError)) {
        notify("error", "Something went wrong! Please try again.");
        captureException(error);
      }
    }
  };

  const isPhysicalCard = checkIsPhysicalDebitCard(card) || checkIsPhysicalCreditCard(card);
  const textColor = isPhysicalCard ? "white" : "black";
  const showArchived = !areDetailsAvailable && isArchived;

  return (
    <div className={classNames(containerClickable && styles.containerClickable)}>
      <div
        className={cn(
          styles.container,
          highbeamCardSizeClasses,
          checkIsPhysicalDebitCard(card) && styles.containerBorderPhysical,
          checkIsPhysicalCreditCard(card) && styles.containerBorderPhysicalCredit,
          (checkIsVirtualDebitCard(card) || checkIsVirtualChargeCard(card)) &&
            styles.containerBorderVirtual,
          checkIsVirtualChargeCard(card) && styles.containerChargeCard,
          checkIsVirtualDebitCard(card) && styles.containerDebitCard,
          styles.noSelect
        )}
        onClick={onClick}
      >
        <div>
          <div className={styles.rowWithSpace}>
            {useHighbeamLogo ? (
              <HighbeamCardLogo isPhysicalCard={isPhysicalCard} />
            ) : (
              <Text
                size={14}
                weight="bold"
                className={isPhysicalCard ? styles.textColorPhysical : styles.textColorVirtual}
              >
                {getCardName(card)}
              </Text>
            )}
            {showDetailsButton && isAllowedToReadCardNumber && areDetailsAvailable && (
              <HighbeamCardDetailsButton
                isPhysicalCard={isPhysicalCard}
                isRevealed={isRevealed}
                isLoading={isLoading}
                onHide={handleHide}
                onReveal={handleReveal}
              />
            )}
            {showStatus && isFraud && <HighbeamCardFraud />}
            {showStatus && !isFraud && isLocked && <HighbeamCardLocked />}
            {showArchived && <HighbeamCardArchived />}
            {showActivateCardButton && (
              <Button className="rounded-xl bg-white" size="sm" variant="plain">
                Activate
              </Button>
            )}
          </div>
        </div>
        <div className={styles.details}>
          <div className="flex items-center justify-between">
            <HighbeamCardTypePills cardType={getCardTypeByCard(card)} />

            {checkIsPhysicalCard(card) && <CardChip />}
          </div>

          <div className={styles.detailsRow}>
            <HighbeamCardNumber
              textColor={textColor}
              isLoading={isLoading}
              isRevealed={isRevealed}
              isAvailable={shouldFetchSensitiveData}
              card={card}
            />
          </div>
          <div className={styles.detailsRow}>
            <HighbeamCardExpiry
              textColor={textColor}
              card={card}
              isAvailable={shouldFetchSensitiveData}
            />
            <HighbeamCardCvv
              textColor={textColor}
              isLoading={isLoading}
              isRevealed={isRevealed}
              isAvailable={shouldFetchSensitiveData}
              card={card}
            />
          </div>
        </div>
        <div className={styles.rowWithSpace}>
          <CardholderInitialsAndFullName cardId={card.id} />
          <VisaLogo isPhysicalCard={isPhysicalCard} />
        </div>
      </div>
    </div>
  );
};

export default HighbeamCard;
