import classNames from "classnames";
import Portal from "components/Portal";
import { useState, useEffect, ReactNode, ComponentProps } from "react";
import { usePopper } from "react-popper";
import Text from "ui/typography/Text";

import styles from "./Tooltip.module.scss";

const getArrowPositionClassName = (currentArrowPosition: string) => {
  if (!currentArrowPosition) {
    return;
  }
  if (currentArrowPosition.includes("top")) {
    return styles["tooltip__arrow--bottom"];
  }
  if (currentArrowPosition.includes("bottom")) {
    return styles["tooltip__arrow--top"];
  }
};

type Props = {
  referenceElement: HTMLElement;
  openTooltip?: () => void;
  closeTooltip?: () => void;
  children: ReactNode;
  additionalArrowClassNames?: string;
  additionalWrapperClassNames?: string;
  additionalInnerClassNames?: string;
  rootElementId?: string;
};

const Tooltip: React.FC<Props> = ({
  referenceElement,
  openTooltip,
  additionalArrowClassNames,
  additionalWrapperClassNames,
  additionalInnerClassNames,
  closeTooltip,
  children,
  rootElementId = "tooltip",
}) => {
  const [currentArrowPosition, setCurrentArrowPosition] = useState("");
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: arrowElement,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 12],
        },
      },
      {
        // Prevents left/right auto placement https://popper.js.org/docs/v2/modifiers/flip/#allowedautoplacements
        name: "flip",
        options: {
          allowedAutoPlacements: ["top", "bottom"],
        },
      },
    ],
  });

  useEffect(() => {
    if (attributes.popper) {
      setCurrentArrowPosition(attributes.popper["data-popper-placement"]);
    }
  }, [attributes.popper]);

  return (
    <Portal elementId={rootElementId}>
      <div
        ref={setPopperElement}
        className={classNames(styles.container, additionalWrapperClassNames)}
        style={{ ...popperStyles.popper }}
        onMouseLeave={closeTooltip}
        onMouseOver={openTooltip}
        {...attributes.popper}
      >
        <div className={classNames(styles.tooltip, additionalInnerClassNames)}>{children}</div>
        <div
          ref={setArrowElement}
          className={classNames(
            currentArrowPosition && getArrowPositionClassName(currentArrowPosition),
            styles.tooltip__arrow,
            additionalArrowClassNames
          )}
          style={popperStyles.arrow}
        />
      </div>
    </Portal>
  );
};

const Content: React.FC<ComponentProps<typeof Text>> = ({ className, ...textProps }) => (
  <Text size={14} className={classNames(styles.content, className)} {...textProps} />
);

export default Object.assign(Tooltip, {
  Content: Content,
});
