import ModalV4 from "ui/overlay/ModalV4";

import EditUserRoleForm from "./EditUserRoleForm";
import {
  useCloseEditUserRoleModal,
  useEditUserRoleModalState,
} from "./state/editUserRoleModalState";

const EditUserRoleModal = () => {
  const closeEditUserRoleModal = useCloseEditUserRoleModal();
  const state = useEditUserRoleModalState();

  if (!state.isOpen) {
    return null;
  }

  return (
    <ModalV4 onClose={closeEditUserRoleModal}>
      <EditUserRoleForm />
    </ModalV4>
  );
};

export default EditUserRoleModal;
