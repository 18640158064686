import TraceNumberLabel from "components/Transactions/TransactionInfoFlexpane/TransactionInfoFlexpaneDetailsSection/TransactionInfoFlexpaneUtils/TraceNumberLabel/TraceNumberLabel";
import { useReceivedPaymentInfoFlexpaneContext } from "dialogs/ReceivedPaymentInfoFlexpane/context/ReceivedPaymentInfoFlexpaneProvider";
import MetadataList from "ui/data-display/MetadataList";
import Flexpane from "ui/overlay/Flexpane";

const ReceivedPaymentInfoFlexpaneDetailsSection = () => {
  const { receivedPayment } = useReceivedPaymentInfoFlexpaneContext();

  return (
    <Flexpane.Section>
      <Flexpane.Section.Heading>ACH details</Flexpane.Section.Heading>
      <MetadataList>
        <MetadataList.Item>
          <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{receivedPayment.attributes.description}</MetadataList.ItemValue>
        </MetadataList.Item>
        {receivedPayment.attributes.addenda && (
          <MetadataList.Item>
            <MetadataList.ItemLabel>Additional details</MetadataList.ItemLabel>
            <MetadataList.ItemValue>{receivedPayment.attributes.addenda}</MetadataList.ItemValue>
          </MetadataList.Item>
        )}
        <MetadataList.Item>
          <MetadataList.ItemLabel>Routing number</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            {receivedPayment.attributes.counterpartyRoutingNumber}
          </MetadataList.ItemValue>
        </MetadataList.Item>
        <MetadataList.Item>
          <MetadataList.ItemLabel>
            <TraceNumberLabel />
          </MetadataList.ItemLabel>
          <MetadataList.ItemValue>{receivedPayment.attributes.traceNumber}</MetadataList.ItemValue>
        </MetadataList.Item>
      </MetadataList>
    </Flexpane.Section>
  );
};

export default ReceivedPaymentInfoFlexpaneDetailsSection;
