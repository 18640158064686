import { ShieldStar, WarningCircle } from "@phosphor-icons/react";
import { useState } from "react";
import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import { useIsSuperusering } from "state/auth/isSuperusering";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import ItemWithTooltip from "ui/overlay/Tooltip/ItemWithTooltip/ItemWithTooltip";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import Text from "ui/typography/Text";
import { getPlaidBankAccountDisplayName } from "utils/account";

import DeletePlaidAccountModal from "./DeletePlaidAccountModal";
import styles from "./PlaidConnectionCardContents.module.scss";

type Props = {
  account: PlaidBankAccountRep.Complete;
};

const InactivePlaidAccountRow = ({ account }: Props) => {
  const isSuperusering = useIsSuperusering();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <ItemWithTooltip
      content={
        <Tooltip.Content size={14}>
          This account was removed from your Plaid connection. Please reconnect below, or reach out
          to Highbeam support to fully disconnect it.
        </Tooltip.Content>
      }
    >
      {isDeleteModalOpen && (
        <DeletePlaidAccountModal
          plaidAccountId={account.plaidAccountId}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}
      <div className={styles.plaidAccountRow}>
        <WarningCircle size={20} color={colors.yellow[600]} />
        <Text size={14} weight="medium" className={styles.inactive}>
          {getPlaidBankAccountDisplayName(account)}
        </Text>
        {isSuperusering && (
          <Button variant="danger" onClick={() => setIsDeleteModalOpen(true)} size="xs">
            <ShieldStar size={12} /> Delete
          </Button>
        )}
      </div>
    </ItemWithTooltip>
  );
};

export default InactivePlaidAccountRow;
