import classNames from "classnames";
import { FC, HTMLAttributes } from "react";
import Shimmer from "ui/feedback/Shimmer";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";

import styles from "./SectionLoading.module.scss";

const LOADING_COLUMNS: Column<string>[] = [
  {
    title: "",
    key: "1",
    headerRender: () => <Shimmer additionalShimmerClassName={[styles.shimmerHeader]} />,
  },
  {
    title: "",
    key: "2",
  },
  {
    title: "",
    key: "3",
  },
  {
    title: "",
    key: "4",
    headerRender: () => <Shimmer additionalShimmerClassName={[styles.shimmerHeaderSmall]} />,
    align: TableColumnAlignment.RIGHT,
  },
];

type SectionLoadingProps = HTMLAttributes<HTMLDivElement>;

const SectionLoading: FC<SectionLoadingProps> = ({ className }) => (
  <div className={classNames(className, styles.container)}>
    <Table isLoading columns={LOADING_COLUMNS} rowKey={"unused"} data={undefined} />
  </div>
);

export default SectionLoading;
