import { ArrowFatLinesUp } from "@phosphor-icons/react";
import { FC } from "react";
import Button, { ButtonProps } from "ui/inputs/Button";

const ChargeCardHighlightCardRepayChargeCardsButton: FC<ButtonProps> = ({ ...buttonProps }) => {
  return (
    <Button variant="secondary" className="w-full" {...buttonProps}>
      <ArrowFatLinesUp size={20} weight="light" />
      Repay
    </Button>
  );
};

export default ChargeCardHighlightCardRepayChargeCardsButton;
