import SubcategoryRep from "reps/Insights/SubcategoryRep";

const VENDOR_TYPE_PAYEE = "Payee";
const VENDOR_TYPE_RULE = "Rule";
const VENDOR_TYPE_MERCHANT_NAME = "MerchantName";
const VENDOR_TYPE_TRANSACTION_DESCRIPTION = "TransactionDescription";

export type VendorType =
  | typeof VENDOR_TYPE_PAYEE
  | typeof VENDOR_TYPE_RULE
  | typeof VENDOR_TYPE_MERCHANT_NAME
  | typeof VENDOR_TYPE_TRANSACTION_DESCRIPTION;

interface VendorSummaryRep {
  readonly vendorName: string;
  readonly vendorType: VendorType;
  readonly vendors: VendorSummaryRep.Vendor[];
  readonly subcategory: SubcategoryRep.Complete | null;
  readonly table: VendorSummaryRep.Datum[];
  readonly total: VendorSummaryRep.Datum;
}

namespace VendorSummaryRep {
  export namespace Vendor {
    interface Base {
      readonly type: VendorType;
      readonly name: string;
    }

    export interface Payee extends Base {
      readonly type: typeof VENDOR_TYPE_PAYEE;
      readonly payeeGuid: string;
    }
    export const isPayeeVendor = (vendor: VendorSummaryRep.Vendor): vendor is Payee =>
      vendor.type === VENDOR_TYPE_PAYEE;

    export interface Rule extends Base {
      readonly type: typeof VENDOR_TYPE_RULE;
      readonly ruleBusinessGuid: string | null;
      readonly ruleGuid: string;
    }
    export const isRuleVendor = (vendor: VendorSummaryRep.Vendor): vendor is Rule =>
      vendor.type === VENDOR_TYPE_RULE;

    export interface MerchantName extends Base {
      readonly type: typeof VENDOR_TYPE_MERCHANT_NAME;
    }
    export const isMerchantNameVendor = (vendor: VendorSummaryRep.Vendor): vendor is MerchantName =>
      vendor.type === VENDOR_TYPE_MERCHANT_NAME;

    export interface TransactionDescription extends Base {
      readonly type: typeof VENDOR_TYPE_TRANSACTION_DESCRIPTION;
    }
    export const isTransactionDescriptionVendor = (
      vendor: VendorSummaryRep.Vendor
    ): vendor is TransactionDescription => vendor.type === VENDOR_TYPE_TRANSACTION_DESCRIPTION;
  }

  export type Vendor =
    | Vendor.Rule
    | Vendor.Payee
    | Vendor.MerchantName
    | Vendor.TransactionDescription;

  export interface Datum {
    readonly amount: number;
  }
}

export default VendorSummaryRep;
