import { FC, ComponentPropsWithoutRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import cn from "utils/tailwind/cn";

const BASE_CLASSES = "text-grey-900";

const Paragraph: FC<ComponentPropsWithoutRef<"p">> = ({ children, className, ...props }) => (
  <p className={cn(BASE_CLASSES, className)} {...props}>
    {children}
  </p>
);

const Span: FC<ComponentPropsWithoutRef<"span">> = ({ children, className, ...props }) => (
  <span className={cn(BASE_CLASSES, className)} {...props}>
    {children}
  </span>
);

const Strong: FC<ComponentPropsWithoutRef<"strong">> = ({ children, className, ...props }) => (
  <strong className={cn(BASE_CLASSES, "font-medium", className)} {...props}>
    {children}
  </strong>
);

const Em: FC<ComponentPropsWithoutRef<"em">> = ({ children, className, ...props }) => (
  <em className={cn(BASE_CLASSES, "italic", className)} {...props}>
    {children}
  </em>
);

const makeTextLinkClasses = (className?: string) =>
  cn(BASE_CLASSES, "text-purple-500 underline visited:text-purple-500", className);

type LinkProps = ComponentPropsWithoutRef<"a"> & {
  href: string;
  target?: never;
};

const Link: FC<LinkProps> = ({ children, className, href, ...props }) => {
  const isExternalLink = href.startsWith("http");
  const classes = makeTextLinkClasses(className);

  if (isExternalLink) {
    return (
      <a className={classes} href={href} {...props}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink className={classes} to={href} {...props}>
      {children}
    </RouterLink>
  );
};

type TextButtonProps = ComponentPropsWithoutRef<"button">;

const TextButton: FC<TextButtonProps> = ({ children, className, disabled, ...props }) => (
  <button
    type="button"
    disabled={disabled}
    className={makeTextLinkClasses(
      cn("inline-flex items-center", disabled && "pointer-events-none opacity-50", className)
    )}
    {...props}
  >
    {children}
  </button>
);

const Heading1: FC<ComponentPropsWithoutRef<"h1">> = ({ children, className, ...props }) => (
  <h1 className={cn(BASE_CLASSES, "text-xl font-medium", className)} {...props}>
    {children}
  </h1>
);

const Heading2: FC<ComponentPropsWithoutRef<"h2">> = ({ children, className, ...props }) => (
  <h2 className={cn(BASE_CLASSES, "text-lg font-bold", className)} {...props}>
    {children}
  </h2>
);

const Heading3: FC<ComponentPropsWithoutRef<"h3">> = ({ children, className, ...props }) => (
  <h3 className={cn(BASE_CLASSES, "text-md font-bold", className)} {...props}>
    {children}
  </h3>
);

const Heading4: FC<ComponentPropsWithoutRef<"h4">> = ({ children, className, ...props }) => (
  <h4 className={cn(BASE_CLASSES, "text-sm font-bold", className)} {...props}>
    {children}
  </h4>
);

export { Paragraph, Span, Strong, Em, Link, TextButton, Heading1, Heading2, Heading3, Heading4 };
