import ChargeCardAccountRep from "reps/ChargeCardAccountRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class ChargeCardAccountApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(
    businessGuid: string,
    capitalAccountGuid: string
  ): Promise<ChargeCardAccountRep.Complete | null> {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      capitalAccountGuid: capitalAccountGuid,
    });
    const url = `/charge-card-accounts?${qp}`;
    return await this.api.get(url);
  }

  async getByUnitCoCreditAccountId(
    unitCoCreditAccountId: string
  ): Promise<ChargeCardAccountRep.Complete | null> {
    const qp = new URLSearchParams({
      unitCoCreditAccountId: unitCoCreditAccountId,
    });
    const url = `/charge-card-accounts?${qp}`;
    return await this.api.get(url);
  }

  async updateRepaymentAccount(
    businessGuid: string,
    chargeCardAccountGuid: string,
    repaymentBankAccountGuid: string
  ): Promise<ChargeCardAccountRep.Complete | null> {
    const qp = new URLSearchParams({ businessGuid });
    const url = `/charge-card-accounts/${chargeCardAccountGuid}/repayment?${qp}`;
    return await this.api.patch(url, { repaymentBankAccountGuid });
  }
}
