import colors from "styles/colors";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";

import TextCell from "../TextCell";

const UpdatingIndicatorCell = () => {
  return (
    <TextCell color={colors.grey[600]} weight="regular">
      <AnimatedSpinner /> Updating
    </TextCell>
  );
};

export default UpdatingIndicatorCell;
