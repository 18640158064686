import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import useMfa from "modules/mfa/useMfa";
import chargeCardAccountQueryHooks from "resources/charge-cards/queries/chargeCardAccountQueryHooks";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

type Data = {
  chargeCardAccountGuid: string;
  repaymentAccountGuid: string;
};

const useEditCapitalAccountAutoPayAccountMutation = (capitalAccountGuid: string) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const { mfa } = useMfa();

  const refreshChargeCardAccountQuery = chargeCardAccountQueryHooks.useRefreshQuery({
    capitalAccountGuid,
  });

  return useMutation({
    mutationFn: async (data: Data) => {
      await mfa();
      await highbeamApi.chargeCardAccount.updateRepaymentAccount(
        businessGuid,
        data.chargeCardAccountGuid,
        data.repaymentAccountGuid
      );
    },
    onSuccess: async () => {
      await refreshChargeCardAccountQuery();
      notify("success", "Auto-pay account changed");
    },
    onError: (error) => {
      notify("error", "Error saving auto-pay account");
      captureException(error);
    },
  });
};

export default useEditCapitalAccountAutoPayAccountMutation;
