import { ReactNode, useEffect, useState } from "react";
import Tooltip from "ui/overlay/Tooltip/Tooltip";

type Props = {
  children: ReactNode;
  onClick?: () => void;
  content: ReactNode;
  rerenderTooltip?: boolean;
  setRerenderTooltip?: (rerenderTooltip: boolean) => void;
  wrapperTooltipClassName?: string;
  wrapperClassName?: string;
  rootElementId?: string;
  delayHideMs?: number;
  hideOnClick?: boolean;
  shouldRenderTooltip?: boolean;
};

const ItemWithTooltip: React.FC<Props> = ({
  children,
  onClick,
  rerenderTooltip,
  setRerenderTooltip,
  content,
  wrapperTooltipClassName,
  wrapperClassName,
  rootElementId = "tooltip",
  delayHideMs,
  hideOnClick = false,
  shouldRenderTooltip = true,
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [isMouseOvered, setIsMouseOver] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);
  const [hideTooltipTimer, setHideTooltipTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!rerenderTooltip || !isMouseOvered) {
      return;
    }

    setShowTooltip(false);
    const timer = setTimeout(() => {
      setShowTooltip(true);
      if (setRerenderTooltip) setRerenderTooltip(false);
    }, 0);

    return () => clearTimeout(timer);
  }, [rerenderTooltip, setRerenderTooltip, isMouseOvered]);

  const handleMouseOver = () => {
    if (delayHideMs && hideTooltipTimer) {
      clearTimeout(hideTooltipTimer);
    }
    setIsMouseOver(true);
    setShowTooltip(true);
  };
  const handleMouseOut = () => {
    if (delayHideMs) {
      const hideTimer = setTimeout(() => {
        setHideTooltipTimer(null);
        setIsMouseOver(false);
        setShowTooltip(false);
      }, delayHideMs);
      setHideTooltipTimer(hideTimer);
    } else {
      setIsMouseOver(false);
      setShowTooltip(false);
    }
  };

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (hideOnClick) {
      setIsMouseOver(false);
      setShowTooltip(false);
    }
    onClick?.();
  };

  return (
    <span
      className={wrapperClassName}
      onClick={handleOnClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      ref={setReferenceElement}
    >
      {children}
      {shouldRenderTooltip && showTooltip && referenceElement && (
        <Tooltip
          closeTooltip={() => setShowTooltip(false)}
          openTooltip={() => setShowTooltip(true)}
          referenceElement={referenceElement}
          additionalWrapperClassNames={wrapperTooltipClassName}
          rootElementId={rootElementId}
        >
          {content}
        </Tooltip>
      )}
    </span>
  );
};

export default ItemWithTooltip;
