import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import cn from "utils/tailwind/cn";

import { ButtonPaddingVariant, ButtonSize, ButtonVariant, getButtonClasses } from "../Button";

export type ButtonLinkProps = LinkProps & {
  variant?: ButtonVariant;
  paddingVariant?: ButtonPaddingVariant;
  size?: ButtonSize;
  disabled?: boolean;
};

const ButtonLink: FC<ButtonLinkProps> = ({
  variant = "default",
  paddingVariant = "regular",
  className,
  size = "md",
  disabled = false,
  children,
  ...props
}) => {
  return (
    <Link
      className={cn(
        getButtonClasses({
          variant,
          paddingVariant,
          size,
        }),
        disabled && "pointer-events-none",
        className
      )}
      aria-disabled={disabled}
      data-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
      {...props}
    >
      {children}
    </Link>
  );
};

export default ButtonLink;
