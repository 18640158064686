import { captureException, captureMessage } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { Dayjs } from "dayjs";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { downloadString } from "utils/download";

type BaseVariables = {
  since?: Dayjs;
  until?: Dayjs;
  search?: string;
};

type AllTransactionsVariables = BaseVariables;

type CardTransactionsVariables = BaseVariables & {
  cardId: string;
  cardLast4Digits: string;
};

type Variables = AllTransactionsVariables | CardTransactionsVariables;

const useDownloadCardTransactionsCsvMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useMutation({
    mutationFn: async (variables: Variables) => {
      if ("cardId" in variables) {
        return await highbeamApi.card.transactionsCsv(
          businessGuid,
          variables.cardId,
          variables.search,
          variables.since,
          variables.until
        );
      } else {
        return await highbeamApi.card.allTransactionsCsv(
          businessGuid,
          variables.search,
          variables.since,
          variables.until
        );
      }
    },
    onSuccess: (csvText, variables) => {
      if (!csvText) {
        if ("cardId" in variables) {
          captureMessage(`Export CSV for ${variables.cardId} failed. `);
        } else {
          captureMessage("Export CSV for all cards transactions failed.");
        }

        notify("info", "Something went wrong while exporting transactions. Please try again.");
        return;
      }

      const fileName =
        "cardId" in variables
          ? `card-transactions_${variables.cardLast4Digits}_${variables.since}_${variables.until}.csv`
          : `card-transactions_${variables.since}_${variables.until}.csv`;

      downloadString(fileName, csvText);
    },
    onError: (err) => {
      captureException(err);
      notify("info", "Something went wrong while exporting transactions. Please try again.");
    },
  });
};

export default useDownloadCardTransactionsCsvMutation;
