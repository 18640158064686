import { Plus } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import { FC } from "react";
import CapitalAssociatedCardsTableView from "resources/capital-accounts/components/CapitalAssociatedCardsTableView";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import CreateCardModal from "resources/cards/dialogs/CreateCardModal";
import useCreateCardModalState from "resources/cards/hooks/useCreateCardModalState";
import cardsQueryHooks from "resources/cards/queries/cardsQueryHooks";
import chargeCardAccountQueryHooks from "resources/charge-cards/queries/chargeCardAccountQueryHooks";
import AccentCard from "ui/data-display/AccentCard";
import Button from "ui/inputs/Button";
import { Heading2, Heading4, Paragraph } from "ui/typography";

import CapitalAvailableSection from "../../components/CapitalAvailableSection";
import ChargeCardRepayments from "../../components/ChargeCardRepayments";
import CapitalAccountBanners from "../../sections/CapitalAccountBanners";
import CapitalAccountHeader from "../../sections/CapitalAccountHeader";

import ChargeCardHighlightCard from "./ChargeCardHighlightCard";
import LineOfCreditHighlightCard from "./LineOfCreditHighlightCard";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardView: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  const chargeCardAccount = chargeCardAccountQueryHooks.useDataRequired({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
  });

  const { data: cards } = cardsQueryHooks.useQuery({
    status: "active",
    unitCoAccountId: chargeCardAccount.unitCoCreditAccountId,
  });

  const { createCardModalState, openCreateCardModal, closeCreateCardModal } =
    useCreateCardModalState();

  const hasAssociatedCards = cards === undefined || cards.length > 0;

  const createCardButton = (
    <Button
      variant="tertiary"
      className="w-fit"
      onClick={() => {
        openCreateCardModal({
          defaultValues: {
            capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
          },
        });
      }}
    >
      <Plus size={16} /> Create card
    </Button>
  );

  return (
    <>
      {createCardModalState.isOpen && (
        <CreateCardModal
          defaultValues={createCardModalState.defaultValues}
          onClose={closeCreateCardModal}
        />
      )}

      <CapitalAccountBanners capitalAccountSummary={capitalAccountSummaryWithChargeCard} />

      <CapitalAccountHeader capitalAccountSummary={capitalAccountSummaryWithChargeCard} />

      <DashboardPage.Section>
        <CapitalAvailableSection
          capitalAccountSummary={capitalAccountSummaryWithChargeCard}
          showViewCardsButton
        />

        <DashboardPage.Section.Body>
          <ChargeCardHighlightCard
            capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
          />
        </DashboardPage.Section.Body>

        <DashboardPage.Section.Body>
          <LineOfCreditHighlightCard capitalAccountSummary={capitalAccountSummaryWithChargeCard} />
        </DashboardPage.Section.Body>
      </DashboardPage.Section>

      <DashboardPage.Section>
        <DashboardPage.Section.Header actions={hasAssociatedCards && createCardButton}>
          <Heading2>Associated cards</Heading2>
        </DashboardPage.Section.Header>

        {hasAssociatedCards ? (
          <CapitalAssociatedCardsTableView cards={cards} />
        ) : (
          <AccentCard className="items-center p-16 text-center">
            <Heading4 className="mb-2">Create a new card</Heading4>
            <Paragraph className="mb-4 w-full max-w-60 text-sm text-grey-600">
              Create a card and connect it with this capital account
            </Paragraph>
            {createCardButton}
          </AccentCard>
        )}
      </DashboardPage.Section>

      <DashboardPage.Section>
        <ChargeCardRepayments
          capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
        />
      </DashboardPage.Section>
    </>
  );
};

export default ChargeCardView;
