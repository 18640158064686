import { Note } from "@phosphor-icons/react";
import { CardTransactionsTableTransaction } from "modules/card-transactions-table/queries/useCardTransactionsTableData";
import { FC } from "react";
import ItemWithTooltip from "ui/overlay/Tooltip/ItemWithTooltip/ItemWithTooltip";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import NotFoundCell from "ui/table/NotFoundCell";

import useCardDetailsPageCardTransactionsTablePaymentMetadata from "../../hooks/useCardDetailsPageCardTransactionsTablePaymentMetadata";

import styles from "./CardTransactionsTableNotesCell.module.scss";

type Props = {
  transaction: CardTransactionsTableTransaction;
};

const CardTransactionsTableNotesCell: FC<Props> = ({ transaction }) => {
  const paymentMetadataItem = useCardDetailsPageCardTransactionsTablePaymentMetadata(
    transaction.attributes.tags?.generalPaymentMetadataGuid ?? undefined
  );

  if (!paymentMetadataItem || !paymentMetadataItem.notes) {
    return <NotFoundCell />;
  }

  return (
    <div>
      <ItemWithTooltip
        wrapperClassName={styles.tooltipWrapper}
        content={<Tooltip.Content>{paymentMetadataItem.notes}</Tooltip.Content>}
      >
        <Note size={24} />
      </ItemWithTooltip>
    </div>
  );
};

export default CardTransactionsTableNotesCell;
