import { MenuItem as BaseMenuItem } from "@headlessui/react";
import classNames from "classnames";
import { cloneElement, ComponentProps, FC, ReactElement } from "react";
import { Link } from "react-router-dom";
import Button, { ButtonVariant } from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./MenuItem.module.scss";

const ICON_SIZE = 16;

export const MenuItemIconSpacer = () => {
  return <div style={{ width: ICON_SIZE, height: ICON_SIZE }} />;
};

type Props = ComponentProps<typeof BaseMenuItem> & {
  icon?: ReactElement<{ size?: number }>;
  variant?: ButtonVariant;
  linkTo?: string;

  // TODO(alex): Figure out how to prevent the menu from closing while pending...
  isLoading?: boolean;
};

const MenuItem: FC<Props> = ({
  icon,
  variant = "ghost",
  children,
  className,
  linkTo,
  isLoading,
  ...menuItemProps
}) => {
  const element = (
    <BaseMenuItem {...menuItemProps}>
      {(renderProps) => (
        <Button
          className={classNames(className, styles.item, styles.border, {
            [styles.active]: renderProps.active,
          })}
          variant={variant}
          isLoading={isLoading}
          disabled={renderProps.disabled}
        >
          <div className={styles.content}>
            {icon && <div className={styles.icon}>{cloneElement(icon, { size: ICON_SIZE })}</div>}

            <Text className={styles.text} size={14} weight="medium">
              {typeof children === "function" ? children(renderProps) : children}
            </Text>
          </div>
        </Button>
      )}
    </BaseMenuItem>
  );

  if (linkTo) {
    return (
      <Link
        to={linkTo}
        className={styles.border} // Preserves first/last child border styles
      >
        {element}
      </Link>
    );
  } else {
    return element;
  }
};

export default Object.assign(MenuItem, {
  IconSpacer: MenuItemIconSpacer,
});
