import applePayLogo from "assets/apple-pay.svg";
import Text from "ui/typography/Text";
import cn from "utils/tailwind/cn";

type Props = {
  className?: string;
};

const ApplePayInfoText: React.FC<Props> = ({ className }) => (
  <div
    className={cn(
      "flex flex-grow items-center justify-center rounded-md bg-grey-100 p-3",
      className
    )}
  >
    <Text className="text-sm">
      Open the wallet app on your phone and add the card to start using it with{" "}
      <img src={applePayLogo} className="align-middle" alt="Apple Pay" />
    </Text>
  </div>
);

export default ApplePayInfoText;
