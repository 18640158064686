import { FC, PropsWithChildren, ReactNode } from "react";
import CopyItemTooltip from "ui/overlay/Tooltip/CopyItemTooltip/CopyItemTooltip";
import Text from "ui/typography/Text";

import styles from "./InlineCopyText.module.scss";

type Props = PropsWithChildren & {
  textToCopy: string;
  tooltipContent?: ReactNode;
};

const InlineCopyText: FC<Props> = ({ textToCopy, tooltipContent, children }) => {
  return (
    <CopyItemTooltip
      textToCopy={textToCopy}
      tooltipContent={tooltipContent}
      wrapperClassName={styles.container}
    >
      <Text as="span" weight="medium">
        {children}
      </Text>
    </CopyItemTooltip>
  );
};

export default InlineCopyText;
