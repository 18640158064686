import { Authorization } from "@highbeam/unit-node-sdk";
import { DISABLE_SCROLL_TO_TOP_STATE } from "components/navigation/ScrollToTopOnNavigate";
import TransactionsV2 from "components/TransactionsV2";
import DashboardPage from "layouts/DashboardPage";
import { FC, Suspense } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import DateTimeCell from "ui/table/DateTimeCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";

import useAccountAuthorizations from "../hooks/useAccountAuthorizations";

type Props = {
  accountId: string;
};

const authorizationColumns: Column<Authorization>[] = [
  {
    title: "Date",
    cellRender: ({ attributes }) => <DateTimeCell date={attributes.createdAt} />,
    width: 120,
  },
  {
    title: "Vendor",
    cellRender: ({ attributes }) => (
      <Text size={14} weight="medium" color={colors.grey[800]}>
        {attributes.merchant.name}
      </Text>
    ),
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: ({ attributes: { amount } }) => (
      <MoneyAmount
        as="div"
        cents={amount}
        direction="negative"
        color={colors.grey[800]}
        weight="medium"
      />
    ),
  },
];

const AccountAuthorizationsTable: FC<Props> = ({ accountId }) => {
  const { authorizations } = useAccountAuthorizations({ accountId });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (authorizations.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Body>
        <TransactionsV2
          title={<TransactionsV2.Title>Pending</TransactionsV2.Title>}
          table={
            <Suspense
              fallback={
                <Table.Loading columns={authorizationColumns.map(({ title }) => ({ title }))} />
              }
            >
              <TransactionsV2.TransactionsTable
                data={authorizations}
                rowKey={({ id }) => id}
                columns={authorizationColumns}
                onRowClick={(authorization) => {
                  const cardId = authorization.relationships.card.data.id;
                  navigate(`/cards/${cardId}/pending/${authorization.id}?${searchParams}`, {
                    state: DISABLE_SCROLL_TO_TOP_STATE,
                  });
                }}
              />
            </Suspense>
          }
        />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default AccountAuthorizationsTable;
