import { ArrowSquareOut, WarningCircle } from "@phosphor-icons/react";
import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import useBill from "resources/bills/queries/useBill";
import Banner from "ui/data-display/Banner";
import Text from "ui/typography/Text";

type Props = {
  duplicateBillId: string;
};

const DuplicateBillBanner: FC<Props> = ({ duplicateBillId }) => {
  const duplicateBill = useBill(duplicateBillId, { required: true });
  const { search } = useLocation();
  return (
    <Banner
      title="Duplicate bill"
      color="orange"
      icon={<WarningCircle />}
      paragraph={
        <>
          The invoice details match{" "}
          <Text as="span" size={14} weight="medium">
            <Link to={`/bills/${duplicateBill.id}${search}`} target="_blank">
              {duplicateBill.invoiceNumber ? duplicateBill.invoiceNumber : "this bill"}{" "}
              <ArrowSquareOut size={16} weight="bold" />
            </Link>
          </Text>
        </>
      }
    />
  );
};

export default DuplicateBillBanner;
