import { ArrowRight } from "@phosphor-icons/react";
import AccountLabel from "components/Accounts/AccountLabel";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import BankAccountRep from "reps/BankAccountRep";
import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import AmountCell from "ui/table/AmountCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";

import styles from "./BankAccountsTable.module.scss";

const createColumns = (): Column<BankAccountRep.Complete>[] => [
  {
    title: "Account",
    cellRender: (account) => <AccountLabel bankAccount={account} showConnectedStores />,
  },
  {
    title: "Available",
    align: TableColumnAlignment.RIGHT,
    cellRender: (account) => <AmountCell cents={account.availableBalance} />,
  },
  {
    title: "",
    align: TableColumnAlignment.RIGHT,
    width: 160,
    cellRender: () => (
      <div className={styles.details}>
        <ArrowRight size={16} />
      </div>
    ),
  },
];

const BankAccountsTable = () => {
  const navigate = useNavigate();
  const bankAccounts = bankAccountsQueryHooks.useData({ status: "open" });
  const columns = useMemo(() => createColumns(), []);

  return (
    <Table
      columns={columns}
      rowKey={(account) => account.guid}
      data={bankAccounts}
      onRowClick={(account) => navigate(`/accounts/${account.guid}`)}
    />
  );
};

export default BankAccountsTable;
