import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import useAccountingPlatformConnectionsQueryOptions from "resources/accounting-platforms/queries/useAccountingPlatformConnectionsQueryOptions";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const useDisconnectRutterConnectionMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const refreshAccountingConnections = useRefreshQuery(
    useAccountingPlatformConnectionsQueryOptions().queryKey
  );

  return useMutation({
    mutationFn: (connectionGuid: string) =>
      highbeamApi.rutterConnection.delete(businessGuid, connectionGuid),
    onSuccess: () => refreshAccountingConnections(),
    onError: (error) => captureException(error),
  });
};

export default useDisconnectRutterConnectionMutation;
