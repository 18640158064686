import { FC, useState } from "react";
import ModalV4 from "ui/overlay/ModalV4";
import { Heading4 } from "ui/typography";

import BillLineItemsContent from "../BillLineItemsContent";
import BillLineItemsHeading from "../BillLineItemsHeading";
import BillLineItemsTotal from "../BillLineItemsTotal";

type BillLineItemsModalProps = {
  billId: string;
  onClose: () => void;
};

const BillLineItemsModal: FC<BillLineItemsModalProps> = ({ billId, onClose }) => (
  <ModalV4 onClose={onClose}>
    <ModalV4.Header>
      <span className="flex w-full items-center justify-between pr-4">
        <BillLineItemsHeading billId={billId} as="span" />
      </span>
    </ModalV4.Header>
    <div className="flex w-full items-center justify-between border-b border-grey-100 bg-grey-50 px-4 py-3">
      <Heading4 className="text-md font-medium text-grey-500">Total</Heading4>
      <BillLineItemsTotal billId={billId} />
    </div>
    <ModalV4.Body>
      <BillLineItemsContent billId={billId} />
    </ModalV4.Body>
  </ModalV4>
);

type Props = {
  billId: string;
};

const BillLineItemsMobile: FC<Props> = ({ billId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className="-mx-8 -mt-6 mb-4 flex cursor-pointer items-center justify-between border-b border-grey-200 px-8 py-4 hover:bg-grey-50 focus-visible:outline-focus active:shadow-inset"
        onClick={() => setIsModalOpen(true)}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            event.currentTarget.click();
          }
        }}
      >
        <BillLineItemsHeading billId={billId} className="" />
      </div>

      {isModalOpen && <BillLineItemsModal billId={billId} onClose={() => setIsModalOpen(false)} />}
    </>
  );
};

export default BillLineItemsMobile;
