import whiteLogo from "assets/highbeam-logo-white.svg";
import purpleLogo from "assets/highbeam-logo-without-name.svg";

import styles from "./HighbeamCard.module.scss";

type Props = {
  isPhysicalCard: boolean;
};

const HighbeamCardLogo: React.FC<Props> = ({ isPhysicalCard }) => (
  <img className={styles.highbeamLogo} src={isPhysicalCard ? whiteLogo : purpleLogo} alt="Logo" />
);

export default HighbeamCardLogo;
