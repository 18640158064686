import { useSuspenseQuery } from "@tanstack/react-query";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

// NB(lev): This query key does *not* share common components with the
// `useAccountingAccounts` query. Unlike `useAccountingAccounts`, this query
// is not parameterized by a business guid.
const makeQueryKey = (accountingAccountId: string) => [
  "accountingAccount",
  { accountingAccountId },
];

const useAccountingAccountQueryOptions = (accountingAccountId: string) => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: makeQueryKey(accountingAccountId),
    queryFn: () => highbeamApi.accountingAccount.get(accountingAccountId),
  });
};

const useAccountingAccount = (accountingAccountId: string) => {
  const { data } = useSuspenseQuery(useAccountingAccountQueryOptions(accountingAccountId));
  return data;
};

export default useAccountingAccount;
