const variants = <
  T extends string | number,
  U extends Record<T, string | Record<any, string> | undefined>,
>(
  value: T,
  options: U
): U[T] => {
  return options[value];
};

export default variants;
