import { RecurringPaymentListParams } from "@highbeam/unit-node-sdk";
import useBusinessUnitCoCustomerId from "resources/business/queries/useBusinessUnitCoCustomerId";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import { Simplify } from "type-fest";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const UNIT_CO_RECURRING_PAYMENTS_QUERY_KEY = "unit-co-recurring-payments";

type Params = Simplify<
  RecurringPaymentListParams & {
    customerId?: never; // Always use the customerId from the business state
  }
>;

const useUnitCoRecurringPaymentsQueryOptions = (params: Params) => {
  const unitApi = useUnitApi();
  const customerId = useBusinessUnitCoCustomerId({ required: true });

  return useQueryOptions({
    queryKey: [UNIT_CO_RECURRING_PAYMENTS_QUERY_KEY, customerId, params],
    queryFn: async () => {
      const response = await unitApi.recurringPayments.list({
        sort: "-createdAt",
        customerId: customerId,
        status: ["Active"],
        ...params,
      });

      const sortedRecurringPayments = response.data.sort(
        (a, b) =>
          Date.parse(a.attributes.schedule.nextScheduledAction) -
          Date.parse(b.attributes.schedule.nextScheduledAction)
      );

      return {
        recurringPayments: sortedRecurringPayments,
        pagination: response.meta.pagination,
      };
    },
  });
};

export default useUnitCoRecurringPaymentsQueryOptions;
