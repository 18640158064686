import { FC, ReactNode } from "react";
import Button, { ButtonProps } from "ui/inputs/Button";
import cn from "utils/tailwind/cn";

import useModalContext, { ModalContextValue } from "./useModalContext";

export const ModalSubmitButton: FC<ButtonProps> = ({ children, ...buttonProps }) => {
  const { formId } = useModalContext();

  return (
    <Button type="submit" variant="primary" form={formId} {...buttonProps}>
      {children}
    </Button>
  );
};

export const ModalCloseButton: FC<ButtonProps> = ({ children = "Close", ...buttonProps }) => {
  const { closeModalWithAnimation } = useModalContext();

  return (
    <Button type="button" variant="default" onClick={closeModalWithAnimation} {...buttonProps}>
      {children}
    </Button>
  );
};

type ModalFooterProps = {
  children: ReactNode | ((renderProps: ModalContextValue) => ReactNode);
  className?: string;
};

const ModalFooter: FC<ModalFooterProps> = ({ children, className }) => {
  const modalContext = useModalContext();

  return (
    <div
      className={cn(
        "flex w-full flex-row-reverse gap-6 rounded-lg bg-grey-50 p-6",
        "sticky bottom-0 tablet:static tablet:bottom-auto",
        className
      )}
    >
      {typeof children === "function" ? children(modalContext) : children}
    </div>
  );
};

export default ModalFooter;
