import { X as XIcon, CheckCircle as CheckCircleIcon } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC, useState, useCallback } from "react";
import BillRep from "reps/BillRep";
import useApproverSelectOptions, {
  ApproverSelectOption,
} from "resources/bill-approvals/hooks/useApproverSelectOptions";
import useCreateBillApprovalMutation from "resources/bill-approvals/mutations/useCreateBillApprovalMutation";
import useBillApprovals from "resources/bill-approvals/queries/useBillApprovals";
import useBill from "resources/bills/queries/useBill";
import { useIsSuperusering } from "state/auth/isSuperusering";
import colors from "styles/colors";
import Pill from "ui/data-display/Pill";
import UserAvatar from "ui/data-display/UserAvatar";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import DropdownV2 from "ui/inputs/DropdownV2";
import ModalV4 from "ui/overlay/ModalV4";
import { Heading3 } from "ui/typography";
import Text from "ui/typography/Text";
import getInitials from "utils/string/getInitials";

import styles from "./BillApproversAddModal.module.scss";

type ApproverOptionLabelProps = {
  approver: ApproverSelectOption;
  withAvatar?: boolean;
};

const ApproverOptionLabel: FC<ApproverOptionLabelProps> = ({ approver, withAvatar }) => {
  const { displayName, isCurrentUser, userRoleName } = approver;

  return (
    <div className={styles.approverOptionLabel}>
      {withAvatar && (
        <UserAvatar initials={getInitials(displayName)} size={32} color="purple-light" />
      )}
      <Text as="span">
        {displayName}
        {isCurrentUser && " (you)"}
      </Text>
      <Pill color="grey-light">{userRoleName}</Pill>
    </div>
  );
};

type SelectedApproverItemProps = {
  approver: ApproverSelectOption;
  onRemove: (approver: ApproverSelectOption) => void;
  isDisabled?: boolean;
};

const SelectedApproverItem: FC<SelectedApproverItemProps> = ({
  approver,
  onRemove,
  isDisabled = false,
}) => {
  return (
    <li
      className={classNames(
        styles.selectedApproverItem,
        isDisabled && styles["selectedApproverItem-disabled"]
      )}
    >
      <ApproverOptionLabel approver={approver} withAvatar />
      <Button
        aria-label="Remove"
        paddingVariant="square"
        variant="ghost"
        disabled={isDisabled}
        onClick={() => onRemove(approver)}
      >
        <XIcon size={16} />
      </Button>
    </li>
  );
};

type DoneTextContentProps = {
  selectedApprovers: ApproverSelectOption[];
};

// Only used in the "done" step for non-draft bills.
const DoneTextContent: FC<DoneTextContentProps> = ({ selectedApprovers }) => {
  const selectedApproversCurrentUser = selectedApprovers.find((approver) => approver.isCurrentUser);
  const selectedApproversOthers = selectedApprovers.filter((approver) => !approver.isCurrentUser);

  const selectedApproversOthersContent =
    selectedApproversOthers.length === 0
      ? null
      : selectedApproversOthers
          .map(({ guid, displayName }) => (
            <Text key={guid} as="strong" weight="medium">
              {displayName}
            </Text>
          ))
          .reduce((left, right) => (
            <>
              {left} and {right}
            </>
          ));

  if (selectedApproversCurrentUser) {
    if (selectedApproversOthers.length > 0) {
      return (
        <>
          You have been added as an approver for this bill and we emailed{" "}
          {selectedApproversOthersContent} asking them to approve this bill.
        </>
      );
    }
    return <>You have been added as an approver for this bill.</>;
  }

  return <>We emailed {selectedApproversOthersContent} asking them to approve this bill.</>;
};

// Common to both <BillApproversAddModalContent> and <BillApproversAddModal>.
type BillApproversAddModalProps = {
  billId: string;
  onClose: () => void;
};

const BillApproversAddModalContent: FC<BillApproversAddModalProps> = ({ billId, onClose }) => {
  const isSuperusering = useIsSuperusering();

  const bill = useBill(billId, { required: true });
  const isDraftBill = bill.state === BillRep.State.Draft;
  const billApprovals = useBillApprovals(billId);

  const [selectedApprovers, setSelectedApprovers] = useState<ApproverSelectOption[]>([]);

  const approverOptions = useApproverSelectOptions().filter(
    ({ guid }) =>
      !selectedApprovers.some((approver) => approver.guid === guid) &&
      !billApprovals.some((billApproval) => billApproval.businessMemberGuid === guid)
  );
  const [approverInputValue, setApproverInputValue] = useState("");

  const pushSelectedApprover = useCallback((approver: ApproverSelectOption) => {
    setSelectedApprovers((currentSelectedApprovers) => [...currentSelectedApprovers, approver]);
    setApproverInputValue("");
  }, []);

  const removeSelectedApprover = useCallback((approver: ApproverSelectOption) => {
    setSelectedApprovers((currentSelectedApprovers) =>
      currentSelectedApprovers.filter((selectedApprover) => selectedApprover.guid !== approver.guid)
    );
  }, []);

  const [isDone, setIsDone] = useState(false);

  const { mutate: createBillApproval, isPending: isCreatingBillApprovals } =
    useCreateBillApprovalMutation(billId);

  const handleSubmit = useCallback(() => {
    if (isDone) {
      onClose();
      return;
    }
    if (isSuperusering) {
      return;
    }

    selectedApprovers.forEach(({ guid }) => {
      createBillApproval(
        {
          businessMemberGuid: guid,
        },
        {
          onSuccess: () => {
            // For draft bills, we skip the "done" step and just display a confirmation toast and close the modal.
            if (isDraftBill) {
              notify("success", `Approver${selectedApprovers.length > 1 ? "s" : ""} added`);
              onClose();
            } else {
              setIsDone(true);
            }
          },
        }
      );
    });
  }, [createBillApproval, isDone, isDraftBill, isSuperusering, onClose, selectedApprovers]);

  return (
    <ModalV4.Form onSubmit={handleSubmit}>
      <ModalV4.Header>Add approvers</ModalV4.Header>
      <ModalV4.Body className={styles.body}>
        {isDone ? (
          <>
            <CheckCircleIcon size={32} color={colors.green[500]} weight="thin" />
            <div className={styles.bodyTextContainer}>
              <Heading3>{selectedApprovers.length > 1 ? "Approvers" : "Approver"} added</Heading3>
              <ModalV4.Paragraph>
                <DoneTextContent selectedApprovers={selectedApprovers} />
              </ModalV4.Paragraph>
            </div>
          </>
        ) : (
          <>
            <ModalV4.Paragraph>
              Add one or many approvers for this bill.{" "}
              {isDraftBill ? (
                <>We will notify the approvers after you select &ldquo;Save as bill&rdquo;.</>
              ) : (
                <>We will notify the approvers after you add them.</>
              )}
            </ModalV4.Paragraph>
            <DropdownV2
              className={styles.approverSelect}
              placeholder="Select approver"
              value={null}
              onChange={(value) => value && pushSelectedApprover(value)}
              inputValue={approverInputValue}
              onInputChange={setApproverInputValue}
              options={approverOptions}
              getOptionLabel={({ displayName }) => displayName}
              getOptionValue={({ guid }) => guid}
              renderOption={(optionProps) => (
                <DropdownV2.Option {...optionProps}>
                  <ApproverOptionLabel approver={optionProps.data} />
                </DropdownV2.Option>
              )}
              disabled={approverOptions.length === 0}
            />
            {selectedApprovers.length > 0 && (
              <ul className={styles.selectedApproversList}>
                {selectedApprovers.map((approver) => (
                  <SelectedApproverItem
                    key={approver.guid}
                    approver={approver}
                    onRemove={removeSelectedApprover}
                    isDisabled={isCreatingBillApprovals}
                  />
                ))}
              </ul>
            )}
            <ModalV4.Paragraph className="text-sm">
              You can add any admin or bookkeeper user as an approver. Invite new users in{" "}
              <Text.InternalLink href="/settings/users">Settings</Text.InternalLink>.
            </ModalV4.Paragraph>
          </>
        )}
      </ModalV4.Body>
      <ModalV4.Footer>
        <ModalV4.SubmitButton
          disabled={
            isSuperusering || (selectedApprovers.length === 0 && !isDone) || isCreatingBillApprovals
          }
          tooltip={isSuperusering ? "Cannot add bill approvers as a superuser." : null}
          isLoading={isCreatingBillApprovals}
        >
          {isDone ? "Done" : `Add ${selectedApprovers.length > 1 ? "approvers" : "approver"}`}
        </ModalV4.SubmitButton>
        <ModalV4.CloseButton>Close</ModalV4.CloseButton>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

const BillApproversAddModal: FC<BillApproversAddModalProps> = (props) => (
  <ModalV4 onClose={props.onClose} dropdownOverflowHack>
    <BillApproversAddModalContent {...props} />
  </ModalV4>
);

export default BillApproversAddModal;
