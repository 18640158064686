import classNames from "classnames";
import * as React from "react";
import { HTMLAttributeAnchorTarget, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import styles from "./TextLink.module.scss";

type Props = {
  external?: boolean;
  to: string;
  target?: HTMLAttributeAnchorTarget;
  className?: string;
} & PropsWithChildren;

/**
 * External links use <a> whereas internal links use <Link>, because React Router doesn't support
 * external links.
 */
const TextLink: React.FC<Props> = ({ external = false, to, target, ...props }) => {
  const className = classNames(styles.link, styles.underline, props.className);

  if (external) {
    return (
      <a href={to} target={target ?? "_blank"} className={className}>
        {props.children}
      </a>
    );
  } else {
    return (
      <Link to={to} target={target} className={className}>
        {props.children}
      </Link>
    );
  }
};

export default TextLink;
