import { Payment } from "@highbeam/unit-node-sdk";
import PaymentInfoFlexpane from "dialogs/PaymentInfoFlexpane";
import PaymentStatusPill from "dialogs/PaymentInfoFlexpane/components/PaymentStatusPill";
import { get } from "lodash-es";
import { FC } from "react";
import BillPaymentRep from "reps/BillPaymentRep";
import BillSummaryRep from "reps/BillSummaryRep";
import { useRefreshBillQuery } from "resources/bills/queries/useBill";
import useBillPayments from "resources/bills/queries/useBillPayments";
import useBillRelatedUnitCoPayments from "resources/bills/queries/useBillRelatedUnitCoPayments";
import MoneyAmount from "ui/data-display/money/MoneyAmountV2";
import AmountCell from "ui/table/AmountCell";
import CounterpartyCell from "ui/table/CounterpartyCell";
import DateTimeCell from "ui/table/DateTimeCell";
import NotFoundCell from "ui/table/NotFoundCell";
import Table, { TableColumnAlignment } from "ui/table/Table";
import useSearchParamValue from "utils/search-params/useSearchParamValue";

type Props = {
  bill: BillSummaryRep.Complete;
};

const findRelatedBillPayment = (unitPayment: Payment, billPayments: BillPaymentRep.Complete[]) => {
  return billPayments.find((billPayment) => {
    return unitPayment.attributes.tags?.paymentGuid === billPayment.paymentGuid;
  });
};

const BillRelatedPaymentsTable: FC<Props> = ({ bill }) => {
  const billId = bill.id;
  const unitPayments = useBillRelatedUnitCoPayments({ billIds: [billId] });
  const billPayments = useBillPayments({ billId });
  const refreshBillQuery = useRefreshBillQuery(billId);

  const [paymentId, setPaymentId] = useSearchParamValue("payment");

  return (
    <>
      <PaymentInfoFlexpane
        paymentId={paymentId}
        onClose={() => {
          setPaymentId("");
        }}
        onPaymentCanceled={() => {
          // NB(lev): After canceling a payment, we want to refetch the bill summary
          // to display the latest payment status (and so on). It appears that there
          // is a race condition right now between canceling the payment and those
          // updates being reflected in the bill summary. The setTimeout here is a
          // GROSS HACK to workaround this for now.
          setTimeout(() => {
            refreshBillQuery();
          }, 1000);
        }}
      />

      <Table
        data={unitPayments}
        rowKey={(unitPayment) => unitPayment.id}
        onRowClick={(unitPayment) => setPaymentId(unitPayment.id)}
        columns={[
          {
            title: "Date",
            key: "formattedCreatedAt",
            cellRender: (unitPayment) => {
              return <DateTimeCell date={unitPayment.attributes.createdAt} />;
            },
            width: 120,
          },
          {
            title: "To",
            cellRender: (unitPayment) => {
              const counterpartyName = get(unitPayment, "attributes.counterparty.name") as
                | string
                | undefined;

              if (!counterpartyName) {
                return <NotFoundCell />;
              }

              return (
                <CounterpartyCell>
                  {counterpartyName} <PaymentStatusPill payment={unitPayment} />
                </CounterpartyCell>
              );
            },
          },

          {
            title: "Amount",
            align: TableColumnAlignment.RIGHT,
            cellRender: (unitPayment) => {
              const billPayment = findRelatedBillPayment(unitPayment, billPayments);

              if (!billPayment) {
                return <AmountCell cents={unitPayment.attributes.amount} />;
              }

              return (
                <MoneyAmount
                  as="div"
                  amount={billPayment.amount.amount}
                  currencyCode={billPayment.amount.currency}
                  showCurrencySymbol
                  showTrailingCurrencyCode
                  currencyCodeTextWeight={"regular"}
                />
              );
            },
          },
        ]}
      />
    </>
  );
};

export default BillRelatedPaymentsTable;
