import InsightsVendorPage from "pages/Insights/InsightsVendorPage";
import { Navigate, Route, Routes } from "react-router-dom";
import allActionItemsInteractedWith from "resources/action-items/utils/allActionItemsInteractedWith";

import { InsightsOnboarding, useInsightsActionItems } from "./InsightsOnboarding";

const InsightsRouter = () => {
  const actionItems = useInsightsActionItems();

  const needsOnboarding = !allActionItemsInteractedWith(actionItems);

  if (needsOnboarding) {
    return (
      <Routes>
        <Route path="/onboarding" element={<InsightsOnboarding />} />.
        <Route path="/*" element={<Navigate to="/spend/onboarding" replace />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/vendor" element={<InsightsVendorPage />} />
      <Route path="*" element={<Navigate to="/spend/vendor" replace />} />
    </Routes>
  );
};

export default InsightsRouter;
