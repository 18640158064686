import { ChatTeardropDots } from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import Button from "ui/inputs/Button";

const SendFeedbackButton = () => {
  const chat = useChatWidget();

  const handleClick = () => {
    chat.show();
  };

  return (
    <Button onClick={handleClick} variant="secondary">
      <ChatTeardropDots size={20} />
      Send feedback
    </Button>
  );
};

export default SendFeedbackButton;
