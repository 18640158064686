import { captureException } from "@sentry/react";
import BillRep from "reps/BillRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useRefreshBillsQueries from "../queries/useRefreshBillsQueries";

const useUpdateBillMutation = (
  billId: string,
  additionalOptions: MutationAdditionalOptions<BillRep.Complete, BillRep.Updater>
) => {
  const highbeamApi = useHighbeamApi();
  const refreshBillsQueries = useRefreshBillsQueries();

  return useMutationWithDefaults(
    {
      mutationFn: (variables) => {
        return highbeamApi.bill.updateBillInfo(billId, variables);
      },
      onError: (error) => {
        captureException(error);
      },
      onSuccess: async () => {
        await refreshBillsQueries();
      },
    },
    additionalOptions
  );
};

export default useUpdateBillMutation;
