import IconWithTooltip from "components/common/IconWithTooltip";

import CreditComparisonColumnLayout from "./CreditComparisonColumnLayout";
import styles from "./CreditComparisonTable.module.scss";

const CreditComparisonTitlesColumn = () => (
  <CreditComparisonColumnLayout
    rows={[
      <>{/* Column Header */}</>,

      <div className={styles.rowTitle} key="row-1">
        Funding amount
      </div>,

      <div className={styles.rowTitle} key="row-2">
        Effective fee
      </div>,

      <div className={styles.rowTitle} key="row-3">
        Total payback amount
      </div>,

      <div className={styles.rowTitle} key="row-4">
        Effective APR
        <IconWithTooltip
          wrapperClassName={styles.infoIconWrapper}
          content={
            <>
              Annual percentage rate (APR) is the total cost of a loan including fees over the{" "}
              <i>Funding amount</i> expressed as a percent, for the year.
            </>
          }
        />
      </div>,

      <div className={styles.rowTitle} key="row-5">
        Repayment period
        <IconWithTooltip
          wrapperClassName={styles.infoIconWrapper}
          content={
            <>
              Repayment period is how long it takes to pay back the <i>Total payback amount</i>.
            </>
          }
        />
      </div>,

      <div className={styles.rowTitle} key="row-5">
        Avg. weekly repayment
      </div>,

      <div className={styles.rowTitle} key="row-6">
        Early pay
        <IconWithTooltip
          wrapperClassName={styles.infoIconWrapper}
          content={
            <>
              Most cash advance providers charge fees up front, meaning that repaying early doesn’t
              save you any money.
              <br />
              <br />
              Traditional lenders charge APR, so repaying early saves you money on interest fees.
              However, some lenders charge prepayment penalty fees.
            </>
          }
        />
      </div>,

      <div className={styles.rowTitle} key="row-7">
        Pause payments
      </div>,

      <div className={styles.rowTitle} key="row-8">
        Configurable repayment schedule
        <IconWithTooltip
          wrapperClassName={styles.infoIconWrapper}
          content={
            <>
              Most cash advance providers don’t allow for you to modify your repayment schedule
              after you’ve taken capital from them.
            </>
          }
        />
      </div>,

      <div className={styles.rowTitle} key="row-9">
        Multiple draws
        <IconWithTooltip
          wrapperClassName={styles.infoIconWrapper}
          content={
            <>
              Many lenders structure their loans such that they have to be drawn down in a single
              pull.
              <br />
              <br />
              However, revolving lines of credit allow for you to draw down and repay as you see fit
              for your business.
              <br />
              <br />
              This ensures You’re not paying fees on money being unused, and also allows you to
              recycle money over time.
            </>
          }
        />
      </div>,

      <div key="row-10">{/* Extras */}</div>,
    ]}
  />
);

export default CreditComparisonTitlesColumn;
