import { captureException } from "@sentry/react";
import ApEmailAliasRep from "reps/ApEmailAliasRep";
import { useRefreshApEmailAliasesQuery } from "resources/ap-email-aliases/queries/useApEmailAliases";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

type Variables = Omit<ApEmailAliasRep.Creator, "businessGuid">;

const useCreateApEmailAliasMutation = (
  additionalOptions?: MutationAdditionalOptions<ApEmailAliasRep.Complete, Variables>
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const { segmentTrack } = useSegment();
  const refreshApEmailAliasesQuery = useRefreshApEmailAliasesQuery(businessGuid);

  return useMutationWithDefaults(
    {
      mutationFn: ({ username }) => highbeamApi.apEmailAlias.create({ username, businessGuid }),
      onSuccess: async () => {
        segmentTrack(SEGMENT_EVENTS.BILL_PAY_EMAIL_ALIAS_CREATED);
        await refreshApEmailAliasesQuery();
      },
      onError: (error) => captureException(error),
    },
    additionalOptions || {}
  );
};

export default useCreateApEmailAliasMutation;
