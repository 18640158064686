import AccountingAccountRep from "reps/AccountingAccountRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillLineItemApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(accountingAccountId: string): Promise<AccountingAccountRep.Complete> {
    const url = `/accounting/accounts/${accountingAccountId}`;
    return (await this.api.get<AccountingAccountRep.Complete>(url))!;
  }

  async search(businessGuid: string): Promise<AccountingAccountRep.Complete[]> {
    const queryParams = new URLSearchParams({ businessGuid: businessGuid });
    const url = `/accounting/accounts?${queryParams}`;
    return (await this.api.get<AccountingAccountRep.Complete[]>(url))!;
  }
}
