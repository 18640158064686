import ChargeCardRepaymentInfoRep from "reps/ChargeCardRepaymentInfoRep";
import ChargeCardRepaymentRep from "reps/ChargeCardRepaymentRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class ChargeCardRepaymentApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  // Used for repaying charge cards **only** without paying back the line of credit.
  async create(
    businessGuid: string,
    chargeCardCreditAccountGuid: string,
    creator: ChargeCardRepaymentRep.Creator
  ) {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      chargeCardAccountGuid: chargeCardCreditAccountGuid,
    });
    const url = `/charge-card-repayments?${qp}`;
    return await this.api.post(url, creator);
  }

  async get(
    businessGuid: string,
    chargeCardAccountGuid: string
  ): Promise<ChargeCardRepaymentRep.Complete[]> {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      chargeCardAccountGuid: chargeCardAccountGuid,
    });
    const url = `/charge-card-repayments?${qp}`;
    const transactions = await this.api.get<ChargeCardRepaymentRep.Complete[]>(url);
    return transactions || [];
  }

  async getCsv(businessGuid: string, chargeCardAccountGuid: string): Promise<string | null> {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      chargeCardAccountGuid: chargeCardAccountGuid,
    });
    const url = `/charge-card-repayments?${qp}`;
    return await this.api.get<string>(url, {
      accept: "text/csv",
    });
  }

  async getInfo(
    businessGuid: string,
    chargeCardCreditAccountGuid: string
  ): Promise<ChargeCardRepaymentInfoRep.Complete | null> {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      chargeCardAccountGuid: chargeCardCreditAccountGuid,
    });
    const url = `/charge-card-repayments/info?${qp}`;
    return await this.api.get(url);
  }
}
