import { captureException } from "@sentry/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useId } from "react";
import Pill from "ui/data-display/Pill";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import { notify } from "ui/feedback/Toast";
import ModalV4 from "ui/overlay/ModalV4";
import { downloadWith404Retry } from "utils/download";

type Props = {
  onClose: () => void;
  getInvoiceDownloadUrl: () => Promise<string>;
  attachmentType: "receipt" | "invoice";
};

const PreviewInvoiceModal: React.FC<Props> = ({
  onClose,
  getInvoiceDownloadUrl,
  attachmentType,
}) => {
  // The `useQuery` here only needs to cache on a component-level because we actually want to cache the downloaded blob by URL, but that value gets fetched asynchronously so we use `ensureQueryData` below to achieve this.
  const id = useId();
  const queryClient = useQueryClient();
  const { data: invoiceSrc, error } = useQuery({
    queryKey: ["invoicePreview", { id }],
    queryFn: async () => {
      const invoiceDownloadUrl = await getInvoiceDownloadUrl();
      return await queryClient.ensureQueryData({
        queryKey: ["invoicePreview", { invoiceDownloadUrl }],
        queryFn: async () => {
          const blob = await downloadWith404Retry(invoiceDownloadUrl);
          return window.URL.createObjectURL(blob);
        },
      });
    },
  });

  useEffect(() => {
    if (error) {
      // This modal doesn't display errors nicely. Instead, send an error toast
      // and trigger a modal close.
      notify("error", "Something went wrong displaying your invoice. Please try again.");
      captureException(error);
      onClose();
    }
  }, [error, onClose]);

  return (
    <ModalV4 onClose={onClose}>
      <ModalV4.Header>Preview {attachmentType}</ModalV4.Header>
      <ModalV4.Body className="h-[800px] bg-grey-100">
        {!invoiceSrc ? (
          <div className="flex h-full w-full items-center justify-center bg-white">
            <Pill color="purple-dark" iconLeft={<AnimatedSpinner />}>
              Loading {attachmentType}
            </Pill>
          </div>
        ) : (
          <img
            src={invoiceSrc}
            className="h-full w-full object-scale-down"
            alt="Uploaded invoice"
          />
        )}
      </ModalV4.Body>
    </ModalV4>
  );
};

export default PreviewInvoiceModal;
