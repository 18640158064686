import { ArrowsDownUp, MinusCircle } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import IconWithTooltip from "components/common/IconWithTooltip";
import TransactionFlexpaneAmount from "components/common/transaction-flexpane/TransactionFlexpaneAmount";
import { SWIFT_FEE } from "pages/SendMoneyPage/utils";
import { ComponentProps, FC } from "react";
import { ConversionSide } from "reps/payments-v2/ConversionSide";
import ExchangeRateQuote from "resources/international-wires/components/ExchangeRateQuote";
import {
  ExchangeRateTooltipContent,
  SwiftFeeTooltipContent,
} from "resources/international-wires/constants";
import useInternationalWireQuoteQueryOptions from "resources/international-wires/queries/useInternationalWireQuoteQueryOptions";
import usePaymentApproval from "resources/payment-approvals/queries/usePaymentApproval";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Shimmer from "ui/feedback/Shimmer";
import Text, { TextProps } from "ui/typography/Text";

import { usePaymentApprovalFlexpaneContext } from "../../context/PaymentApprovalFlexpaneProvider";

import styles from "./PaymentApprovalAmount.module.scss";

const Label: FC<TextProps> = (props) => (
  <Text size={14} color={colors.grey[500]} className={styles.label} {...props} />
);

const PaymentApprovalInternationalWireMoneyAmount: FC<ComponentProps<typeof MoneyAmount>> = ({
  ...moneyAmountProps
}) => {
  return <MoneyAmount size={28} weight="medium" className={styles.amount} {...moneyAmountProps} />;
};

const SwiftFeeRate = () => {
  return (
    <>
      <Label>
        <MinusCircle size={16} />
        SWIFT fee
        <IconWithTooltip color="light" content={<SwiftFeeTooltipContent />} />
      </Label>

      <div className={styles.rateQuoteContainer}>
        <MoneyAmount cents={SWIFT_FEE} weight="medium" size={16} />
      </div>
    </>
  );
};

type ExchangeRateProps = {
  currency: string; // TODO(alex): string union
};

export const ExchangeRate: FC<ExchangeRateProps> = ({ currency }) => {
  return (
    <>
      <Label>
        <ArrowsDownUp size={16} />
        Exchange rate{" "}
        <IconWithTooltip
          color="light"
          wrapperClassName={styles.infoIconWrapper}
          content={<ExchangeRateTooltipContent />}
        />
      </Label>

      <div className={styles.rateQuoteContainer}>
        <ExchangeRateQuote currency={currency} size={16} weight="medium" />
      </div>
    </>
  );
};

type PaymentApprovalUsdToUsdAmountProps = {
  amountBeforeSwiftFeeInCents: number;
};

const PaymentApprovalUsdToUsdAmount: FC<PaymentApprovalUsdToUsdAmountProps> = ({
  amountBeforeSwiftFeeInCents,
}) => {
  return (
    <div>
      <Label>You send</Label>

      <PaymentApprovalInternationalWireMoneyAmount
        cents={amountBeforeSwiftFeeInCents + SWIFT_FEE}
      />

      <div className={styles.rateContainer}>
        <SwiftFeeRate />
      </div>

      <Label>Payee receives</Label>

      <PaymentApprovalInternationalWireMoneyAmount cents={amountBeforeSwiftFeeInCents} />
    </div>
  );
};

type PaymentApprovalInternationalWireAmountProps = {
  fixedSide: ConversionSide;
  sendAmount: number;
  receiveAmount: number;
  receiveCurrency: string;
};

const PaymentApprovalInternationalWireAmount: FC<PaymentApprovalInternationalWireAmountProps> = ({
  fixedSide,
  sendAmount,
  receiveAmount,
  receiveCurrency,
}) => {
  const { data: quote, isPending } = useQuery(
    useInternationalWireQuoteQueryOptions(receiveCurrency)
  );

  if (isPending) {
    return <Shimmer />;
  }

  if (quote) {
    return (
      <div>
        <Label>You send{fixedSide === "Send" && " exactly"}</Label>

        <PaymentApprovalInternationalWireMoneyAmount
          cents={fixedSide === "Send" ? sendAmount : quote.inverse * receiveAmount}
        />

        <div className={styles.rateContainer}>
          <ExchangeRate currency={receiveCurrency} />
        </div>

        <Label>Payee receives{fixedSide === "Receive" && " exactly"}</Label>

        <PaymentApprovalInternationalWireMoneyAmount
          cents={fixedSide === "Receive" ? receiveAmount : quote.rate * sendAmount}
          currency={receiveCurrency}
          trailingCurrencyCode
        />
      </div>
    );
  }

  return (
    <Text size={16} weight="medium">
      Error: Rate not found
    </Text>
  );
};

const PaymentApprovalAmount = () => {
  const { paymentApprovalGuid } = usePaymentApprovalFlexpaneContext();
  const paymentApproval = usePaymentApproval(paymentApprovalGuid, { required: true });

  switch (paymentApproval.type) {
    case "international-wire":
      if (paymentApproval.receiveCurrency === "USD") {
        return (
          <PaymentApprovalUsdToUsdAmount amountBeforeSwiftFeeInCents={paymentApproval.amount} />
        );
      } else {
        return (
          <PaymentApprovalInternationalWireAmount
            sendAmount={paymentApproval.amount}
            {...paymentApproval}
          />
        );
      }
    case "unit-payment":
      return <TransactionFlexpaneAmount cents={paymentApproval.amount} direction="negative" />;
  }
};

export default PaymentApprovalAmount;
