import { zodResolver } from "@hookform/resolvers/zod";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import useEditCapitalAccountNameMutation from "resources/capital-accounts/mutations/useEditCapitalAccountNameMutation";
import Helper from "ui/inputs/Helper";
import TextInputV2 from "ui/inputs/TextInputV2";
import ModalV4 from "ui/overlay/ModalV4";
import useModalContext from "ui/overlay/ModalV4/useModalContext";
import { z } from "zod";

const schema = z.object({
  name: z.string().min(1, { message: "Please enter a name." }),
});

export type EditCapitalAccountNameFormInputs = z.infer<typeof schema>;

type Params = {
  defaultValues: EditCapitalAccountNameFormInputs;
};

const useEditCapitalAccountNameForm = ({ defaultValues }: Params) => {
  return useForm<EditCapitalAccountNameFormInputs>({
    defaultValues,
    resolver: zodResolver(schema),
  });
};

type EditCapitalAccountNameFormProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const EditCapitalAccountNameForm: FC<EditCapitalAccountNameFormProps> = ({
  capitalAccountSummary,
}) => {
  const form = useEditCapitalAccountNameForm({
    defaultValues: {
      name: capitalAccountSummary.name,
    },
  });
  const { closeModalWithAnimation } = useModalContext();

  const { mutateAsync: editCapitalAccountName, isPending } = useEditCapitalAccountNameMutation({
    capitalAccountGuid: capitalAccountSummary.guid,
  });

  const onSubmit = form.handleSubmit(async (data) => {
    await editCapitalAccountName({
      name: data.name,
    });
    closeModalWithAnimation();
  });

  return (
    <ModalV4.Form onSubmit={onSubmit}>
      <ModalV4.Header>Edit capital account name</ModalV4.Header>

      <ModalV4.Body>
        <Controller
          name="name"
          control={form.control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <TextInputV2
                label="Capital account name"
                showErrorOutline={Boolean(error)}
                {...field}
              />
              {error && <Helper iconVariant="error">{error.message}</Helper>}
            </div>
          )}
        />
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton isLoading={isPending}>Save changes</ModalV4.SubmitButton>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  onClose: () => void;
};

const EditCapitalAccountNameModal: FC<Props> = ({ capitalAccountSummary, onClose }) => {
  return (
    <ModalV4 onClose={onClose}>
      <EditCapitalAccountNameForm capitalAccountSummary={capitalAccountSummary} />
    </ModalV4>
  );
};

export default EditCapitalAccountNameModal;
