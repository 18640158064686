import PlaidConnectionRep from "reps/PlaidConnectionRep";
import Button, { ButtonVariant } from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useIsAllowedToConnectBankAccounts from "utils/permissions/useIsAllowedToConnectBankAccounts";

type ConnectPlaidButtonProps = {
  existingConnection?: PlaidConnectionRep.Complete;
  variant: ButtonVariant;
  children: React.ReactNode;
  className?: string;
  openPlaidLinkModal: () => void;
};

export const ConnectPlaidButton: React.FC<ConnectPlaidButtonProps> = ({
  variant = "default",
  children,
  className,
  openPlaidLinkModal,
}) => {
  const isAllowedToConnectBankAccounts = useIsAllowedToConnectBankAccounts();

  return (
    <>
      <Button
        variant={variant}
        onClick={openPlaidLinkModal}
        className={className}
        disabled={!isAllowedToConnectBankAccounts}
        tooltip={
          !isAllowedToConnectBankAccounts && (
            <Text size={14}>You don’t have permission to connect banks or cards.</Text>
          )
        }
      >
        {children}
      </Button>
    </>
  );
};
