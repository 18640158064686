import { captureException } from "@sentry/react";
import CardRep from "reps/CardRep";
import cardSpendLimitQueryHooks, {
  CardSpendLimit,
} from "resources/card-spend-limit/queries/cardSpendLimitQueryHooks";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import { useRefreshCardQuery } from "../queries/useCard";
import { useRefreshCardLimitsQuery } from "../queries/useCardLimits";

type Variables = {
  cardType: CardRep.CardType;
  cardName: string;
  cardSpendLimit: Pick<CardSpendLimit, "period" | "limitInCents"> | null;
};

const useEditCardLimitMutation = (
  cardId: string,
  additionalOptions: MutationAdditionalOptions<CardRep.Complete, Variables> = {}
) => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();
  const refreshCardQuery = useRefreshCardQuery({ cardId });
  const refreshCardLimitQuery = useRefreshCardLimitsQuery({ cardId });
  const refreshCardSpendLimitQuery = cardSpendLimitQueryHooks.useRefreshQueries();

  return useMutationWithDefaults(
    {
      mutationFn: async ({ cardType, cardName, cardSpendLimit }: Variables) => {
        return highbeamApi.card.update(businessGuid, cardId, {
          type: cardType,
          name: cardName,
          dailyPurchaseLimit:
            cardSpendLimit?.period === "daily" ? cardSpendLimit.limitInCents : null,
          monthlyPurchaseLimit:
            cardSpendLimit?.period === "monthly" ? cardSpendLimit.limitInCents : null,
        });
      },
      onError: (err) => {
        captureException(err);
      },
      onSuccess: () => {
        refreshCardQuery();
        refreshCardLimitQuery();
        refreshCardSpendLimitQuery();
      },
    },
    additionalOptions
  );
};

export default useEditCardLimitMutation;
