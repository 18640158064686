import { atom } from "recoil";
import { stringLocalStorageEffect } from "utils/recoil";

const businessGuidState = atom<string>({
  key: "auth/businessGuid",
  default: "",
  effects: [stringLocalStorageEffect("businessGuid")],
});

export default businessGuidState;
