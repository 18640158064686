import classNames from "classnames";
import IconWithTooltip from "components/common/IconWithTooltip";
import { FC } from "react";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import ProgressBar from "ui/data-visualization/ProgressBar";
import Text from "ui/typography/Text";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import useIsAllowedToCreateDraftPayments from "utils/permissions/useIsAllowedToCreateDraftPayments";
import { percentage } from "utils/string";

import useAccountLimitWarnings from "../queries/useAccountLimitWarnings";
import { LimitWarning, PaymentMethod, LimitPeriod } from "../types";

import styles from "./AccountLimitWarnings.module.scss";

const makeWarningTitle = (
  paymentMethod: PaymentMethod,
  limitPeriod: LimitPeriod,
  limitWarning: LimitWarning
) => {
  if (paymentMethod === "ach" || paymentMethod === "domestic-wire") {
    const paymentMethodTitle = paymentMethod === "ach" ? "ACH" : "wire";
    const endPhrase = `the ${limitPeriod} ${paymentMethodTitle} limit`;

    if (limitWarning === "exceeded") return `You have exceeded ${endPhrase}`;
    if (limitWarning === "at") return `You have reached ${endPhrase}`;
    if (limitWarning === "approaching") return `You are nearing ${endPhrase}`;
  }

  // Temporary code until we implement other payment methods here.
  throw new Error("makeWarningTitle: Not implemented payment method");
};

type WarningBarProps = {
  paymentMethod: PaymentMethod;
  limitPeriod: LimitPeriod;
  limitWarning: LimitWarning;
  limitAmountInCents: number;
  usingAmountInCents: number;
  isAllowedToApprovePayments: boolean;
  isAllowedToCreatePaymentDrafts: boolean;
  className?: string;
};

const WarningBar: FC<WarningBarProps> = ({
  paymentMethod,
  limitPeriod,
  limitWarning,
  limitAmountInCents,
  usingAmountInCents,
  isAllowedToApprovePayments,
  isAllowedToCreatePaymentDrafts,
  className,
}) => {
  const isAch = paymentMethod === "ach";
  const isExceeded = limitWarning === "exceeded";

  return (
    <div className={classNames(styles.warningBarContainer, className)}>
      <div className={styles.warningBarHeader}>
        <div className={styles.warningBarTitleContainer}>
          <Text as="p">{makeWarningTitle(paymentMethod, limitPeriod, limitWarning)}</Text>
          <IconWithTooltip
            color="light"
            content={
              <>
                We set this limit to protect you from events that could leave you financially
                responsible for large amounts, like fraud and hacks. We’ll work with you to adjust
                your limit over time.
              </>
            }
          />
        </div>
        <Text as="p" className={styles.warningBarAmountInfo}>
          <MoneyAmount
            weight="medium"
            color={isExceeded ? colors.orange[600] : colors.yellow[700]}
            cents={usingAmountInCents}
            withCents={false}
          />
          <Text>/</Text>
          <MoneyAmount color={colors.grey[500]} cents={limitAmountInCents} withCents={false} />
        </Text>
      </div>
      <ProgressBar
        className={styles.warningBarProgressBar}
        percent={percentage(usingAmountInCents, limitAmountInCents)}
        color={isExceeded ? colors.orange[400] : colors.yellow[500]}
      />
      {isAllowedToApprovePayments && isExceeded && (
        <Text as="p">
          {isAch ? (
            <>Consider sending from a different account or using a wire for large payments</>
          ) : (
            <>Consider sending from a different account for large payments</>
          )}
        </Text>
      )}
      {!isAllowedToApprovePayments && isAllowedToCreatePaymentDrafts && !isExceeded && (
        <Text as="p">
          {isAch ? (
            <>
              If this payment exceeds the limit at the time of approval, it will not send. Consider
              sending large amounts as wires instead
            </>
          ) : (
            <>
              If this payment exceeds the limit at the time of approval, it will not send. Consider
              sending large amounts as a different payment method instead
            </>
          )}
        </Text>
      )}
    </div>
  );
};

type Props = {
  paymentMethod: PaymentMethod;
  amountInCents: number;
  unitCoDepositAccountId?: string;
  className?: string;
};

const AccountLimitWarnings: FC<Props> = ({
  paymentMethod,
  amountInCents,
  unitCoDepositAccountId,
  ...restProps
}) => {
  const {
    accountLimitInfo,
    achDailyCreditWarning,
    achMonthlyCreditWarning,
    wireDailyTransferWarning,
    wireMonthlyTransferWarning,
  } = useAccountLimitWarnings(amountInCents, unitCoDepositAccountId);
  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const isAllowedToCreatePaymentDrafts = useIsAllowedToCreateDraftPayments();

  if (paymentMethod === "ach") {
    if (achMonthlyCreditWarning) {
      return (
        <WarningBar
          paymentMethod="ach"
          limitPeriod="monthly"
          limitWarning={achMonthlyCreditWarning}
          limitAmountInCents={accountLimitInfo.achMonthlyCreditLimit}
          usingAmountInCents={accountLimitInfo.achMonthlyCreditUsed + amountInCents}
          isAllowedToApprovePayments={isAllowedToApprovePayments}
          isAllowedToCreatePaymentDrafts={isAllowedToCreatePaymentDrafts}
          {...restProps}
        />
      );
    }
    if (achDailyCreditWarning) {
      return (
        <WarningBar
          paymentMethod="ach"
          limitPeriod="daily"
          limitWarning={achDailyCreditWarning}
          limitAmountInCents={accountLimitInfo.achDailyCreditLimit}
          usingAmountInCents={accountLimitInfo.achDailyCreditUsed + amountInCents}
          isAllowedToApprovePayments={isAllowedToApprovePayments}
          isAllowedToCreatePaymentDrafts={isAllowedToCreatePaymentDrafts}
          {...restProps}
        />
      );
    }
    return null;
  }

  if (paymentMethod === "domestic-wire") {
    if (wireMonthlyTransferWarning) {
      return (
        <WarningBar
          paymentMethod="domestic-wire"
          limitPeriod="monthly"
          limitWarning={wireMonthlyTransferWarning}
          limitAmountInCents={accountLimitInfo.wireMonthlyTransferLimit}
          usingAmountInCents={accountLimitInfo.wireMonthlyTransferUsed + amountInCents}
          isAllowedToApprovePayments={isAllowedToApprovePayments}
          isAllowedToCreatePaymentDrafts={isAllowedToCreatePaymentDrafts}
          {...restProps}
        />
      );
    }
    if (wireDailyTransferWarning) {
      return (
        <WarningBar
          paymentMethod="domestic-wire"
          limitPeriod="daily"
          limitWarning={wireDailyTransferWarning}
          limitAmountInCents={accountLimitInfo.wireDailyTransferLimit}
          usingAmountInCents={accountLimitInfo.wireDailyTransferUsed + amountInCents}
          isAllowedToApprovePayments={isAllowedToApprovePayments}
          isAllowedToCreatePaymentDrafts={isAllowedToCreatePaymentDrafts}
          {...restProps}
        />
      );
    }
    return null;
  }

  return null;
};

export default AccountLimitWarnings;
