import { ConnectedStoreType } from "../types";

type Params = {
  connectedStoreType: ConnectedStoreType;
  isGrey?: boolean;
};

const getConnectedStoreIcon = ({ connectedStoreType, isGrey }: Params): string => {
  switch (connectedStoreType) {
    case "Shopify":
      return isGrey ? "/assets/shopify-icon-grey.svg" : "/assets/shopify-icon.svg";
    case "Amazon":
      return isGrey ? "/assets/merchants/amazon-icon-grey.svg" : "/assets/merchants/amazon.svg";
    case "Stripe":
      return isGrey ? "/assets/stripe-icon-grey.svg" : "/assets/stripe-icon.svg";
    case "PayPal":
      return isGrey ? "/assets/merchants/paypal-icon-grey.svg" : "/assets/merchants/paypal.svg";
  }
};

export default getConnectedStoreIcon;
