import { ChartBar, CheckCircle, Icon, IconWeight, Info, Warning } from "@phosphor-icons/react";
import { FC } from "react";
import colors from "styles/colors";
import variants from "utils/tailwind/variants";

export type InfoIconErrorVariant = "error" | "chartError";

export type InfoIconVariant = "info" | "success" | "warning" | InfoIconErrorVariant;

const getIcon = (variant: InfoIconVariant): Icon => {
  return {
    info: Info,
    success: CheckCircle,
    warning: Warning,
    error: Warning,
    chartError: ChartBar,
  }[variant];
};

type Props = {
  variant: InfoIconVariant;
  size?: number;
  className?: string;
  color?: string;
  weight?: IconWeight;
};

const InfoIcon: FC<Props> = ({ variant, size = 20, weight }) => {
  const IconComponent = getIcon(variant);
  return (
    <IconComponent
      color={variants(variant, {
        info: colors.purple[500],
        success: colors.green[400],
        warning: "#eba015", // TODO(alex): yellow-???
        error: colors.red[500],
        chartError: colors.red[500],
      })}
      size={size}
      weight={weight}
    />
  );
};

export default InfoIcon;
