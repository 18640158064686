import { Icon } from "@phosphor-icons/react";
import { FC, ReactNode } from "react";
import AccentCard from "ui/data-display/AccentCard";
import Pill from "ui/data-display/Pill";
import PillList from "ui/data-display/PillList";
import { highbeamCardSizeClasses } from "ui/feedback/CardShimmer";
import Button from "ui/inputs/Button";
import { Heading4, Paragraph } from "ui/typography";
import useIsAllowedToCreateCards from "utils/permissions/useIsAllowedToCreateCards";
import stringToReactKey from "utils/react-helpers/stringToReactKey";
import cn from "utils/tailwind/cn";

const PROMPT_COLORS = ["purple", "yellow", "pink", "blue"] as const;

const getPromptColor = (index: number) => PROMPT_COLORS[index % PROMPT_COLORS.length];

const sharedContainerClasses = [
  "items-center justify-center gap-6 rounded-2xl border border-dashed border-purple-200",
  highbeamCardSizeClasses,
];

type Props = {
  primaryText: string;
  secondaryText?: string;
  prompts?: string[];
  ctaText: string;
  ctaOnClick: () => void;
  ctaIcon: Icon;
  className?: string;
  onPromptClick?: (prompt: string) => void;
  disablePrimaryAction: boolean;
  disabledPrimaryText: ReactNode;
};

const CardPlaceholder: FC<Props> = ({
  primaryText,
  secondaryText,
  prompts = [],
  ctaIcon: CtaIcon,
  ctaText,
  ctaOnClick,
  className,
  onPromptClick,
  disablePrimaryAction,
  disabledPrimaryText,
}) => {
  const isAllowedToCreateCards = useIsAllowedToCreateCards();

  return (
    <AccentCard className={cn(sharedContainerClasses, className)}>
      <div className="flex flex-col items-center gap-y-2 px-5">
        <Heading4>{primaryText}</Heading4>

        {secondaryText && <Paragraph className="text-sm text-grey-800">{secondaryText}</Paragraph>}
      </div>

      <Button
        variant="tertiary"
        onClick={ctaOnClick}
        size="sm"
        disabled={disablePrimaryAction}
        tooltip={disablePrimaryAction && disabledPrimaryText}
      >
        <CtaIcon size={12} />
        {ctaText}
      </Button>

      <PillList
        className={cn(
          "flex w-full gap-1.5 px-4",
          !isAllowedToCreateCards && "cursor-not-allowed opacity-50"
        )}
      >
        {prompts.map((prompt, index) => (
          <Pill
            key={stringToReactKey(prompt, index)}
            color={getPromptColor(index)}
            onClick={() => isAllowedToCreateCards && onPromptClick && onPromptClick(prompt)}
          >
            {prompt}
          </Pill>
        ))}
      </PillList>
    </AccentCard>
  );
};

export default CardPlaceholder;

type EmptyPlaceholderProps = {
  className?: string;
  heading: string;
  subheading?: string;
};

export const EmptyCardPlaceholder: FC<EmptyPlaceholderProps> = ({
  className,
  heading,
  subheading,
}) => {
  return (
    <AccentCard className={cn(sharedContainerClasses, className)}>
      <div className="flex max-w-60 flex-col items-center gap-y-2 px-5">
        <Heading4>{heading}</Heading4>
        {subheading && <Paragraph className="text-sm text-grey-600">{subheading}</Paragraph>}
      </div>
    </AccentCard>
  );
};
