import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import deepEquals from "fast-deep-equal";
import { get } from "lodash-es";
import { useCallback } from "react";
import { useForm, UseFormProps } from "react-hook-form";
import InstitutionRep from "reps/InstitutionRep";
import SuggestedPayeeRep from "reps/SuggestedPayeeRep";
import { BASE_FINANCIAL_INSTITUTIONS_QUERY_KEY } from "resources/financial-institutions/queries/useFinancialInstitution";
import { Address, INITIAL_US_ADDRESS } from "utils/address";
import accountNumberSchema from "utils/zod/accountNumberSchema";
import routingNumberSchema, { RoutingNumberSchemaParams } from "utils/zod/routingNumberSchema";
import { z } from "zod";

const makeDefaultAddress = (suggestedPaymentMethod?: SuggestedPayeeRep.PaymentMethod) => {
  if (!suggestedPaymentMethod) {
    return INITIAL_US_ADDRESS;
  }
  const address = { ...INITIAL_US_ADDRESS };
  return Object.assign(address, {
    addressLine1: suggestedPaymentMethod.addressLine1,
    addressLine2: suggestedPaymentMethod.addressLine2 ?? "",
    city: suggestedPaymentMethod.city ?? "",
    state: suggestedPaymentMethod.state,
    zipCode: suggestedPaymentMethod.zipCode ?? "",
    country: suggestedPaymentMethod.country,
  });
};

type DomesticWirePaymentMethodFormSchemaParams = RoutingNumberSchemaParams;

const domesticWirePaymentMethodFormSchema = ({
  fetchFinancialInstitutionByRoutingNumber,
  paymentMethod,
}: DomesticWirePaymentMethodFormSchemaParams) => {
  return z.object({
    routingNumber: routingNumberSchema({
      fetchFinancialInstitutionByRoutingNumber,
      paymentMethod,
    }),
    accountNumber: accountNumberSchema,
    address: z.custom<Address>((data) => {
      // TODO(alex): HB-5275 This code is not good, but need to ship and it works for now.
      const addressLine1 = get(data, "addressLine1.value");
      const city = get(data, "city");
      const state = get(data, "state.value");
      const zipCode = get(data, "zipCode");
      const country = get(data, "country.value");
      return Boolean(addressLine1 && city && state && zipCode && country);
    }, "Please enter a valid address."),
  });
};

export type DomesticWirePaymentMethodFormInputs = z.infer<
  ReturnType<typeof domesticWirePaymentMethodFormSchema>
>;

type UseDomesticWirePaymentMethodFormParams = Pick<
  UseFormProps<DomesticWirePaymentMethodFormInputs>,
  "defaultValues" | "disabled"
> & {
  suggestedPaymentMethod?: SuggestedPayeeRep.PaymentMethod;
};

const useDomesticWirePaymentMethodForm = (params: UseDomesticWirePaymentMethodFormParams = {}) => {
  const { suggestedPaymentMethod } = params;

  const queryClient = useQueryClient();

  const form = useForm({
    ...params,

    resolver: zodResolver(
      domesticWirePaymentMethodFormSchema({
        fetchFinancialInstitutionByRoutingNumber: async (routingNumber) => {
          const financialInstitutionCache = queryClient.getQueriesData<InstitutionRep.Complete>({
            queryKey: [BASE_FINANCIAL_INSTITUTIONS_QUERY_KEY, { routingNumber }],
          })[0];

          const financialInstitution = financialInstitutionCache[1] ?? null;

          if (!financialInstitution) {
            return null;
          }
          return {
            isAchSupported: financialInstitution.isAchSupported,
            isDomesticWireSupported: financialInstitution.isWireSupported,
            name: financialInstitution.name,
          };
        },
        paymentMethod: "domestic-wire",
      })
    ),
    defaultValues: {
      routingNumber: suggestedPaymentMethod?.wireRoutingNumber ?? "",
      accountNumber: suggestedPaymentMethod?.wireAccountNumber ?? "",
      address: makeDefaultAddress(suggestedPaymentMethod),
      ...params?.defaultValues,
    },
  });

  const isBlank = useCallback(() => {
    const { routingNumber, accountNumber, address } = form.getValues();
    return !routingNumber && !accountNumber && deepEquals(address, INITIAL_US_ADDRESS);
  }, [form]);

  return Object.assign(form, { isBlank: isBlank });
};

export default useDomesticWirePaymentMethodForm;
