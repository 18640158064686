import classNames from "classnames";
import { ReactNode } from "react";
import Shimmer from "ui/feedback/Shimmer";
import Text from "ui/typography/Text";
import { TextSize, TextWeight } from "ui/typography/Text/TextTypes";

import TextCell from "../TextCell";

import styles from "./Table.module.scss";

export enum TableColumnAlignment {
  RIGHT = "right-align-children",
}

export type Column<T> = {
  title: string;
  key?: string;
  align?: TableColumnAlignment;
  headerRender?: (column: Column<T>) => ReactNode;
  cellRender?: (datum: T) => ReactNode;
  cellClassName?: string | ((datum: T) => string);
  cellTextDarken?: boolean;
  cellTextNumeric?: boolean;
  cellTextWeight?: TextWeight;
  headerClassName?: string;
  width?: 120 | 160 | 200 | 240 | 280 | 320;
};

type ExternalProps<T> = {
  className?: string;
  headerClassName?: string;
  rowClassName?: string;
  cellClassName?: string;
  columns: Column<T>[];
  rowKey: string | ((datum: T, index: number) => string);
  isLoading?: boolean;
  isLoadingRows?: number;
  data: T[] | undefined;
  onRowClick?: (datum: T) => void;
  size?: TextSize;
  headerTextWeight?: TextWeight;
};

type Props<T> = {
  data: T[];
} & ExternalProps<T>;

function getColKey<T>(column: Column<T>) {
  return column.key || column.title;
}

const TableSkeleton = ({
  isLoading,
  className,
  data,
  rowKey,
  columns,
  onRowClick,
  size: textSize = 14,
  cellClassName,
  rowClassName,
  headerClassName,
  headerTextWeight = "regular",
}: Props<any>) => {
  const headers = columns.map((column) => {
    const colKey = getColKey(column);
    return (
      <th
        className={classNames(
          column.align && styles[column.align],
          headerClassName,
          column.headerClassName,
          column.width && styles[`column-width--${column.width}`]
        )}
        key={colKey}
      >
        {column.headerRender ? (
          column.headerRender(column)
        ) : (
          <Text size={textSize} weight={headerTextWeight}>
            {column.title}
          </Text>
        )}
      </th>
    );
  });

  const rows = data.map((datum, index) => {
    const trKey = typeof rowKey === "string" ? datum[rowKey] : rowKey(datum, index);

    const cells = columns.map((column) => {
      const colKey = getColKey(column);

      return (
        <td
          key={trKey + colKey}
          className={classNames(
            styles.data,
            column.align && styles[column.align],
            typeof column.cellClassName === "function"
              ? column.cellClassName(datum)
              : column.cellClassName,
            cellClassName
          )}
        >
          <Text className={styles.label} size={textSize}>
            {column.title}
          </Text>
          {isLoading ? (
            <Shimmer additionalShimmerClassName={[styles.shimmer]} />
          ) : (
            (column.cellRender?.(datum) ?? (
              <TextCell
                darken={column.cellTextDarken}
                numeric={column.cellTextNumeric}
                size={textSize}
                weight={column.cellTextWeight}
              >
                {datum[colKey]}
              </TextCell>
            ))
          )}
        </td>
      );
    });

    return (
      <tr
        onClick={onRowClick ? () => onRowClick(datum) : undefined}
        className={classNames(
          {
            [styles.row]: true,
            [styles["row--is-link"]]: onRowClick,
          },
          rowClassName
        )}
        key={trKey}
      >
        {cells}
      </tr>
    );
  });

  return (
    <table className={classNames(styles.table, className)}>
      <thead className={styles.heading}>
        <tr>{headers}</tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

const Table = <T extends unknown>({
  columns,
  isLoading: isLoadingProp,
  isLoadingRows,
  data,
  rowKey,
  ...rest
}: ExternalProps<T>) => {
  const isLoading = isLoadingProp || data === undefined;
  return (
    <TableSkeleton
      columns={columns}
      isLoading={isLoading}
      // When loading, shows at number of data rows, but at least 2.
      data={isLoading ? shimmerData(data, isLoadingRows) : data}
      rowKey={isLoading ? (d) => d : rowKey}
      {...rest}
    />
  );
};

export default Table;

function shimmerData<T>(data: T[] | undefined, isLoadingRows = 2) {
  const minRowCount = isLoadingRows;
  if (data && data.length > minRowCount) {
    // Return 1 shimmer row per data row.
    return data.map((_datum, i) => i);
  }
  // Return the default number of shimmer rows.
  return Array.from(Array(minRowCount).keys());
}
