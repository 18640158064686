import Tooltip from "ui/overlay/Tooltip/Tooltip";

export const NotAllowedToActivateLineOfCreditContent = () => (
  <Tooltip.Content>You don’t have permission to activate a line of credit.</Tooltip.Content>
);

export const NotAllowedToDownloadCreditAgreement = () => (
  <Tooltip.Content>You don’t have permission to download the credit agreement.</Tooltip.Content>
);

export const NotAllowedToRepayCapital = () => (
  <Tooltip.Content>You don’t have permission to repay.</Tooltip.Content>
);

export const NotAllowedToDrawDownCapital = () => (
  <Tooltip.Content>You don’t have permission to draw down.</Tooltip.Content>
);
