import { captureException } from "@sentry/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useState } from "react";
import SubcategoryRep from "reps/Insights/SubcategoryRep";
import VendorSummaryRep from "reps/Insights/VendorSummaryRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useTransactionSubcategoriesQueryOptions from "resources/transaction-subcategories/queries/useTransactionSubcategoriesQueryOptions";
import useUser from "resources/user/queries/useUser";
import { notify } from "ui/feedback/Toast";
import { OnDropdownChange, Option } from "ui/inputs/Dropdown";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

import { clean } from "./util";

type UseUpdateVendorCategory = {
  isUpdatingCategory: boolean;
  updateVendorCategory: (subcategoryOption: Option | null) => Promise<OnDropdownChange>;
};

const useUpdateVendorCategory = (
  summary: VendorSummaryRep,
  onUpdate: (
    summary: VendorSummaryRep,
    subcategory: Option & { subcategory: SubcategoryRep.Complete }
  ) => void
): UseUpdateVendorCategory => {
  const { segmentTrack } = useSegment();
  const businessGuid = useBusinessGuid();
  const user = useUser();
  const highbeamApi = useHighbeamApi();

  const { data: subcategoriesMap } = useSuspenseQuery({
    ...useTransactionSubcategoriesQueryOptions(),
    select: (subcategories) => {
      return new Map(subcategories.map((subcategory) => [subcategory.guid, subcategory]));
    },
  });

  const [isUpdatingCategory, setIsUpdatingCategory] = useState(false);

  const updateVendorCategory = async (
    subcategoryOption: Option | null
  ): Promise<OnDropdownChange> => {
    if (subcategoryOption === null) return { error: false };

    setIsUpdatingCategory(true);

    try {
      await Promise.all(
        summary.vendors.map((vendor) => {
          if (VendorSummaryRep.Vendor.isPayeeVendor(vendor)) {
            return highbeamApi.categorizedResource.set(businessGuid, {
              subcategoryGuid: subcategoryOption.value,
              resourceGuid: vendor.payeeGuid,
              resourceType: "Payee",
            });
          } else if (VendorSummaryRep.Vendor.isRuleVendor(vendor)) {
            const subcategory = subcategoriesMap.get(subcategoryOption.value)!;
            if (!vendor.ruleBusinessGuid) {
              return highbeamApi.categorizationRule.getByBusiness(null).then((rules) => {
                const rule = rules.find((rule) => rule.guid === vendor.ruleGuid)!;
                return highbeamApi.categorizationRule.create(businessGuid, {
                  category: subcategory.category.category,
                  subcategoryName: subcategory.name,
                  description: rule.description,
                  sources: rule.sources ?? undefined,
                  plaidCategoryId: rule.plaidCategoryId ?? undefined,
                  minAmount: rule.minAmount ?? undefined,
                  maxAmount: rule.maxAmount ?? undefined,
                  unitCoCounterpartyAccountType: rule.unitCoCounterpartyAccountType ?? undefined,
                  merchantName: rule.merchantName ?? undefined,
                  name: rule.name ?? undefined,
                });
              });
            } else {
              return highbeamApi.categorizationRule.update(businessGuid, vendor.ruleGuid, {
                category: subcategory.category.category,
                subcategoryName: subcategory.name,
              });
            }
          } else if (VendorSummaryRep.Vendor.isMerchantNameVendor(vendor)) {
            const subcategory = subcategoriesMap.get(subcategoryOption.value)!;
            return highbeamApi.categorizationRule.create(businessGuid, {
              category: subcategory.category.category,
              subcategoryName: subcategory.name,
              description: vendor.name,
              merchantName: clean(vendor.name),
            });
          } else if (VendorSummaryRep.Vendor.isTransactionDescriptionVendor(vendor)) {
            const subcategory = subcategoriesMap.get(subcategoryOption.value)!;
            return highbeamApi.categorizationRule.create(businessGuid, {
              category: subcategory.category.category,
              subcategoryName: subcategory.name,
              description: vendor.name,
              name: clean(vendor.name),
            });
          } else {
            throw new Error("Cannot recategorize this vendor.");
          }
        })
      );
      segmentTrack(SEGMENT_EVENTS.INSIGHTS_SPEND_BY_VENDOR_RECATEGORIZED, { businessGuid, user });
    } catch (e) {
      notify("error", "Something went wrong. Please try again later.");
      segmentTrack(SEGMENT_EVENTS.INSIGHTS_SPEND_BY_VENDOR_RECATEGORIZATION_FAILED, {
        businessGuid,
        user,
      });
      captureException(e);
      return { error: true };
    } finally {
      setIsUpdatingCategory(false);
      onUpdate(summary, subcategoryOption as Option & { subcategory: SubcategoryRep.Complete });
    }

    notify("success", `${summary.vendorName} categorized`);
    return { error: false };
  };

  return { isUpdatingCategory, updateVendorCategory };
};

export default useUpdateVendorCategory;
