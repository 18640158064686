import SubcategoryDropdown, { initialSubcategory } from "components/SubcategoryDropdown";
import { FEATURE_FLAGS } from "flags";
import React from "react";
import { useRecoilValue } from "recoil";
import SubcategoryRep from "reps/Insights/SubcategoryRep";
import VendorSummaryRep from "reps/Insights/VendorSummaryRep";
import useTransactionSubcategoryOptions from "resources/transaction-subcategories/hooks/useTransactionSubcategoryOptions";
import featureFlagsState from "state/featureFlags";
import { Option, useDropdown } from "ui/inputs/Dropdown";

import useUpdateVendorCategory from "./useUpdateVendorCategory";

type Props = {
  menuPlacement: "bottom" | "top";
  onUpdate: (
    summary: VendorSummaryRep,
    subcategory: Option & { subcategory: SubcategoryRep.Complete }
  ) => void;
  vendorSummary: VendorSummaryRep;
};

const SpendByVendorTableCategoryColumn: React.FC<Props> = ({
  menuPlacement,
  onUpdate,
  vendorSummary: summary,
}) => {
  const flags = useRecoilValue(featureFlagsState);
  const subcategoryOptions = useTransactionSubcategoryOptions();

  const { isUpdatingCategory, updateVendorCategory } = useUpdateVendorCategory(summary, onUpdate);

  const dropdownState = useDropdown({
    initialValue: initialSubcategory(summary.subcategory?.guid, subcategoryOptions),
    onChange: updateVendorCategory,
    shouldSetTouchedOnChange: true,
  });

  return (
    <SubcategoryDropdown
      dropdownState={dropdownState}
      hideLabel
      inline
      disabled={!flags[FEATURE_FLAGS.SPEND_BY_VENDOR_ALLOW_RECATEGORIZATION]}
      isLoading={isUpdatingCategory}
      menuPlacement={menuPlacement}
    />
  );
};

export default SpendByVendorTableCategoryColumn;
