import { captureException } from "@sentry/react";
import classNames from "classnames";
import env from "env";
import useMfa from "modules/mfa/useMfa";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { getBaseVgsCss } from "resources/cards/utils";
import useGetUnitCoSensitiveTokenWithMfa from "resources/unit-co-customer-token/hooks/useGetUnitCoSensitiveTokenWithMfa";

import styles from "./HighbeamCard.module.scss";
import HighbeamCardFieldShimmer from "./HighbeamCardFieldShimmer";

type Props = {
  isRevealed: boolean;
  isLoading: boolean;
  vgs: { cardId: string; name: string; field: string };
  copy: { buttonRef: RefObject<HTMLDivElement>; onCopy: () => void };
  textColor?: "white" | "black";
};

// @ts-ignore // TODO: Use real typing using TS Declare.
const vgsShow = VGSShow.create(env.UNIT_VGS_VAULT_ID);

const HighbeamCardSensitiveData: React.FC<Props> = ({
  isRevealed,
  isLoading,
  vgs,
  copy: { buttonRef, onCopy },
  textColor = "black",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const copyOverlayRef = useRef<HTMLDivElement>(null);
  const { isMfaComplete } = useMfa();
  const [iframeMountedSuccessfully, setIframeMountedSuccessfully] = useState(false);

  const getUnitCoSensitiveTokenWithMfa = useGetUnitCoSensitiveTokenWithMfa({
    scopes: ["CARDS_SENSITIVE"],
  });

  // NB(alex): This field works by listening to changes in `isMfaComplete`. If a user is not MFA'd, but then calls `mfa()` and `isMfaComplete` switches to `true`, that triggers this `useEffect`, which then loads an invisible iframe with the card details.
  // `isRevealed` determines whether or not the iframe is visible/invisible. We render an invisible iframe as a hack for the "copy" button.
  useEffect(() => {
    if (isMfaComplete && !iframeMountedSuccessfully) {
      getUnitCoSensitiveTokenWithMfa().then((sensitiveToken) => {
        if (containerRef.current?.hasChildNodes()) {
          return;
        }

        const iframe = vgsShow.request({
          name: vgs.name,
          method: "GET",
          path: `/cards/${vgs.cardId}/secure-data/${vgs.field}`,
          headers: {
            Authorization: `Bearer ${sensitiveToken}`,
          },
          htmlWrapper: "text",
          serializers: [
            vgsShow.SERIALIZERS.replace("(\\d{4})(\\d{4})(\\d{4})(\\d{4})", "$1 $2 $3 $4"),
          ],
          jsonPathSelector: `data.attributes.${vgs.field}`,
        });

        iframe.on("revealFail", (e: unknown) => {
          // TODO(alex): Commenting out this line because it gets triggered when creating a new card, but we should fix this.
          // notify("error", "Something went wrong! Please try again.");
          captureException(e);
        });

        iframe.on("revealSuccess", () => {
          setIframeMountedSuccessfully(true);
        });

        iframe.render(containerRef.current, {
          ...getBaseVgsCss({ monospace: true, numeric: true }),
          color: textColor,
        });

        [copyOverlayRef, buttonRef].forEach((ref) => {
          vgsShow
            .copyFrom(
              iframe,
              { serializers: [vgsShow.SERIALIZERS.replace(" ", "")] },
              (status: string) => {
                if (status === "success") onCopy();
              }
            )
            .render(ref.current, { cursor: "pointer" });
        });
      });
    }

    // We only want to run this effect once, when mfa is complete.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMfaComplete, iframeMountedSuccessfully]);

  if (isLoading) {
    return <HighbeamCardFieldShimmer />;
  }

  return (
    <>
      <div
        ref={containerRef}
        className={classNames(styles.sensitiveData, { [styles.hidden]: !isRevealed })}
      />
      <div ref={copyOverlayRef} className={styles.sensitiveData} />
    </>
  );
};

export default HighbeamCardSensitiveData;
