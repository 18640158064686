import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { useRefreshAllBankAccountQueries } from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

import useSetThreadActionItemMutation from "../../migrateToThreadActionItems/useSetThreadActionItemMutation";
import { useCloseActivateThreadView } from "../state/activateThreadViewState";

const useMigrateToThreadMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const closeActivateThreadView = useCloseActivateThreadView();
  const refreshBankAccounts = useRefreshAllBankAccountQueries();
  const { mutateAsync: setThreadActionItemMutation } = useSetThreadActionItemMutation();

  return useMutation({
    mutationFn: () => {
      return highbeamApi.bankAccount.migrateToThread({
        businessGuid: businessGuid,
        copyAllAccounts: true,
        accountNamePostfix: "Thread",
      });
    },
    onError: (err) => {
      captureException(err);
      notify("error", "Something went wrong. Please try again.");
    },
    onSuccess: async () => {
      await refreshBankAccounts();
      await setThreadActionItemMutation({
        actionItemName: "ActivateThreadView",
        state: "Complete",
      });
      closeActivateThreadView();
    },
  });
};

export default useMigrateToThreadMutation;
