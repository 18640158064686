import { useQuery } from "@tanstack/react-query";
import shopifyIconGrey from "assets/shopify-icon-grey.svg";
import shopifyIconLightning from "assets/shopify-icon-lightning.svg";
import { usePayoutInstructionsModal } from "pages/PayoutInstructionModal/state/payoutInstructionsModalState";
import { useRef } from "react";
import useShopifyConnectionsQueryOptions from "resources/connected-stores/queries/useShopifyConnectionsQueryOptions";
import Button from "ui/inputs/Button";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import useShowTooltip from "ui/overlay/Tooltip/useShowTooltip";
import Text from "ui/typography/Text";

import styles from "./ShopifyPayoutsStatusIcon.module.scss";

const InactiveTooltipContent = () => {
  const { open: openPayoutInstructionsModal } = usePayoutInstructionsModal();

  return (
    <div className={styles.inactiveTooltip}>
      <Text size={14}>Set up automatic payouts to fund your Highbeam account.</Text>
      <Button
        variant="tertiary"
        size="sm"
        onClick={() => openPayoutInstructionsModal({ activeTab: "Shopify" })}
      >
        Set up payouts
      </Button>
    </div>
  );
};

const ShopifyPayoutsStatusIcon = () => {
  const { data: shopifyConnections } = useQuery(useShopifyConnectionsQueryOptions());

  const iconRef = useRef<HTMLImageElement>(null);
  const [showTooltip, setShowTooltip] = useShowTooltip(300);

  if (!shopifyConnections || shopifyConnections.length === 0) {
    return null;
  }

  const hasActiveShopifyConnections = shopifyConnections.some((connection) => connection.isActive);

  return (
    <>
      {iconRef?.current && showTooltip && (
        <Tooltip
          closeTooltip={() => setShowTooltip(false)}
          openTooltip={() => setShowTooltip(true)}
          referenceElement={iconRef.current}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowTooltip(false);
            }}
            className={styles.tooltipWrapper}
          >
            {hasActiveShopifyConnections ? (
              <Text size={14}>Shopify payouts are active.</Text>
            ) : (
              <InactiveTooltipContent />
            )}
          </div>
        </Tooltip>
      )}

      <img
        onMouseOver={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={(e) => e.stopPropagation()}
        className={styles.icon}
        ref={iconRef}
        src={hasActiveShopifyConnections ? shopifyIconLightning : shopifyIconGrey}
        alt="Shopify"
      />
    </>
  );
};

export default ShopifyPayoutsStatusIcon;
