import { captureException } from "@sentry/react";
import { ApiError, request, RequestMethod } from "utils/ajax";
import { v4 as uuidv4 } from "uuid";

export default class HighbeamBaseApi {
  private readonly requestOrigin: string;
  private readonly getJwt: () => Promise<string | undefined>;

  constructor(requestOrigin: string, getJwt: () => Promise<string | undefined>) {
    this.requestOrigin = requestOrigin;
    this.getJwt = getJwt;
  }

  get<T>(url: string, headers?: object): Promise<T | null> {
    return this.request("GET", url, undefined, headers);
  }

  post<T>(url: string, body?: object, headers: object = {}): Promise<T | null> {
    return this.request("POST", url, JSON.stringify(body), {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    });
  }

  patch<T>(url: string, body?: object, headers: object = {}): Promise<T | null> {
    return this.request("PATCH", url, JSON.stringify(body), {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    });
  }

  put<T>(url: string, body?: object, headers: object = {}): Promise<T | null> {
    return this.request("PUT", url, JSON.stringify(body), {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    });
  }

  delete<T>(url: string, body?: object, headers: object = {}): Promise<T | null> {
    return this.request("DELETE", url, JSON.stringify(body), {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    });
  }

  private request<T>(
    method: RequestMethod,
    url: string,
    body?: BodyInit,
    headers?: object
  ): Promise<T | null> {
    return new Promise<T | null>(async (resolve, reject) => {
      const headersWithAuth = await this.headers(headers);

      try {
        const response = await request<T>(method, this.getRequestUrl(url), body, headersWithAuth);
        resolve(response);
      } catch (e: unknown) {
        if (e instanceof ApiError) {
          if (e.statusCode === 404) {
            // TODO: Maybe we want to handle these differently
            resolve(null);
            return;
          }
        }
        captureException(e, { extra: { url, requestTraceGuid: headersWithAuth["X-Request-Id"] } });
        reject(e);
      }
    });
  }

  private async headers(extraHeaders: object = {}): Promise<any> {
    const jwt = await this.getJwt();
    const headers: any = {};
    if (jwt) {
      headers["Authorization"] = `Bearer ${jwt}`;
      headers["Jwt-Payload"] = extractJwtPayload(jwt);
    }
    headers["X-Request-Id"] = uuidv4();

    return {
      ...headers,
      ...extraHeaders,
    };
  }

  private getRequestUrl(pathname: string): string {
    return this.requestOrigin + pathname;
  }
}

const extractJwtPayload = (jwt: string): string => {
  const parts = jwt.split(".");
  if (parts.length !== 3) return "";
  return parts[1];
};
