import { CheckDepositStatus } from "@highbeam/unit-node-sdk";
import TransactionFlexpaneStatus from "components/common/transaction-flexpane/TransactionFlexpaneStatus";
import { useCheckDepositFlexpaneContext } from "dialogs/CheckDepositFlexpane/context/CheckDepositFlexpaneProvider";
import { ValueOf } from "type-fest";
import { PillColor } from "ui/data-display/Pill";

const simplifiedCheckDepositStatuses = {
  AwaitingBackImage: "Unknown",
  AwaitingCustomerConfirmation: "Unknown",
  AwaitingFrontImage: "Unknown",
  AwaitingImages: "Unknown",
  Canceled: "Canceled",
  Clearing: "Clearing",
  Pending: "Pending",
  PendingReview: "Pending",
  Rejected: "Failed",
  Returned: "Failed",
  Sent: "Sent",
} as const satisfies { [key in CheckDepositStatus]: string };

type SimplifiedCheckDepositStatuses = ValueOf<typeof simplifiedCheckDepositStatuses>;

const pillColorMappings: { [key in SimplifiedCheckDepositStatuses]: PillColor } = {
  Canceled: "orange",
  Clearing: "grey",
  Pending: "grey-light",
  Failed: "pink-dark",
  Sent: "green", // TODO(alex): The previous `PaymentStatusPill` didn't handle this case so this value should be audited.
  Unknown: "grey-light", // TODO(alex): The previous `PaymentStatusPill` didn't handle this case so this value should be audited.
};

const CheckDepositFlexpaneStatusPill = () => {
  const { checkDeposit } = useCheckDepositFlexpaneContext();

  const statusLabel = simplifiedCheckDepositStatuses[checkDeposit.attributes.status];
  const pillColor = pillColorMappings[statusLabel];

  return (
    <TransactionFlexpaneStatus
      pillColor={pillColor}
      status={statusLabel}
      methodName="Check deposit"
    />
  );
};

export default CheckDepositFlexpaneStatusPill;
