import dayjs, { Dayjs } from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";

dayjs.extend(objectSupport);

type PlusUnit = "month";

type StartOfUnit = "year";

export class LocalMonth {
  private month: Dayjs;

  private constructor(month: Dayjs) {
    this.month = month;
  }

  plus(value: number, unit: PlusUnit): LocalMonth {
    return new LocalMonth(this.month.add(value, unit));
  }

  minus(value: number, unit: PlusUnit): LocalMonth {
    return new LocalMonth(this.month.subtract(value, unit));
  }

  startOf(unit: StartOfUnit): LocalMonth {
    return new LocalMonth(this.month.startOf(unit));
  }

  endOf(unit: StartOfUnit): LocalMonth {
    return new LocalMonth(this.month.endOf(unit));
  }

  rangeTo(other: LocalMonth): LocalMonth[] {
    const result: LocalMonth[] = [];
    // eslint-disable-next-line functional/no-let
    for (let month: LocalMonth = this; month <= other; month = month.plus(1, "month")) {
      result.push(month);
    }
    return result;
  }

  valueOf(): number {
    return this.month.year() * 100 + this.month.month() + 1;
  }

  toString(): string {
    return this.month.format("YYYY-MM");
  }

  static now() {
    return new LocalMonth(dayjs().startOf("month"));
  }

  static of(year: number, month: number) {
    return new LocalMonth(dayjs({ year, month: month - 1 }));
  }
}
