import { FC, ReactElement } from "react";
import { SingleRenderedElement } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";
import variants from "utils/tailwind/variants";

import styles from "./Pill.module.scss";

export type PillColor =
  | "blue"
  | "green"
  | "green-dark"
  | "grey"
  | "grey-light"
  | "orange"
  | "orange-red"
  | "pink"
  | "pink-dark"
  | "purple"
  | "purple-dark"
  | "purple-light"
  | "red"
  | "yellow"
  | "white"
  | "custom";

export type PillSize = "2xs" | "xs";

type Props = {
  // Any singular rendered element is allowed. This ensures people use `iconLeft`/`iconRight` props instead of passing the icon in as `children`.
  children: SingleRenderedElement;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  color: PillColor;
  className?: string;
  size?: PillSize;
  bordered?: boolean;
  onClick?: () => void;
};

const Pill: FC<Props> = ({
  children,
  color,
  className,
  size = "xs",
  bordered,
  onClick,
  iconLeft,
  iconRight,
}) => {
  const iconSizeClasses = variants(size, {
    "2xs": "[&>svg]:size-3",
    xs: "[&>svg]:size-4",
  });

  return (
    <span
      className={cn(
        "inline-flex w-min items-center justify-end whitespace-nowrap rounded-full font-medium",
        "gap-x-1 px-2 py-1",
        variants(size, {
          "2xs": "text-2xs",
          xs: "text-xs",
        }),
        color !== "custom" && styles[color],
        bordered ? "border" : "border-0",
        onClick && "cursor-pointer",
        className
      )}
      onClick={onClick}
    >
      {/* We always want to render the `span`s because we always want the added gap between the children and spans regardless of if an icon is passed in. */}
      <span className={cn("inline-flex", iconSizeClasses)}>{iconLeft}</span>
      <span
        className={variants(size, {
          "2xs": undefined,
          xs: "mb-[-1px]", // This helps a lot with making text look vertically centered next to icons, especially circular ones.
        })}
      >
        {children}
      </span>
      <span className={cn("inline-flex", iconSizeClasses)}>{iconRight}</span>
    </span>
  );
};

export default Pill;
