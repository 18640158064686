import { Coins, DotsThree, Pencil } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalAccountIcon from "resources/capital-accounts/components/CapitalAccountIcon";
import EditCapitalAccountNameModal from "resources/capital-accounts/dialogs/EditCapitalAccountNameModal";
import getCapitalAccountTitle from "resources/capital-accounts/utils/getCapitalAccountTitle";
import useIsCapitalOverviewPageEnabled from "resources/capital/queries/useIsCapitalOverviewPageEnabled";
import Button from "ui/inputs/Button";
import ButtonArray from "ui/navigation/ButtonArray";
import Menu from "ui/overlay/Menu";
import useSearchParamBoolean from "utils/search-params/useSearchParamBoolean";

import LineOfCreditDrawdownButtonArrayButton from "../../components/LineOfCreditDrawdownButtonArrayButton";
import LineOfCreditRepayButtonArrayButton from "../../components/LineOfCreditRepayButtonArrayButton";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountHeader: FC<Props> = ({ capitalAccountSummary }) => {
  const isCapitalOverviewPageEnabled = useIsCapitalOverviewPageEnabled();
  const [isEditCapitalAccountNameModalOpen, setIsEditCapitalAccountNameModalOpen] =
    useSearchParamBoolean("edit-name", false);

  return (
    <>
      {isEditCapitalAccountNameModalOpen && (
        <EditCapitalAccountNameModal
          capitalAccountSummary={capitalAccountSummary}
          onClose={() => {
            setIsEditCapitalAccountNameModalOpen(false);
          }}
        />
      )}

      <DashboardPage.Header
        actions={
          (capitalAccountSummary.state === CapitalAccountRep.State.Active ||
            capitalAccountSummary.state === CapitalAccountRep.State.OfferAccepted) && (
            <>
              {capitalAccountSummary.type === CapitalAccountRep.Type.CashAccessOnly && (
                <ButtonArray alignment="horizontal">
                  <LineOfCreditRepayButtonArrayButton
                    capitalAccountSummary={capitalAccountSummary}
                  />
                  <LineOfCreditDrawdownButtonArrayButton
                    capitalAccountSummary={capitalAccountSummary}
                  />
                </ButtonArray>
              )}
              <Menu
                button={
                  <Button variant="tertiary" paddingVariant="square">
                    <DotsThree size={16} />
                  </Button>
                }
              >
                <Menu.Item
                  icon={<Pencil size={16} />}
                  onClick={() => {
                    setIsEditCapitalAccountNameModalOpen(true);
                  }}
                >
                  Edit capital account name
                </Menu.Item>
              </Menu>
            </>
          )
        }
      >
        {isCapitalOverviewPageEnabled ? (
          <>
            <DashboardPage.Header.BackCaret to="/capital" />
            <DashboardPage.Header.IconTile
              icon={<CapitalAccountIcon capitalAccountType={capitalAccountSummary.type} />}
            />
            <DashboardPage.Header.Title>
              {getCapitalAccountTitle(capitalAccountSummary)}
            </DashboardPage.Header.Title>
          </>
        ) : (
          <>
            <DashboardPage.Header.IconTile icon={<Coins />} />
            <DashboardPage.Header.Title>Capital</DashboardPage.Header.Title>
          </>
        )}
      </DashboardPage.Header>
    </>
  );
};

export default CapitalAccountHeader;
