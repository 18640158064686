import {
  PlusCircle as PlusCircleIcon,
  User as UserIcon,
  DotsThreeVertical as DotsThreeVerticalIcon,
  Trash as TrashIcon,
  X as XIcon,
  EnvelopeSimple as EnvelopeSimpleIcon,
} from "@phosphor-icons/react";
import { FC, Suspense, useState } from "react";
import BillApprovalRep from "reps/BillApprovalRep";
import BillRep from "reps/BillRep";
import BillSummaryRep from "reps/BillSummaryRep";
import BillApproversAddModal from "resources/bill-approvals/components/BillApproversAddModal";
import useCanAddApproversToBill from "resources/bill-approvals/hooks/useCanAddApproversToBill";
import useDeleteBillApprovalMutation from "resources/bill-approvals/mutations/useDeleteBillApprovalMutation";
import useSendReminderEmailMutation from "resources/bill-approvals/mutations/useSendReminderEmailMutation";
import useBillApprovals from "resources/bill-approvals/queries/useBillApprovals";
import useBill from "resources/bills/queries/useBill";
import { useCurrentBusinessMember } from "resources/business-members/queries/businessMemberQueryHooks";
import businessMembersQueryHooks from "resources/business-members/queries/businessMembersQueryHooks";
import getBusinessMemberByBusinessMemberGuid from "resources/business-members/utils/getBusinessMemberByBusinessMemberGuid";
import EmptyState from "ui/data-display/EmptyState";
import Pill from "ui/data-display/Pill";
import UserAvatar from "ui/data-display/UserAvatar";
import ShimmerV2 from "ui/feedback/ShimmerV2";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import Menu from "ui/overlay/Menu";
import Text from "ui/typography/Text";
import { timeAgo } from "utils/date";
import useHasPermission from "utils/permissions/useHasPermission";
import getInitials from "utils/string/getInitials";

import styles from "./BillApprovers.module.scss";

type BillApprovalItemMainProps = {
  billApproval: BillApprovalRep.Complete;
  bill: BillSummaryRep.Complete;
};

const BillApprovalItemMain: FC<BillApprovalItemMainProps> = ({ bill, billApproval }) => {
  const isDraftBill = bill.state === BillRep.State.Draft;
  const { businessMemberGuid, status, requestedAt, grantedAt } = billApproval;
  const businessMembers = businessMembersQueryHooks.useData({});
  const businessMember = getBusinessMemberByBusinessMemberGuid(businessMembers, businessMemberGuid);
  const displayName = businessMember?.displayName;
  const currentBusinessMember = useCurrentBusinessMember();
  const isCurrentUser = currentBusinessMember
    ? currentBusinessMember.guid === businessMember?.guid
    : false;

  const subtextContent = (() => {
    if (isDraftBill) {
      return "Save bill to notify approver";
    }
    if (status === "Granted") {
      return <>Approved {timeAgo(grantedAt!)}</>;
    }
    return <>Requested {timeAgo(requestedAt!)}</>;
  })();

  return (
    <div className={styles.billApprovalItemMain}>
      <div className={styles.billApprovalItemMainAvatarContainer}>
        {displayName ? (
          <UserAvatar initials={getInitials(displayName)} size={32} color="purple-light" />
        ) : (
          <UserAvatar icon={<UserIcon />} size={32} color="grey" />
        )}
      </div>
      <div className={styles.billApprovalItemMainDetails}>
        <Text as="h4" className={styles.billApprovalItemHeading}>
          {businessMember ? (
            <>
              {displayName}
              {isCurrentUser ? " (you)" : ""}
            </>
          ) : (
            <>Deactivated member</>
          )}
        </Text>
        <Text as="p" className={styles.billApprovalItemSubtext}>
          {subtextContent}
        </Text>
      </div>
    </div>
  );
};

type BillApprovalItemProps = {
  billApproval: BillApprovalRep.Complete;
  bill: BillSummaryRep.Complete;
};

const BillApprovalItem: FC<BillApprovalItemProps> = ({ bill, billApproval }) => {
  const isDraftBill = bill.state === BillRep.State.Draft;
  const { status } = billApproval;
  const canDeleteBillApproval = useHasPermission("accountsPayableBillApproval:delete");
  const { mutate: deleteBillApproval, isPending: isDeletingBillApproval } =
    useDeleteBillApprovalMutation(billApproval.id, billApproval.billId, {
      onSuccess: () => {
        if (!isDraftBill) {
          notify("success", "Approver removed");
        }
      },
    });
  const { mutate: sendReminder, isPending: isSendingReminder } = useSendReminderEmailMutation(
    billApproval.id
  );
  const currentBusinessMember = useCurrentBusinessMember();

  return (
    <li className={styles.billApprovalItem}>
      <Suspense
        fallback={
          <div className={styles.billApprovalItemLoading}>
            <ShimmerV2 className="w-32" />
            <ShimmerV2 className="w-16" />
          </div>
        }
      >
        <BillApprovalItemMain bill={bill} billApproval={billApproval} />

        <div className={styles.billApprovalItemActions}>
          {isDraftBill ? (
            <Button
              aria-label="Remove"
              variant="danger"
              size="sm"
              paddingVariant="square"
              isLoading={isDeletingBillApproval}
              onClick={() => deleteBillApproval()}
            >
              <XIcon size={16} />
            </Button>
          ) : (
            <>
              {status === "Requested" && <Pill color="yellow">Pending</Pill>}
              {status === "Granted" && <Pill color="green">Approved</Pill>}

              {(canDeleteBillApproval ||
                currentBusinessMember?.guid !== billApproval.businessMemberGuid) && (
                <Menu
                  button={
                    <Button variant="ghost" paddingVariant="square" aria-label="Actions">
                      <DotsThreeVerticalIcon size={16} />
                    </Button>
                  }
                >
                  {billApproval.status === "Requested" &&
                    currentBusinessMember?.guid !== billApproval.businessMemberGuid && (
                      <Menu.Item
                        icon={<EnvelopeSimpleIcon />}
                        variant="tertiary"
                        isLoading={isSendingReminder}
                        onClick={() => sendReminder()}
                      >
                        Send reminder
                      </Menu.Item>
                    )}
                  {canDeleteBillApproval && (
                    <Menu.Item
                      icon={<TrashIcon />}
                      variant="danger"
                      isLoading={isDeletingBillApproval}
                      onClick={() => deleteBillApproval()}
                    >
                      Remove
                    </Menu.Item>
                  )}
                </Menu>
              )}
            </>
          )}
        </div>
      </Suspense>
    </li>
  );
};

type Props = {
  billId: string;
};

const BillApprovers: FC<Props> = ({ billId }) => {
  const bill = useBill(billId, { required: true });
  const billApprovals = useBillApprovals(billId);
  const [isAddApproversOpen, setIsAddApproversOpen] = useState(false);
  const canAddApproversToBill = useCanAddApproversToBill(bill);

  return (
    <div className={styles.container}>
      {billApprovals.length > 0 ? (
        <>
          <ul className={styles.billApprovalsList}>
            {billApprovals.map((billApproval) => (
              <BillApprovalItem key={billApproval.id} bill={bill} billApproval={billApproval} />
            ))}
          </ul>
          {canAddApproversToBill && (
            <div>
              <Button paddingVariant="bare" onClick={() => setIsAddApproversOpen(true)}>
                <PlusCircleIcon size={16} />
                Add approver
              </Button>
            </div>
          )}
        </>
      ) : (
        <EmptyState
          className="w-full"
          variant="inset-card"
          body={
            <EmptyState.CTA
              variant="tertiary"
              disabled={!canAddApproversToBill}
              tooltip={
                canAddApproversToBill
                  ? null
                  : "Approvers cannot be added to this bill because it has payments"
              }
              onClick={() => setIsAddApproversOpen(true)}
            >
              <PlusCircleIcon />
              Add approvers
            </EmptyState.CTA>
          }
        />
      )}

      {isAddApproversOpen && (
        <BillApproversAddModal billId={billId} onClose={() => setIsAddApproversOpen(false)} />
      )}
    </div>
  );
};

export default BillApprovers;
