import { CalendarBlank } from "@phosphor-icons/react";
import classNames from "classnames";
import dayjs from "dayjs";
import MultiStep from "layouts/MultiStep";
import RepaymentBankAccountDropdown from "pages/capital/CapitalRepayLineOfCreditPage/components/RepaymentBankAccountDropdown";
import { FC, useMemo, useRef, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import chargeCardAccountQueryHooks from "resources/charge-cards/queries/chargeCardAccountQueryHooks";
import useChargeCardRepaymentInfo from "resources/charge-cards/queries/useChargeCardRepaymentInfo";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import Pill from "ui/data-display/Pill";
import BarChart from "ui/data-visualization/BarChart";
import CurrencyInput from "ui/inputs/CurrencyInputV2";
import Helper from "ui/inputs/Helper";
import RadioCardSelect from "ui/inputs/RadioCardSelect";
import RadioCardSimple from "ui/inputs/RadioCardSimple";
import Text from "ui/typography/Text";
import { formatAmount } from "utils/string";

import useRepayHighbeamCardsForm, {
  useRepayHighbeamCardsFormContext,
} from "../hooks/useRepayHighbeamCardsForm";

type RepaymentOption =
  | "pending-and-settled-balance"
  | "settled-balance"
  | "cycle-balance"
  | "custom";

const REPAY_HIGHBEAM_CARDS_FORM_ID = "repay-highbeam-cards-form";

type RepayHighbeamCardsAmountFormProps = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const RepayHighbeamCardsAmountForm: FC<RepayHighbeamCardsAmountFormProps> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  const { cardBalance, cardPending } = capitalAccountSummaryWithChargeCard;

  const chargeCardAccount = chargeCardAccountQueryHooks.useDataRequired({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
  });
  const { remainingAmountDue, nextRepaymentDueDate } = useChargeCardRepaymentInfo(
    chargeCardAccount.guid,
    {
      required: true,
    }
  );

  const navigate = useNavigate();
  const { control, handleSubmit, watch } = useRepayHighbeamCardsFormContext();
  const amountInputRef = useRef<HTMLInputElement>(null);

  const amountInputValue = watch("amount");
  const selectedRepaymentBankAccount = watch("repaymentBankAccount");
  const amountPending = 0 - cardPending;
  const amountOwed = 0 - cardBalance;
  const amountPendingAndOwed = amountPending + amountOwed;

  const repaymentOptions = useMemo((): RepaymentOption[] => {
    const options: RepaymentOption[] = [];

    if (amountPending > 0) {
      options.push("pending-and-settled-balance");
    }
    if (amountOwed > 0) {
      options.push("settled-balance");
    }
    if (remainingAmountDue > 0) {
      options.push("cycle-balance");
    }
    options.push("custom");

    return options;
  }, [remainingAmountDue, amountOwed, amountPending]);

  const defaultRepaymentSelectValue = repaymentOptions[0];
  const [repaymentOptionValue, setRepaymentOptionValue] = useState<RepaymentOption>(
    defaultRepaymentSelectValue
  );

  const onSubmit = handleSubmit((data) => {
    navigate(`/capital/${capitalAccountSummaryWithChargeCard.guid}/cards/repay/confirm`, {
      state: {
        amount: data.amount,
        repaymentBankAccountGuid: data.repaymentBankAccount.guid,
      },
    });
  });

  const availableAfterRepayment = capitalAccountSummaryWithChargeCard.available + amountInputValue;

  const availableAfterRepaymentPercentage =
    (availableAfterRepayment / capitalAccountSummaryWithChargeCard.details.limit) * 100;

  const repaymentBankAccountAvailableBalance = selectedRepaymentBankAccount.availableBalance;
  const repaymentBankAccountBalanceAfterRepayment =
    repaymentBankAccountAvailableBalance - amountInputValue;
  const insufficientFunds = repaymentBankAccountBalanceAfterRepayment < 0;

  return (
    <MultiStep.Form id={REPAY_HIGHBEAM_CARDS_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>
            How much do you want to repay?
          </MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <Controller
          control={control}
          name="amount"
          render={({ field }) => {
            return (
              <RadioCardSelect
                options={repaymentOptions}
                value={repaymentOptionValue}
                onChange={setRepaymentOptionValue}
                rowGap={8}
              >
                {({ option, isSelected, onSelect }) => {
                  switch (option) {
                    case "pending-and-settled-balance":
                      return (
                        <RadioCardSimple
                          key={option}
                          value={option}
                          checked={isSelected}
                          onChange={(value) => {
                            onSelect(value);
                            field.onChange(amountPendingAndOwed);
                          }}
                          label={<MoneyAmount cents={amountPendingAndOwed} weight="medium" />}
                          helper="Settled + pending balance"
                        />
                      );
                    case "settled-balance":
                      return (
                        <RadioCardSimple
                          key={option}
                          value={option}
                          checked={isSelected}
                          onChange={(value) => {
                            onSelect(value);
                            field.onChange(amountOwed);
                          }}
                          label={<MoneyAmount cents={amountOwed} weight="medium" />}
                          helper="Settled balance"
                        />
                      );
                    case "cycle-balance":
                      return (
                        <RadioCardSimple
                          key={option}
                          value={option}
                          checked={isSelected}
                          onChange={(value) => {
                            onSelect(value);
                            field.onChange(remainingAmountDue);
                          }}
                          label={<MoneyAmount cents={remainingAmountDue} weight="medium" />}
                          helper={
                            <Pill color="yellow" iconLeft={<CalendarBlank />}>
                              Due {dayjs(nextRepaymentDueDate).format("MMM D")}
                            </Pill>
                          }
                        />
                      );
                    case "custom":
                      return (
                        <RadioCardSimple
                          key={option}
                          value={option}
                          checked={isSelected}
                          onChange={(value) => {
                            onSelect(value);
                            field.onChange(amountPendingAndOwed);

                            // Focus & select the amount input when the "custom" option is selected
                            // NB(alex): I wasn't able to figure out how to unset the field so I opted to select & highlight the field instead
                            amountInputRef.current?.focus();
                            setTimeout(() => amountInputRef.current?.select(), 0);
                          }}
                          label="Custom amount"
                        />
                      );
                  }
                }}
              </RadioCardSelect>
            );
          }}
        />

        <Controller
          control={control}
          name="amount"
          render={({ field }) => {
            return (
              <CurrencyInput
                onFocus={(e) => e.target.select()}
                inputRef={amountInputRef}
                prefixValue="$"
                label="Amount"
                className={classNames(repaymentOptionValue !== "custom" && "hidden", "mt-2")}
                {...field}
              />
            );
          }}
        />

        {insufficientFunds && (
          <Helper iconVariant="error">
            The amount you entered is greater than your account balance of{" "}
            {formatAmount(selectedRepaymentBankAccount.availableBalance, {
              withCents: true,
            })}
            .
          </Helper>
        )}

        {amountInputValue > Math.max(0, amountPendingAndOwed) && (
          <Helper iconVariant="warning">
            The amount you entered is greater than the amount you owe.
          </Helper>
        )}
      </MultiStep.Section>

      <MultiStep.Section className="mt-8">
        <RepaymentBankAccountDropdown control={control} />
      </MultiStep.Section>

      <Divider className="my-8" />

      <MultiStep.Section>
        <Text color={colors.grey[900]} size={14} weight="medium">
          Capital available after repayment
        </Text>

        <BarChart height={8} className="my-3">
          <BarChart.Bar color="green" widthPercentage={availableAfterRepaymentPercentage} />
        </BarChart>

        <MoneyAmountFraction
          numeratorInCents={availableAfterRepayment}
          denominatorInCents={capitalAccountSummaryWithChargeCard.details.limit}
        />
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.NextButton
          form={REPAY_HIGHBEAM_CARDS_FORM_ID}
          disabled={insufficientFunds}
        >
          Next
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const RepayHighbeamCardsAmountView: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  const openBankAccounts = bankAccountsQueryHooks.useData({ status: "open" });

  const defaultRepaymentBankAccount =
    openBankAccounts.find(
      (bankAccount) =>
        bankAccount.guid === capitalAccountSummaryWithChargeCard.details.repayment.bankAccountGuid
    ) ?? openBankAccounts[0];

  const amountOwed =
    0 -
    capitalAccountSummaryWithChargeCard.cardBalance -
    capitalAccountSummaryWithChargeCard.cardPending;

  const form = useRepayHighbeamCardsForm({
    defaultValues: {
      amount: Math.max(0, amountOwed),
      repaymentBankAccount: defaultRepaymentBankAccount,
    },
  });

  return (
    <FormProvider {...form}>
      <RepayHighbeamCardsAmountForm
        capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
      />
    </FormProvider>
  );
};

export default RepayHighbeamCardsAmountView;
