import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);

type BankingDayOpts = {
  format?: dayjs.OptionType;
  keepLocalTime?: boolean;
};

export const bankingDay = (date?: dayjs.ConfigType, opts: BankingDayOpts = {}): Dayjs =>
  dayjs(date, opts.format).tz("America/New_York", opts.keepLocalTime);

export const startOfBankingDay = (date?: dayjs.ConfigType, opts: BankingDayOpts = {}): Dayjs =>
  bankingDay(date, opts).startOf("day");

export const startOfYear = (date?: dayjs.ConfigType, opts: BankingDayOpts = {}): Dayjs =>
  bankingDay(date, opts).startOf("year");

export const dateOnly = (date?: dayjs.ConfigType): Dayjs => dayjs(date).tz(undefined, true);

export const formatDate = (date: dayjs.ConfigType) => dayjs(date).format("MMM D, YYYY");

export const formatBankingDate = (date: dayjs.ConfigType, hideCurrentYear = false) => {
  return hideCurrentYear
    ? dayjs(date).tz("America/New_York").format("MMM D")
    : dayjs(date).tz("America/New_York").format("MMM D, YYYY");
};

export const formatEventTime = (date: dayjs.ConfigType) =>
  dayjs(date).format("MMM D, YYYY [at] h:mm A z");

export const getDurationFromPeriod = (period: string): plugin.Duration => {
  return dayjs.duration(period);
};

export const getMsFromDays = (days: number): number => {
  return dayjs.duration(days, "days").asMilliseconds();
};

export const getDaysFromMs = (ms: number): number => {
  return dayjs.duration(ms, "milliseconds").asDays();
};

export const getUsersLocalTimezoneAbbreviation = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = { timeZoneName: "short" };
  const dateString = new Intl.DateTimeFormat("en-US", options).format(date);
  const match = dateString.match(/\b[A-Z]{3,5}\b/);
  return match ? match[0] : "";
};

export const timeAgo = (date: dayjs.ConfigType) => {
  const target = dayjs(date);
  const now = dayjs();

  return now.diff(target, "week") > 1 ? formatDate(target) : target.fromNow();
};
