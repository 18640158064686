import { Copy } from "@phosphor-icons/react";
import classNames from "classnames";
import React, { ReactNode, RefObject, useEffect, useState } from "react";
import ItemWithTooltip from "ui/overlay/Tooltip/ItemWithTooltip/ItemWithTooltip";
import Text from "ui/typography/Text";

import styles from "./HighbeamCard.module.scss";

type Props = {
  label?: string;
  children: ReactNode;
  numeric?: boolean;
  isCopyable?: boolean;
  copy: {
    buttonRef?: RefObject<HTMLDivElement>; // Allows for externally-managed copy/paste.
    onClick?: () => void;
    state?: [boolean, (copied: boolean) => void]; // Optionally manage copied state externally.
  };
  textColor?: "white" | "black";
};

const HighbeamCardField: React.FC<Props> = ({
  label,
  children,
  numeric,
  copy,
  isCopyable,
  textColor = "black",
}) => {
  // We assume that [copy.state]'s existence is stable.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [copied, setCopied] = copy.state ?? useState(false);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setCopied(true);
    copy.onClick?.();
  };

  useEffect(() => {
    if (!copied) return;
    const timer = setTimeout(() => setCopied(false), 2000);
    return () => clearTimeout(timer);
  }, [copied, setCopied]);

  return (
    <div className={classNames(styles.field)}>
      {label ? (
        <Text size={14} weight="medium" color={textColor}>
          {label}
        </Text>
      ) : null}
      {/* Does not use CopyItemTooltip because we need to expose an interface that can work with the
          VGS iframe, which differs from that component's interface and is not a pattern used
          elsewhere in the app. */}
      {isCopyable && (
        <ItemWithTooltip
          content={<Text size={14}>{!copied ? "Click to copy" : "Copied"}</Text>}
          wrapperClassName={classNames(styles.content, styles.copyable)}
        >
          <div
            className={classNames(styles.fieldValue, { [styles.numeric]: numeric })}
            onClick={handleClick}
          >
            {children}
          </div>
          <div className={styles.copyButton}>
            <Copy size={16} color={textColor} />
            <div ref={copy.buttonRef} className={styles.sensitiveData} onClick={handleClick} />
          </div>
        </ItemWithTooltip>
      )}
      {!isCopyable && (
        <div className={classNames(styles.content)}>
          <div className={classNames(styles.fieldValue, { [styles.numeric]: numeric })}>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default HighbeamCardField;
