import { ErrorBoundary } from "@sentry/react";
import classNames from "classnames";
import ErrorCard from "modules/error/components/ErrorCard";
import { FC, ReactNode, Suspense } from "react";
import DotsLoader from "ui/feedback/DotsLoader";

import styles from "./DashboardPage.module.scss";
import Header from "./Header";
import Section from "./Section";

const DashboardPageDotsLoader = () => {
  return (
    <div className={styles.dotsContainer}>
      <DotsLoader dots={5} />
    </div>
  );
};

type DashboardPageProps = {
  children: ReactNode;
  className?: string;
  suspenseFallback?: ReactNode;
};

const DashboardPage: FC<DashboardPageProps> = ({
  children,
  className,
  suspenseFallback = <DashboardPageDotsLoader />,
}) => (
  <div className={classNames(styles.page, className)}>
    <ErrorBoundary
      fallback={({ error }) => (
        <div className={styles.errorCardContainer}>
          <ErrorCard error={error} />
        </div>
      )}
    >
      <div className={styles.content}>
        <Suspense fallback={suspenseFallback}>{children}</Suspense>
      </div>
    </ErrorBoundary>
  </div>
);

export default Object.assign(DashboardPage, {
  Header: Header,
  Section: Section,
  DotsLoader: DashboardPageDotsLoader,
});
