import { ArrowRight } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import { Link } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import checkIsCapitalAccountSummaryWithChargeCard from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import TeaserV2 from "ui/data-display/TeaserV2";
import Button from "ui/inputs/Button";
import { Heading2, Paragraph, Span } from "ui/typography";
import Text from "ui/typography/Text";
import useIsAllowedToAcceptLineOfCredit from "utils/permissions/useIsAllowedToAcceptLineOfCredit";

import { NotAllowedToActivateLineOfCreditContent } from "../../constants";
import CapitalAccountHeader from "../../sections/CapitalAccountHeader";

import ChargeCardSummaryItems from "./ChargeCardSummaryItems";
import LineOfCreditSummaryItems from "./LineOfCreditSummaryItems";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const OfferedCapitalView: React.FC<Props> = ({ capitalAccountSummary }) => {
  const isAllowedToAcceptLineOfCredit = useIsAllowedToAcceptLineOfCredit();

  return (
    <>
      <CapitalAccountHeader capitalAccountSummary={capitalAccountSummary} />

      <DashboardPage.Section>
        <TeaserV2 color="purple">
          <div className="mb-9">
            <Span className="mb-4 text-3xl">🎉</Span>
            <Heading2>Your {capitalAccountSummary.name} is ready!</Heading2>
            <Paragraph className="text-md text-grey-500">
              Accept your offer to get started!
            </Paragraph>
          </div>

          {checkIsCapitalAccountSummaryWithChargeCard(capitalAccountSummary) ? (
            <ChargeCardSummaryItems capitalAccountSummaryWithChargeCard={capitalAccountSummary} />
          ) : (
            <LineOfCreditSummaryItems capitalAccountSummaryCashAccessOnly={capitalAccountSummary} />
          )}

          <Link to={`/capital/${capitalAccountSummary.guid}/review-line-offer/how-it-works`}>
            <Button
              variant="primary"
              disabled={!isAllowedToAcceptLineOfCredit}
              tooltip={
                !isAllowedToAcceptLineOfCredit && <NotAllowedToActivateLineOfCreditContent />
              }
              className="mt-9 w-full tablet:w-auto"
            >
              <Text size={14}>Review and accept</Text>
              <ArrowRight size={14} />
            </Button>
          </Link>
        </TeaserV2>
      </DashboardPage.Section>
    </>
  );
};

export default OfferedCapitalView;
