import Card from "ui/data-display/Card";
import Button from "ui/inputs/Button";
import { Heading4, Paragraph } from "ui/typography";
import Text from "ui/typography/Text";
import useIsAllowedToConnectStores from "utils/permissions/useIsAllowedToConnectStores";

// NB(alex): This can probably be consolidated with `RutterConnectionCard`.

type Props = {
  title: string;
  description: string;
  addText: string;
  onAdd: () => void;
};

const AddIntegration: React.FC<Props> = ({ title, description, addText, onAdd }) => {
  const isAllowedToConnectStores = useIsAllowedToConnectStores();

  return (
    <Card
      className="flex w-full min-w-full flex-col justify-center gap-1 border-grey-300 p-5 tablet:min-w-80"
      borderVariant="dashed"
      shadow="none"
    >
      <Heading4>{title}</Heading4>

      <Paragraph className="mb-3 mt-2 flex items-center gap-x-2 text-sm text-grey-600">
        {description}
      </Paragraph>

      <Button
        className="w-full"
        variant="tertiary"
        onClick={onAdd}
        disabled={!isAllowedToConnectStores}
        tooltip={
          !isAllowedToConnectStores && (
            <Text size={14}>You don’t have permission to connect stores.</Text>
          )
        }
      >
        {addText}
      </Button>
    </Card>
  );
};

export default AddIntegration;
