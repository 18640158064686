import { Info, FileText } from "@phosphor-icons/react";
import classNames from "classnames";
import { CHAT_WIDGET_SHOWN_EVENT } from "components/ChatWidget";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import BillLineItemsDesktop from "resources/bill-line-items/components/BillLineItemsDesktop";
import useBillSyncQueryIfEnabled from "resources/bill-syncs/hooks/useBillSyncQueryIfEnabled";
import BillDetailsDocumentsView from "resources/bills/views/BillDetailsDocumentsView";
import BillDetailsInfoView from "resources/bills/views/BillDetailsInfoView";
import SegmentControls from "ui/navigation/SegmentControls";
import FullPageModal from "ui/overlay/FullPageModal";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import { useIsMobile } from "utils/device/useMediaQuery";
import { useEventBusListener } from "utils/event-bus";
import useTabState from "utils/tabs/useTabState";

import styles from "./BillDetailsFullPageModal.module.scss";
import BillDetailsFullPageModalHeader from "./BillDetailsFullPageModalHeader";

type BillDetailsPageProps = {
  billId: string;
  onClose: () => void;
};

const BillDetailsPage: FC<BillDetailsPageProps> = ({ billId, onClose }) => {
  const billPayLineItemsEnabled = useFeatureFlag("BILL_PAY_LINE_ITEMS_UI");

  // If accounting sync is enabled, we want to ensure that there is a bill
  // sync whenever viewing a bill details page.
  useBillSyncQueryIfEnabled(billId);

  const isMobile = useIsMobile();

  const tabs = {
    "bill-info": {
      icon: <Info />,
      label: "Bill info",
    },
    documents: {
      icon: <FileText />,
      label: "Documents",
    },
  };

  const [activeTab, setActiveTab] = useTabState(tabs);

  const navigate = useNavigate();

  const onCreatePayment = () => {
    navigate(`/bills/payment?bills=${billId}`);
  };

  const chatWidgetShownHandler = useCallback(() => {
    // Close the bill details modal when the chat widget is shown.
    onClose();
  }, [onClose]);

  useEventBusListener(CHAT_WIDGET_SHOWN_EVENT, chatWidgetShownHandler);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <BillDetailsFullPageModalHeader billId={billId} />

        <div className={styles.billInfoAndDocumentsSegmentControlContainer}>
          <SegmentControls
            size="sm"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabs}
          />
        </div>
      </div>

      <div className={styles.bodyContainer}>
        <div
          className={classNames(
            styles.sidebarContainer,
            activeTab === "bill-info" && styles.active
          )}
        >
          <BillDetailsInfoView
            billId={billId}
            onCreatePayment={onCreatePayment}
            onDeleteSuccessful={onClose}
            onDraftBillSavedAsOpenSuccess={onClose}
            onMarkAsPaid={onClose}
          />
        </div>

        <div
          className={classNames(
            styles.contentContainer,
            activeTab === "documents" && styles.active
          )}
        >
          <BillDetailsDocumentsView billId={billId} />

          {billPayLineItemsEnabled && !isMobile && <BillLineItemsDesktop billId={billId} />}
        </div>
      </div>
    </div>
  );
};

type Props = {
  billId: string;
  onClose: () => void;
};

const BillDetailsFullPageModal: FC<Props> = ({ billId, onClose }) => {
  return (
    <FullPageModal onClose={onClose}>
      <BillDetailsPage billId={billId} onClose={onClose} />
    </FullPageModal>
  );
};

export default BillDetailsFullPageModal;
