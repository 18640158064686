import { atom } from "recoil";
import { DialogState } from "utils/dialog/useDialog";
import useModal from "utils/dialog/useModal";

type EditUserSuccessfulParams = {
  displayName: string;
  roleName: string;
};

type EditUserSuccessfulModalState = DialogState<EditUserSuccessfulParams>;

const editUserSuccessfulModalState = atom<EditUserSuccessfulModalState>({
  key: "editUserSuccessfulModalState",
  default: { isOpen: false },
});

export default editUserSuccessfulModalState;

export const useEditUserSuccessfulModal = () => {
  return useModal<EditUserSuccessfulParams>(editUserSuccessfulModalState);
};
