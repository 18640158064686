import PaymentSummary from "components/PaymentSummary";
import MultiStep from "layouts/MultiStep";
import { FC, FormEvent } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import BankAccountBar from "resources/bank-accounts/components/BankAccountBar";
import { useOpenBankAccountRequired } from "resources/bank-accounts/queries/bankAccountQueryHooks";
import useCreateLineOfCreditRepaymentMutation from "resources/line-of-credit/mutations/useCreateLineOfCreditRepaymentMutation";
import MoneyAmount from "ui/data-display/money/MoneyAmount";

const REPAY_LINE_OF_CREDIT_CONFIRMATION_FORM_ID = "repay-line-of-credit-confirmation-form";

type RepayLineOfCreditConfirmationViewState = {
  amount: number;
  repaymentBankAccountGuid: string;
};

type RepayLineOfCreditConfirmationFormProps = RepayLineOfCreditConfirmationViewState & {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const RepayLineOfCreditConfirmationForm: FC<RepayLineOfCreditConfirmationFormProps> = ({
  amount,
  repaymentBankAccountGuid,
  capitalAccountSummary,
}) => {
  const navigate = useNavigate();
  const availableAfterRepayment = capitalAccountSummary.available + amount;
  const currentAmountOwed = 0 - capitalAccountSummary.runningBalance;
  const amountOwedAfterRepayment = currentAmountOwed - amount;

  const { mutateAsync: repayLineOfCredit, isPending } = useCreateLineOfCreditRepaymentMutation();

  const repaymentBankAccount = useOpenBankAccountRequired(repaymentBankAccountGuid);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // TODO(alex): will do downstream
    if (capitalAccountSummary) {
      await repayLineOfCredit({
        lineOfCreditGuid: capitalAccountSummary.guid,
        amountInCents: amount,
        transferFromBankAccountGuid: repaymentBankAccountGuid,
      });

      navigate(`/capital/${capitalAccountSummary.guid}`);
    }
  };

  return (
    <MultiStep.Form id={REPAY_LINE_OF_CREDIT_CONFIRMATION_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Confirm details</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <PaymentSummary>
          <PaymentSummary.Header>
            <PaymentSummary.Header.Title>Repayment amount</PaymentSummary.Header.Title>
            <PaymentSummary.Header.Amount cents={amount} />
          </PaymentSummary.Header>

          <PaymentSummary.SectionDivider />

          <PaymentSummary.Section>
            <PaymentSummary.Section.SummaryItem
              label="Pay from"
              value={<BankAccountBar bankAccount={repaymentBankAccount} />}
            />

            <PaymentSummary.Section.SummaryItem
              label="Capital available (current)"
              value={
                <MoneyAmount size={14} cents={capitalAccountSummary.available} weight="medium" />
              }
            />

            <PaymentSummary.Section.SummaryItem
              label="Capital available after repaying"
              value={<MoneyAmount size={14} cents={availableAfterRepayment} weight="medium" />}
            />

            <PaymentSummary.SectionDivider />

            <PaymentSummary.Section.SummaryItem
              label="Line used (current)"
              value={<MoneyAmount size={14} cents={currentAmountOwed} weight="medium" />}
            />

            <PaymentSummary.Section.SummaryItem
              label="Line used after repaying"
              value={<MoneyAmount size={14} cents={amountOwedAfterRepayment} weight="medium" />}
            />
          </PaymentSummary.Section>
        </PaymentSummary>
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.BackButtonLink
          to={`/capital/${capitalAccountSummary.guid}/credit/repay`}
        />

        <MultiStep.Controls.NextButton
          form={REPAY_LINE_OF_CREDIT_CONFIRMATION_FORM_ID}
          autoFocus
          isLoading={isPending}
        >
          Complete repayment
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

const isValidRepayLineOfCreditConfirmationViewState = (
  state: unknown
): state is RepayLineOfCreditConfirmationViewState => {
  return (
    typeof state === "object" &&
    state !== null &&
    "amount" in state &&
    typeof state.amount === "number" &&
    "repaymentBankAccountGuid" in state &&
    typeof state.repaymentBankAccountGuid === "string"
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const RepayLineOfCreditConfirmationView: FC<Props> = ({ capitalAccountSummary }) => {
  const { state } = useLocation();

  if (!isValidRepayLineOfCreditConfirmationViewState(state)) {
    return <Navigate to="/capital" />;
  }

  return (
    <RepayLineOfCreditConfirmationForm
      capitalAccountSummary={capitalAccountSummary}
      amount={state.amount}
      repaymentBankAccountGuid={state.repaymentBankAccountGuid}
    />
  );
};

export default RepayLineOfCreditConfirmationView;
