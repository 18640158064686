import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/react";
import { Camera, UploadSimple } from "@phosphor-icons/react";
import { captureException } from "@sentry/react";
import imageCompression from "browser-image-compression";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CaptureOption, CheckDepositValidation } from "state/checkDeposit";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Button from "ui/inputs/Button";
import StepHeader from "ui/navigation/Steps/StepHeader";
import Text from "ui/typography/Text";
import { useMitekAutoCapture } from "utils/customHooks/mitekCapture/useMitekAutoCapture";
import { useMitekUploadCapture } from "utils/customHooks/mitekCapture/useMitekUploadCapture";
import { fileTypeExtensions } from "utils/dropzone/file-types";

import styles from "./CaptureCheck.module.scss";
import { AutoCapturePhotoBody, UploadImageBody } from "./CaptureCheckTabBody";

export type OptionConfig = {
  pageTitle: string;
  stepNumber: number;
  direction: string;
  icon: string;
  mitekDocumentType: string;
};

type Props = {
  config: OptionConfig;
  currentImage: string | null;
  setCurrentImage: (image: string | null) => void;
  validation: CheckDepositValidation;
  setValidation: (validationState: CheckDepositValidation) => void;
  selectedOption: CaptureOption;
  setSelectedOption: (option: CaptureOption) => void;
  onPrevPress: () => void;
  onNextPress: () => void;
};

const CaptureCheck: React.FC<Props> = ({
  config,
  currentImage,
  setCurrentImage,
  validation,
  setValidation,
  selectedOption,
  setSelectedOption,
  onPrevPress,
  onNextPress,
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNextPress();
  };

  const isNextStepAllowed = currentImage !== null && validation.isValidated;
  const mitekDocumentType = config.direction === "front" ? "CHECK_FRONT" : "CHECK_BACK";

  // ################## AUTOCAPTURE ##################
  const { isLoadingCamera, startAutoCapture, setStartAutoCapture, autoCapturedImage } =
    useMitekAutoCapture("misnap", mitekDocumentType, setValidation);

  useEffect(() => {
    if (selectedOption === CaptureOption.CAMERA && autoCapturedImage !== null) {
      setCurrentImage(autoCapturedImage);
      setStartAutoCapture(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoCapturedImage]);

  // ################## UPLOAD ##################

  const { processUpload, isProcessing } = useMitekUploadCapture(mitekDocumentType, setValidation);

  // We have to do this because dropzone doesn't allow us to clear files
  const [checkImageFile, setCheckImageFile] = useState<File>();

  const onDrop = async (acceptedFiles: File[]) => {
    const imageFile = acceptedFiles[0];

    try {
      const compressedFile = await imageCompression(imageFile, { maxSizeMB: 0.5 });
      setCheckImageFile(compressedFile);
    } catch (error) {
      setCheckImageFile(imageFile);
      captureException(error);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: fileTypeExtensions.jpeg,
    maxSize: 20971520, // 20mb => 20971520 bytes
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  useEffect(() => {
    if (selectedOption === CaptureOption.UPLOAD && checkImageFile) {
      const reader = new FileReader();
      reader.readAsDataURL(checkImageFile);
      reader.onloadend = function () {
        if (typeof reader.result === "string") {
          setCurrentImage(reader.result);
          processUpload(reader.result);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkImageFile]);

  const getTabBody = (currentTab: CaptureOption) => (
    <div className={styles.tabBodyContainer}>
      {/* Had to keep Auto Capture components separated due to MitekSDK looking for misnap div container */}
      {currentTab === CaptureOption.CAMERA && !startAutoCapture && (
        <AutoCapturePhotoBody
          direction={config.direction}
          onClick={() => {
            setStartAutoCapture(true);
          }}
          currentImage={currentImage}
        />
      )}

      {currentTab === CaptureOption.CAMERA && startAutoCapture && (
        <>
          {isLoadingCamera && (
            <AnimatedSpinner size={20} className={styles.spinner}></AnimatedSpinner>
          )}
          <div className={styles.autoCaptureCamera} id="misnap"></div>
        </>
      )}

      {currentTab === CaptureOption.UPLOAD && (
        <div {...getRootProps()} className={styles.uploadContainer}>
          <input {...getInputProps()} />
          <UploadImageBody
            direction={config.direction}
            onClick={open}
            currentImage={currentImage}
            isProcessing={isProcessing}
            validation={validation}
          />
        </div>
      )}
    </div>
  );

  const tabs = (
    <TabGroup
      selectedIndex={selectedOption}
      onChange={(index) => {
        setSelectedOption(index);
        setCheckImageFile(undefined);
        setStartAutoCapture(false);
        setCurrentImage(null);
      }}
    >
      <TabList className={styles.tabsContainer}>
        <Tab className={styles.tabsContainerButton}>
          {({ selected }) => (
            <Button
              className={selected ? styles.tabsContainerButtonActive : styles.tabsContainerButton}
            >
              <Camera />
              <Text align="left" weight="medium" className={styles.tabBodyContainerText}>
                Take a photo
              </Text>
            </Button>
          )}
        </Tab>
        <Tab className={styles.tabsContainerButton}>
          {({ selected }) => (
            <Button
              className={selected ? styles.tabsContainerButtonActive : styles.tabsContainerButton}
            >
              <UploadSimple />
              <Text align="left" weight="medium" className={styles.tabBodyContainerText}>
                Upload an image
              </Text>
            </Button>
          )}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>{getTabBody(selectedOption)}</TabPanel>
        <TabPanel>{getTabBody(selectedOption)}</TabPanel>
      </TabPanels>
    </TabGroup>
  );

  const bottomNavButtons = (
    <div className={styles.navButtonsContainer}>
      {selectedOption === CaptureOption.UPLOAD && currentImage !== null && (
        <Button className={styles.navButtonsContainerButton} variant="tertiary" onClick={open}>
          Change image
        </Button>
      )}
      {selectedOption === CaptureOption.CAMERA && currentImage !== null && (
        <Button
          className={styles.navButtonsContainerButton}
          variant="tertiary"
          onClick={() => {
            setCurrentImage(null);
            setValidation({ isValidated: false, errorMessage: null });
            setStartAutoCapture(true);
          }}
        >
          Retake photo
        </Button>
      )}
      <Button
        className={styles.navButtonsContainerButton}
        type="submit"
        form="capture-check"
        variant="primary"
        disabled={!isNextStepAllowed}
        onClick={() => {
          setCurrentImage(currentImage!);
        }}
      >
        Next
      </Button>
    </div>
  );

  return (
    <>
      <StepHeader
        stepNumber={config.stepNumber}
        disableBackButton={false}
        disableForwardButton={!isNextStepAllowed}
        handleBackButtonClick={() => {
          window.mitekScienceSDK.cmd("SDK_STOP");
          onPrevPress();
        }}
        handleForwardButtonClick={() => {
          window.mitekScienceSDK.cmd("SDK_STOP");
          onNextPress();
        }}
        title={config.pageTitle}
      />
      <form id="capture-check" className={styles.form} onSubmit={handleSubmit}>
        {tabs}
        {bottomNavButtons}
      </form>
    </>
  );
};

export default CaptureCheck;
