import { Info, WarningCircle } from "@phosphor-icons/react";
import classNames from "classnames";
import { ComponentProps } from "react";
import colors from "styles/colors";
import ItemWithTooltip from "ui/overlay/Tooltip/ItemWithTooltip/ItemWithTooltip";
import Tooltip from "ui/overlay/Tooltip/Tooltip";

import styles from "./IconWithTooltip.module.scss";

export type InfoIconColor = "primary" | "dark" | "light" | "warning";
export type IconType = "info" | "warning";

const getInfoIconColor = (color: InfoIconColor): string => {
  switch (color) {
    case "primary":
      return colors.purple[500];
    case "dark":
      return colors.grey[900];
    case "warning":
      return colors.yellow[700];
    case "light":
      return colors.grey[500];
  }
};

const getIcon = (iconType: IconType, color: InfoIconColor) => {
  switch (iconType) {
    case "info":
      return <Info className={styles.icon} size={16} color={getInfoIconColor(color)} />;
    case "warning":
      return <WarningCircle className={styles.icon} size={16} color={getInfoIconColor(color)} />;
  }
};

type Props = Omit<ComponentProps<typeof ItemWithTooltip>, "children"> & {
  content: React.ReactNode;
  color?: InfoIconColor;
  icon?: IconType;
};

const IconWithTooltip: React.FC<Props> = ({
  content,
  wrapperClassName,
  color = "primary",
  icon = "info",
  ...itemWithTooltipProps
}) => (
  <ItemWithTooltip
    wrapperClassName={classNames(styles.wrapper, wrapperClassName)}
    content={<Tooltip.Content size={14}>{content}</Tooltip.Content>}
    {...itemWithTooltipProps}
  >
    {getIcon(icon, color)}
  </ItemWithTooltip>
);

export default IconWithTooltip;
