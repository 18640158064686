import { TaxForm } from "@highbeam/unit-node-sdk";
import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import { notify } from "ui/feedback/Toast";
import { downloadBlob } from "utils/download";

const useDownloadTaxFormPdfMutation = () => {
  const unitApi = useUnitApi();

  return useMutation({
    mutationFn: async (taxForm: TaxForm) => {
      return {
        taxForm,
        data: (await unitApi.taxForms.getPdf(taxForm.id)) as unknown as Blob,
      };
    },
    onError: (err) => {
      notify("error", "Something went wrong with downloading your tax form.");
      captureException(err);
    },
    onSuccess: async ({ taxForm, data }: { taxForm: TaxForm; data: Blob }) => {
      notify("success", "Tax form downloaded.");

      downloadBlob(
        `${taxForm.attributes.formType}-${taxForm.attributes.taxYear}.pdf`,
        window.URL.createObjectURL(data)
      );
    },
  });
};

export default useDownloadTaxFormPdfMutation;
