import { useQuery } from "@tanstack/react-query";
import emptyImg from "assets/empty-state.svg";
import useUnitCoRecurringPaymentsQueryOptions from "resources/unit-co-recurring-payments/queries/useUnitCoRecurringPaymentsQueryOptions";
import PageIndicator from "ui/navigation/PageIndicator";
import EmptyState from "ui/table/EmptyState";
import useSearchParamNumber from "utils/search-params/useSearchParamNumber";

import styles from "./RecurringPayments.module.scss";
import RecurringPaymentsTable from "./RecurringPaymentsTable";

const PAGE_SIZE = 100;

const RecurringPayments = () => {
  const [page, setPage] = useSearchParamNumber("page", 1);

  const { data, isLoading } = useQuery(
    useUnitCoRecurringPaymentsQueryOptions({
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (page - 1),
    })
  );

  return (
    <div className={styles.container}>
      {isLoading || (data?.recurringPayments && data.recurringPayments.length > 0) ? (
        <>
          <RecurringPaymentsTable
            isLoading={isLoading}
            recurringPayments={data?.recurringPayments || []}
          />
          {data?.pagination && data.pagination.total > PAGE_SIZE && (
            <PageIndicator
              currentPage={page - 1}
              setCurrentPage={(page) => setPage(page + 1)}
              totalPages={Math.ceil(data.pagination.total / 3)}
            />
          )}
        </>
      ) : (
        <EmptyState image={emptyImg} primaryText="No scheduled payments" />
      )}
    </div>
  );
};

export default RecurringPayments;
