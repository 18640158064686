import { captureException } from "@sentry/react";
import { useIsMutating, useMutation } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import { COUNTERPARTY_REPORT_QUERY_KEY } from "../queries/useCounterpartyReportQueryOptions";

export const UPDATE_COUNTERPARTY_CATEGORY_MUTATION_KEY = "update-counterparty-category";

type Variables = {
  category: string;
};

type Params = {
  counterparty: string;
};

const useUpdateCounterpartyCategoryMutation = ({ counterparty }: Params) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const refreshCounterpartyReportQuery = useRefreshQuery([COUNTERPARTY_REPORT_QUERY_KEY]);

  return useMutation({
    // Exemplar: When to use `mutationKey` - You can globally fetch a mutation's loading state by its `mutationKey`.
    mutationKey: [UPDATE_COUNTERPARTY_CATEGORY_MUTATION_KEY, counterparty],
    mutationFn: async ({ category }: Variables) => {
      return highbeamApi.transactionCategorizationMatcher.set({
        businessGuid: businessGuid,
        category: category,
        counterparty: counterparty,
      });
    },
    onError: (error) => {
      captureException(error);
      notify(
        "error",
        "There was an issue updating the category. Please try again or contact support."
      );
    },
    onSuccess: async () => {
      await refreshCounterpartyReportQuery();
      notify("success", "Category updated");
    },
  });
};

export default useUpdateCounterpartyCategoryMutation;

// NB(alex): This is the first time we've needed to use a mutation's key to get its loading state in a different place. If we end up needed this more, we should create a hook like `useRefreshQuery` for it.
export const useIsUpdateCounterpartyCategoryMutationPending = () => {
  return useIsMutating({ mutationKey: [UPDATE_COUNTERPARTY_CATEGORY_MUTATION_KEY] });
};
