import { useCallback } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";

type EditUserRoleModalClosedState = {
  isOpen: false;
};

export type EditUserRoleModalParams = {
  userGuid: string;
  initials: string;
  fullName: string;
  userRoleName: string;
  userRoleGuid: string;
};

type EditUserRoleModalOpenState = EditUserRoleModalParams & {
  isOpen: true;
};

type EditUserRoleModalState = EditUserRoleModalClosedState | EditUserRoleModalOpenState;

const editUserRoleModalState = atom<EditUserRoleModalState>({
  key: "settings/editUserRoleModalState",
  default: { isOpen: false },
});

export default editUserRoleModalState;

export const useEditUserRoleModalState = () => {
  return useRecoilValue(editUserRoleModalState);
};

export const useEditUserRoleModalOpenState = () => {
  const state = useEditUserRoleModalState();

  if (!state.isOpen) {
    throw new Error("Attempted to access modal open state while modal is closed.");
  }

  return state;
};

export const useCloseEditUserRoleModal = () => {
  const setModalState = useSetRecoilState(editUserRoleModalState);
  return useCallback(() => setModalState({ isOpen: false }), [setModalState]);
};

export const useOpenEditUserRoleModal = () => {
  const setModalState = useSetRecoilState(editUserRoleModalState);
  return useCallback(
    (params: EditUserRoleModalParams) =>
      setModalState({
        isOpen: true,
        ...params,
      }),
    [setModalState]
  );
};
