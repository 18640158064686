import { FC } from "react";
import BankAccountRep from "reps/BankAccountRep";
import PlatformIcon, { BaseIconProps, PlatformName } from "ui/icons/PlatformIcon";

export type HighbeamAccountTypeName = BankAccountRep.HighbeamAccountType["name"];

const highbeamAccountTypeNameToPlatformNameMappings: {
  [key in HighbeamAccountTypeName]: PlatformName;
} = {
  DepositAccount: "highbeam",
  HighYield: "highbeam-high-yield",
};

type Props = BaseIconProps & {
  highbeamTypeName: HighbeamAccountTypeName;
};

const HighbeamAccountIcon: FC<Props> = ({ highbeamTypeName, ...baseIconProps }) => {
  return (
    <PlatformIcon
      platformName={highbeamAccountTypeNameToPlatformNameMappings[highbeamTypeName]}
      {...baseIconProps}
    />
  );
};

export default HighbeamAccountIcon;
