import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import { useCardAuthorizationFlexpaneContext } from "../context/CardAuthorizationFlexpaneProvider";

const useCardAuthorization = () => {
  const { authorizationId } = useCardAuthorizationFlexpaneContext();

  const unitApi = useUnitApi();

  const { data: authorization } = useSuspenseQuery({
    queryKey: ["unit-co-authorization", { authorizationId }],
    queryFn: async () => {
      const response = await unitApi.authorizations.get(authorizationId, true);
      return response.data;
    },
  });

  if (!authorization) {
    throw new RequiredButNotFoundError();
  }

  return authorization;
};

export default useCardAuthorization;
