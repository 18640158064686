import DotsLoader from "ui/feedback/DotsLoader";

import styles from "./SpendByVendorTableLoader.module.scss";

const SpendByVendorTableLoader = () => (
  <div className={styles.container}>
    <DotsLoader dots={5} />
  </div>
);

export default SpendByVendorTableLoader;
