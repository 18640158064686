import chargeCardCalendar from "assets/charge-card-calendar.svg";
import { FC, useState } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import BankAccountsDropdown from "resources/bank-accounts/components/BankAccountsDropdown";
import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import useEditCapitalAccountAutoPayAccountMutation from "resources/capital-accounts/mutations/useEditCapitalAccountAutoPayAccountMutation";
import chargeCardAccountQueryHooks from "resources/charge-cards/queries/chargeCardAccountQueryHooks";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Helper from "ui/inputs/Helper";
import ModalV4 from "ui/overlay/ModalV4";
import { Heading4, Paragraph } from "ui/typography";

type EditCapitalAccountAutoPayAccountModalContentProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const EditCapitalAccountAutoPayAccountModalContent: FC<
  EditCapitalAccountAutoPayAccountModalContentProps
> = ({ capitalAccountSummary }) => {
  const chargeCardAccount = chargeCardAccountQueryHooks.useDataRequired({
    capitalAccountGuid: capitalAccountSummary.guid,
  });

  const openBankAccounts = bankAccountsQueryHooks.useData({
    status: "open",
  });

  const [selectedAccount, setSelectedAccount] = useState(
    openBankAccounts.find((option) => {
      return option.guid === chargeCardAccount.repaymentBankAccountGuid;
    }) ?? openBankAccounts[0]
  );
  const { mutateAsync: updateRepaymentAccount, isPending } =
    useEditCapitalAccountAutoPayAccountMutation(capitalAccountSummary.guid);

  const chargeCardBalance =
    0 - (capitalAccountSummary.cardBalance + capitalAccountSummary.cardPending);

  return (
    <ModalV4.Form
      onSubmit={async (_e, { closeModalWithAnimation }) => {
        await updateRepaymentAccount({
          chargeCardAccountGuid: chargeCardAccount.guid,
          repaymentAccountGuid: selectedAccount.guid,
        });
        closeModalWithAnimation();
      }}
    >
      <ModalV4.Header>Change auto-pay account</ModalV4.Header>

      <ModalV4.Body>
        <div className="flex flex-col gap-y-6">
          <div>
            <img src={chargeCardCalendar} alt="" className="w-min shrink-0" />

            <Heading4 className="text-xs font-regular">Current card balance</Heading4>
            <MoneyAmount className="font-medium" cents={chargeCardBalance} withCents />
          </div>

          <Paragraph className="text-sm font-regular text-grey-500">
            Highbeam will auto debit from this account on each repayment date. If the account
            balance is insufficient, we’ll draw down from your line of credit to cover the full
            amount.
          </Paragraph>

          <div>
            <BankAccountsDropdown
              label="Auto-pay account"
              value={selectedAccount}
              onChange={(option) => {
                if (option) {
                  setSelectedAccount(option);
                }
              }}
              options={openBankAccounts}
            />

            {selectedAccount.availableBalance < chargeCardBalance ? (
              <Helper iconVariant="warning">
                Account balance of{" "}
                <MoneyAmount cents={selectedAccount.availableBalance} withCents /> is less than the
                current card balance.
              </Helper>
            ) : (
              <Helper>
                Account balance: <MoneyAmount cents={selectedAccount.availableBalance} withCents />
              </Helper>
            )}
          </div>
        </div>
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton
          variant="secondary"
          disabled={selectedAccount.guid === chargeCardAccount.repaymentBankAccountGuid}
          isLoading={isPending}
        >
          Save changes
        </ModalV4.SubmitButton>

        <ModalV4.CloseButton variant="ghost">Cancel</ModalV4.CloseButton>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  onClose: () => void;
};

const EditCapitalAccountAutoPayAccountModal: FC<Props> = ({ capitalAccountSummary, onClose }) => {
  return (
    <ModalV4 onClose={onClose} dropdownOverflowHack>
      <EditCapitalAccountAutoPayAccountModalContent capitalAccountSummary={capitalAccountSummary} />
    </ModalV4>
  );
};

export default EditCapitalAccountAutoPayAccountModal;
