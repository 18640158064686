import { Link } from "react-router-dom";
import CapitalAccountCard, {
  EmptyCapitalAccountCard,
} from "resources/capital-accounts/components/CapitalAccountCard";
import useNonTerminatedCapitalAccounts from "resources/capital-accounts/hooks/useNonTerminatedCapitalAccounts";

const CapitalAccountsList = () => {
  const nonTerminatedCapitalAccounts = useNonTerminatedCapitalAccounts();

  return (
    <div className="grid gap-4 @3xl:grid-cols-2 @6xl:grid-cols-3">
      {nonTerminatedCapitalAccounts.map((capitalAccount) => (
        <Link key={capitalAccount.guid} to={`/capital/${capitalAccount.guid}`}>
          <CapitalAccountCard capitalAccountGuid={capitalAccount.guid} />
        </Link>
      ))}

      <EmptyCapitalAccountCard />
    </div>
  );
};

export default CapitalAccountsList;
