// HACK(alex): Uses the existing Table component to hack a loading state, but we should figure out a better way to do this.

import Table from "./Table";

type Props = {
  columns: { title: string }[];
  isLoadingRows?: number;
};

const Loading: React.FC<Props> = ({ columns, isLoadingRows = 10 }) => (
  <Table isLoading rowKey={(d) => d} data={[]} columns={columns} isLoadingRows={isLoadingRows} />
);

export default Loading;
