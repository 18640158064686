import { zodResolver } from "@hookform/resolvers/zod";
import { Dayjs, isDayjs } from "dayjs";
import { useAccountLimitInfoRetriever } from "modules/account-limits/queries/useAccountLimitInfo";
import makeAccountLimitWarnings from "modules/account-limits/utils/makeAccountLimitWarnings";
import { InternationalWireLocalPurposeOption } from "pages/SendMoneyPage/internationalWires";
import { MIN_WIRE_TRANSFER_AMOUNT_IN_CENTS } from "pages/SendMoneyPage/utils";
import { useForm } from "react-hook-form";
import BankAccountRep from "reps/BankAccountRep";
import { BillPaymentMethod } from "resources/bills/queries/useBillPaymentMethods";
import { ReadyForPaymentBill } from "resources/bills/queries/useReadyForPaymentBill";
import isBillPaymentMethod from "resources/bills/utils/isBillPaymentMethod";
import currenciesWithRequiredPurposeCode from "resources/international-wires/constants/currenciesWithRequiredPurposeCode";
import { SetNonNullable } from "type-fest";
import { roundEpsilon } from "utils/math";
import { getCentsFromDollars } from "utils/money";
import getCurrencyDecimalPlaces from "utils/money/getCurrencyDecimalPlaces";
import shouldExitSuperRefineIfValuesHaveAbortedStatus from "utils/zod/shouldExitSuperRefineIfValuesHaveAbortedStatus";
import { z } from "zod";

// NB(lev): We apply an error state on the `sendAmount` field when the user has exceeded their relevant account limits,
// to prevent schema validation. However, we don't want to display the error message below the field, because we
// display limit warnings separately below the field. This message is essentially a sentinel value that we use to
// suppress displaying the error message in the form component.
export const SEND_AMOUNT_HIDDEN_ERROR_MESSAGE = "~~~hidden~~~";

type SchemaParams = {
  bill: ReadyForPaymentBill;
  retrieveAccountLimitInfo: ReturnType<typeof useAccountLimitInfoRetriever>;
};

const billPaymentPayloadFormSchema = (schemaParams: SchemaParams) => {
  const { bill } = schemaParams;
  const billCurrency = bill.amount.currency;
  const isBillAmountInUsd = billCurrency === "USD";

  return z
    .object({
      sendAmount: z
        .string({
          required_error: "Please enter an amount.", // eslint-disable-line camelcase
        })
        .refine((value) => {
          const isGreaterThanZero = getCentsFromDollars(value) > 0;
          return isGreaterThanZero;
        }, "Please enter an amount.")
        .refine((value) => {
          // Handle field validation in `receiveMoney`
          if (!isBillAmountInUsd) return true;

          const remainingAmountInCents = getCentsFromDollars(bill.remainingAmount.amount);
          const sendAmountInCents = getCentsFromDollars(value);
          const isLteRemainingAmount = sendAmountInCents <= remainingAmountInCents;
          return isLteRemainingAmount;
        }, "Please enter a value less than the remaining amount of your bill."),
      receiveAmount: z.string().refine((value) => {
        if (isBillAmountInUsd) return true;

        const currencyDecimalPlaces = getCurrencyDecimalPlaces(bill.remainingAmount.currency);
        const billRemainingAmount = roundEpsilon(
          Number(bill.remainingAmount.amount) * Math.pow(10, currencyDecimalPlaces),
          0
        );
        const billReceiveInputAmount = roundEpsilon(
          Number(value) * Math.pow(10, currencyDecimalPlaces),
          0
        );
        const isLteRemainingAmount = billReceiveInputAmount <= billRemainingAmount;
        return isLteRemainingAmount;
      }, "Please enter a value less than the remaining amount of your bill."),
      purposeCode: z.custom<InternationalWireLocalPurposeOption | null>().refine((value) => {
        const billCurrencyRequiresPurposeCodeButNoValueProvided =
          currenciesWithRequiredPurposeCode.includes(billCurrency) && !value;

        return !billCurrencyRequiresPurposeCodeButNoValueProvided;
      }, "Please select a purpose code."),
      billPaymentMethod: z
        .custom<BillPaymentMethod | null>((data) => data === null || isBillPaymentMethod(data))
        // We need the output to be a non-nullable `BillPaymentMethod.payeePaymentMethod`.
        .transform<SetNonNullable<BillPaymentMethod, "payeePaymentMethod">>((value, ctx) => {
          if (!value) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Please select a payment method.",
            });
            return z.NEVER;
          }
          if (!value.payeePaymentMethod) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Payment method is missing.", // TODO(alex): I don't think we want to show an error for this because we prompt them to enter details.
            });
            return z.NEVER;
          }
          // NB(alex): Not sure why this type-cast is necessary. I'm like 99% sure this is a typescript bug.
          return value as SetNonNullable<BillPaymentMethod, "payeePaymentMethod">;
        }),
      paymentDate: z
        .custom<Dayjs | null>((value) => value === null || isDayjs(value))
        .transform<Dayjs>((value, ctx) => {
          if (!isDayjs(value)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Please select a payment date.",
            });
            return z.NEVER;
          }
          return value;
        }),
      fromBankAccount: z
        .custom<BankAccountRep.Complete | null>((value) => {
          return value === null || (typeof value === "object" && value !== null); // Would be nice to have an actual `isBankAccount` type-guard.
        })
        .transform<BankAccountRep.Complete>((value, ctx) => {
          if (!value) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Please select a bank account.",
            });
            return z.NEVER;
          }
          return value;
        }),
      paymentDescription: z.string(),
      sendConfirmationEmailToPayee: z.boolean(),
      payeeEmail: z.string().email("Please enter a valid email.").or(z.literal("").nullable()),
    })
    .superRefine(async (values, ctx) => {
      if (shouldExitSuperRefineIfValuesHaveAbortedStatus(values)) {
        return z.NEVER;
      }

      const { retrieveAccountLimitInfo } = schemaParams;
      const { billPaymentMethod, sendAmount, fromBankAccount, receiveAmount } = values;

      const paymentMethodMethod = billPaymentMethod.payeePaymentMethod.method;
      const sendAmountInCents = getCentsFromDollars(sendAmount);

      const accountLimitInfo = await retrieveAccountLimitInfo(
        fromBankAccount.unitCoDepositAccountId
      );
      const accountLimitWarnings = makeAccountLimitWarnings(accountLimitInfo, sendAmountInCents);

      if (paymentMethodMethod === "ach") {
        if (
          accountLimitWarnings.achDailyCreditWarning === "exceeded" ||
          accountLimitWarnings.achMonthlyCreditWarning === "exceeded"
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["sendAmount"],
            message: SEND_AMOUNT_HIDDEN_ERROR_MESSAGE,
          });
          return z.NEVER;
        }
      } else if (paymentMethodMethod === "domestic-wire") {
        // Validate wire amount is large enough and recommend ACH if not.
        if (sendAmountInCents < 100_00) {
          ctx.addIssue({
            code: z.ZodIssueCode.too_small,
            inclusive: true,
            minimum: MIN_WIRE_TRANSFER_AMOUNT_IN_CENTS,
            type: "number",
            message: `You may only send wires for payments of at least $${
              MIN_WIRE_TRANSFER_AMOUNT_IN_CENTS / 100
            }. Please select ACH as your payment method instead.`,
            path: ["paymentMethod"],
          });
          return z.NEVER;
        }
        if (
          accountLimitWarnings.wireDailyTransferWarning === "exceeded" ||
          accountLimitWarnings.wireMonthlyTransferWarning === "exceeded"
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["sendAmount"],
            message: SEND_AMOUNT_HIDDEN_ERROR_MESSAGE,
          });
          return z.NEVER;
        }
      } else if (paymentMethodMethod === "international-wire") {
        const selectedPaymentMethodCurrency = billPaymentMethod.payeePaymentMethod.currency;
        if (selectedPaymentMethodCurrency !== "USD") {
          const isGreaterThanZero = getCentsFromDollars(receiveAmount) > 0;
          if (!isGreaterThanZero) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["receiveAmount"],
              message: "Please enter an amount.",
            });
            return z.NEVER;
          }
        }
      }

      // Validate `payeeEmail` is defined when toggle is enabled.
      if (values.sendConfirmationEmailToPayee && !values.payeeEmail) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please enter a valid email.",
          path: ["payeeEmail"],
        });
      }
    });
};

export type BillPaymentPayloadFormInputs = z.input<ReturnType<typeof billPaymentPayloadFormSchema>>;

export type BillPaymentPayloadFormTransformedOutputs = z.output<
  ReturnType<typeof billPaymentPayloadFormSchema>
>;

type UseBillPaymentPayloadFormParams = {
  defaultValues: BillPaymentPayloadFormInputs;
  bill: ReadyForPaymentBill;
};

const useBillPaymentPayloadForm = ({
  defaultValues,
  bill,
  ...params
}: UseBillPaymentPayloadFormParams) => {
  const retrieveAccountLimitInfo = useAccountLimitInfoRetriever();

  return useForm<
    BillPaymentPayloadFormInputs,
    object,
    BillPaymentPayloadFormTransformedOutputs // Exemplar: Transforming form inputs to a different type. See example here https://github.com/react-hook-form/resolvers/issues/416#issuecomment-2051680714
  >({
    resolver: zodResolver(billPaymentPayloadFormSchema({ bill, retrieveAccountLimitInfo })),
    defaultValues: defaultValues,
    ...params,
  });
};

export default useBillPaymentPayloadForm;
