import { useSuspenseQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Controller } from "react-hook-form";
import useUserRolesQueryOptions from "resources/user-roles/queries/useUserRolesQueryOptions";
import sortUserRoles from "resources/user-roles/utils/sortUserRoles";
import RadioCard from "ui/inputs/RadioCard";
import RadioCardSelect from "ui/inputs/RadioCardSelect";

import { UserRoleOptionDescription, UserRoleOptionIcon } from "../InviteUserModal/constants";

import { useEditUserRoleFormContext } from "./hooks/useEditUserRoleForm";
import { useEditUserRoleModalOpenState } from "./state/editUserRoleModalState";

const EditUserRoleSelect = () => {
  const { control } = useEditUserRoleFormContext();
  const { data: userRoles } = useSuspenseQuery(useUserRolesQueryOptions());
  const { userRoleGuid } = useEditUserRoleModalOpenState();
  const sortedUserRoles = useMemo(() => sortUserRoles(userRoles), [userRoles]);

  return (
    <Controller
      name="userRole"
      control={control}
      render={({
        field: {
          ref: _ref, // `RadioCardSelect` does not support `ref` yet so we need to omit it.
          ...field
        },
      }) => (
        <RadioCardSelect options={sortedUserRoles} value={field.value} onChange={field.onChange}>
          {({ option: role, isSelected, onSelect }) => {
            const isCurrentRole = role.guid === userRoleGuid;

            return (
              <RadioCard
                icon={<UserRoleOptionIcon type={role.type} />}
                checked={isSelected}
                value={role.guid}
                label={isCurrentRole ? `${role.name} (Current role)` : role.name}
                disabled={isCurrentRole}
                description={<UserRoleOptionDescription type={role.type} />}
                onChange={() => onSelect(role)}
              />
            );
          }}
        </RadioCardSelect>
      )}
    />
  );
};

export default EditUserRoleSelect;
