import { useSuspenseQueries } from "@tanstack/react-query";
import BusinessMemberRep from "reps/BusinessMemberRep";
import UserRoleRep from "reps/UserRoleRep";
import businessMembersQueryHooks from "resources/business-members/queries/businessMembersQueryHooks";
import { useBusinessQueryOptions } from "resources/business/queries/useBusiness";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import useUserRoleMembershipsQueryOptions from "resources/user-roles/queries/useUserRoleMembershipsQueryOptions";
import useUserRolesQueryOptions from "resources/user-roles/queries/useUserRolesQueryOptions";
import getUserRoleNameForRoleGuids from "resources/user-roles/utils/getUserRoleNameForRoleGuids";

export type ApproverSelectOption = BusinessMemberRep.Complete & {
  userRoleGuids: string[];
  isCurrentUser: boolean;
  isOrganizationOwner: boolean;
  userRoleName: string;
  userRoleType: UserRoleRep.UserRoleType;
};

// Implementation for combining user role membership info with business members
// is based on `pages/settings/SettoingsUsersPage/hooks/useUsersTableData.ts`.
const useApproverSelectOptions = (): ApproverSelectOption[] => {
  // Exemplar: run multiple suspense queries in parallel with `useSuspenseQueries`.
  const [
    { data: business },
    { data: businessMembers },
    { data: userRoleMemberships },
    { data: userRoles },
  ] = useSuspenseQueries({
    queries: [
      useBusinessQueryOptions(),
      businessMembersQueryHooks.useQueryOptions({}),
      useUserRoleMembershipsQueryOptions(),
      useUserRolesQueryOptions(),
    ],
  });
  // This query could, in principle, also be grouped with the others above.
  // We're not doing this here right now just to avoid refactoring useUserGuid,
  // which encapsulates its query options. Note also that this query is technically
  // just reading the user guid from the JWT, so it shouldn't actually involve a network
  // request by the time we're here (the JWT is already in the cache).
  const userGuid = useUserGuid();

  return businessMembers
    .map((businessMember) => {
      const memberships = userRoleMemberships
        .filter((userRoleMembership) => userRoleMembership.userGuid === businessMember.userGuid)
        .map((membershipRep) => ({
          ...membershipRep,
          userRole: userRoles.find((role) => role.guid === membershipRep.userRoleGuid)!,
        }));

      const userRoleGuids = memberships.map((membership) => membership.userRoleGuid);

      const isOrganizationOwner = businessMember.userGuid === business.ownerUserGuid;
      return {
        ...businessMember,
        userRoleGuids: userRoleGuids,
        isCurrentUser: businessMember.userGuid === userGuid,
        isOrganizationOwner: isOrganizationOwner,
        userRoleName: isOrganizationOwner
          ? "Account owner"
          : getUserRoleNameForRoleGuids(userRoleGuids, userRoles),
        // NB(lev): Derive the role type from the first user role guid (ignoring the possibility of multiple roles for now).
        // This is so we can filter out non-admins and non-bookkeepers from the list of possible approvers for now.
        userRoleType: userRoles.find((userRole) => userRole.guid === userRoleGuids[0])!.type,
      };
    })
    .filter(({ userRoleType }) => {
      // NB(lev): Filter out non-admins and non-bookkeepers from the list of possible approvers for now.
      // This is temporary until we roll out bill approvals to employees.
      return userRoleType === "Admin" || userRoleType === "Bookkeeper";
    })
    .sort((a, b) => a.displayName.localeCompare(b.displayName));
};

export default useApproverSelectOptions;
