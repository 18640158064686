import { DefaultError, QueryClient, useMutation, UseMutationOptions } from "@tanstack/react-query";
import { SetRequired } from "type-fest";

// NB(alex): Experimental. Ready to be used, but leaving comments in places I'm still unsure of.

type UseMutationWithDefaultsDefaultOptions<
  TData = unknown,
  TError = DefaultError,
  TVariables = unknown,
  TContext = unknown,
> = SetRequired<UseMutationOptions<TData, TError, TVariables, TContext>, "mutationFn">;

export type MutationAdditionalOptions<
  TData = unknown,
  TVariables = unknown, // Intentionally putting this before TError because we want custom variables more often than custom errors.
  TError = DefaultError,
  TContext = unknown,
> = Omit<UseMutationOptions<TData, TError, TVariables, TContext>, "mutationFn">;

const useMutationWithDefaults = <
  TData = unknown,
  TError = DefaultError,
  TVariables = unknown,
  TContext = unknown,
>(
  defaultOptions: UseMutationWithDefaultsDefaultOptions<TData, TError, TVariables, TContext>,
  additionalOptions: MutationAdditionalOptions<TData, TVariables, TError, TContext>,
  queryClient?: QueryClient
) => {
  return useMutation<TData, TError, TVariables, TContext>(
    {
      ...defaultOptions,
      ...additionalOptions,

      // NB(alex): Do we want to let `additionalOptions` overwrite or receive the result of `defaultOptions.mutationFn`?
      mutationFn: defaultOptions.mutationFn,

      // NB(alex): ts error gets thrown if we return an array. I wonder what the cleanest way to handle this is?
      onMutate: async (...args) => {
        const [defaultResult, additionalResult] = await Promise.all([
          defaultOptions.onMutate?.(...args),
          additionalOptions?.onMutate?.(...args),
        ]);
        return defaultResult || additionalResult;
      },

      // Would it make sense to pass the result of `defaultOptions.onSuccess` to `additionalOptions.onSuccess`?
      onSuccess: (...args) => {
        return Promise.all([
          defaultOptions.onSuccess?.(...args),
          additionalOptions?.onSuccess?.(...args),
        ]);
      },

      // Same here... would it make sense to pass the result to `additionalOptions.onError`?
      onError: (...args) => {
        return Promise.all([
          defaultOptions.onError?.(...args),
          additionalOptions?.onError?.(...args),
        ]);
      },

      // Ditto.
      onSettled: (...args) => {
        return Promise.all([
          defaultOptions.onSettled?.(...args),
          additionalOptions?.onSettled?.(...args),
        ]);
      },
    },
    queryClient
  );
};

export default useMutationWithDefaults;
