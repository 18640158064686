import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import colors from "styles/colors";
import StepsV3 from "ui/navigation/StepsV3";
import Text from "ui/typography/Text";
import { getDollarsFromCents } from "utils/money";
import { formatNumberCompact } from "utils/numbers";

import styles from "./AutoPayScheduleSteps.module.scss";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const AutoPayScheduleSteps: FC<Props> = ({ capitalAccountSummary }) => {
  const targetRepaymentDays = capitalAccountSummary.details.targetRepaymentDays;

  return (
    <StepsV3>
      <StepsV3.Step variant="solid" state="incomplete" isLast={false}>
        <div className={styles.stepTitle}>
          <Text size={14} weight="medium">
            Day 0:
          </Text>
          <Text>
            Draw down $
            {formatNumberCompact(getDollarsFromCents(capitalAccountSummary.details.limit))}
          </Text>
        </div>
      </StepsV3.Step>
      <StepsV3.Step variant="solid" state="incomplete" isLast={false}>
        <Text size={14} weight="medium">
          Day 0 - Day {targetRepaymentDays}
        </Text>
      </StepsV3.Step>
      <StepsV3.Step variant="solid" state="success" isLast>
        <Text size={14} color={colors.green[600]} weight="medium">
          Day {targetRepaymentDays}: Line fully paid
        </Text>
      </StepsV3.Step>
    </StepsV3>
  );
};

export default AutoPayScheduleSteps;
