import { zodResolver } from "@hookform/resolvers/zod";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useForm, useFormContext } from "react-hook-form";
import UserRoleRep from "reps/UserRoleRep";
import useUserRolesQueryOptions from "resources/user-roles/queries/useUserRolesQueryOptions";
import { z } from "zod";

import { useEditUserRoleModalOpenState } from "../state/editUserRoleModalState";

const schema = z.object({
  userRole: z.custom<UserRoleRep.Complete>((value) => Boolean(value), "Please select a role."),
});

export type EditUserRoleFormInputs = z.infer<typeof schema>;

const useEditUserRoleForm = () => {
  const { userRoleGuid } = useEditUserRoleModalOpenState();
  const { data: userRoles } = useSuspenseQuery(useUserRolesQueryOptions());

  return useForm<EditUserRoleFormInputs>({
    mode: "onBlur", // Validate input on blur because button is disabled until form fields are valid.
    resolver: zodResolver(schema),
    defaultValues: {
      userRole: userRoles.find(({ guid }) => guid === userRoleGuid),
    },
  });
};

export default useEditUserRoleForm;

export const useEditUserRoleFormContext = () => {
  return useFormContext<EditUserRoleFormInputs>();
};
