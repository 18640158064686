import { FC, ReactElement, ReactNode } from "react";
import CountBadge from "ui/data-display/CountBadge";
import cn from "utils/tailwind/cn";
import variants from "utils/tailwind/variants";

export type SegmentControlsButtonSize = "sm" | "md";

type Props = {
  children: ReactNode;
  className?: string;
  count?: number;
  displayAttentionBadge?: boolean;
  icon?: ReactElement<{ size?: number }>;
  isActive?: boolean;
  onClick?: () => void;
  size: SegmentControlsButtonSize;
};

const SegmentControlsButton: FC<Props> = ({
  children,
  className,
  count,
  displayAttentionBadge,
  icon,
  isActive,
  size,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        "flex flex-1 items-center justify-center gap-x-2 whitespace-nowrap rounded-full bg-transparent",
        isActive && "bg-white font-bold shadow-xs",
        variants(size, {
          sm: "h-6 px-2 text-xs",
          md: "h-7 px-4 text-sm",
        }),
        className
      )}
      aria-selected={isActive}
      role="tab"
      type="button"
    >
      {icon && (
        <span className={"flex size-4 items-center justify-center [&>svg]:size-4"}>{icon}</span>
      )}

      {children}

      {count !== undefined && !displayAttentionBadge && (
        <CountBadge count={count} backgroundColor={isActive ? "grey-700" : "grey-500"} />
      )}

      {displayAttentionBadge && (
        <span aria-hidden="true" className="block size-1 rounded-full bg-purple-400" />
      )}
    </button>
  );
};

export default SegmentControlsButton;
