import visaLogoBlue from "assets/visa-logo-blue.svg";
import visaLogoWhite from "assets/visa-logo-white.svg";

import styles from "./HighbeamCard.module.scss";

type Props = {
  isPhysicalCard: boolean;
};

const VisaLogo: React.FC<Props> = ({ isPhysicalCard }) => (
  <img
    className={styles.cardProviderLogo}
    src={isPhysicalCard ? visaLogoWhite : visaLogoBlue}
    alt="Logo"
  />
);

export default VisaLogo;
