import { ArrowsClockwise, Check } from "@phosphor-icons/react";
import { FC, ReactNode } from "react";
import Card from "ui/data-display/Card";
import Button from "ui/inputs/Button";
import ItemWithTooltip from "ui/overlay/Tooltip/ItemWithTooltip/ItemWithTooltip";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import { Heading4, Paragraph } from "ui/typography";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useIsAllowedToConnectStores from "utils/permissions/useIsAllowedToConnectStores";

// NB(alex): This can probably be consolidated with `AddIntegration`.

type Props = {
  name: string;
  hasSynced?: boolean;
  logo?: ReactNode;
  onDisconnect?: () => void;
};

const RutterConnectionCard: FC<Props> = ({ name, hasSynced, logo, onDisconnect }) => {
  const isAllowedToConnectStores = useIsAllowedToConnectStores();
  const canDisconnectStoreInSettings = useFeatureFlag("ALLOW_STORE_DISCONNECTION_IN_SETTINGS");
  const disabledDisconnect = !canDisconnectStoreInSettings || !isAllowedToConnectStores;

  return (
    <Card
      className="flex min-h-40 w-full min-w-full flex-col items-start justify-between border border-grey-200 p-5 tablet:min-w-80"
      shadow="xs"
    >
      <div>
        <div className="mb-3 flex items-center gap-x-2">
          {logo}
          <Heading4>{name}</Heading4>
        </div>

        <Paragraph className="mt-2 flex items-center gap-x-2 text-grey-600">
          {hasSynced ? (
            <>
              <Check size={16} className="bg-green-400 p-0.5" color="white" />
              <Text size={14} as="p">
                Connected
              </Text>
            </>
          ) : (
            <ItemWithTooltip
              wrapperClassName="flex items-center gap-x-2"
              content={
                <Tooltip.Content>
                  Sync can take from an average of 24h up to a week.
                </Tooltip.Content>
              }
            >
              <ArrowsClockwise size={16} className="bg-white" />
              <Text size={14}>Syncing store data</Text>
            </ItemWithTooltip>
          )}
        </Paragraph>
      </div>

      {onDisconnect && (
        <Button
          className="w-full"
          variant={disabledDisconnect ? "danger" : "tertiary"}
          onClick={onDisconnect}
          disabled={disabledDisconnect}
          tooltip={
            disabledDisconnect && (
              <DisconnectStoreTooltip
                title={name}
                isAllowedToConnectStore={isAllowedToConnectStores}
              />
            )
          }
        >
          Disconnect
        </Button>
      )}
    </Card>
  );
};

const DisconnectStoreTooltip: FC<{
  title: string;
  isAllowedToConnectStore: boolean;
}> = ({ title, isAllowedToConnectStore }) => (
  <Tooltip.Content>
    {!isAllowedToConnectStore ? (
      <>You don’t have permission to disconnect stores.</>
    ) : (
      <>If you want to disconnect {title}, please reach out to Highbeam support.</>
    )}
  </Tooltip.Content>
);

export default RutterConnectionCard;
