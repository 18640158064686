import Invoice from "components/Invoice";
import { FC } from "react";
import LargeDrawdownBanner from "resources/capital-drawdown-approvals/components/LargeDrawdownBanner";
import { useCapitalDrawdownApprovalQuery } from "resources/capital-drawdown-approvals/queries/useCapitalDrawdownApproval";
import InfoBox from "ui/data-display/InfoBox";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Flexpane from "ui/overlay/Flexpane";
import { Heading3 } from "ui/typography";

import PendingDrawdownParties from "./PendingDrawdownParties";

type PendingDrawdownFlexpaneContentProps = {
  capitalAccountGuid: string;
  pendingDrawdownGuid: string;
  onClose: () => void;
};

const PendingDrawdownFlexpaneContent: FC<PendingDrawdownFlexpaneContentProps> = ({
  capitalAccountGuid,
  pendingDrawdownGuid,
  onClose,
}) => {
  const { data: pendingDrawdown } = useCapitalDrawdownApprovalQuery({
    capitalAccountGuid,
    pendingDrawdownGuid: pendingDrawdownGuid,
  });

  return (
    <>
      <Flexpane.Header onClose={onClose}>Drawdown info</Flexpane.Header>

      <Flexpane.Section>
        <PendingDrawdownParties
          capitalAccountGuid={capitalAccountGuid}
          pendingDrawdownGuid={pendingDrawdownGuid}
        />
      </Flexpane.Section>

      <Flexpane.Section>
        {pendingDrawdown ? (
          <MoneyAmount
            cents={pendingDrawdown.amount}
            withCents={pendingDrawdown.amount % 100 !== 0}
            direction="negative"
            weight="medium"
            size={20}
          />
        ) : (
          <Flexpane.Section.Shimmer />
        )}
      </Flexpane.Section>

      <Flexpane.Section>
        {pendingDrawdown ? (
          <>
            {pendingDrawdown.note ? (
              <InfoBox
                variant="info"
                iconSize={24}
                text={
                  <>
                    This drawdown is under review by Highbeam to verify that the drawdown is for
                    inventory spend. <strong>Review can take up to 3 business days.</strong>
                  </>
                }
              />
            ) : (
              <LargeDrawdownBanner />
            )}
          </>
        ) : (
          <Flexpane.Section.Shimmer />
        )}
      </Flexpane.Section>

      {pendingDrawdown && pendingDrawdown.note && (
        <Flexpane.Section>
          <div className="flex flex-col gap-y-4">
            <Heading3>Invoice</Heading3>
            <Invoice invoiceUrl={pendingDrawdown.attachmentUrl} />
          </div>
        </Flexpane.Section>
      )}
    </>
  );
};

type Props = {
  capitalAccountGuid: string;
  pendingDrawdownGuid?: string;
  onClose: () => void;
};

const PendingDrawdownFlexpane: FC<Props> = ({
  capitalAccountGuid,
  pendingDrawdownGuid,
  onClose,
}) => {
  const isOpen = Boolean(pendingDrawdownGuid);

  return (
    <Flexpane isOpen={isOpen} onClose={onClose}>
      {pendingDrawdownGuid && (
        <PendingDrawdownFlexpaneContent
          capitalAccountGuid={capitalAccountGuid}
          pendingDrawdownGuid={pendingDrawdownGuid}
          onClose={onClose}
        />
      )}
    </Flexpane>
  );
};

export default PendingDrawdownFlexpane;
