import { ErrorBoundary } from "@sentry/react";
import IconWithTooltip from "components/common/IconWithTooltip";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { SwiftFeeTooltipContent } from "resources/international-wires/constants";
import { isLocalPaymentState } from "state/payments/international/isLocalPayment";
import { quoteCurrencyState } from "state/payments/international/quoteCurrency";
import quotesState from "state/payments/international/quotes";
import MetadataList from "ui/data-display/MetadataList";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";

import ExchangeRateInfoIcon from "../ExchangeRateInfoIcon";

import styles from "./PaymentQuote.module.scss";

const LocalPaymentQuote = () => {
  const internationalQuoteCurrencyOption = useRecoilValue(quoteCurrencyState);
  const quote = useRecoilValue(quotesState(internationalQuoteCurrencyOption.value));

  return (
    <MetadataList className={styles.list}>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Exchange rate</MetadataList.ItemLabel>
        <div className={styles.rate}>
          <MetadataList.ItemValue>
            1 USD = {quote.rate.toFixed(5) + ` ${internationalQuoteCurrencyOption.value}`}
          </MetadataList.ItemValue>
          <ExchangeRateInfoIcon color="dark" wrapperClassName={styles.tooltip} />
        </div>
      </MetadataList.Item>
    </MetadataList>
  );
};

const UsdToUsdSwiftFee = () => {
  return (
    <MetadataList className={styles.list}>
      <MetadataList.Item>
        <MetadataList.ItemLabel>SWIFT fee</MetadataList.ItemLabel>
        <div className={styles.rate}>
          <MoneyAmount cents={20_00} />{" "}
          <IconWithTooltip
            color="dark"
            wrapperClassName={styles.tooltip}
            content={<SwiftFeeTooltipContent />}
          />
        </div>
      </MetadataList.Item>
    </MetadataList>
  );
};

const PaymentQuoteFallback = () => (
  <MetadataList className={styles.list}>
    <MetadataList.Item>
      <MetadataList.ItemLabel>Exchange rate</MetadataList.ItemLabel>
      <MetadataList.ItemValue>
        <AnimatedSpinner size={14} />
      </MetadataList.ItemValue>
    </MetadataList.Item>
  </MetadataList>
);

const PaymentQuote = () => {
  const isLocalPayment = useRecoilValue(isLocalPaymentState);

  return (
    <ErrorBoundary fallback={() => <></>}>
      <Suspense fallback={<PaymentQuoteFallback />}>
        {isLocalPayment ? <LocalPaymentQuote /> : <UsdToUsdSwiftFee />}
      </Suspense>
    </ErrorBoundary>
  );
};

export default PaymentQuote;
