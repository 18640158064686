import { FC } from "react";
import Button, { ButtonProps } from "ui/inputs/Button";

const NextButton: FC<ButtonProps> = ({ children = "Next", ...buttonV2Props }) => (
  <Button variant="primary" {...buttonV2Props}>
    {children}
  </Button>
);

export default NextButton;
