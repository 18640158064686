import classNames from "classnames";
import { cloneElement, ReactElement } from "react";
import colors from "styles/colors";

import styles from "./IconTile.module.scss";

export enum IconType {
  RoundedSquare,
  Circle,
}

export enum IconColor {
  Purple,
  Green,
  Orange,
  Red,
}

type Props = {
  icon: ReactElement;
  size?: number;
  iconSize?: number;
  type?: IconType;
  color?: IconColor;
  className?: string;
};

const IconTile: React.FC<Props> = ({
  icon,
  size = 44,
  iconSize = 24,
  type = IconType.RoundedSquare,
  color = IconColor.Purple,
  className,
}) => {
  const iconColor = (() => {
    switch (color) {
      case IconColor.Purple:
        return colors.purple[500];
      case IconColor.Green:
        return colors.green[500];
      case IconColor.Orange:
        return colors.orange[500];
      case IconColor.Red:
        return colors.red[500];
    }
  })();

  const customIcon: ReactElement = cloneElement(icon, {
    size: iconSize,
    color: iconColor,
  });

  const borderRadius = (() => {
    switch (type) {
      case IconType.RoundedSquare:
        return 8;
      case IconType.Circle:
        return size;
    }
  })();

  const background = (() => {
    switch (color) {
      case IconColor.Purple:
        return colors.purple[100];
      case IconColor.Green:
        return colors.green[100];
      case IconColor.Orange:
        return colors.orange[100];
      case IconColor.Red:
        return colors.red[100];
    }
  })();

  return (
    <div
      style={{ height: size, width: size, borderRadius, background }}
      className={classNames(styles.container, className)}
    >
      {customIcon}
    </div>
  );
};
export default IconTile;
