import { FC } from "react";
import UserAvatar, { UserAvatarSize } from "ui/data-display/UserAvatar";
import { Span } from "ui/typography";
import cn from "utils/tailwind/cn";
import variants from "utils/tailwind/variants";

type Props = {
  initials: string;
  fullName: string;
  userRoleName?: string;
  className?: string;
  avatarSize?: UserAvatarSize;
};

const UserAvatarBar: FC<Props> = ({
  initials,
  fullName,
  userRoleName,
  avatarSize = 44,
  className,
}) => {
  return (
    <div
      className={cn(
        "flex items-center gap-x-4",
        variants(avatarSize, {
          32: "gap-x-2.5",
          44: "gap-x-4",
        }),
        className
      )}
    >
      <UserAvatar color="purple-light" size={avatarSize} initials={initials} />

      <Span className="text-sm font-medium">{fullName}</Span>

      {userRoleName && (
        <div className="flex items-center rounded-xl bg-grey-100 px-2 py-1">
          <Span className="text-2xs text-grey-500">{userRoleName}</Span>
        </div>
      )}
    </div>
  );
};

export default UserAvatarBar;
