import { FC, ReactNode } from "react";
import SectionHeader from "ui/data-display/SectionHeader";
import IconTile from "ui/icons/IconTile";
import BackCaret from "ui/navigation/BackCaret";
import Text, { TextProps } from "ui/typography/Text";

import styles from "./Header.module.scss";

type HeaderContainerProps = {
  actions?: ReactNode;
  children: ReactNode;
};

export const HeaderContainer: FC<HeaderContainerProps> = ({ actions, children }) => {
  return (
    <div className={styles.header}>
      <SectionHeader actions={actions}>{children}</SectionHeader>
    </div>
  );
};

type HeaderTitleProps = TextProps;

export const HeaderTitle: FC<HeaderTitleProps> = ({ ...textProps }) => {
  return <Text as="h2" size={20} {...textProps} />;
};

const Header = Object.assign(HeaderContainer, {
  BackCaret: BackCaret,
  IconTile: IconTile,
  Title: HeaderTitle,
});

export default Header;
