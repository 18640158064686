import { DISABLE_SCROLL_TO_TOP_STATE } from "components/navigation/ScrollToTopOnNavigate";
import TransactionInfoFlexpane from "components/Transactions/TransactionInfoFlexpane";
import CardAuthorizationFlexpane from "dialogs/CardAuthorizationFlexpane";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import CardAuthorizations from "modules/card-authorizations-table/CardAuthorizations";
import useCardAuthorizations from "modules/card-authorizations-table/CardAuthorizations/hooks/useCardAuthorizations";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useIsAllowedToNavigateToCardTransactionsPage from "utils/permissions/navigation/useIsAllowedToNavigateToCardTransactionsPage";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import Transactions from "./Transactions";

const CardTransactionsPageContent = () => {
  const hasAuthorizations = useCardAuthorizations().pagination.total > 0;
  const { authorizationId, unitCoDepositAccountId, transactionId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirectTo = `/cards/transactions?${searchParams}`;

  return (
    <>
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => {
          // NB(alex): Necessary for resetting the error boundary after navigation takes place.
          resetErrorBoundary();

          if (error instanceof RequiredButNotFoundError) {
            return <Navigate to={redirectTo} state={DISABLE_SCROLL_TO_TOP_STATE} />;
          }

          // Trigger a different error boundary if it isn't a RequiredButNotFoundError
          throw error;
        }}
      >
        <CardAuthorizationFlexpane
          authorizationId={authorizationId}
          onClose={() => navigate(redirectTo, { state: DISABLE_SCROLL_TO_TOP_STATE })}
        />

        <TransactionInfoFlexpane
          params={
            unitCoDepositAccountId && transactionId
              ? {
                  unitCoDepositAccountId: unitCoDepositAccountId,
                  transactionId: transactionId,
                }
              : undefined
          }
          onClose={() => navigate(redirectTo, { state: DISABLE_SCROLL_TO_TOP_STATE })}
          // TODO(zafin): add refresh transactions logic
        />
      </ErrorBoundary>

      {hasAuthorizations && (
        <DashboardPage.Section>
          <DashboardPage.Section.Body>
            <CardAuthorizations />
          </DashboardPage.Section.Body>
        </DashboardPage.Section>
      )}

      <DashboardPage.Section>
        <DashboardPage.Section.Body>
          <Transactions />
        </DashboardPage.Section.Body>
      </DashboardPage.Section>
    </>
  );
};

const CardTransactionsPage = () => {
  const isAllowedToNavigateToCardTransactionsPage = useIsAllowedToNavigateToCardTransactionsPage();

  if (!isAllowedToNavigateToCardTransactionsPage) {
    return <Navigate to="/cards" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/cards">Cards</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Transactions</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <CardTransactionsPageContent />
      </DashboardPage>
    </>
  );
};

export default CardTransactionsPage;
