import { FC, Suspense, useState } from "react";
import EmbeddedDrawer from "ui/overlay/EmbeddedDrawer";

import BillLineItemsContent from "../BillLineItemsContent";
import BillLineItemsHeading from "../BillLineItemsHeading";
import BillLineItemsTotal from "../BillLineItemsTotal";

type Props = {
  billId: string;
};

const BillLineItemsDesktop: FC<Props> = ({ billId }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <Suspense fallback={null}>
      <EmbeddedDrawer
        isOpen={isDrawerOpen}
        onOpen={() => setIsDrawerOpen(true)}
        onClose={() => setIsDrawerOpen(false)}
      >
        <EmbeddedDrawer.Header>
          <BillLineItemsHeading billId={billId} />
          <div className="flex items-center gap-4">
            <BillLineItemsTotal billId={billId} />
            <EmbeddedDrawer.HeaderCaret />
          </div>
        </EmbeddedDrawer.Header>
        <EmbeddedDrawer.Body>
          <BillLineItemsContent billId={billId} />
        </EmbeddedDrawer.Body>
      </EmbeddedDrawer>
    </Suspense>
  );
};

export default BillLineItemsDesktop;
