import DashboardPage from "layouts/DashboardPage";
import useUser from "resources/user/queries/useUser";
import SectionLoading from "ui/feedback/SectionLoading";
import withSuspense from "ui/feedback/withSuspense";
import Text from "ui/typography/Text";

import AccountsOverviewPageHeaderActions from "../../components/AccountsOverviewPageHeaderActions";

import styles from "./AccountsOverviewPageHeader.module.scss";

const AccountsOverviewPageHeader = () => {
  const user = useUser();

  return (
    <DashboardPage.Header actions={<AccountsOverviewPageHeaderActions />}>
      <Text size={14} className={styles.welcome}>
        Welcome, {user.firstName || user.displayName}
      </Text>
    </DashboardPage.Header>
  );
};

export default withSuspense(AccountsOverviewPageHeader, {
  fallback: <SectionLoading />,
});
