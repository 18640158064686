import PaymentSummary from "components/PaymentSummary";
import dayjs from "dayjs";
import MultiStep from "layouts/MultiStep";
import { FC, FormEvent } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import BankAccountBar from "resources/bank-accounts/components/BankAccountBar";
import { useOpenBankAccountRequired } from "resources/bank-accounts/queries/bankAccountQueryHooks";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import useCreateChargeCardsRepaymentMutation from "resources/charge-cards/mutations/useCreateChargeCardsRepaymentMutation";
import chargeCardAccountQueryHooks from "resources/charge-cards/queries/chargeCardAccountQueryHooks";
import useChargeCardRepaymentInfo from "resources/charge-cards/queries/useChargeCardRepaymentInfo";
import MoneyAmount from "ui/data-display/money/MoneyAmount";

const REPAY_HIGHBEAM_CARDS_CONFIRMATION_FORM_ID = "repay-highbeam-cards-confirmation-form";

type RepayHighbeamCardsConfirmationViewState = {
  amount: number;
  repaymentBankAccountGuid: string;
};

type RepayHighbeamCardsConfirmationFormProps = RepayHighbeamCardsConfirmationViewState & {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const RepayHighbeamCardsConfirmationForm: FC<RepayHighbeamCardsConfirmationFormProps> = ({
  amount,
  repaymentBankAccountGuid,
  capitalAccountSummaryWithChargeCard,
}) => {
  const repaymentBankAccount = useOpenBankAccountRequired(repaymentBankAccountGuid);
  const navigate = useNavigate();

  const availableAfterRepayment = capitalAccountSummaryWithChargeCard.available + amount;
  const chargeCardAccount = chargeCardAccountQueryHooks.useDataRequired({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
  });
  const { remainingAmountDue, nextRepaymentDueDate } = useChargeCardRepaymentInfo(
    chargeCardAccount.guid,
    {
      required: true,
    }
  );

  const { mutateAsync: repayHighbeamCards, isPending } = useCreateChargeCardsRepaymentMutation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await repayHighbeamCards({
      amountInCents: amount,
      chargeCardCreditAccountGuid: chargeCardAccount.guid,
      transferFromBankAccountGuid: repaymentBankAccountGuid,
    });

    navigate(`/capital/${capitalAccountSummaryWithChargeCard.guid}`);
  };

  return (
    <MultiStep.Form id={REPAY_HIGHBEAM_CARDS_CONFIRMATION_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Confirm details</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <PaymentSummary>
          <PaymentSummary.Header>
            <PaymentSummary.Header.Title>Repayment amount</PaymentSummary.Header.Title>
            <PaymentSummary.Header.Amount cents={amount} />
          </PaymentSummary.Header>

          <PaymentSummary.SectionDivider />

          <PaymentSummary.Section>
            <PaymentSummary.Section.SummaryItem
              label="Pay from"
              value={<BankAccountBar bankAccount={repaymentBankAccount} />}
            />

            <PaymentSummary.Section.SummaryItem
              label="Capital available (current)"
              value={
                <MoneyAmount
                  size={14}
                  cents={capitalAccountSummaryWithChargeCard.available}
                  weight="medium"
                />
              }
            />

            <PaymentSummary.Section.SummaryItem
              label="Capital available after repaying"
              value={<MoneyAmount size={14} cents={availableAfterRepayment} weight="medium" />}
            />

            <PaymentSummary.SectionDivider />

            <PaymentSummary.Section.SummaryItem
              label="Settled balance (current)"
              value={
                <MoneyAmount
                  size={14}
                  cents={0 - capitalAccountSummaryWithChargeCard.cardBalance}
                  weight="medium"
                />
              }
            />

            <PaymentSummary.Section.SummaryItem
              label="Settled balance after repaying"
              value={
                <MoneyAmount
                  size={14}
                  cents={0 - capitalAccountSummaryWithChargeCard.cardBalance - amount}
                  weight="medium"
                />
              }
            />

            {Boolean(remainingAmountDue) && (
              <>
                <PaymentSummary.Section.SummaryItem
                  label={`Due ${dayjs(nextRepaymentDueDate).format("MMM D")} (current)`}
                  value={<MoneyAmount size={14} cents={remainingAmountDue} weight="medium" />}
                />

                <PaymentSummary.Section.SummaryItem
                  label={`Due ${dayjs(nextRepaymentDueDate).format("MMM D")} after repaying`}
                  value={
                    <MoneyAmount
                      size={14}
                      cents={Math.max(remainingAmountDue - amount, 0)}
                      weight="medium"
                    />
                  }
                />
              </>
            )}
          </PaymentSummary.Section>
        </PaymentSummary>
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.BackButtonLink
          to={`/capital/${capitalAccountSummaryWithChargeCard.guid}/cards/repay`}
        />

        <MultiStep.Controls.NextButton
          form={REPAY_HIGHBEAM_CARDS_CONFIRMATION_FORM_ID}
          autoFocus
          isLoading={isPending}
        >
          Complete repayment
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

const isValidRepayHighbeamCardsConfirmationViewState = (
  state: unknown
): state is RepayHighbeamCardsConfirmationViewState => {
  return (
    typeof state === "object" &&
    state !== null &&
    "amount" in state &&
    typeof state.amount === "number" &&
    "repaymentBankAccountGuid" in state &&
    typeof state.repaymentBankAccountGuid === "string"
  );
};

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const RepayHighbeamCardsConfirmationView: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  const { state } = useLocation();

  if (!isValidRepayHighbeamCardsConfirmationViewState(state)) {
    return <Navigate to="/capital" />;
  }

  return (
    <RepayHighbeamCardsConfirmationForm
      amount={state.amount}
      repaymentBankAccountGuid={state.repaymentBankAccountGuid}
      capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
    />
  );
};

export default RepayHighbeamCardsConfirmationView;
