import classNames from "classnames";
import { FC } from "react";
import Text, { TextProps } from "ui/typography/Text";

import styles from "./TextCell.module.scss";

type Props = TextProps & {
  darken?: boolean;
  overflowEllipsis?: boolean;
};

const TextCell: FC<Props> = ({ className, darken, overflowEllipsis, children, ...textProps }) => (
  <Text
    size={14}
    className={classNames(className, styles.textCell, {
      [styles.darken]: darken,
      [styles.overflowEllipsis]: overflowEllipsis,
    })}
    {...textProps}
  >
    {children}
  </Text>
);

export default TextCell;
