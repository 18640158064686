import BillRep from "reps/BillRep";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useRefreshBillsQueries from "../queries/useRefreshBillsQueries";

type Variables = {
  billId: string;
  state: BillRep.State;
};

const useUpdateBillStateMutation = (
  additionalOptions: MutationAdditionalOptions<BillRep.Complete, Variables> = {}
) => {
  const highbeamApi = useHighbeamApi();
  const refreshBills = useRefreshBillsQueries();

  return useMutationWithDefaults(
    {
      mutationFn: ({ billId, state }) => {
        return highbeamApi.billAction.updateState(billId, state);
      },
      onSuccess: async () => {
        await refreshBills();
      },
      onError: (error) => {
        notify(
          "error",
          error.message ?? "Something went wrong. Please try again or contact support."
        );
      },
    },
    additionalOptions
  );
};

export default useUpdateBillStateMutation;
