import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import BillRep from "reps/BillRep";
import BillSummaryRep from "reps/BillSummaryRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { BILLS_QUERY_KEY } from "./useRefreshBillsQueries";

const filterBillsByState = (bills: BillSummaryRep.Complete[], states: BillRep.State[]) => {
  return bills.filter(({ state }) => states.includes(state));
};

export const useBillsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [BILLS_QUERY_KEY, businessGuid],
    queryFn: () => {
      return highbeamApi.billSummary.search(businessGuid);
    },
  });
};

export const useBillsQuery = () => {
  return useQuery(useBillsQueryOptions());
};

export const useAllBillsQuery = () => {
  return useQuery({
    ...useBillsQueryOptions(),
    select: (data) =>
      filterBillsByState(data, [BillRep.State.Open, BillRep.State.Paid, BillRep.State.Closed]),
  });
};

export const useProcessingBills = () => {
  const { data } = useSuspenseQuery({
    ...useBillsQueryOptions(),
    select: (data) => filterBillsByState(data, [BillRep.State.Processing]),
  });
  return data;
};

export const useDraftBillsQuery = () => {
  return useQuery({
    ...useBillsQueryOptions(),
    select: (data) => filterBillsByState(data, [BillRep.State.Draft]),
  });
};

const useBills = () => {
  const { data } = useSuspenseQuery(useBillsQueryOptions());
  return data;
};

export default useBills;
