import ApSettingsRep from "reps/ApSettingsRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class ApSettingsApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(businessGuid: string): Promise<ApSettingsRep.Complete> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/accounts-payable/settings?${queryParams}`;
    return (await this.api.get<ApSettingsRep.Complete>(url))!;
  }
}
