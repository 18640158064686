import { CreditCard } from "@phosphor-icons/react";
import SparklingStar from "assets/sparkling-star.svg?react";
import { FC } from "react";
import CardRep from "reps/CardRep";
import colors from "styles/colors";
import Pill from "ui/data-display/Pill";

type Props = {
  cardType: CardRep.CardType;
};

const HighbeamCardTypePills: FC<Props> = ({ cardType }) => {
  switch (cardType) {
    case "PHYSICAL":
      return (
        <div className="flex items-center gap-x-1">
          <Pill
            color="custom"
            iconLeft={<CreditCard />}
            className="bg-purple-500 py-1.5 text-purple-50"
          >
            Physical
          </Pill>
          <Pill color="custom" className="bg-purple-500 py-1.5 text-purple-50">
            Debit
          </Pill>
        </div>
      );
    case "VIRTUAL":
      return (
        <div className="flex items-center gap-x-1">
          <Pill
            color="custom"
            iconLeft={<SparklingStar fill={colors.purple[500]} />}
            className="bg-purple-100 py-1.5 text-purple-500"
          >
            Virtual
          </Pill>
          <Pill color="custom" className="bg-purple-100 py-1.5 text-purple-500">
            Debit
          </Pill>
        </div>
      );
    case "PHYSICAL_CREDIT":
      return (
        <Pill color="custom" iconLeft={<CreditCard />} className="bg-grey-700 py-1.5 text-white">
          Physical
        </Pill>
      );
    case "VIRTUAL_CREDIT":
      return (
        <Pill
          color="custom"
          iconLeft={<SparklingStar />}
          className="bg-black bg-opacity-5 py-1.5 text-grey-700"
        >
          Virtual
        </Pill>
      );
  }
};

export default HighbeamCardTypePills;
