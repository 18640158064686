import { ClockCounterClockwise } from "@phosphor-icons/react";
import ButtonArray from "ui/navigation/ButtonArray";
import useIsAllowedToSyncInsightsSheet from "utils/permissions/useIsAllowedToSyncInsightsSheet";

import { useCashFlowSyncSheetModal } from "../CashFlowSyncSheetModal/state/cashFlowSyncSheetModalState";

const CashFlowSyncSheetButton = () => {
  const isAllowedToSyncInsightsSheet = useIsAllowedToSyncInsightsSheet();
  const { open: openModal } = useCashFlowSyncSheetModal();
  return (
    <ButtonArray alignment="horizontal">
      <ButtonArray.Button
        variant="secondary"
        disabled={!isAllowedToSyncInsightsSheet}
        onClick={openModal}
      >
        <ClockCounterClockwise weight="light" size={24} />
        Sync to Google sheet
      </ButtonArray.Button>
    </ButtonArray>
  );
};

export default CashFlowSyncSheetButton;
