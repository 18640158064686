import { useEffect, useRef, useState } from "react";

const useTooltipAnchor = <T extends HTMLElement>() => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const tooltipAnchorRef = useRef<T>(null);

  useEffect(() => {
    const element = tooltipAnchorRef.current;

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    if (element) {
      element.addEventListener("mouseenter", handleMouseEnter);
      element.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (element) {
        element.removeEventListener("mouseenter", handleMouseEnter);
        element.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  return { tooltipAnchorRef, isHovered };
};

export default useTooltipAnchor;
