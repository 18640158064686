import React from "react";
import VendorSummaryRep from "reps/Insights/VendorSummaryRep";
import { usePagination } from "ui/navigation/PageIndicator";
import TableV2, { ColumnProps, FetchTableData } from "ui/table/TableV2";
import { HasKey } from "ui/table/TableV2/TableWrapper";

import SpendByVendorTableLoader from "./SpendByVendorTableLoader";

type Props = {
  columns: ColumnProps<VendorSummaryRep>[];
  fetchData: FetchTableData<VendorSummaryRep & HasKey>;
};

const SpendByVendorTable: React.FC<Props> = ({ columns, fetchData }) => {
  const pagination = usePagination({ pageSize: 15 });

  return (
    <TableV2
      columns={columns}
      fetchData={fetchData}
      initialSort={{ by: "total", direction: "descending" }}
      pagination={pagination}
      renderLoader={() => <SpendByVendorTableLoader />}
    >
      <TableV2.Table
        scrollable
        style={({ length }) => (length ? { paddingBottom: computePadding(length) } : undefined)}
      >
        <TableV2.Head />
        <TableV2.Body />
      </TableV2.Table>
      <TableV2.PageIndicator />
    </TableV2>
  );
};

export default SpendByVendorTable;

/**
 * Padding below the table is necessary to give space for the dropdowns to open without being clipped.
 */
const computePadding = (length: number): string =>
  `${Math.max(4 - Math.max(length - 4, 0), 0) * 69}px`;
