import { zodResolver } from "@hookform/resolvers/zod";
import * as LabelPrimitive from "@radix-ui/react-label";
import env from "env";
import { FC, useId, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import BusinessRep from "reps/BusinessRep";
import useCreateApEmailAliasMutation from "resources/ap-email-aliases/mutations/useCreateApEmailAliasMutation";
import useBusiness from "resources/business/queries/useBusiness";
import { notify } from "ui/feedback/Toast";
import TextInputV2 from "ui/inputs/TextInputV2";
import ModalV4 from "ui/overlay/ModalV4";
import { Span } from "ui/typography";
import useHasPermission from "utils/permissions/useHasPermission";
import { z } from "zod";

const makeDefaultUsernameForApEmailAlias = (business: BusinessRep.Complete) => {
  const { displayName } = business;

  if (!displayName) {
    return "";
  }

  return displayName
    .toLowerCase()
    .replace(/[\s_]+/g, "")
    .replace(/[^a-z0-9\s-]/g, "");
};

const onboardingFormSchema = z.object({
  username: z
    .string()
    .nonempty()
    .regex(/^[a-zA-Z0-9-_]+$/),
});

type OnboardingFormInputs = z.infer<typeof onboardingFormSchema>;

type Props = {
  onClose: () => void;
  onComplete: () => void;
};

const BillPayOnboardingModal: FC<Props> = ({ onClose, onComplete }) => {
  const baseId = useId();
  const business = useBusiness();
  const canCreateApEmailAlias = useHasPermission("accountsPayableBill:write");

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<OnboardingFormInputs>({
    resolver: zodResolver(onboardingFormSchema),
    defaultValues: {
      username: makeDefaultUsernameForApEmailAlias(business),
    },
    mode: "onChange",
  });

  const { mutate: createApEmailAlias, isPending: isCreatingApEmailAlias } =
    useCreateApEmailAliasMutation({
      onSuccess: () => {
        onComplete();
      },
      onError: (error) => {
        notify("error", error.message ?? "Failed to create inbox email address");
      },
    });

  const onSubmit = useCallback(
    ({ username }: OnboardingFormInputs) => {
      if (isCreatingApEmailAlias || !canCreateApEmailAlias) return;
      createApEmailAlias({ username });
    },
    [createApEmailAlias, isCreatingApEmailAlias, canCreateApEmailAlias]
  );

  return (
    <ModalV4 onClose={onClose}>
      <ModalV4.Form onSubmit={handleSubmit(onSubmit)}>
        <ModalV4.Header>Create inbox email address</ModalV4.Header>
        <ModalV4.Body>
          <ModalV4.Paragraph>
            Once you create your inbox email address, you can forward invoices to the address to
            automatically create bills for your review.
          </ModalV4.Paragraph>
          <div className="mt-6 flex flex-col gap-2">
            <Controller
              name="username"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <LabelPrimitive.Root
                    htmlFor={`${baseId}-username`}
                    className="text-sm font-medium text-grey-800"
                  >
                    Email address
                  </LabelPrimitive.Root>
                  <TextInputV2
                    id={`${baseId}-username`}
                    endAdornment={
                      <Span className="text-sm text-grey-500">{`@${env.AP_EMAIL_ALIAS_DOMAIN}`}</Span>
                    }
                    showErrorOutline={Boolean(fieldState.error)}
                    {...field}
                  />
                </>
              )}
            />
          </div>
        </ModalV4.Body>
        <ModalV4.Footer>
          <ModalV4.SubmitButton
            disabled={!isValid || !canCreateApEmailAlias}
            isLoading={isCreatingApEmailAlias}
            tooltip={
              !canCreateApEmailAlias
                ? "You don’t have permission to create an email address."
                : undefined
            }
          >
            Save email address
          </ModalV4.SubmitButton>
        </ModalV4.Footer>
      </ModalV4.Form>
    </ModalV4>
  );
};

export default BillPayOnboardingModal;
