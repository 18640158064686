import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUserRoleMembershipsQueryOptions from "resources/user-roles/queries/useUserRoleMembershipsQueryOptions";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import {
  useCloseEditUserRoleModal,
  useEditUserRoleModalOpenState,
} from "../state/editUserRoleModalState";

type Variables = {
  userRoleGuid: string;
};

const useEditUserRoleMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const closeEditUserRoleModal = useCloseEditUserRoleModal();
  const refreshUserRoleMembershipsQuery = useRefreshQuery(
    useUserRoleMembershipsQueryOptions().queryKey
  );
  const { userGuid } = useEditUserRoleModalOpenState();

  return useMutation({
    mutationFn: (variables: Variables) => {
      return highbeamApi.userRoleMembership.setByUser(businessGuid, userGuid, [
        { userRoleGuid: variables.userRoleGuid },
      ]);
    },
    onSuccess: async () => {
      await refreshUserRoleMembershipsQuery();
      closeEditUserRoleModal();
    },
  });
};

export default useEditUserRoleMutation;
