import successGif from "assets/success.gif";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import colors from "styles/colors";
import ModalV3 from "ui/overlay/ModalV3";
import Text from "ui/typography/Text";

import editUserSuccessfulModalState, {
  useEditUserSuccessfulModal,
} from "./state/editUserSuccessfulModalState";

const EditUserSuccessfulModal: FC = () => {
  const { close: onClose } = useEditUserSuccessfulModal();

  // NB(alex): need to fix this pattern to share context throughout the modal when open
  const state = useRecoilValue(editUserSuccessfulModalState);

  return (
    <ModalV3 isOpen={state.isOpen} onClose={onClose}>
      {state.isOpen && (
        <>
          <ModalV3.Header onClose={onClose}>Change user role</ModalV3.Header>

          <ModalV3.Body>
            <img src={successGif} alt="success" width={70} height={70} />
            <Text color={colors.grey[800]} size={16} weight="bold">
              {state.displayName}’s role has been changed to {state.roleName}
            </Text>
            <Text color={colors.grey[600]} size={16} weight="regular">
              Please allow up to 10 minutes for this change to take effect.
            </Text>
          </ModalV3.Body>

          <ModalV3.Footer>
            <ModalV3.Footer.SubmitButton onClick={onClose}>Close</ModalV3.Footer.SubmitButton>
          </ModalV3.Footer>
        </>
      )}
    </ModalV3>
  );
};

export default EditUserSuccessfulModal;
