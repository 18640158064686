import { RecurringPayment } from "@highbeam/unit-node-sdk";
import isRecurring from "resources/unit-co-recurring-payments/utils/isRecurring";
import Pill, { PillColor } from "ui/data-display/Pill";

type StatusLabel = {
  text: string;
  pillColor: PillColor;
};

const getLabel = (recurringPayment: RecurringPayment): StatusLabel | undefined => {
  switch (recurringPayment.attributes.status) {
    case "Active":
      if (isRecurring(recurringPayment)) {
        return {
          text: "Recurring",
          pillColor: "blue",
        };
      } else {
        return {
          text: "Scheduled",
          pillColor: "purple-light",
        };
      }
    case "Disabled":
      return {
        text: "Disabled",
        pillColor: "orange",
      };
    case "Completed":
      return {
        text: "Completed",
        pillColor: "green",
      };
    default:
      return undefined;
  }
};

type Props = {
  recurringPayment: RecurringPayment;
  className?: string;
};

const RecurringPaymentStatusPill: React.FC<Props> = ({ recurringPayment, className }) => {
  const label = getLabel(recurringPayment);

  if (!label) return null;

  return (
    <Pill color={label.pillColor} bordered className={className}>
      {label.text}
    </Pill>
  );
};

export default RecurringPaymentStatusPill;
