import { Archive } from "@phosphor-icons/react";
import Text from "ui/typography/Text";

import styles from "./HighbeamCard.module.scss";

const HighbeamCardArchived = () => (
  <div className={styles.archivedContainer}>
    <Archive size={12} weight={"bold"} className={styles.archivedColor} />
    <Text size={12} weight={"bold"} className={styles.archivedColor}>
      Archived
    </Text>
  </div>
);

export default HighbeamCardArchived;
