import { Info } from "@phosphor-icons/react";
import colors from "styles/colors";
import ItemWithTooltip from "ui/overlay/Tooltip/ItemWithTooltip/ItemWithTooltip";
import Text from "ui/typography/Text";

import styles from "./PayeeInfo.module.scss";

export type Recipient = {
  name: string;
  email?: string;
  isEmailToggled: boolean;
};

type Props = {
  to: Recipient;
  infoText: string;
};

const PayeeInfo: React.FC<Props> = ({ to, infoText }) => (
  <div>
    <Text size={14} weight="medium">
      {to.name}
    </Text>
    {to.isEmailToggled && to.email && (
      <div className={styles.recipientInfo}>
        <Text color={colors.grey[600]}>{to.email}</Text>
        <ItemWithTooltip
          wrapperClassName={styles.recipientInfo}
          content={<Text size={14}>{infoText}</Text>}
        >
          <Info color={colors.grey[600]}></Info>
        </ItemWithTooltip>
      </div>
    )}
  </div>
);

export default PayeeInfo;
