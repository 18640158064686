import React from "react";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text from "ui/typography/Text";

type Props = {
  children: number;
};

const SpendByVendorTableNumericColumn: React.FC<Props> = ({ children }) => (
  <Text color={colors.grey[900]} numeric size={14} weight="medium">
    <MoneyAmount cents={children} withCents={false} />
  </Text>
);

export default SpendByVendorTableNumericColumn;
