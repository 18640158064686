import { EnvelopeSimple, FileText, Files, ArrowLineUp } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC, useEffect, useMemo, useState } from "react";
import { ErrorCode } from "react-dropzone";
import BillDocumentRep from "reps/BillDocumentRep";
import BillSummaryRep from "reps/BillSummaryRep";
import BillDocumentDisplay from "resources/bill-documents/components/BillDocumentDisplay";
import BillDocumentFilePreviewWithLabel from "resources/bill-documents/components/BillDocumentFilePreviewWithLabel";
import useUploadBillDocumentsMutation from "resources/bill-documents/mutations/useUploadBillDocumentsMutation";
import useUploadBillInvoiceDocumentMutation from "resources/bill-documents/mutations/useUploadBillInvoiceDocumentMutation";
import { useBillDocumentsQuery } from "resources/bill-documents/queries/useBillDocuments";
import BillEmailDetails from "resources/bill-emails/components/BillEmailDetails";
import useBill from "resources/bills/queries/useBill";
import getIsBillBlank from "resources/bills/utils/getIsBillBlank";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import NoFilesUploaded from "ui/inputs/NoFilesUploaded";
import SegmentControls from "ui/navigation/SegmentControls";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";
import useElementSize from "utils/device/useElementSize";
import useSearchParamOption from "utils/search-params/useSearchParamOption";
import { TabConfig } from "utils/tabs/useTabState";
import getObjectKeys from "utils/ts/getObjectKeys";

import styles from "./BillDetailsDocumentsView.module.scss";
import BillDetailsDocumentsViewAddDocumentsButton from "./BillDetailsDocumentsViewAddDocumentsButton";

const useDocumentsTabs = (
  bill: BillSummaryRep.Complete,
  billOtherDocuments?: BillDocumentRep.Complete[]
) => {
  const invoiceTab = {
    icon: <FileText />,
    label: "Invoice",
  };
  const emailTab = {
    icon: <EnvelopeSimple />,
    label: "Email",
  };
  const otherFilesTab = {
    icon: <Files />,
    label: "Other files",
    count: billOtherDocuments?.length || undefined,
  };

  // Only include the email tab if the bill has an associated bill email.
  const tabs: TabConfig = bill.billEmailId
    ? {
        invoice: invoiceTab,
        email: emailTab,
        "other-files": otherFilesTab,
      }
    : {
        invoice: invoiceTab,
        "other-files": otherFilesTab,
      };

  const [activeTab, setActiveTab] = useSearchParamOption(
    "document",
    getObjectKeys(tabs),
    "invoice"
  );

  return {
    tabs: tabs,
    activeTab: activeTab,
    setActiveTab: setActiveTab,
  };
};

type UploadInvoiceConfirmationModalProps = {
  file: File;
  onConfirm: () => void;
  onClose: () => void;
};

const UploadInvoiceConfirmationModal: FC<UploadInvoiceConfirmationModalProps> = ({
  file,
  onConfirm,
  onClose,
}) => {
  return (
    <ModalV4 onClose={onClose}>
      <ModalV4.Header>Update details from invoice?</ModalV4.Header>
      <ModalV4.Body>
        <ModalV4.Paragraph>
          The uploaded invoice{" "}
          <Text as="strong">
            {"{"}
            {file.name}
            {"}"}
          </Text>{" "}
          contains new bill details. Select &ldquo;Update details&rdquo; to overwrite the current
          bill details.
        </ModalV4.Paragraph>
      </ModalV4.Body>
      <ModalV4.Footer>
        <Button variant="primary" onClick={() => onConfirm()}>
          Update details
        </Button>
        <ModalV4.CloseButton>Close</ModalV4.CloseButton>
      </ModalV4.Footer>
    </ModalV4>
  );
};

type Props = {
  billId: string;
};

const BillDetailsDocumentsView: FC<Props> = ({ billId }) => {
  const { elementRef: containerRef, width: containerWidth = 0 } = useElementSize();
  const [controlsOpacity, setControlsOpacity] = useState(1);
  const [_scrollY, setScrollY] = useState(0);

  const { data: billInvoiceDocuments, isSuccess: hasSuccessfullyLoadedBillInvoiceDocuments } =
    useBillDocumentsQuery({
      billId,
      type: [BillDocumentRep.DocumentType.Invoice],
    });

  const { data: billOtherDocuments, isSuccess: hasSuccessfullyLoadedBillOtherDocuments } =
    useBillDocumentsQuery({
      billId,
      type: [BillDocumentRep.DocumentType.Other],
    });

  const bill = useBill(billId, { required: true });
  const { billEmailId } = bill;
  const isBillBlank = getIsBillBlank(bill);

  const { tabs, activeTab, setActiveTab } = useDocumentsTabs(bill, billOtherDocuments);

  const billDocuments = useMemo(
    () => (activeTab === "invoice" ? billInvoiceDocuments : billOtherDocuments) ?? [],
    [activeTab, billInvoiceDocuments, billOtherDocuments]
  );
  const hasSuccessfullyLoadedBillDocuments =
    activeTab === "invoice"
      ? hasSuccessfullyLoadedBillInvoiceDocuments
      : hasSuccessfullyLoadedBillOtherDocuments;

  const { mutate: uploadBillDocuments, isPending: isUploadingBillDocuments } =
    useUploadBillDocumentsMutation();

  const { mutate: uploadBillInvoice, isPending: isUploadingBillInvoice } =
    useUploadBillInvoiceDocumentMutation(billId);

  const [activeBillDocument, setActiveBillDocument] = useState<BillDocumentRep.Complete>();

  // Set first document as the default once the query loads.
  useEffect(() => {
    if (billDocuments.length > 0) {
      if (!billDocuments.some(({ id }) => id === activeBillDocument?.id)) {
        setActiveBillDocument(billDocuments[0]);
      }
    }
  }, [activeBillDocument, billDocuments]);

  const showPreviewDocuments =
    hasSuccessfullyLoadedBillDocuments &&
    billDocuments.length > 0 &&
    (activeTab === "invoice" || activeTab === "other-files");

  const [confirmUploadInvoiceFile, setConfirmUploadInvoiceFile] = useState<File | null>(null);

  return (
    <div
      ref={containerRef}
      className={styles.container}
      onScroll={(e) => {
        const nextScrollY = e.currentTarget.scrollTop;
        setScrollY((prevScrollY) => {
          // Transitions the controls opacity based on scroll direction
          if (nextScrollY === 0) {
            setControlsOpacity(1);
          } else if (prevScrollY > nextScrollY) {
            setControlsOpacity((prev) => Math.min(prev + 0.16, 1));
          } else {
            setControlsOpacity((prev) => Math.max(prev - 0.08, 0));
          }
          return nextScrollY;
        });
      }}
    >
      <div
        style={{
          opacity: controlsOpacity,
          pointerEvents: controlsOpacity === 0 ? "none" : "auto",
        }}
        className={styles.headerContainer}
      >
        <div
          className={classNames(
            styles.segmentControlContainer,
            showPreviewDocuments && styles.hasDocumentPreviews
          )}
        >
          {/* NB(lev): Doing some hacky type casts here because Typescript is
          getting confused by the keys of tabs, which are dynamic because "email" tab
          is included conditionally. */}
          <SegmentControls
            size="md"
            activeTab={activeTab}
            setActiveTab={setActiveTab as (tab: string) => void}
            tabs={tabs}
          />
        </div>

        {showPreviewDocuments && (
          <div className={styles.filePreviewsContainer}>
            {billDocuments.map((billDocument) => {
              return (
                <BillDocumentFilePreviewWithLabel
                  key={billDocument.id}
                  billDocument={billDocument}
                  onClick={() => setActiveBillDocument(billDocument)}
                  isActive={activeBillDocument?.id === billDocument.id}
                />
              );
            })}

            {activeTab === "other-files" && (
              <div className={styles.addFilesButtonContainer}>
                <BillDetailsDocumentsViewAddDocumentsButton billId={billId} />
              </div>
            )}
          </div>
        )}
      </div>

      <div className={styles.documentContainer}>
        {activeTab === "invoice" && (
          <>
            {showPreviewDocuments && !isUploadingBillInvoice ? (
              <>
                {activeBillDocument && (
                  <BillDocumentDisplay
                    billDocument={activeBillDocument}
                    width={Math.min(containerWidth - 32, 900)}
                  />
                )}
              </>
            ) : (
              <NoFilesUploaded
                heading="No invoice"
                subheading={
                  isBillBlank
                    ? "Upload or drag and drop an invoice to auto-fill missing bill details."
                    : undefined
                }
                uploadFileButton={
                  <NoFilesUploaded.UploadFileButton>
                    <ArrowLineUp size={16} />
                    Upload invoice
                  </NoFilesUploaded.UploadFileButton>
                }
                fileTypes={["pdf", "image"]}
                maxFiles={activeTab === "invoice" ? 1 : undefined}
                onDropRejected={(fileRejections) => {
                  if (fileRejections[0]?.errors[0]?.code === ErrorCode.TooManyFiles) {
                    notify("error", `You can only upload 1 invoice.`);
                  } else {
                    notify("error", "Something went wrong. Please try again or contact support.");
                  }
                }}
                onDropAccepted={async (files) => {
                  if (isBillBlank) {
                    uploadBillInvoice({
                      file: files[0],
                    });
                  } else {
                    setConfirmUploadInvoiceFile(files[0]);
                  }
                }}
                isLoading={isUploadingBillInvoice}
                loadingText={
                  isBillBlank ? (
                    <NoFilesUploaded.LoadingText>
                      Auto-filling bill details&hellip;
                    </NoFilesUploaded.LoadingText>
                  ) : null
                }
              />
            )}

            {confirmUploadInvoiceFile && (
              <UploadInvoiceConfirmationModal
                file={confirmUploadInvoiceFile}
                onConfirm={() => {
                  uploadBillInvoice({
                    file: confirmUploadInvoiceFile,
                  });
                  setConfirmUploadInvoiceFile(null);
                }}
                onClose={() => setConfirmUploadInvoiceFile(null)}
              />
            )}
          </>
        )}

        {activeTab === "email" && billEmailId && <BillEmailDetails billEmailId={billEmailId} />}

        {activeTab === "other-files" && (
          <>
            {showPreviewDocuments ? (
              <>
                {activeBillDocument && (
                  <BillDocumentDisplay
                    billDocument={activeBillDocument}
                    width={Math.min(containerWidth - 32, 900)}
                  />
                )}
              </>
            ) : (
              <NoFilesUploaded
                fileTypes={["pdf", "image"]}
                onDropRejected={() => {
                  notify("error", "Something went wrong. Please try again or contact support.");
                }}
                onDropAccepted={async (files) => {
                  uploadBillDocuments({
                    files,
                    billId,
                    type: BillDocumentRep.DocumentType.Other,
                  });
                }}
                isLoading={isUploadingBillDocuments}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BillDetailsDocumentsView;
