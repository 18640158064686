import TransactionFlexpaneDispute from "components/common/transaction-flexpane/TransactionFlexpaneDispute";
import { FC } from "react";
import Flexpane from "ui/overlay/Flexpane";

import CardAuthorizationAmount from "./components/CardAuthorizationAmount";
import CardAuthorizationDetails from "./components/CardAuthorizationDetails";
import CardAuthorizationParties from "./components/CardAuthorizationParties";
import CardAuthorizationFlexpaneProvider from "./context/CardAuthorizationFlexpaneProvider";

type Props = {
  authorizationId?: string;
  onClose: () => void;
};

const CardAuthorizationFlexpane: FC<Props> = ({ authorizationId, onClose }) => {
  return (
    <Flexpane isOpen={Boolean(authorizationId)} onClose={onClose}>
      {authorizationId && (
        <CardAuthorizationFlexpaneProvider authorizationId={authorizationId} onClose={onClose}>
          <Flexpane.Header onClose={onClose}>Transaction info</Flexpane.Header>

          <Flexpane.Section>
            <CardAuthorizationParties />
          </Flexpane.Section>

          <Flexpane.Section>
            <CardAuthorizationAmount />
          </Flexpane.Section>

          <Flexpane.Section>
            <CardAuthorizationDetails />
          </Flexpane.Section>

          <Flexpane.Section>
            <TransactionFlexpaneDispute />
          </Flexpane.Section>
        </CardAuthorizationFlexpaneProvider>
      )}
    </Flexpane>
  );
};

export default CardAuthorizationFlexpane;
