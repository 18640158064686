import { FC } from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { TextSize } from "ui/typography/Text/TextTypes";
import cn from "utils/tailwind/cn";

import MoneyAmount from "../MoneyAmount";

type MoneyAmountFractionVariant = "inline-text" | "prominent" | "headline";

const gapClassNameByVariant: { [key in MoneyAmountFractionVariant]: string } = {
  "inline-text": "gap-1",
  prominent: "gap-2",
  headline: "gap-2",
};

const numeratorSizeByVariant: { [key in MoneyAmountFractionVariant]: TextSize } = {
  "inline-text": 14,
  prominent: 18,
  headline: 28,
};

const denominatorSizeByVariant: { [key in MoneyAmountFractionVariant]: TextSize } = {
  "inline-text": 14,
  prominent: 14,
  headline: 16,
};

type Props = {
  variant?: MoneyAmountFractionVariant;
  numeratorInCents: number;
  denominatorInCents: number;
  numeratorColor?: string;
  denominatorColor?: string;
  numeratorSize?: TextSize;
  denominatorSize?: TextSize;
  className?: string;
};

const MoneyAmountFraction: FC<Props> = ({
  variant = "inline-text",
  numeratorInCents,
  denominatorInCents,
  numeratorColor,
  denominatorColor = colors.grey[500],
  numeratorSize = numeratorSizeByVariant[variant],
  denominatorSize = denominatorSizeByVariant[variant],
  className,
}) => {
  return (
    <span className={cn("flex items-end leading-none", gapClassNameByVariant[variant], className)}>
      <MoneyAmount
        cents={numeratorInCents}
        color={numeratorColor}
        size={numeratorSize}
        weight="medium"
        withCents={numeratorInCents !== denominatorInCents}
      />
      <Text
        as="span"
        size={denominatorSize}
        color={denominatorColor}
        weight="medium"
        className={cn(variant === "headline" && "mb-0.5")}
      >
        {" / "}
      </Text>
      <MoneyAmount
        weight="medium"
        cents={denominatorInCents}
        color={denominatorColor}
        size={denominatorSize}
        withCents={denominatorInCents % 100 !== 0}
        className={cn(variant === "headline" && "mb-0.5")}
      />
    </span>
  );
};

export default MoneyAmountFraction;
