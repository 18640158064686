import { useSuspenseQuery, useQueryClient } from "@tanstack/react-query";
import HighbeamApi from "api/HighbeamApi";
import AccountingAccountRep from "reps/AccountingAccountRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import makeAccountingAccountsGroups from "../utils/makeAccountingAccountsGroups";

const makeQueryKey = (businessGuid: string) => ["accounting-accounts", { businessGuid }];

const makeQueryFn = (highbeamApi: HighbeamApi, businessGuid: string) => () =>
  highbeamApi.accountingAccount.search(businessGuid);

const useAccountingAccountsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: makeQueryKey(businessGuid),
    queryFn: makeQueryFn(highbeamApi, businessGuid),
  });
};

const useAccountingAccounts = () => {
  const { data } = useSuspenseQuery(useAccountingAccountsQueryOptions());
  return data;
};

const makeGroupsQueryKey = (businessGuid: string) => [
  "accounting-accounts-groups",
  { businessGuid },
];

const useAccountingAccountsGroupsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const queryClient = useQueryClient();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: makeGroupsQueryKey(businessGuid),
    queryFn: async () => {
      const accountingAccounts: AccountingAccountRep.Complete[] = await queryClient.ensureQueryData(
        {
          queryKey: makeQueryKey(businessGuid),
          queryFn: makeQueryFn(highbeamApi, businessGuid),
        }
      );
      return makeAccountingAccountsGroups(accountingAccounts);
    },
  });
};

// The useAccountingGroupsQuery is derived from the same query as useAccountingAccounts,
// but rather than returning the list of accounting accounts, it transforms the raw list
// into a list of "accounting accounts groups", each containing a list of accounting accounts
// belonging to the same category. Groups are sorted by category, with "expense" category
// always being first. Accounting accounts within each group are based on their hierarchy
// (e.g. a parent account will always come before its child account), with siblings
// sorted alphabetically. The accounting accounts within each group are also annotated
// with their depth in the hierarchy, so that they can be rendered with the appropriate
// indentation level.
// See makeAccountingAccountsGroups for more details.
export const useAccountingAccountsGroups = () => {
  const { data } = useSuspenseQuery(useAccountingAccountsGroupsQueryOptions());
  return data;
};

export default useAccountingAccounts;
