import { Card } from "@highbeam/unit-node-sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { Controller } from "react-hook-form";
import BankAccountsDropdown from "resources/bank-accounts/components/BankAccountsDropdown";
import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import BusinessMemberDropdownLabel from "resources/business-members/components/BusinessMemberDropdownLabel";
import { ActiveCapitalAccountWithChargeCard } from "resources/capital-accounts/hooks/useActiveCapitalAccountsWithChargeCard";
import SpendLimitFieldset, {
  SpendLimitFieldsetForm,
} from "resources/card-spend-limit/components/SpendLimitFieldset";
import cardsQueryHooks from "resources/cards/queries/cardsQueryHooks";
import { CardCreditOrDebit, CardVirtualOrPhysical } from "resources/cards/types";
import {
  checkIsCreditCard,
  checkIsDebitCard,
  checkIsPhysicalCard,
} from "resources/cards/utils/typeguards";
import chargeCardAccountQueryHooks from "resources/charge-cards/queries/chargeCardAccountQueryHooks";
import useUsersTableData, {
  UsersTableDatum,
} from "resources/users-table-data/queries/useUsersTableData";
import Button from "ui/inputs/Button";
import DateInputFieldset from "ui/inputs/DateInputFieldset";
import DropdownV2 from "ui/inputs/DropdownV2";
import Helper from "ui/inputs/Helper";
import TextInputV2 from "ui/inputs/TextInputV2";
import ModalV4 from "ui/overlay/ModalV4";
import ItemWithTooltip from "ui/overlay/Tooltip/ItemWithTooltip/ItemWithTooltip";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import Text from "ui/typography/Text";
import { getDollarsFromCents } from "utils/money";
import { FormSubmitHandlerProps } from "utils/react-hook-form/GetUseFormSubmitHandler";

import { SectionHeading } from "../headings";

import useCreateCardModalCardDetailsForm, {
  CreateCardModalCardDetailsFormInputs,
  CreateCardModalCardDetailsFormOutputs,
} from "./useCreateCardModalCardDetailsForm";

type GetIsCardholderOptionDisabledReasonParams = {
  user: UsersTableDatum;
  cards: Card[];
};

type DisabledReason = "invited" | "not-onboarded" | "card-exists";

const getIsCardholderOptionDisabledReason = ({
  user,
  cards,
}: GetIsCardholderOptionDisabledReasonParams): DisabledReason | null => {
  if (user.isInvited) return "invited";
  if (!user.isOnboarded) return "not-onboarded";
  if (
    !user.isInvited &&
    cards.some((card) => card.attributes.tags.businessMemberGuid! === user.guid)
  ) {
    return "card-exists";
  }
  return null;
};

const TooltipContent: FC<{
  reason: DisabledReason;
}> = ({ reason }) => {
  switch (reason) {
    case "invited":
    case "not-onboarded":
      return <Tooltip.Content>This user has not finished onboarding yet.</Tooltip.Content>;
    case "card-exists":
      return <Tooltip.Content>This user already has a physical card.</Tooltip.Content>;
  }
};

const getDefaultCardName = (cardholder: UsersTableDatum) => {
  return `${cardholder.fullName}’s card`;
};

type Props = FormSubmitHandlerProps<typeof useCreateCardModalCardDetailsForm> & {
  onBack: () => void;
  nextButtonText: string;
  isSubmitLoading: boolean;
  creditOrDebit: CardCreditOrDebit;
  virtualOrPhysical: CardVirtualOrPhysical;
  selectedCapitalAccount?: ActiveCapitalAccountWithChargeCard;
  defaultCardName?: string;
  submittedDataDefaultValues: CreateCardModalCardDetailsFormOutputs | null;
};

const CreateCardModalCardDetailsForm: FC<Props> = ({
  onValid,
  onInvalid,
  onBack,
  nextButtonText,
  isSubmitLoading,
  creditOrDebit,
  virtualOrPhysical,
  selectedCapitalAccount,
  defaultCardName,
  submittedDataDefaultValues,
}) => {
  const chargeCardAccount = chargeCardAccountQueryHooks.useData({
    capitalAccountGuid: selectedCapitalAccount?.guid ?? null,
  });

  const cardsQueryKey = cardsQueryHooks.useQueryKey({ status: "default" });
  const cardsQueryFn = cardsQueryHooks.useQueryFn({ status: "default" });
  const { data: existingCards } = useSuspenseQuery({
    queryKey: [...cardsQueryKey, { virtualOrPhysical, chargeCardAccount, creditOrDebit }],
    queryFn: async (context) => {
      // Only enabled for physical cards.
      if (virtualOrPhysical !== "physical") {
        return [];
      }
      const cards = await cardsQueryFn(context);

      // "1 physical debit card per business member and 1 physical credit card per charge card account"
      // https://highbeamco.slack.com/archives/C04D9AQ02KW/p1728590244175989?thread_ts=1728589623.013769&cid=C04D9AQ02KW
      return cards.filter((card) => {
        if (creditOrDebit === "debit") {
          return checkIsDebitCard(card) && checkIsPhysicalCard(card);
        } else {
          return (
            checkIsCreditCard(card) &&
            checkIsPhysicalCard(card) &&
            chargeCardAccount &&
            card.attributes.tags.creditAccountGuid === chargeCardAccount.guid
          );
        }
      });
    },
  });

  const users = useUsersTableData();
  const openBankAccounts = bankAccountsQueryHooks.useData({ status: "open" });

  const defaultCardholder = useMemo(() => {
    const currentUser = users.find((user) => user.isCurrentUser);

    // Default to the current user if not disabled.
    if (
      currentUser &&
      !getIsCardholderOptionDisabledReason({ user: currentUser, cards: existingCards })
    ) {
      return currentUser;
    }

    // Otherwise, default to the first user that is not disabled.
    return (
      users.find((user) => {
        return !getIsCardholderOptionDisabledReason({ user, cards: existingCards });
      }) ?? null
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const defaultSpendLimit: CreateCardModalCardDetailsFormInputs["spendLimit"] = useMemo(() => {
    if (submittedDataDefaultValues?.spendLimit) {
      if (submittedDataDefaultValues.spendLimit.enabled) {
        return {
          enabled: true,
          period: submittedDataDefaultValues.spendLimit.period,
          amount: getDollarsFromCents(submittedDataDefaultValues.spendLimit.amount).toString(),
        };
      } else {
        return {
          enabled: false,
        };
      }
    } else {
      return {
        enabled: true,
        period: "monthly",
        amount: "",
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const form = useCreateCardModalCardDetailsForm({
    defaultValues: {
      cardholder: defaultCardholder,
      cardholderDateOfBirth: null,
      cardName: defaultCardName ?? (defaultCardholder ? getDefaultCardName(defaultCardholder) : ""),
      associatedBankAccount: openBankAccounts[0],
      ...submittedDataDefaultValues,
      spendLimit: defaultSpendLimit,
    },
  });

  const selectedCardholder = form.watch("cardholder");

  return (
    <ModalV4.Form onSubmit={form.handleSubmit(onValid, onInvalid)}>
      <ModalV4.Body className="flex flex-col gap-6">
        <div>
          <SectionHeading className="mb-3">Cardholder</SectionHeading>

          <Controller
            control={form.control}
            name="cardholder"
            render={({ field: { onChange, ...field } }) => (
              <DropdownV2
                {...field}
                onChange={(value) => {
                  onChange(value);
                  form.setValue("cardName", value ? getDefaultCardName(value) : "");
                }}
                // In the unlikely case there isn't a default value, we want to show a placeholder. This will likely result in the user seeing a list of disabled user options but the tooltip will explain why they are disabled.
                placeholder="Select a cardholder"
                options={users}
                getOptionLabel={(option) => option.displayName}
                isOptionDisabled={(option) =>
                  Boolean(
                    getIsCardholderOptionDisabledReason({
                      user: option,
                      cards: existingCards,
                    })
                  )
                }
                renderOption={(optionProps) => {
                  const user = optionProps.data;
                  const isDisabled = optionProps.isDisabled;
                  const disabledReason = getIsCardholderOptionDisabledReason({
                    user,
                    cards: existingCards,
                  });

                  return (
                    <ItemWithTooltip
                      shouldRenderTooltip={isDisabled}
                      content={disabledReason ? <TooltipContent reason={disabledReason} /> : null}
                    >
                      <DropdownV2.Option {...optionProps}>
                        <BusinessMemberDropdownLabel
                          fullName={user.fullName}
                          userRoleName={user.userRoleName}
                          isOnboarded={!user.isInvited && user.isOnboarded}
                        />
                      </DropdownV2.Option>
                    </ItemWithTooltip>
                  );
                }}
                components={{
                  SingleValue: (singleValueProps) => {
                    const user = singleValueProps.data;

                    return (
                      <DropdownV2.SingleValue {...singleValueProps}>
                        <BusinessMemberDropdownLabel
                          fullName={
                            <>
                              {user.fullName}
                              {user.isInvited && <strong> (has not accepted invite yet)</strong>}
                            </>
                          }
                          userRoleName={user.userRoleName}
                          isOnboarded={!user.isInvited && user.isOnboarded}
                        />
                      </DropdownV2.SingleValue>
                    );
                  },
                }}
              />
            )}
          />

          {selectedCardholder &&
            !selectedCardholder.isInvited &&
            !selectedCardholder.dateOfBirth && (
              <div className="mt-4 flex flex-col gap-2">
                <Text size={14} weight="medium">
                  Date of birth
                </Text>

                <Controller
                  control={form.control}
                  name="cardholderDateOfBirth"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <DateInputFieldset
                        value={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        fieldsetErrorMessage={error?.message}
                      />
                    );
                  }}
                />
              </div>
            )}
        </div>

        <div>
          <SectionHeading className="mb-3">Card details</SectionHeading>

          <Controller
            control={form.control}
            name="cardName"
            render={({ field, fieldState: { error } }) => {
              return (
                <div>
                  <TextInputV2 label="Card name" {...field} />
                  {error && <Helper iconVariant="error">{error.message}</Helper>}
                </div>
              );
            }}
          />

          {creditOrDebit === "debit" && (
            <Controller
              control={form.control}
              name="associatedBankAccount"
              render={({ field }) => {
                return (
                  <div className="mt-4">
                    <BankAccountsDropdown
                      options={openBankAccounts}
                      label="Associated account"
                      {...field}
                    />
                    <Helper>The funds will be debited from the associated account.</Helper>
                  </div>
                );
              }}
            />
          )}

          <SpendLimitFieldset
            // NB(alex): Unfortunate work-around but this is safe because we share the same underlying schema.
            // Exemplar: An intentional type-cast for creating reusable fieldsets with react-hook-form + zod schema.
            form={form as unknown as SpendLimitFieldsetForm}
            className="mt-4 py-3"
          />
        </div>
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton isLoading={isSubmitLoading}>{nextButtonText}</ModalV4.SubmitButton>
        <Button onClick={onBack} variant="ghost">
          Back
        </Button>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

export default CreateCardModalCardDetailsForm;
