import { FC, ReactElement } from "react";
import IconTile, { IconType } from "ui/icons/IconTile";
import { Heading1, Paragraph } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import linesBgImg from "./assets/hero-banner-lines.webp";

const HeroBannerMain: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <div
    className={cn(
      "flex flex-col gap-4 p-6 tablet:px-[3.75rem] tablet:pb-28 tablet:pt-16",
      className
    )}
  >
    {children}
  </div>
);

type HeroBannerIconTileProps = {
  icon: ReactElement;
  className?: string;
};

const HeroBannerIconTile: FC<HeroBannerIconTileProps> = ({ icon, className }) => (
  <IconTile icon={icon} size={60} type={IconType.Circle} className={className} />
);

const HeroBannerHeading: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <Heading1 className={cn("font-bold", className)}>{children}</Heading1>
);

const HeroBannerParagraph: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <Paragraph className={cn("text-md text-grey-600", className)}>{children}</Paragraph>
);

const HeroBannerActions: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <div
    className={cn("mt-4 flex items-center justify-start gap-6 text-sm text-grey-600", className)}
  >
    {children}
  </div>
);

const HeroBannerShowcase: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <div
    className={cn(
      "bg-position-right absolute bottom-0 left-1/2 right-0 top-0 hidden rounded-e-xl pl-12 @[52rem]/hero-banner:block",

      className
    )}
  >
    <div
      aria-hidden="true"
      className="absolute inset-0 bg-cover opacity-70"
      style={{
        backgroundImage: `url(${linesBgImg})`,
      }}
    />
    <div className="relative size-full">{children}</div>
  </div>
);

const HeroBanner: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <div
    className={cn(
      "relative flex items-center justify-between rounded-xl border border-purple-100 bg-purple-50 @container/hero-banner",
      className
    )}
  >
    {children}
  </div>
);

export default Object.assign(HeroBanner, {
  Main: HeroBannerMain,
  IconTile: HeroBannerIconTile,
  Heading: HeroBannerHeading,
  Paragraph: HeroBannerParagraph,
  Actions: HeroBannerActions,
  Showcase: HeroBannerShowcase,
});
