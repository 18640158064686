import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import CreditApplicationRep from "reps/CreditApplicationRep";
import useNewCreditApplication from "resources/credit-application/queries/useNewCreditApplication";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

const useUpdateCreditApplication = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const newCreditApplication = useNewCreditApplication();

  return useMutation({
    mutationFn: (body: CreditApplicationRep.Updater) => {
      return highbeamApi.creditApplication.update(businessGuid, newCreditApplication.guid, body);
    },
    onError: (error) => {
      captureException(error);
      notify("info", "Failed to update the credit application. Please try again.");
    },
  });
};

export default useUpdateCreditApplication;
