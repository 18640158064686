import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import CreditApplicationRep from "reps/CreditApplicationRep";
import useCreditApplicationsQueryOptions from "resources/credit-application/queries/useCreditApplicationsQueryOptions";
import useNewCreditApplication from "resources/credit-application/queries/useNewCreditApplication";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const useSubmitCreditApplication = () => {
  const navigate = useNavigate();

  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const newCreditApplication = useNewCreditApplication();
  const refreshCreditApplications = useRefreshQuery(useCreditApplicationsQueryOptions().queryKey);

  // NB(alex): please consult #eng-frontend before using react-query
  return useMutation({
    mutationFn: (body: CreditApplicationRep.Updater) =>
      highbeamApi.creditApplication.submit(businessGuid, newCreditApplication.guid, body),
    onSuccess: async () => {
      await refreshCreditApplications();
      navigate("/capital");
    },
    onError: (error) => {
      captureException(error);
      // NB(alex): Shows a generic toast message. We should map this error message once we establish a better error message pattern.
      notify("info", "Failed to submit the credit application. Please try again.");
    },
  });
};

export default useSubmitCreditApplication;
