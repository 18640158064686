import { ArrowElbowDownRight as ArrowElbowDownRightIcon } from "@phosphor-icons/react";
import { FC, ComponentPropsWithoutRef, useMemo } from "react";
import AccountingAccountRep from "reps/AccountingAccountRep";
import Combobox from "ui/inputs/Combobox";
import { toTitleCase } from "utils/string";
import cn from "utils/tailwind/cn";

import { useAccountingAccountsGroups } from "../../queries/useAccountingAccounts";
import ContactSupportToConnectAccounting from "../ContactSupportToConnectAccounting";

type ItemDepthIndicatorProps = {
  depth: number;
};

const ItemDepthIndicator: FC<ItemDepthIndicatorProps> = ({ depth }) => (
  <span aria-hidden="true">
    {Array(depth - 1)
      .fill(0)
      .map((_, index) => (
        // The index key here is fine. This is a static array.
        <span key={index} className="ml-4"></span>
      ))}{" "}
    <ArrowElbowDownRightIcon className="mr-1 h-4 w-4 text-grey-400" />
  </span>
);

type Props = ComponentPropsWithoutRef<typeof Combobox> & {
  labelText?: string;
  clearable?: boolean;
  noAccountsDisplayVariant?: "contact-support" | "not-available";
};

const AccountingCategorySelect: FC<Props> = ({
  value,
  onValueChange,
  labelText = "Accounting category",
  clearable = false,
  disabled = false,
  noAccountsDisplayVariant = "contact-support",
  variant = "default",
  ...props
}) => {
  const accountingAccountsGroups = useAccountingAccountsGroups();
  const hasAccountingAccounts = accountingAccountsGroups.length > 0;

  const accountingAccountsLookup = useMemo(() => {
    const lookup = new Map<string, AccountingAccountRep.Complete>();
    accountingAccountsGroups.forEach(({ accountingAccountsWithDepth }) => {
      accountingAccountsWithDepth.forEach(([accountingAccount]) => {
        lookup.set(accountingAccount.id, accountingAccount);
      });
    });
    return lookup;
  }, [accountingAccountsGroups]);

  return (
    <Combobox
      clearable={clearable}
      disabled={disabled || !hasAccountingAccounts}
      value={value}
      onValueChange={onValueChange}
      variant={variant}
      {...props}
    >
      <Combobox.Field>
        {(hasAccountingAccounts || noAccountsDisplayVariant === "contact-support") && (
          <Combobox.Label>{labelText}</Combobox.Label>
        )}
        {!hasAccountingAccounts && noAccountsDisplayVariant === "not-available" ? (
          <Combobox.Trigger aria-label={labelText}>Not available</Combobox.Trigger>
        ) : (
          <Combobox.Trigger>{value && accountingAccountsLookup.get(value)?.name}</Combobox.Trigger>
        )}

        {!hasAccountingAccounts && noAccountsDisplayVariant === "contact-support" && (
          <ContactSupportToConnectAccounting
            className={cn("absolute right-4 top-2.5", variant === "minimal" && "top-1")}
          />
        )}
      </Combobox.Field>
      <Combobox.Menu>
        <Combobox.MenuEmptyState>No accounting categories found.</Combobox.MenuEmptyState>

        {accountingAccountsGroups.map(({ category, accountingAccountsWithDepth }) => (
          <Combobox.Group key={category} heading={toTitleCase(category)}>
            {accountingAccountsWithDepth.map(([accountingAccount, depth]) => (
              // Note that it's common for multiple accounting accounts to have the same name.
              // For example, an accounting account named "Foo" might exist in both "Expense" and
              // "Income" categories (as two discrete accounting accounts). Cmdk will auto-select
              // an item based on its value if the current search text matches the value (i.e. its
              // searchableText), which can cause it to auto-select multiple items if they have
              // the same value. To avoid this, we include both the name and category in the
              // searchableText.
              <Combobox.Item
                key={accountingAccount.id}
                value={accountingAccount.id}
                searchableText={`${accountingAccount.name} (${category})`}
                keywords={[category]}
                disabled={accountingAccount.status !== "active"}
              >
                {depth > 0 && <ItemDepthIndicator depth={depth} />}
                {accountingAccount.name}
              </Combobox.Item>
            ))}
          </Combobox.Group>
        ))}
      </Combobox.Menu>
    </Combobox>
  );
};

export default AccountingCategorySelect;
